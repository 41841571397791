import { __assign, __decorate } from "tslib";
import { action, observable, makeObservable } from "mobx";
import { getStatusCodeType, getContentWithLegacyExamples } from "../../utils";
import { FieldModel } from "./Field";
import { MediaContentModel } from "./MediaContent";

var ResponseModel = function () {
  function e(e) {
    var t = this;
    Object.defineProperty(this, "expanded", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "content", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "code", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "summary", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "description", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "headers", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    });
    var o = e.parser,
        r = e.code,
        i = e.defaultAsError,
        a = e.infoOrRef,
        n = e.options,
        s = e.isEvent,
        d = e.operation,
        l = !!s;
    makeObservable(this), this.expanded = "all" === n.expandResponses || n.expandResponses[r];
    var p = o.deref(a);
    o.exitRef(a), this.code = r;
    var b = getContentWithLegacyExamples(p);
    void 0 !== b && (this.content = new MediaContentModel(o, b, l, n, {
      operation: d,
      type: "response",
      response: this
    })), void 0 !== p["x-summary"] ? (this.summary = p["x-summary"], this.description = p.description || "") : (this.summary = p.description || "", this.description = ""), this.type = getStatusCodeType(r, i);
    var u = p.headers;
    void 0 !== u && (this.headers = Object.keys(u).map(function (e) {
      var r = u[e];
      return new FieldModel(o, __assign(__assign({}, r), {
        name: e
      }), "", n, void 0, {
        operation: d,
        type: "response",
        response: t
      });
    }));
  }

  return Object.defineProperty(e.prototype, "toggle", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.expanded = !this.expanded;
    }
  }), Object.defineProperty(e.prototype, "expand", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.expanded = !0;
    }
  }), __decorate([observable], e.prototype, "expanded", void 0), __decorate([action], e.prototype, "toggle", null), __decorate([action], e.prototype, "expand", null), e;
}();

export { ResponseModel };