import * as React from "react";
import { ClipboardService } from "../../redoc-lib/src/services";
export var SelectOnClick = function SelectOnClick(e) {
  var r,
      t = e.children,
      c = function c() {
    ClipboardService.selectElement(r);
  };

  return React.createElement("div", {
    ref: function ref(e) {
      return r = e;
    },
    onClick: c,
    onFocus: c,
    tabIndex: 0,
    role: "button"
  }, t);
};