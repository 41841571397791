import { __makeTemplateObject } from "tslib";
import { transparentize } from "polished";
import styled, { mediaCSS } from "../../styled-components";
export var DropdownLabel = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 12px;\n  position: absolute;\n  z-index: 1;\n  top: -11px;\n  left: 12px;\n  font-weight: ", ";\n  ", "\n"], ["\n  font-size: 12px;\n  position: absolute;\n  z-index: 1;\n  top: -11px;\n  left: 12px;\n  font-weight: ", ";\n  ", "\n"])), function (t) {
  return t.theme.typography.fontWeightBold;
}, function (t) {
  var e = t.theme;
  return mediaCSS({
    color: transparentize(.3, e.rightPanel.textColor),
    fontFamily: e.typography.headings.fontFamily
  });
});
export var DropdownWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 10px;\n"], ["\n  position: relative;\n  margin-bottom: 10px;\n"])));
export var ExampleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 5px;\n"], ["\n  margin-top: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;