import { __makeTemplateObject } from "tslib";
import * as React from "react";
import { darken } from "polished";
import { styled, MenuItemLabel } from "../../redoc-lib";
export var CrossIcon = function CrossIcon() {
  return React.createElement("svg", {
    version: "1.1",
    viewBox: "0 0 12 12",
    width: "12",
    height: "12",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    className: "clear-icon"
  }, React.createElement("path", {
    d: "M11 1L6 6L11 11",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), React.createElement("path", {
    d: "M1 1L6 6L1 11",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export var ButtonClose = styled.i.attrs(function () {
  return {
    className: "button-close"
  };
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  position: absolute;\n  right: 15px;\n  color: ", ";\n"], ["\n  cursor: pointer;\n  position: absolute;\n  right: 15px;\n  color: ", ";\n"])), function (n) {
  return n.theme.colors.text.primary;
});
export var ButtonClear = styled.i.attrs(function () {
  return {
    className: "button-clear"
  };
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  position: absolute;\n"], ["\n  cursor: pointer;\n  position: absolute;\n"])));
export var SearchIcon = function SearchIcon() {
  return React.createElement("svg", {
    version: "1.1",
    viewBox: "0 0 15 16",
    width: "15",
    height: "16",
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    className: "search-icon"
  }, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z",
    stroke: "currentColor",
    strokeWidth: "2"
  }), React.createElement("path", {
    d: "M7.5 5C6.11929 5 5 6.11929 5 7.5",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round"
  }), React.createElement("path", {
    d: "M1 15L3 13",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};
export var SearchFieldWrap = styled.button.attrs(function () {
  return {
    className: "button-field-search"
  };
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: text;\n  margin: 10px 20px 13px;\n  padding: 0 18px 0 3px;\n  border: 0;\n  position: relative;\n  border-bottom: 1px solid ", ";\n  background-color: transparent;\n  height: 37px;\n  color: ", ";\n  text-align: left;\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  flex: 0 0 auto;\n\n  span {\n    display: block;\n    position: absolute;\n    right: 0;\n    width: 18px;\n    height: 37px;\n    background-color: ", ";\n    z-index: 2;\n  }\n\n  .search-icon {\n    margin-left: 6px;\n    position: relative;\n    top: 2px;\n    z-index: 1;\n  }\n\n  .button-clear {\n    padding: 3px;\n    right: 0;\n    margin-top: 4px;\n    z-index: 3;\n  }\n"], ["\n  cursor: text;\n  margin: 10px 20px 13px;\n  padding: 0 18px 0 3px;\n  border: 0;\n  position: relative;\n  border-bottom: 1px solid ", ";\n  background-color: transparent;\n  height: 37px;\n  color: ", ";\n  text-align: left;\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  flex: 0 0 auto;\n\n  span {\n    display: block;\n    position: absolute;\n    right: 0;\n    width: 18px;\n    height: 37px;\n    background-color: ", ";\n    z-index: 2;\n  }\n\n  .search-icon {\n    margin-left: 6px;\n    position: relative;\n    top: 2px;\n    z-index: 1;\n  }\n\n  .button-clear {\n    padding: 3px;\n    right: 0;\n    margin-top: 4px;\n    z-index: 3;\n  }\n"])), function (n) {
  return n.theme.colors.border.light;
}, function (n) {
  return n.theme.sidebar.textColor;
}, function (n) {
  return n.theme.typography.fontFamily;
}, function (n) {
  return n.theme.sidebar.backgroundColor;
});
export var SearchPopUpBG = styled.div.attrs(function () {
  return {
    className: "search-popup"
  };
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 100;\n  background: rgba(0, 0, 0, 0.45);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 100;\n  background: rgba(0, 0, 0, 0.45);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var SearchPopUpWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 500px;\n  height: 445px;\n"], ["\n  width: 100%;\n  max-width: 500px;\n  height: 445px;\n"])));
export var SearchPopUpHeader = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: 45px;\n  display: flex;\n  align-items: center;\n  position: relative;\n\n  .search-icon {\n    position: absolute;\n    left: 15px;\n  }\n\n  .button-clear {\n    right: 45px;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 0.8em;\n    background-color: ", ";\n    padding: 4px 16px;\n    border-radius: ", ";\n    &:hover {\n      background-color: ", ";\n    }\n  }\n"], ["\n  background-color: ", ";\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  height: 45px;\n  display: flex;\n  align-items: center;\n  position: relative;\n\n  .search-icon {\n    position: absolute;\n    left: 15px;\n  }\n\n  .button-clear {\n    right: 45px;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 0.8em;\n    background-color: ", ";\n    padding: 4px 16px;\n    border-radius: ", ";\n    &:hover {\n      background-color: ", ";\n    }\n  }\n"])), function (n) {
  return n.theme.colors.secondary.light;
}, function (n) {
  return n.theme.components.panels.borderRadius;
}, function (n) {
  return n.theme.components.panels.borderRadius;
}, function (n) {
  return n.theme.colors.secondary.main;
}, function (n) {
  return n.theme.shape.borderRadius;
}, function (n) {
  var e = n.theme;
  return darken(.1, e.colors.secondary.main);
});
export var TextContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 30px 20px;\n"], ["\n  padding: 30px 20px;\n"])));
export var SearchResultsBox = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 10px;\n  background-color: #fff;\n  min-height: 100px;\n  max-height: 400px;\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n\n  ", " {\n    margin-right: 30px;\n    border-radius: ", ";\n\n    svg {\n      display: none;\n    }\n  }\n"], ["\n  padding: 10px;\n  background-color: #fff;\n  min-height: 100px;\n  max-height: 400px;\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n\n  ", " {\n    margin-right: 30px;\n    border-radius: ", ";\n\n    svg {\n      display: none;\n    }\n  }\n"])), function (n) {
  return n.theme.components.panels.borderRadius;
}, function (n) {
  return n.theme.components.panels.borderRadius;
}, MenuItemLabel, function (n) {
  return n.theme.shape.borderRadius;
});
export var SearchResultsItems = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  max-height: 372px;\n"], ["\n  max-height: 372px;\n"])));
export var SearchInput = styled.input.attrs(function () {
  return {
    className: "search-input"
  };
})(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 0 35px;\n  outline: none;\n  border: 0;\n  background-color: transparent;\n  width: 100%;\n  margin-top: 2px;\n"], ["\n  padding: 0 35px;\n  outline: none;\n  border: 0;\n  background-color: transparent;\n  width: 100%;\n  margin-top: 2px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;