"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoUnusedComponents = void 0;

var NoUnusedComponents = function NoUnusedComponents() {
  var components = new Map();

  function registerComponent(location, name) {
    var _a;

    components.set(location.absolutePointer, {
      used: ((_a = components.get(location.absolutePointer)) === null || _a === void 0 ? void 0 : _a.used) || false,
      location: location,
      name: name
    });
  }

  return {
    ref: function ref(_ref2, _ref) {
      var type = _ref.type,
          resolve = _ref.resolve,
          key = _ref.key,
          location = _ref.location;

      if (['Schema', 'Header', 'Parameter', 'Response', 'Example', 'RequestBody'].includes(type.name)) {
        var resolvedRef = resolve(_ref2);
        if (!resolvedRef.location) return;
        components.set(resolvedRef.location.absolutePointer, {
          used: true,
          name: key.toString(),
          location: location
        });
      }
    },
    DefinitionRoot: {
      leave: function leave(_, _ref3) {
        var report = _ref3.report;
        components.forEach(function (usageInfo) {
          if (!usageInfo.used) {
            report({
              message: "Component: \"".concat(usageInfo.name, "\" is never used."),
              location: usageInfo.location.key()
            });
          }
        });
      }
    },
    NamedSchemas: {
      Schema: function Schema(schema, _ref4) {
        var location = _ref4.location,
            key = _ref4.key;

        if (!schema.allOf) {
          // FIXME: find a better way to detect possible discriminator
          registerComponent(location, key.toString());
        }
      }
    },
    NamedParameters: {
      Parameter: function Parameter(_parameter, _ref5) {
        var location = _ref5.location,
            key = _ref5.key;
        registerComponent(location, key.toString());
      }
    },
    NamedResponses: {
      Response: function Response(_response, _ref6) {
        var location = _ref6.location,
            key = _ref6.key;
        registerComponent(location, key.toString());
      }
    },
    NamedExamples: {
      Example: function Example(_example, _ref7) {
        var location = _ref7.location,
            key = _ref7.key;
        registerComponent(location, key.toString());
      }
    },
    NamedRequestBodies: {
      RequestBody: function RequestBody(_requestBody, _ref8) {
        var location = _ref8.location,
            key = _ref8.key;
        registerComponent(location, key.toString());
      }
    },
    NamedHeaders: {
      Header: function Header(_header, _ref9) {
        var location = _ref9.location,
            key = _ref9.key;
        registerComponent(location, key.toString());
      }
    }
  };
};

exports.NoUnusedComponents = NoUnusedComponents;