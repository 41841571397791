import { __makeTemplateObject } from "tslib";
import { darken } from "polished";
import styled, { css, keyframes, mediaCSS } from "../../redoc-lib/src/styled-components";
var shake = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  10%, 90% {\n    transform: translate3d(-1px, 0, 0);\n  }\n\n  20%, 80% {\n    transform: translate3d(2px, 0, 0);\n  }\n\n  30%, 50%, 70% {\n    transform: translate3d(-4px, 0, 0);\n  }\n\n  40%, 60% {\n    transform: translate3d(4px, 0, 0);\n  }\n"], ["\n  10%, 90% {\n    transform: translate3d(-1px, 0, 0);\n  }\n\n  20%, 80% {\n    transform: translate3d(2px, 0, 0);\n  }\n\n  30%, 50%, 70% {\n    transform: translate3d(-4px, 0, 0);\n  }\n\n  40%, 60% {\n    transform: translate3d(4px, 0, 0);\n  }\n"])));
export var blink = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    background-color: ", ";\n  }\n\n  50% {\n    background-color: #59C3FF;\n  }\n\n  100% {\n    background-color: ", ";\n  }\n"], ["\n  0% {\n    background-color: ", ";\n  }\n\n  50% {\n    background-color: #59C3FF;\n  }\n\n  100% {\n    background-color: ", ";\n  }\n"])), darken(.3, "#59C3FF"), darken(.3, "#59C3FF"));
var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    templateObject_6,
    templateObject_7,
    shakeAnimation = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  animation: ", " 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;\n"], ["\n  animation: ", " 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;\n"])), shake);
export var ConsoleWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  font-family: ", ";\n  border-radius: ", ";\n  overflow: hidden;\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  max-height: calc(85vh - ", "px);\n  margin-bottom: 10px;\n  ", ";\n  > div:last-child {\n    border-top-left-radius: 0;\n    border-top-right-radius: 0;\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  font-family: ", ";\n  border-radius: ", ";\n  overflow: hidden;\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  max-height: calc(85vh - ", "px);\n  margin-bottom: 10px;\n  ", ";\n  > div:last-child {\n    border-top-left-radius: 0;\n    border-top-right-radius: 0;\n  }\n"])), function (n) {
  return n.theme.rightPanel.panelBackgroundColor;
}, function (n) {
  return n.theme.colors.text.light;
}, function (n) {
  return n.theme.typography.code.fontFamily;
}, function (n) {
  return n.theme.components.panels.borderRadius;
}, function (n) {
  return n.theme.scrollYOffset();
}, function (n) {
  return n.shaking && shakeAnimation;
});
export var ConsoleBody = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  padding: 20px 20px 15px;\n  overflow: auto;\n  display: ", ";\n  flex-direction: column;\n  position: relative;\n  background-color: ", ";\n"], ["\n  flex: 1;\n  padding: 20px 20px 15px;\n  overflow: auto;\n  display: ", ";\n  flex-direction: column;\n  position: relative;\n  background-color: ", ";\n"])), function (n) {
  return n.hidden ? "none" : "flex";
}, function (n) {
  return n.theme.rightPanel.panelBackgroundColor;
});
export var CloseButton = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n  font-size: 24px;\n  font-weight: bold;\n  color: ", ";\n"], ["\n  cursor: pointer;\n  font-size: 24px;\n  font-weight: bold;\n  color: ", ";\n"])), function (n) {
  return n.theme.colors.text.light;
});
export var invertedInputStyles = css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: none;\n  outline: none;\n  padding: 8px;\n  border-radius: ", ";\n  background-color: ", ";\n  ", "\n\n  &::placeholder {\n    opacity: 0.6;\n    color: ", ";\n  }\n\n  &:-webkit-autofill {\n    background-color: ", ";\n  }\n"], ["\n  border: none;\n  outline: none;\n  padding: 8px;\n  border-radius: ", ";\n  background-color: ", ";\n  ", "\n\n  &::placeholder {\n    opacity: 0.6;\n    color: ", ";\n  }\n\n  &:-webkit-autofill {\n    background-color: ", ";\n  }\n"])), function (n) {
  return n.theme.shape.borderRadius;
}, function (n) {
  return n.theme.rightPanel.panelControlsBackgroundColor;
}, function (n) {
  var e = n.theme;
  return mediaCSS({
    color: e.colors.text.light,
    fontFamily: e.typography.code.fontFamily,
    fontSize: e.typography.fontSize
  });
}, function (n) {
  return n.theme.colors.text.light;
}, function (n) {
  return n.theme.rightPanel.panelBackgroundColor;
});