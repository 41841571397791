import { __makeTemplateObject } from "tslib";
import * as React from "react";
import { styled, IS_BROWSER } from "../redoc-lib";
import { userIsForcingNavigation } from "../redoc-lib/src/common-elements/linkify";
import { ButtonLink, Button } from "./shared/Button";
import { NextSectionButtonWrap } from "./styled.elements";
export var NextSectionButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-left: auto;\n\n  ", ";\n"], ["\n  font-size: 14px;\n  margin-left: auto;\n\n  ", ";\n"])), function (e) {
  var t,
      n,
      o,
      i = e.theme;
  return i.overrides && (null === (t = i.overrides) || void 0 === t ? void 0 : t.NextSectionButton) && (null === (o = null === (n = i.overrides) || void 0 === n ? void 0 : n.NextSectionButton) || void 0 === o ? void 0 : o.custom) || "";
});
export var NextSectionButtonLink = styled(ButtonLink)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-left: auto;\n\n  ", ";\n"], ["\n  font-size: 14px;\n  margin-left: auto;\n\n  ", ";\n"])), function (e) {
  var t,
      n,
      o,
      i = e.theme;
  return i.overrides && (null === (t = i.overrides) || void 0 === t ? void 0 : t.NextSectionButton) && (null === (o = null === (n = i.overrides) || void 0 === n ? void 0 : n.NextSectionButton) || void 0 === o ? void 0 : o.custom) || "";
});
export var ContinueButton = function ContinueButton(e) {
  var t = e.pagination,
      n = e.menu;
  if (!t || "none" === t) return null;
  var o = getNext(t, n.renderItems, n.flatItems),
      i = o.nextItem,
      r = o.nextLabel;
  return i ? React.createElement(NextSectionButtonWrap, null, React.createElement(NextSectionButtonLink, {
    color: "secondary",
    onClick: function onClick(e) {
      var t;
      e.defaultPrevented || userIsForcingNavigation(e) || (e.preventDefault(), t = i, IS_BROWSER && window.scrollTo(0, 0), n.activateAndScroll(t), setTimeout(function () {
        return n.activateAndScroll(t);
      }));
    },
    href: i.href
  }, "➔ Next to ", React.createElement("strong", null, r))) : null;
};

function getNext(e, t, n) {
  var o,
      i,
      r,
      l = void 0;
  if (!t.length) return "group" === (l = n[0]).type && (l = l.items[0]), {
    nextItem: l,
    nextLabel: r = l.name
  };
  if ("section" === e) return (l = nextItemOfDepth(t[t.length - 1], n)) ? {
    nextItem: l,
    nextLabel: r = null == l ? void 0 : l.name
  } : {};

  if ("item" === e) {
    switch (t[0].type) {
      case "tag":
        r = null == (l = nextOperationOrTagItem(t[0], n)) ? void 0 : l.name;
        break;

      case "section":
        r = null == (l = nextItemOfDepth(t[t.length - 1], n)) ? void 0 : l.name;
        break;

      case "operation":
        r = null == (l = getNextForOperation(t[0], n)) ? void 0 : l.name;
        break;

      default:
        return {};
    }

    return "tag" === (null == l ? void 0 : l.type) && !(null == l ? void 0 : l.description) && (null == l ? void 0 : l.items.length) && (r = (null === (o = null == (l = null == l ? void 0 : l.items[0]) ? void 0 : l.parent) || void 0 === o ? void 0 : o.name) + " / " + (null == l ? void 0 : l.name)), l && l.parent && 0 === l.parent.items.indexOf(l) && (r = (null === (i = l.parent) || void 0 === i ? void 0 : i.name) + " / " + l.name), {
      nextItem: l,
      nextLabel: r
    };
  }

  return {};
}

function nextItemOfDepth(e, t) {
  for (var n, o = (null !== (n = null == e ? void 0 : e.absoluteIdx) && void 0 !== n ? n : 0) + 1; o < t.length; o++) {
    if (t[o].depth === e.depth) return t[o];
  }
}

function nextOperationOrTagItem(e, t) {
  for (var n, o = (null !== (n = null == e ? void 0 : e.absoluteIdx) && void 0 !== n ? n : 0) + 1; o < t.length; o++) {
    if ("operation" === t[o].type || "tag" === t[o].type) return t[o];
  }
}

function getNextForOperation(e, t) {
  for (var n, o, i = (null !== (n = null == e ? void 0 : e.absoluteIdx) && void 0 !== n ? n : 0) + 1; i < t.length; i++) {
    if ("tag" === (o = t[i]).type || "operation" === o.type || "section" === o.type) return o;
  }
}

var templateObject_1, templateObject_2;