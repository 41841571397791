"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var error = {
  message: function message(_ref) {
    var schemaCode = _ref.schemaCode;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must be multiple of ", ""])), schemaCode);
  },
  params: function params(_ref2) {
    var schemaCode = _ref2.schemaCode;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{multipleOf: ", "}"])), schemaCode);
  }
};
var def = {
  keyword: "multipleOf",
  type: "number",
  schemaType: "number",
  $data: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        data = cxt.data,
        schemaCode = cxt.schemaCode,
        it = cxt.it; // const bdt = bad$DataType(schemaCode, <string>def.schemaType, $data)

    var prec = it.opts.multipleOfPrecision;
    var res = gen.let("res");
    var invalid = prec ? codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["Math.abs(Math.round(", ") - ", ") > 1e-", ""])), res, res, prec) : codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", " !== parseInt(", ")"])), res, res);
    cxt.fail$data(codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["(", " === 0 || (", " = ", "/", ", ", "))"])), schemaCode, res, data, schemaCode, invalid));
  }
};
exports.default = def;