"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var code_1 = require("../code");

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var util_2 = require("../../compile/util");

var def = {
  keyword: "patternProperties",
  type: "object",
  schemaType: "object",
  code: function code(cxt) {
    var gen = cxt.gen,
        schema = cxt.schema,
        data = cxt.data,
        parentSchema = cxt.parentSchema,
        it = cxt.it;
    var opts = it.opts;
    var patterns = code_1.allSchemaProperties(schema);
    var alwaysValidPatterns = patterns.filter(function (p) {
      return util_1.alwaysValidSchema(it, schema[p]);
    });

    if (patterns.length === 0 || alwaysValidPatterns.length === patterns.length && (!it.opts.unevaluated || it.props === true)) {
      return;
    }

    var checkProperties = opts.strictSchema && !opts.allowMatchingProperties && parentSchema.properties;
    var valid = gen.name("valid");

    if (it.props !== true && !(it.props instanceof codegen_1.Name)) {
      it.props = util_2.evaluatedPropsToName(gen, it.props);
    }

    var props = it.props;
    validatePatternProperties();

    function validatePatternProperties() {
      var _iterator = _createForOfIteratorHelper(patterns),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var pat = _step.value;
          if (checkProperties) checkMatchingProperties(pat);

          if (it.allErrors) {
            validateProperties(pat);
          } else {
            gen.var(valid, true); // TODO var

            validateProperties(pat);
            gen.if(valid);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }

    function checkMatchingProperties(pat) {
      for (var prop in checkProperties) {
        if (new RegExp(pat).test(prop)) {
          util_1.checkStrictMode(it, "property ".concat(prop, " matches pattern ").concat(pat, " (use allowMatchingProperties)"));
        }
      }
    }

    function validateProperties(pat) {
      gen.forIn("key", data, function (key) {
        gen.if(codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", ".test(", ")"])), code_1.usePattern(cxt, pat), key), function () {
          var alwaysValid = alwaysValidPatterns.includes(pat);

          if (!alwaysValid) {
            cxt.subschema({
              keyword: "patternProperties",
              schemaProp: pat,
              dataProp: key,
              dataPropType: util_2.Type.Str
            }, valid);
          }

          if (it.opts.unevaluated && props !== true) {
            gen.assign(codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", "[", "]"])), props, key), true);
          } else if (!alwaysValid && !it.allErrors) {
            // can short-circuit if `unevaluatedProperties` is not supported (opts.next === false)
            // or if all properties were evaluated (props === true)
            gen.if(codegen_1.not(valid), function () {
              return gen.break();
            });
          }
        });
      });
    }
  }
};
exports.default = def;