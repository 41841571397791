"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationTagDefined = void 0;

var OperationTagDefined = function OperationTagDefined() {
  var definedTags;
  return {
    DefinitionRoot: function DefinitionRoot(root) {
      var _a;

      definedTags = new Set(((_a = root.tags) !== null && _a !== void 0 ? _a : []).map(function (t) {
        return t.name;
      }));
    },
    Operation: function Operation(operation, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (operation.tags) {
        for (var i = 0; i < operation.tags.length; i++) {
          if (!definedTags.has(operation.tags[i])) {
            report({
              message: "Operation tags should be defined in global tags.",
              location: location.child(['tags', i])
            });
          }
        }
      }
    }
  };
};

exports.OperationTagDefined = OperationTagDefined;