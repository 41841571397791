"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoLicenseUrl = void 0;

var utils_1 = require("../utils");

var InfoLicenseUrl = function InfoLicenseUrl() {
  return {
    License: function License(license, ctx) {
      utils_1.validateDefinedAndNonEmpty('url', license, ctx);
    }
  };
};

exports.InfoLicenseUrl = InfoLicenseUrl;