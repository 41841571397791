"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decorators = exports.preprocessors = exports.rules = void 0;

var spec_1 = require("../common/spec");

var info_description_1 = require("../common/info-description");

var info_contact_1 = require("../common/info-contact");

var info_license_url_1 = require("../common/info-license-url");

var license_url_1 = require("../common/license-url");

var boolean_parameter_prefixes_1 = require("./boolean-parameter-prefixes");

var tag_description_1 = require("../common/tag-description");

var tags_alphabetical_1 = require("../common/tags-alphabetical");

var paths_kebab_case_1 = require("../common/paths-kebab-case");

var no_enum_type_mismatch_1 = require("../common/no-enum-type-mismatch");

var no_path_trailing_slash_1 = require("../common/no-path-trailing-slash");

var operation_2xx_response_1 = require("../common/operation-2xx-response");

var operation_4xx_response_1 = require("../common/operation-4xx-response");

var operation_operationId_unique_1 = require("../common/operation-operationId-unique");

var operation_parameters_unique_1 = require("../common/operation-parameters-unique");

var path_params_defined_1 = require("../common/path-params-defined");

var operation_tag_defined_1 = require("../common/operation-tag-defined");

var path_declaration_must_exist_1 = require("../common/path-declaration-must-exist");

var operation_operationId_url_safe_1 = require("../common/operation-operationId-url-safe");

var operation_description_1 = require("../common/operation-description");

var path_not_include_query_1 = require("../common/path-not-include-query");

var parameter_description_1 = require("../common/parameter-description");

var operation_singular_tag_1 = require("../common/operation-singular-tag");

var operation_security_defined_1 = require("../common/operation-security-defined");

var no_unresolved_refs_1 = require("../no-unresolved-refs");

var path_http_verbs_order_1 = require("../common/path-http-verbs-order");

var registry_dependencies_1 = require("../common/registry-dependencies");

var no_identical_paths_1 = require("../common/no-identical-paths");

var operation_operationId_1 = require("../common/operation-operationId");

var operation_summary_1 = require("../common/operation-summary");

var no_ambiguous_paths_1 = require("../common/no-ambiguous-paths");

var no_http_verbs_in_paths_1 = require("../common/no-http-verbs-in-paths");

var path_excludes_patterns_1 = require("../common/path-excludes-patterns");

var request_mime_type_1 = require("./request-mime-type");

var response_mime_type_1 = require("./response-mime-type");

var path_segment_plural_1 = require("../common/path-segment-plural");

var operation_description_override_1 = require("../common/operation-description-override");

var tag_description_override_1 = require("../common/tag-description-override");

var info_description_override_1 = require("../common/info-description-override");

exports.rules = {
  spec: spec_1.OasSpec,
  'info-description': info_description_1.InfoDescription,
  'info-contact': info_contact_1.InfoContact,
  'info-license': info_license_url_1.InfoLicense,
  'info-license-url': license_url_1.InfoLicenseUrl,
  'tag-description': tag_description_1.TagDescription,
  'tags-alphabetical': tags_alphabetical_1.TagsAlphabetical,
  'paths-kebab-case': paths_kebab_case_1.PathsKebabCase,
  'no-enum-type-mismatch': no_enum_type_mismatch_1.NoEnumTypeMismatch,
  'boolean-parameter-prefixes': boolean_parameter_prefixes_1.BooleanParameterPrefixes,
  'no-path-trailing-slash': no_path_trailing_slash_1.NoPathTrailingSlash,
  'operation-2xx-response': operation_2xx_response_1.Operation2xxResponse,
  'operation-4xx-response': operation_4xx_response_1.Operation4xxResponse,
  'operation-operationId-unique': operation_operationId_unique_1.OperationIdUnique,
  'operation-parameters-unique': operation_parameters_unique_1.OperationParametersUnique,
  'path-parameters-defined': path_params_defined_1.PathParamsDefined,
  'operation-tag-defined': operation_tag_defined_1.OperationTagDefined,
  'path-declaration-must-exist': path_declaration_must_exist_1.PathDeclarationMustExist,
  'operation-operationId-url-safe': operation_operationId_url_safe_1.OperationIdUrlSafe,
  'operation-operationId': operation_operationId_1.OperationOperationId,
  'operation-summary': operation_summary_1.OperationSummary,
  'operation-description': operation_description_1.OperationDescription,
  'path-not-include-query': path_not_include_query_1.PathNotIncludeQuery,
  'path-params-defined': path_params_defined_1.PathParamsDefined,
  'parameter-description': parameter_description_1.ParameterDescription,
  'operation-singular-tag': operation_singular_tag_1.OperationSingularTag,
  'operation-security-defined': operation_security_defined_1.OperationSecurityDefined,
  'no-unresolved-refs': no_unresolved_refs_1.NoUnresolvedRefs,
  'no-identical-paths': no_identical_paths_1.NoIdenticalPaths,
  'no-ambiguous-paths': no_ambiguous_paths_1.NoAmbiguousPaths,
  'path-http-verbs-order': path_http_verbs_order_1.PathHttpVerbsOrder,
  'no-http-verbs-in-paths': no_http_verbs_in_paths_1.NoHttpVerbsInPaths,
  'path-excludes-patterns': path_excludes_patterns_1.PathExcludesPatterns,
  'request-mime-type': request_mime_type_1.RequestMimeType,
  'response-mime-type': response_mime_type_1.ResponseMimeType,
  'path-segment-plural': path_segment_plural_1.PathSegmentPlural
};
exports.preprocessors = {};
exports.decorators = {
  'registry-dependencies': registry_dependencies_1.RegistryDependencies,
  'operation-description-override': operation_description_override_1.OperationDescriptionOverride,
  'tag-description-override': tag_description_override_1.TagDescriptionOverride,
  'info-description-override': info_description_override_1.InfoDescriptionOverride
};