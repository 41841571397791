import * as React from "react";
import { Header, Title, Trigger } from "../../../../components/Panel";
import { ShelfIcon } from "../../common-elements/icons";
import { Markdown } from "../Markdown/Markdown";
export var ResponseSummary = function ResponseSummary(e) {
  var n = e.expanded,
      r = e.toggle,
      t = e.response,
      o = t.content,
      a = t.headers,
      l = t.description,
      c = (null == o ? void 0 : o.mediaTypes.filter(function (e) {
    return void 0 !== e.schema;
  })) || [],
      m = 0 === a.length && 0 === c.length && !l;
  return React.createElement(Header, {
    onClick: r,
    disabled: m
  }, React.createElement(Trigger, null, React.createElement(Title, null, React.createElement("span", null, t.code), t.summary && React.createElement(Markdown, {
    inline: !0,
    source: t.summary
  })), m ? null : React.createElement(ShelfIcon, {
    direction: n ? "down" : "right"
  })));
};