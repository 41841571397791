import * as React from "react";
import { CodePanel } from "../../../../components/Panel";
import { CollapsingTabs, TabPanel } from "../../../../components/Tabs";
import { OptionsContext } from "../OptionsProvider";
import { PayloadSamples } from "../PayloadSamples/PayloadSamples";
import { creatPanelToggleEvent } from "../RequestSamples/languageSwitchEvent";
import { l } from "../../services/Labels";
export var ResponseSamples = function ResponseSamples(e) {
  var t = e.operation,
      a = React.useContext(OptionsContext).events,
      n = t.responses.filter(function (e) {
    return e.content && e.content.hasSample;
  });
  if (!n.length) return null;
  var o = n.map(function (e) {
    var t = e.code;
    return {
      key: t,
      title: t
    };
  });
  return React.createElement(CodePanel, {
    header: l("responseSamples"),
    onToggle: function onToggle(e) {
      var n,
          o = creatPanelToggleEvent({
        operation: t,
        isExpanded: e,
        panelType: "response-samples"
      });
      null === (n = null == a ? void 0 : a.panelToggle) || void 0 === n || n.call(a, o);
    }
  }, React.createElement(CollapsingTabs, {
    tabs: o,
    operation: t
  }, n.map(function (e) {
    return React.createElement(TabPanel, {
      key: e.code,
      value: e.code
    }, React.createElement("div", null, React.createElement(PayloadSamples, {
      content: e.content
    })));
  })));
};