import { __assign } from "tslib";
import { VariableType } from "./constants";
export function getPreserveTransformer(r) {
  var e = r.body,
      n = r.authorizationHeader;
  return function (r, a, t) {
    return e && "body" === a && "string" == typeof r.body ? r.body : n && "Authorization" === a && "string" == typeof r.Authorization ? r.Authorization : t;
  };
}
export function addIndentation(r, e) {
  var n = void 0 === e ? {} : e,
      a = n.level,
      t = void 0 === a ? 0 : a,
      i = n.indent,
      o = void 0 === i ? "  " : i,
      s = n.firstLine,
      p = void 0 === s || s,
      l = o.repeat(t),
      u = r.replace(/(\r\n|\n|\r)/gm, "\n" + l);
  return p ? l.concat(u) : u;
}
export function parseUrlIntoOperands(r, e) {
  for (var n = [], a = 0, t = 0, i = Object.keys(e).map(function (e) {
    return {
      name: e,
      idx: r.indexOf("{" + e + "}")
    };
  }).filter(function (r) {
    return r.idx > -1;
  }).sort(function (r, e) {
    return r.idx - e.idx;
  }); t < i.length; t++) {
    var o = i[t],
        s = o.name,
        p = o.idx;
    p > a && n.push({
      type: "string",
      value: r.substring(a, p)
    }), n.push({
      type: "variable",
      name: s
    }), a = p + s.length + 2;
  }

  return a < r.length && n.push({
    type: "string",
    value: r.substring(a)
  }), n;
}
export function buildUrlExpression(r, e) {
  var n = e.langOptions,
      a = n.joinSymbol,
      t = n.variableWrapperFn,
      i = n.stringLiteral,
      o = n.urlFieldName,
      s = n.rawName,
      p = r.pathParameters,
      l = void 0 === p ? {} : p,
      u = r.serverVariables,
      v = void 0 === u ? {} : u,
      d = __assign(__assign({}, l), v);

  return parseUrlIntoOperands(r[o] || "", d).map(function (r) {
    if ("variable" === r.type) {
      var n = d[r.name],
          a = s ? r.name : e.var(r.name, VariableType.PATH);
      return t(a, n);
    }

    return i(r.value);
  }).join(a);
}
export function printUrlVariablesDeclarations(r, e, n) {
  var a = r.pathParameters,
      t = void 0 === a ? {} : a,
      i = r.serverVariables,
      o = void 0 === i ? {} : i;
  Object.entries(__assign(__assign({}, t), o)).forEach(function (r) {
    var a = r[0],
        t = r[1] || "YOUR_" + a + "_PARAMETER",
        i = e.var(a, VariableType.PATH);
    void 0 !== n ? e.push(n, e.langOptions.variableDeclaration(i), t) : e.push(e.langOptions.variableDeclaration(i), t);
  });
}