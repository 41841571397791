var REDOC_OVERVIEW_ITEM_ID = "overview",
    PAGE_TYPES = {
  referenceDocsPage: "redoc-operation",
  referenceDocsOverview: "redoc-info",
  markdown: "markdown",
  mdx: "mdx",
  dataTemplate: "data-template",
  referenceDocsContainerPage: "redoc-redirect"
},
    REDOCLY_CONFIG_DIR = ".redocly-config",
    CUSTOM_REDIRECTS_FILE_NAME = "redirects";
module.exports = {
  REDOC_OVERVIEW_ITEM_ID: "overview",
  PAGE_TYPES: PAGE_TYPES,
  REDOCLY_CONFIG_DIR: REDOCLY_CONFIG_DIR,
  CUSTOM_REDIRECTS_FILE_NAME: CUSTOM_REDIRECTS_FILE_NAME
};