"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Stats = void 0;

var Stats = function Stats(statsAccumulator) {
  return {
    ExternalDocs: {
      leave: function leave() {
        statsAccumulator.externalDocs.total++;
      }
    },
    ref: {
      enter: function enter(ref) {
        statsAccumulator.refs.items.add(ref['$ref']);
      }
    },
    Tag: {
      leave: function leave(tag) {
        statsAccumulator.tags.items.add(tag.name);
      }
    },
    Link: {
      leave: function leave(link) {
        statsAccumulator.links.items.add(link.operationId);
      }
    },
    DefinitionRoot: {
      leave: function leave() {
        statsAccumulator.parameters.total = statsAccumulator.parameters.items.size;
        statsAccumulator.refs.total = statsAccumulator.refs.items.size;
        statsAccumulator.links.total = statsAccumulator.links.items.size;
        statsAccumulator.tags.total = statsAccumulator.tags.items.size;
      }
    },
    WebhooksMap: {
      Operation: {
        leave: function leave(operation) {
          operation.tags.forEach(function (tag) {
            statsAccumulator.tags.items.add(tag);
          });
        }
      }
    },
    PathMap: {
      PathItem: {
        leave: function leave() {
          statsAccumulator.pathItems.total++;
        },
        Operation: {
          leave: function leave(operation) {
            statsAccumulator.operations.total++;
            operation.tags && operation.tags.forEach(function (tag) {
              statsAccumulator.tags.items.add(tag);
            });
          }
        },
        Parameter: {
          leave: function leave(parameter) {
            statsAccumulator.parameters.items.add(parameter.name);
          }
        }
      }
    },
    NamedSchemas: {
      Schema: {
        leave: function leave() {
          statsAccumulator.schemas.total++;
        }
      }
    }
  };
};

exports.Stats = Stats;