import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
export var MimeLabel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  font-family: ", ";\n  margin-bottom: 0;\n  height: 30px;\n  padding: 7px 10px;\n  font-size: 14px;\n  line-height: 16px;\n  border-radius: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n  font-family: ", ";\n  margin-bottom: 0;\n  height: 30px;\n  padding: 7px 10px;\n  font-size: 14px;\n  line-height: 16px;\n  border-radius: ", ";\n"])), function (e) {
  return e.theme.rightPanel.panelControlsBackgroundColor;
}, function (e) {
  return e.theme.colors.text.light;
}, function (e) {
  return e.theme.typography.code.fontFamily;
}, function (e) {
  return e.theme.shape.borderRadius;
});
var templateObject_1;