import { __assign, __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { Schema } from "./Schema";
import { ArrayClosingLabel, ArrayOpenningLabel } from "../../common-elements";
import styled from "../../styled-components";
import { humanizeConstraints } from "../../utils";
import { TypeName } from "../../common-elements/fields";

var templateObject_1,
    PaddedSchema = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: ", "px;\n"], ["\n  padding-left: ", "px;\n"])), function (e) {
  return 2 * e.theme.spacing.unit;
}),
    ArraySchema = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.schema,
          a = e.schema,
          n = a.minItems,
          r = a.maxItems,
          m = a.items,
          l = void 0 === n && void 0 === r ? "" : "(" + humanizeConstraints(t) + ")";
      return !t.displayType || m || l.length ? React.createElement("div", null, React.createElement(ArrayOpenningLabel, null, " Array ", l), React.createElement(PaddedSchema, null, React.createElement(Schema, __assign({}, this.props, {
        schema: m
      }))), React.createElement(ArrayClosingLabel, null)) : React.createElement("div", null, React.createElement(TypeName, null, t.displayType));
    }
  }), t;
}(React.PureComponent);

export { ArraySchema };