import Mark from "mark.js";

var MarkerService = function () {
  function e() {
    Object.defineProperty(this, "map", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: new Map()
    }), Object.defineProperty(this, "prevTerm", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    });
  }

  return Object.defineProperty(e.prototype, "add", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      this.map.set(e, new Mark(e));
    }
  }), Object.defineProperty(e.prototype, "delete", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      this.map.delete(e);
    }
  }), Object.defineProperty(e.prototype, "addOnly", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var r = this;
      this.map.forEach(function (t, a) {
        -1 === e.indexOf(a) && (t.unmark(), r.map.delete(a));
      });

      for (var t = 0, a = e; t < a.length; t++) {
        var n = a[t];
        this.map.has(n) || this.map.set(n, new Mark(n));
      }
    }
  }), Object.defineProperty(e.prototype, "clearAll", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.unmark(), this.map.clear();
    }
  }), Object.defineProperty(e.prototype, "mark", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var r = this;
      (e || this.prevTerm) && (this.map.forEach(function (t) {
        t.unmark(), t.mark(e || r.prevTerm);
      }), this.prevTerm = e || this.prevTerm);
    }
  }), Object.defineProperty(e.prototype, "unmark", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.map.forEach(function (e) {
        return e.unmark();
      }), this.prevTerm = "";
    }
  }), e;
}();

export { MarkerService };