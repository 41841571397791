import { __assign, __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../../redoc-lib/src/styled-components";
export var StyledInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  vertical-align: middle;\n  margin-left: 0;\n  margin-right: 5px;\n  height: 20px;\n"], ["\n  cursor: pointer;\n  vertical-align: middle;\n  margin-left: 0;\n  margin-right: 5px;\n  height: 20px;\n"])));
var templateObject_1,
    templateObject_2,
    templateObject_3,
    CheckboxLabel = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  cursor: pointer;\n  vertical-align: middle;\n  position: relative;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  overflow-wrap: break-word;\n  line-height: 20px;\n"], ["\n  display: inline-block;\n  cursor: pointer;\n  vertical-align: middle;\n  position: relative;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  overflow-wrap: break-word;\n  line-height: 20px;\n"]))),
    CheckBoxWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n  display: inline-flex;\n  align-items: center;\n\n  & > label {\n    white-space: ", ";\n  }\n"], ["\n  width: ", ";\n  display: inline-flex;\n  align-items: center;\n\n  & > label {\n    white-space: ", ";\n  }\n"])), function (e) {
  return e.fullWidth ? "100%" : "50%";
}, function (e) {
  return e.nowrap ? "nowrap" : "normal";
});

function CheckboxComponent(e, t) {
  var n = e.fullWidth,
      l = e.id,
      i = e.label,
      a = e.nowrap;
  return React.createElement(CheckBoxWrap, {
    fullWidth: n,
    nowrap: a
  }, React.createElement(StyledInput, __assign({}, e, {
    type: "checkbox",
    ref: t
  })), React.createElement(CheckboxLabel, {
    htmlFor: l
  }, i));
}

export var Checkbox = React.forwardRef(CheckboxComponent);