var offsetY = 0;

var getTargetOffset = function getTargetOffset(a) {
  var b = a && a.element;

  if (b) {
    var _a = document && document.querySelectorAll(b) || [];

    for (var _b = 0; _b < _a.length; _b++) {
      var c = _a[_b].getBoundingClientRect();

      if (1 < c.height) return Math.ceil(c.height);
    }

    return 0;
  }

  return "number" == typeof a ? a : 0;
},
    scrollWithOffset = function scrollWithOffset(a, b) {
  var c = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop,
      d = a.getBoundingClientRect().top;
  window.scrollTo(0, d + c - b);
};

exports.onRouteUpdate = function (_ref, c) {
  var a = _ref.location,
      b = _ref.prevLocation;
  var d = window.decodeURI(a.hash),
      e = !b || b.pathname !== a.pathname;

  if (e && (offsetY = getTargetOffset(c.offsetY), document.querySelectorAll(".hidden-anchor").forEach(function (a) {
    a.style.top = "-" + offsetY + "px";
  })), d) {
    var _a2 = window.decodeURI(d.replace("#", "")),
        _b2 = document.getElementById(_a2);

    if (!_b2) return;
    "hidden-anchor" === _b2.className ? _b2.scrollIntoView() : e ? setTimeout(function () {
      scrollWithOffset(_b2, offsetY);
    }, 0) : scrollWithOffset(_b2, offsetY);
  }
};