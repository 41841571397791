import { __makeTemplateObject } from "tslib";
import * as React from "react";
import { styled, Dropdown } from "../redoc-lib";
export var VersionSwitcher = function VersionSwitcher(e) {
  var n = e.spec,
      t = e.onChange,
      o = n.versionsInfo.map(function (e, n) {
    return {
      idx: n,
      value: e.title
    };
  });
  return React.createElement(VersionWrapper, null, React.createElement(Dropdown, {
    options: o,
    value: o[n.activeVersionIdx].value,
    onChange: t,
    ariaLabel: "Version"
  }));
};
var templateObject_1,
    VersionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n  div[role='button'] {\n    .dropdown-selector-value {\n      font-weight: 600;\n      color: ", ";\n    }\n  }\n"], ["\n  padding: ", ";\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n  div[role='button'] {\n    .dropdown-selector-value {\n      font-weight: 600;\n      color: ", ";\n    }\n  }\n"])), function (e) {
  var n = e.theme;
  return 2 * n.spacing.unit + "px " + 4 * n.spacing.unit + "px";
}, function (e) {
  return e.theme.colors.text.primary;
});