"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30, _templateObject31, _templateObject32, _templateObject33;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportTypeError = exports.checkDataTypes = exports.checkDataType = exports.coerceAndCheckDataType = exports.getJSONTypes = exports.getSchemaTypes = exports.DataType = void 0;

var rules_1 = require("../rules");

var applicability_1 = require("./applicability");

var errors_1 = require("../errors");

var codegen_1 = require("../codegen");

var util_1 = require("../util");

var DataType;

(function (DataType) {
  DataType[DataType["Correct"] = 0] = "Correct";
  DataType[DataType["Wrong"] = 1] = "Wrong";
})(DataType = exports.DataType || (exports.DataType = {}));

function getSchemaTypes(schema) {
  var types = getJSONTypes(schema.type);
  var hasNull = types.includes("null");

  if (hasNull) {
    if (schema.nullable === false) throw new Error("type: null contradicts nullable: false");
  } else {
    if (!types.length && schema.nullable !== undefined) {
      throw new Error('"nullable" cannot be used without "type"');
    }

    if (schema.nullable === true) types.push("null");
  }

  return types;
}

exports.getSchemaTypes = getSchemaTypes;

function getJSONTypes(ts) {
  var types = Array.isArray(ts) ? ts : ts ? [ts] : [];
  if (types.every(rules_1.isJSONType)) return types;
  throw new Error("type must be JSONType or JSONType[]: " + types.join(","));
}

exports.getJSONTypes = getJSONTypes;

function coerceAndCheckDataType(it, types) {
  var gen = it.gen,
      data = it.data,
      opts = it.opts;
  var coerceTo = coerceToTypes(types, opts.coerceTypes);
  var checkTypes = types.length > 0 && !(coerceTo.length === 0 && types.length === 1 && applicability_1.schemaHasRulesForType(it, types[0]));

  if (checkTypes) {
    var wrongType = checkDataTypes(types, data, opts.strictNumbers, DataType.Wrong);
    gen.if(wrongType, function () {
      if (coerceTo.length) coerceData(it, types, coerceTo);else reportTypeError(it);
    });
  }

  return checkTypes;
}

exports.coerceAndCheckDataType = coerceAndCheckDataType;
var COERCIBLE = new Set(["string", "number", "integer", "boolean", "null"]);

function coerceToTypes(types, coerceTypes) {
  return coerceTypes ? types.filter(function (t) {
    return COERCIBLE.has(t) || coerceTypes === "array" && t === "array";
  }) : [];
}

function coerceData(it, types, coerceTo) {
  var gen = it.gen,
      data = it.data,
      opts = it.opts;
  var dataType = gen.let("dataType", codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["typeof ", ""])), data));
  var coerced = gen.let("coerced", codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["undefined"]))));

  if (opts.coerceTypes === "array") {
    gen.if(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " == 'object' && Array.isArray(", ") && ", ".length == 1"])), dataType, data, data), function () {
      return gen.assign(data, codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", "[0]"])), data)).assign(dataType, codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["typeof ", ""])), data)).if(checkDataTypes(types, data, opts.strictNumbers), function () {
        return gen.assign(coerced, data);
      });
    });
  }

  gen.if(codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["", " !== undefined"])), coerced));

  var _iterator = _createForOfIteratorHelper(coerceTo),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var t = _step.value;

      if (COERCIBLE.has(t) || t === "array" && opts.coerceTypes === "array") {
        coerceSpecificType(t);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  gen.else();
  reportTypeError(it);
  gen.endIf();
  gen.if(codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["", " !== undefined"])), coerced), function () {
    gen.assign(data, coerced);
    assignParentData(it, coerced);
  });

  function coerceSpecificType(t) {
    switch (t) {
      case "string":
        gen.elseIf(codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", " == \"number\" || ", " == \"boolean\""])), dataType, dataType)).assign(coerced, codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\"\" + ", ""])), data)).elseIf(codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", " === null"])), data)).assign(coerced, codegen_1._(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\"\""]))));
        return;

      case "number":
        gen.elseIf(codegen_1._(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["", " == \"boolean\" || ", " === null\n              || (", " == \"string\" && ", " && ", " == +", ")"])), dataType, data, dataType, data, data, data)).assign(coerced, codegen_1._(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["+", ""])), data));
        return;

      case "integer":
        gen.elseIf(codegen_1._(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["", " === \"boolean\" || ", " === null\n              || (", " === \"string\" && ", " && ", " == +", " && !(", " % 1))"])), dataType, data, dataType, data, data, data, data)).assign(coerced, codegen_1._(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["+", ""])), data));
        return;

      case "boolean":
        gen.elseIf(codegen_1._(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["", " === \"false\" || ", " === 0 || ", " === null"])), data, data, data)).assign(coerced, false).elseIf(codegen_1._(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["", " === \"true\" || ", " === 1"])), data, data)).assign(coerced, true);
        return;

      case "null":
        gen.elseIf(codegen_1._(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["", " === \"\" || ", " === 0 || ", " === false"])), data, data, data));
        gen.assign(coerced, null);
        return;

      case "array":
        gen.elseIf(codegen_1._(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["", " === \"string\" || ", " === \"number\"\n              || ", " === \"boolean\" || ", " === null"])), dataType, dataType, dataType, data)).assign(coerced, codegen_1._(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["[", "]"])), data));
    }
  }
}

function assignParentData(_ref, expr) {
  var gen = _ref.gen,
      parentData = _ref.parentData,
      parentDataProperty = _ref.parentDataProperty;
  // TODO use gen.property
  gen.if(codegen_1._(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["", " !== undefined"])), parentData), function () {
    return gen.assign(codegen_1._(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["", "[", "]"])), parentData, parentDataProperty), expr);
  });
}

function checkDataType(dataType, data, strictNums) {
  var correct = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DataType.Correct;
  var EQ = correct === DataType.Correct ? codegen_1.operators.EQ : codegen_1.operators.NEQ;
  var cond;

  switch (dataType) {
    case "null":
      return codegen_1._(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["", " ", " null"])), data, EQ);

    case "array":
      cond = codegen_1._(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["Array.isArray(", ")"])), data);
      break;

    case "object":
      cond = codegen_1._(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["", " && typeof ", " == \"object\" && !Array.isArray(", ")"])), data, data, data);
      break;

    case "integer":
      cond = numCond(codegen_1._(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["!(", " % 1) && !isNaN(", ")"])), data, data));
      break;

    case "number":
      cond = numCond();
      break;

    default:
      return codegen_1._(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["typeof ", " ", " ", ""])), data, EQ, dataType);
  }

  return correct === DataType.Correct ? cond : codegen_1.not(cond);

  function numCond() {
    var _cond = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : codegen_1.nil;

    return codegen_1.and(codegen_1._(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["typeof ", " == \"number\""])), data), _cond, strictNums ? codegen_1._(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["isFinite(", ")"])), data) : codegen_1.nil);
  }
}

exports.checkDataType = checkDataType;

function checkDataTypes(dataTypes, data, strictNums, correct) {
  if (dataTypes.length === 1) {
    return checkDataType(dataTypes[0], data, strictNums, correct);
  }

  var cond;
  var types = util_1.toHash(dataTypes);

  if (types.array && types.object) {
    var notObj = codegen_1._(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["typeof ", " != \"object\""])), data);

    cond = types.null ? notObj : codegen_1._(_templateObject31 || (_templateObject31 = _taggedTemplateLiteral(["!", " || ", ""])), data, notObj);
    delete types.null;
    delete types.array;
    delete types.object;
  } else {
    cond = codegen_1.nil;
  }

  if (types.number) delete types.integer;

  for (var t in types) {
    cond = codegen_1.and(cond, checkDataType(t, data, strictNums, correct));
  }

  return cond;
}

exports.checkDataTypes = checkDataTypes;
var typeError = {
  message: function message(_ref2) {
    var schema = _ref2.schema;
    return "must be ".concat(schema);
  },
  params: function params(_ref3) {
    var schema = _ref3.schema,
        schemaValue = _ref3.schemaValue;
    return typeof schema == "string" ? codegen_1._(_templateObject32 || (_templateObject32 = _taggedTemplateLiteral(["{type: ", "}"])), schema) : codegen_1._(_templateObject33 || (_templateObject33 = _taggedTemplateLiteral(["{type: ", "}"])), schemaValue);
  }
};

function reportTypeError(it) {
  var cxt = getTypeErrorContext(it);
  errors_1.reportError(cxt, typeError);
}

exports.reportTypeError = reportTypeError;

function getTypeErrorContext(it) {
  var gen = it.gen,
      data = it.data,
      schema = it.schema;
  var schemaCode = util_1.schemaRefOrVal(it, schema, "type");
  return {
    gen: gen,
    keyword: "type",
    data: data,
    schema: schema.type,
    schemaCode: schemaCode,
    schemaValue: schemaCode,
    parentSchema: schema,
    params: {},
    it: it
  };
}