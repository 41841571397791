import { __makeTemplateObject } from "tslib";
import { mediaCSS, css } from "../redoc-lib";
import merge from "deepmerge";
export function extendTheme(e) {
  var o, n, t, a, r, l, i, d, c, p, m, h;
  return e.theme ? (e.theme = merge(defaultProTheme, e.theme), e.theme.extensionsHook = extensionsHook, (null === (o = e.theme) || void 0 === o ? void 0 : o.menu) && !(null === (n = e.theme) || void 0 === n ? void 0 : n.sidebar) && (console.warn('Theme setting "menu" is deprecated. Rename to "sidebar"'), e.theme.sidebar = e.theme.menu), (null === (t = e.theme) || void 0 === t ? void 0 : t.codeSample) && (console.warn('Theme setting "codeSample" is deprecated. Rename to "codeBlock"'), Object.assign(null === (a = e.theme) || void 0 === a ? void 0 : a.codeBlock, e.theme.codeSample)), (null === (i = null === (l = null === (r = e.theme) || void 0 === r ? void 0 : r.typography) || void 0 === l ? void 0 : l.code) || void 0 === i ? void 0 : i.tokens) && (console.warn('Theme setting "typography.code.tokens" is deprecated. Move to "codeBlock.tokens"'), e.theme.codeBlock = (null === (d = e.theme) || void 0 === d ? void 0 : d.codeBlock) || {}, Object.assign(null === (p = null === (c = e.theme) || void 0 === c ? void 0 : c.codeBlock) || void 0 === p ? void 0 : p.tokens, (null === (h = null === (m = e.theme) || void 0 === m ? void 0 : m.typography) || void 0 === h ? void 0 : h.code).tokens)), e) : (e.theme = defaultProTheme, e);
}
var defaultProTheme = {
  overrides: {
    DownloadButton: {
      custom: ""
    },
    NextSectionButton: {
      custom: ""
    }
  },
  codeBlock: {
    borderRadius: function borderRadius(e) {
      return e.components.panels.borderRadius;
    },
    tokens: {}
  },
  schema: {
    constraints: {},
    examples: {}
  },
  rightPanel: {
    textColor: "#eeeeee"
  },
  typography: {
    heading1: {},
    heading2: {},
    heading3: {},
    rightPanelHeading: {}
  }
};

function optional(e, o) {
  return o ? e + ": " + o + ";" : "";
}

function typography(e) {
  return e ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", "\n  "], ["\n    ", "\n  "])), mediaCSS({
    fontSize: e.fontSize || "",
    fontWeight: e.fontWeight || "",
    fontFamily: e.fontFamily || "",
    lineHeight: e.lineHeight || "",
    color: e.color || "",
    textTransform: e.transform || ""
  })) : "";
}

function genTokens(e) {
  if (!e) return "";

  for (var o = "", n = 0, t = Object.keys(e); n < t.length; n++) {
    var a = t[n];
    "default" !== a && (o += ".token." + a + " { color: " + e[a].color + "; }\n");
  }

  return e.default ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      pre& {\n        ", "\n      }\n      ", "\n    "], ["\n      pre& {\n        ", "\n      }\n      ", "\n    "])), typography(e.default), o) : o;
}

var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    templateObject_6,
    EXTENSIONS = {
  DownloadButton: function DownloadButton(e) {
    var o = e.theme;
    return void 0 !== o.overrides && o.overrides.DownloadButton && o.overrides.DownloadButton.custom || "";
  },
  H1: function H1(e) {
    return typography(e.theme.typography.heading1);
  },
  H2: function H2(e) {
    return typography(e.theme.typography.heading2);
  },
  H3: function H3(e) {
    return typography(e.theme.typography.heading3);
  },
  RightPanelHeader: function RightPanelHeader(e) {
    return typography(e.theme.typography.rightPanelHeading);
  },
  PropertyNameCell: function PropertyNameCell(e) {
    var o = e.theme;
    return o.schema.breakFieldNames ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        ", "\n        ", "\n      "], ["\n        ", "\n        ", "\n      "])), typography(o.typography.fieldName), "\n      && span:last-child {\n        white-space: normal;\n        word-break: break-word;\n        vertical-align: top;\n        max-width: calc(100% - 21px);\n        display: inline-block;\n      }\n    ") : typography(o.typography.fieldName);
  },
  Markdown: function Markdown(e) {
    var o,
        n,
        t,
        a,
        r = e.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      h1 {\n        ", "\n        clear: both;\n      }\n      h2 {\n        ", "\n        clear: both;\n      }\n      h3 {\n        ", "\n        clear: both;\n      }\n\n      pre {\n        border-radius: ", ";\n      }\n\n      code {\n        ", "\n      }\n    "], ["\n      h1 {\n        ", "\n        clear: both;\n      }\n      h2 {\n        ", "\n        clear: both;\n      }\n      h3 {\n        ", "\n        clear: both;\n      }\n\n      pre {\n        border-radius: ", ";\n      }\n\n      code {\n        ", "\n      }\n    "])), typography(r.typography.heading1), typography(r.typography.heading2), typography(r.typography.heading3), null !== (n = null === (o = r.codeBlock) || void 0 === o ? void 0 : o.borderRadius) && void 0 !== n ? n : 0, optional("word-break", null === (a = null === (t = r.typography) || void 0 === t ? void 0 : t.code) || void 0 === a ? void 0 : a.wordBreak));
  },
  ConstraintItem: function ConstraintItem(e) {
    var o,
        n,
        t,
        a,
        r = e.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      ", ";\n      ", ";\n      ", "\n      ", "\n    "], ["\n      ", ";\n      ", ";\n      ", "\n      ", "\n    "])), typography(r.schema.constraints), optional("background-color", null === (o = r.schema.constraints) || void 0 === o ? void 0 : o.backgroundColor), optional("border", null === (n = r.schema.constraints) || void 0 === n ? void 0 : n.border), optional("word-break", null === (a = null === (t = r.typography) || void 0 === t ? void 0 : t.code) || void 0 === a ? void 0 : a.wordBreak));
  },
  ExampleValue: function ExampleValue(e) {
    var o,
        n,
        t,
        a,
        r = e.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      ", ";\n      ", ";\n      ", "\n      ", "\n    "], ["\n      ", ";\n      ", ";\n      ", "\n      ", "\n    "])), typography(r.schema.examples), optional("background-color", null === (o = r.schema.examples) || void 0 === o ? void 0 : o.backgroundColor), optional("border", null === (n = r.schema.examples) || void 0 === n ? void 0 : n.border), optional("word-break", null === (a = null === (t = r.typography) || void 0 === t ? void 0 : t.code) || void 0 === a ? void 0 : a.wordBreak));
  },
  Prism: function Prism(e) {
    var o,
        n,
        t,
        a = e.theme;
    return genTokens(null === (o = null == a ? void 0 : a.codeBlock) || void 0 === o ? void 0 : o.tokens) + optional("word-break", null === (t = null === (n = a.typography) || void 0 === n ? void 0 : n.code) || void 0 === t ? void 0 : t.wordBreak);
  }
};

function extensionsHook(e, o) {
  return EXTENSIONS[e] && EXTENSIONS[e](o);
}