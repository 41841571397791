import * as Sampler from "openapi-sampler";
import { SchemaModel } from "./Schema";
import { isJsonLike, mapValues } from "../../utils";
import { ExampleModel } from "./Example";

var MediaTypeModel = function () {
  function e(e, i, a, t, l, o) {
    var s = void 0 === o ? {} : o,
        r = s.operation,
        n = s.type,
        p = s.response;
    Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: l
    }), Object.defineProperty(this, "examples", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "schema", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "isRequestType", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "onlyRequiredInSamples", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "operation", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.name = i, this.operation = r, this.isRequestType = a, this.schema = t.schema && new SchemaModel(e, t.schema, "", l, void 0, void 0, {
      operation: r,
      type: n,
      response: p
    }), this.onlyRequiredInSamples = l.onlyRequiredInSamples, void 0 !== t.examples ? this.examples = mapValues(t.examples, function (a) {
      return new ExampleModel(e, a, i, t.encoding);
    }) : void 0 !== t.example ? this.examples = {
      default: new ExampleModel(e, {
        value: e.shallowDeref(t.example)
      }, i, t.encoding)
    } : isJsonLike(i) && this.generateExample(e, t);
  }

  return Object.defineProperty(e.prototype, "generateExample", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, i) {
      var a = {
        skipReadOnly: this.isRequestType,
        skipWriteOnly: !this.isRequestType,
        skipNonRequired: this.isRequestType && this.onlyRequiredInSamples,
        maxSampleDepth: this.options.generatedPayloadSamplesMaxDepth
      };

      if (this.schema && this.schema.oneOf) {
        this.examples = {};

        for (var t = 0, l = this.schema.oneOf; t < l.length; t++) {
          var o = l[t],
              s = Sampler.sample(o.rawSchema, a, e.definition);
          this.schema.discriminatorProp && "object" == typeof s && s && (s[this.schema.discriminatorProp] = o.title), this.examples[o.title] = new ExampleModel(e, {
            value: s
          }, this.name, i.encoding);
        }
      } else this.schema && (this.examples = {
        default: new ExampleModel(e, {
          value: Sampler.sample(i.schema, a, e.definition)
        }, this.name, i.encoding)
      });
    }
  }), e;
}();

export { MediaTypeModel };