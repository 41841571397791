import { __assign, __makeTemplateObject, __rest } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { ShelfIcon } from "../../common-elements/icons";
import { shortenHTTPVerb } from "../../utils/openapi";
import { MenuItems } from "./MenuItems";
import { MenuItemLabel, MenuItemLink, MenuItemLi, MenuItemTitle, OperationBadge } from "./styled.elements";
import { OptionsContext } from "../OptionsProvider";
import styled from "../../styled-components";
import { l } from "../../services/Labels";
import { userIsForcingNavigation } from "../../common-elements/linkify";
export var MenuExternalLink = observer(function (e) {
  var t = e.link;
  return React.createElement(React.Fragment, null, isSeparator(t) ? t.separator ? React.createElement(MenuItemLabel, {
    depth: 0,
    active: !1
  }, t.separator) : null : React.createElement(MenuItemLi, {
    depth: 1
  }, React.createElement(MenuItemLink, {
    href: t.link,
    target: t.target,
    active: !1,
    depth: 1
  }, React.createElement(MenuItemTitle, {
    title: t.label
  }, t.label))), t.separatorLine ? React.createElement(SeparatorLine, null) : null);
});

function preventDefaultIfNotForced(e) {
  e.defaultPrevented || userIsForcingNavigation(e) || e.preventDefault();
}

var MenuItemLinkOrLabel = React.forwardRef(function (e, t) {
  var n = e.href,
      a = e.children,
      r = __rest(e, ["href", "children"]);

  return n ? React.createElement(MenuItemLink, __assign({
    href: n,
    onClick: preventDefaultIfNotForced
  }, r, {
    ref: t
  }), a) : React.createElement(MenuItemLabel, __assign({}, r, {
    ref: t
  }), a);
}),
    MenuItemOperationLinkOrLabel = styled(MenuItemLinkOrLabel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-wrap: wrap;\n"], ["\n  flex-wrap: wrap;\n"])));
export var MenuItem = observer(function (e) {
  var t = e.item,
      n = e.withoutChildren,
      a = e.onActivate,
      r = React.createRef(),
      i = React.useState(!1),
      l = i[0],
      o = i[1],
      c = React.useState("group" === t.type && t.expanded),
      m = c[0],
      p = c[1];
  React.useEffect(function () {
    o(t.active);
  }, [t.active]), React.useEffect(function () {
    p(t.expanded);
  }, [t.expanded]);
  var s = React.useCallback(function (e) {
    userIsForcingNavigation(e) || (null == a || a(t, e), e.stopPropagation());
  }, [a, t]);
  return React.useEffect(function () {
    e.item.active && r.current && r.current.scrollIntoViewIfNeeded();
  }, [e.item, e.item.active, r]), React.createElement(MenuItemLi, {
    onClick: s,
    depth: t.depth,
    "data-item-id": t.id
  }, "operation" === t.type ? React.createElement(OperationItemContent, __assign({}, e, {
    item: t,
    ref: r
  })) : React.createElement(MenuItemLinkOrLabel, {
    depth: t.depth,
    active: l,
    type: t.type,
    ref: r,
    href: t.href,
    hasChildren: t.items && t.items.length > 0
  }, t.depth > 0 && t.items.length > 0 && React.createElement(ShelfIcon, {
    direction: m ? "down" : "right"
  }) || null, React.createElement(MenuItemTitle, {
    title: t.name
  }, t.name, e.children)), !n && t.items && t.items.length > 0 && React.createElement(MenuItems, {
    expanded: m,
    items: t.items,
    onActivate: a
  }));
});
var OperationItemContent = observer(React.forwardRef(function (e, t) {
  var n,
      a,
      r = e.item;
  return "path-first" !== React.useContext(OptionsContext).sideNavStyle && !r.search || "operation" !== r.type ? (n = r.name, a = null) : (n = React.createElement(MenuItemPath, {
    title: r.path
  }, " ", r.path, " "), a = React.createElement(MenuItemDescription, null, " ", r.name, " ")), React.createElement(MenuItemOperationLinkOrLabel, {
    depth: r.depth,
    active: r.active,
    deprecated: r.deprecated,
    ref: t,
    href: r.href
  }, r.isWebhook ? React.createElement(OperationBadge, {
    type: "hook"
  }, l("webhook")) : React.createElement(OperationBadge, {
    type: r.httpVerb
  }, shortenHTTPVerb(r.httpVerb)), React.createElement(MenuItemTitle, {
    width: "calc(100% - 42px)"
  }, n, e.children), a);
}));

function isSeparator(e) {
  return null != e.separator || !!e.separatorLine && !e.link;
}

export var MenuItemPath = styled.code(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 1.4;\n  display: inline-block;\n  margin-bottom: 5px;\n  ", "\n"], ["\n  line-height: 1.4;\n  display: inline-block;\n  margin-bottom: 5px;\n  ", "\n"])), function (e) {
  var t;
  return (null === (t = e.theme.sidebar) || void 0 === t ? void 0 : t.breakPath) ? "word-break: break-all" : "";
});
var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    MenuItemDescription = styled.small(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  font-size: 0.87em;\n  line-height: 1.2;\n"], ["\n  display: block;\n  font-size: 0.87em;\n  line-height: 1.2;\n"])));
export var SeparatorLine = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  margin: ", "px 0;\n"], ["\n  display: block;\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  margin: ", "px 0;\n"])), function (e) {
  return e.theme.sidebar.separatorLineColor;
}, function (e) {
  return e.theme.sidebar.spacing.paddingVertical;
});