import { __decorate, __extends } from "tslib";
import * as React from "react";
import { MenuItem } from "../SideMenu/MenuItem";
import { bind, debounce } from "decko";
import { PerfectScrollbarWrap } from "../../common-elements/perfect-scrollbar";
import { ClearIcon, SearchIcon, SearchInput, SearchResultsBox, SearchWrap } from "./styled.elements";

var SearchBox = function (e) {
  function t(t) {
    var r = e.call(this, t) || this;
    return Object.defineProperty(r, "activeItemRef", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(r, "clear", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        r.setState({
          results: [],
          term: "",
          activeItemIdx: -1
        }), r.props.marker.unmark();
      }
    }), Object.defineProperty(r, "handleKeyDown", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        if (27 === e.keyCode && r.clear(), 40 === e.keyCode && (r.setState({
          activeItemIdx: Math.min(r.state.activeItemIdx + 1, r.state.results.length - 1)
        }), e.preventDefault()), 38 === e.keyCode && (r.setState({
          activeItemIdx: Math.max(0, r.state.activeItemIdx - 1)
        }), e.preventDefault()), 13 === e.keyCode) {
          var t = r.state.results[r.state.activeItemIdx];

          if (t) {
            var a = r.props.getItemById(t.meta);
            a && r.props.onActivate(a);
          }
        }
      }
    }), Object.defineProperty(r, "search", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        var t = e.target.value;
        t.length < 3 ? r.clearResults(t) : r.setState({
          term: t
        }, function () {
          return r.searchCallback(r.state.term);
        });
      }
    }), r.state = {
      results: [],
      term: "",
      activeItemIdx: -1
    }, r;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "clearResults", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      this.setState({
        results: [],
        term: e
      }), this.props.marker.unmark();
    }
  }), Object.defineProperty(t.prototype, "setResults", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      this.setState({
        results: e
      }), this.props.marker.mark(t);
    }
  }), Object.defineProperty(t.prototype, "searchCallback", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this;
      this.props.search.search(e).then(function (r) {
        t.setResults(r, e);
      });
    }
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this,
          t = this.state.activeItemIdx,
          r = this.state.results.map(function (t) {
        return {
          item: e.props.getItemById(t.meta),
          score: t.score
        };
      });
      return r.sort(function (e, t) {
        return t.score - e.score;
      }), React.createElement(SearchWrap, {
        role: "search"
      }, this.state.term && React.createElement(ClearIcon, {
        onClick: this.clear
      }, "×"), React.createElement(SearchIcon, null), React.createElement(SearchInput, {
        value: this.state.term,
        onKeyDown: this.handleKeyDown,
        placeholder: "Search...",
        "aria-label": "Search",
        type: "text",
        onChange: this.search
      }), r.length > 0 && React.createElement(PerfectScrollbarWrap, {
        options: {
          wheelPropagation: !1
        }
      }, React.createElement(SearchResultsBox, {
        "data-role": "search:results"
      }, r.map(function (r, a) {
        return React.createElement(MenuItem, {
          item: Object.create(r.item, {
            active: {
              value: a === t
            }
          }),
          onActivate: e.props.onActivate,
          withoutChildren: !0,
          key: r.item.id,
          "data-role": "search:result"
        });
      }))));
    }
  }), __decorate([bind, debounce(400)], t.prototype, "searchCallback", null), t;
}(React.PureComponent);

export { SearchBox };