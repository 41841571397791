"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoServerExample = void 0;

var NoServerExample = function NoServerExample() {
  return {
    Server: function Server(server, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (['example.com', 'localhost'].indexOf(server.url) !== -1) {
        report({
          message: 'Server `url` should not point at example.com.',
          location: location.child(['url'])
        });
      }
    }
  };
};

exports.NoServerExample = NoServerExample;