"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoEmptyEnumServers = void 0;
var enumError;

(function (enumError) {
  enumError["empty"] = "empty";
  enumError["invalidDefaultValue"] = "invalidDefaultValue";
})(enumError || (enumError = {}));

var NoEmptyEnumServers = function NoEmptyEnumServers() {
  return {
    DefinitionRoot: function DefinitionRoot(root, _ref) {
      var report = _ref.report,
          location = _ref.location;
      if (!root.servers || root.servers.length === 0) return;
      var invalidVariables = [];

      if (Array.isArray(root.servers)) {
        var _iterator = _createForOfIteratorHelper(root.servers),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var server = _step.value;
            var enumErrors = checkEnumVariables(server);
            if (!enumErrors) continue;
            invalidVariables.push.apply(invalidVariables, _toConsumableArray(enumErrors));
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        var _enumErrors = checkEnumVariables(root.servers);

        if (!_enumErrors) return;
        invalidVariables.push.apply(invalidVariables, _toConsumableArray(_enumErrors));
      }

      for (var _i = 0, _invalidVariables = invalidVariables; _i < _invalidVariables.length; _i++) {
        var invalidVariable = _invalidVariables[_i];

        if (invalidVariable === enumError.empty) {
          report({
            message: 'Server variable with `enum` must be a non-empty array.',
            location: location.child(['servers']).key()
          });
        }

        if (invalidVariable === enumError.invalidDefaultValue) {
          report({
            message: 'Server variable define `enum` and `default`. `enum` must include default value',
            location: location.child(['servers']).key()
          });
        }
      }
    }
  };
};

exports.NoEmptyEnumServers = NoEmptyEnumServers;

function checkEnumVariables(server) {
  var _a;

  if (server.variables && Object.keys(server.variables).length === 0) return;
  var errors = [];

  for (var variable in server.variables) {
    var serverVariable = server.variables[variable];
    if (!serverVariable.enum) continue;
    if (Array.isArray(serverVariable.enum) && ((_a = serverVariable.enum) === null || _a === void 0 ? void 0 : _a.length) === 0) errors.push(enumError.empty);
    if (!serverVariable.default) continue;
    var defaultValue = server.variables[variable].default;
    if (serverVariable.enum && !serverVariable.enum.includes(defaultValue)) errors.push(enumError.invalidDefaultValue);
  }

  if (errors.length) return errors;
  return;
}