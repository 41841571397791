import { __makeTemplateObject } from "tslib";
import { styled, MiddlePanel, mediaCSS, ZERO_BREAKPOINT } from "../../redoc-lib";
export var SamplesWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", "px;\n  border-radius: ", ";\n  ", "\n"], ["\n  padding: ", "px;\n  border-radius: ", ";\n  ", "\n"])), function (e) {
  return 4 * e.theme.spacing.unit;
}, function (e) {
  return e.theme.codeBlock.borderRadius;
}, function (e) {
  var t = e.theme;
  return mediaCSS({
    backgroundColor: t.rightPanel.panelBackgroundColor
  });
});
export var ExamplesWrap = styled(SamplesWrap)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var FullWidthPanel = styled(MiddlePanel)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (e) {
  var t,
      a = e.theme;
  return mediaCSS({
    paddingLeft: a.spacing.sectionHorizontal,
    paddingRight: a.spacing.sectionHorizontal,
    width: (t = {}, t[ZERO_BREAKPOINT] = "100%", t[a.rightPanel.showAtBreakpoint] = "100%", t)
  });
});
var templateObject_1, templateObject_2, templateObject_3;