import { __assign, __rest } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { MediaTypeSamples } from "./MediaTypeSamples";
import { DropdownOrLabel } from "../DropdownOrLabel/DropdownOrLabel";
import { MediaTypesSwitch } from "../MediaTypeSwitch/MediaTypesSwitch";
import { MimeLabel } from "../../../../components/shared";
import { Dropdown } from "../../common-elements/dropdown";
import { DropdownWrapper } from "../../..";
export var PayloadSamples = observer(function (e) {
  var r = e.onlyDefaultMimeType,
      o = e.operation,
      t = e.content,
      a = __rest(e, ["onlyDefaultMimeType", "operation", "content"]);

  if (void 0 === t) return null;
  var n = r && (null == o ? void 0 : o.defaultMimeType);
  return React.createElement(MediaTypesSwitch, {
    content: t,
    renderDropdown: n ? function () {
      return null;
    } : function (e) {
      return React.createElement(DropdownWrapper, null, React.createElement(DropdownOrLabel, __assign({
        Label: MimeLabel,
        Dropdown: Dropdown,
        variant: "dark",
        fullWidth: !0
      }, e)));
    }
  }, function (e) {
    return React.createElement(MediaTypeSamples, __assign({
      key: "samples",
      mediaType: e
    }, a));
  });
});