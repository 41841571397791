import { __assign } from "tslib";
import stringifyObject from "stringify-object";
import { CodeBuilder } from "../../helpers/code-builder";
import { HTTPSnippet } from "../..";
import { addIndentation, buildUrlExpression, printUrlVariablesDeclarations } from "../../helpers/code-helpers";
import { normalizeMimeType } from "../../../../utils";
import { capitalizeFirst } from "../../../generator";
import { Lang } from "../../helpers/constants";

var handler = function handler(t, e, a) {
  var s,
      n,
      i,
      r,
      o = a.target,
      p = a.client,
      l = Object.assign({
    indent: "  ",
    withWrapper: !0
  }, e),
      u = new CodeBuilder({
    indentation: l.indent,
    variablesPrefix: l.variablesPrefix,
    capitalize: !0,
    lang: Lang.CSHARP
  }),
      c = formatHttpMethod(t.method);

  if (l.withComments && addComments(u), l.withWrapper && (u.push("using System;"), u.push("using System.Net.Http;"), u.push("using System.Threading.Tasks;"), (w("application/json") || w("application/xml") || t.basicAuth) && u.push("using System.Text;"), (w("application/x-www-form-urlencoded") || (null === (s = t.securityOAuth2ExtraCalls) || void 0 === s ? void 0 : s.length)) && u.push("using System.Collections.Generic;"), (w("application/json") || (null === (n = t.securityOAuth2ExtraCalls) || void 0 === n ? void 0 : n.length)) && u.push("using Newtonsoft.Json.Linq;"), t.headersObj && "application/octet-stream" === t.headersObj["Content-Type"] && (u.push("using System.IO;"), u.push("using System.Net.Http.Headers;")), u.blank(), u.push("public class Program"), u.push("{"), u.push(1, "public static async Task Main(string[] args)"), u.push(1, "{"), u.push(2, "using (var client = new HttpClient())"), u.push(2, "{")), null === (i = t.securityOAuth2ExtraCalls) || void 0 === i ? void 0 : i.length) {
    var h = new HTTPSnippet(null === (r = t.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r[0]).convert(o, p, __assign(__assign({}, l), {
      withImports: !1,
      withWrapper: !1,
      withComments: !1,
      variablesPrefix: "oauth2"
    }));
    u.push(h), u.push(3, "var oauth2Response = await oauth2Request.Content.ReadAsStringAsync();"), u.push(3, "JObject inputJObject = JObject.Parse(oauth2Response);"), u.push(3, 'var accessToken = inputJObject["access_token"].ToString();'), u.blank();
  }

  var d = Object.keys(t.allHeaders).filter(function (t) {
    switch (t.toLowerCase()) {
      case "content-type":
      case "content-length":
      case "accept-encoding":
        return !1;

      default:
        return !0;
    }
  });

  if (d.length && d.forEach(function (e) {
    var a;
    u.push(3, 'client.DefaultRequestHeaders.Add("%s", %s);', e, (null === (a = t.securityOAuth2ExtraCalls) || void 0 === a ? void 0 : a.length) ? '"Bearer " + accessToken' : '"' + t.allHeaders[e] + '"');
  }), t.basicAuth) {
    var m = t.basicAuth,
        g = m.username,
        f = m.password;
    u.push(3, 'var base64String = Convert.ToBase64String(Encoding.ASCII.GetBytes("' + g + ":" + f + '"));'), u.push(3, 'client.DefaultRequestHeaders.Add("Authorization", "Basic "+ base64String);');
  }

  if (t.postData) switch (normalizeMimeType(t.postData.mimeType)) {
    case "application/x-www-form-urlencoded":
      t.postData.params && (u.blank(), u.push(3, "List<KeyValuePair<string, string>> postData = new List<KeyValuePair<string, string>>();"), t.postData.params.forEach(function (t) {
        u.push(3, 'postData.Add(new KeyValuePair<string, string>("%s", "%s"));', t.name, t.value);
      }), u.blank());
      break;

    case "multipart/form-data":
      t.postData.params && (u.blank(), u.push(3, "MultipartFormDataContent postData = new MultipartFormDataContent();"), t.postData.params.forEach(function (t) {
        u.push(3, 'postData.Add(new StringContent("%s"), "%s");', t.value, t.name);
      }));
      break;

    case "application/json":
      t.postData.jsonObj && (u.push(3, 'JObject json = JObject.Parse(@"' + addIndentation(stringifyObject(t.postData.jsonObj, {
        indent: l.indent,
        inlineCharacterLimit: 25
      }).replace(/"/g, '""'), {
        level: 3,
        firstLine: !1
      }) + '");'), u.push(3, 'var postData = new StringContent(json.ToString(), Encoding.UTF8, "application/json");'));
      break;

    case "application/xml":
      t.postData.text && (t.postData.text.trim().split("\n").forEach(function (t, e) {
        var a = (0 === e ? 'string xml = @"' : "") + t.replace(/"/g, '""');
        u.push(0 === e ? 3 : 4, a);
      }), u.push(3, '";'), u.push(3, 'var postData = new StringContent(xml, Encoding.UTF8, "application/xml");'));
  } else b() && (u.push(3, "var ms = new MemoryStream();"), u.push(3, "var postData = new StreamContent(ms);"), u.push(3, 'postData.Headers.ContentType = new MediaTypeHeaderValue("application/octet-stream");'));
  return printUrlVariablesDeclarations(t, u, 3), u.push(3, "var %s = await client.%sAsync(%s%s);", u.var("request"), c, buildUrlExpression(t, u), l.withImports ? isDataMethod(t.method) ? "application/x-www-form-urlencoded" === normalizeMimeType(t.postData && t.postData.mimeType) ? ", new FormUrlEncodedContent(postData)" : "delete" === t.method ? "" : t.postData && t.postData.params || b() ? ", postData" : ", null" : "" : ", new FormUrlEncodedContent(postData)"), l.withWrapper && (u.push(3, "var response = await request.Content.ReadAsStringAsync();"), u.blank(), u.push(3, "Console.WriteLine(response);"), u.push(2, "}"), u.push(1, "}"), u.push("}")), u.join();

  function w(e) {
    return t.postData && normalizeMimeType(t.postData.mimeType) === e;
  }

  function b() {
    return t.headersObj && "application/octet-stream" === t.headersObj["Content-Type"];
  }
};

export var info = {
  key: "httpclient",
  title: "HttpClient",
  link: "https://docs.microsoft.com/en-us/dotnet/api/system.net.http.httpclient",
  description: ".NET Standard HTTP Client"
};
export default handler;

function addComments(t) {
  t.push("/*"), t.push(" * Requires >= .NET 5"), t.push(" *"), t.push(' * Requires package "Newtonsoft.Json" >= 9.01'), t.push(" * See here for installation details:"), t.push(" *  https://www.newtonsoft.com/json"), t.push(" */"), t.blank();
}

function formatHttpMethod(t) {
  return capitalizeFirst(t.toLowerCase());
}

function isDataMethod(t) {
  return t && ["POST", "PUT", "DELETE", "PATCH"].includes(t.toUpperCase());
}