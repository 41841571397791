"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathsKebabCase = void 0;

var PathsKebabCase = function PathsKebabCase() {
  return {
    PathItem: function PathItem(_path, _ref) {
      var report = _ref.report,
          key = _ref.key;
      var segments = key.substr(1).split('/');

      if (!segments.every(function (segment) {
        return /^{.+}$/.test(segment) || /^[a-z0-9-.]+$/.test(segment);
      })) {
        report({
          message: "`".concat(key, "` does not use kebab-case."),
          location: {
            reportOnKey: true
          }
        });
      }
    }
  };
};

exports.PathsKebabCase = PathsKebabCase;