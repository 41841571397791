import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
import { FieldsGroupHeader, ShelfIcon } from "../../redoc-lib/src/common-elements/";
import { Header } from "./Header";
import { Title } from "./Title";
import { Panel } from "./Panel";
import { PanelBody } from "./PanelBody";
export var ContentPanel = styled(Panel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  background: #ffffff;\n\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n\n  > ", " {\n    padding: ", "px;\n    font-size: 18px;\n    line-height: 18px;\n    font-weight: ", ";\n\n    ", " {\n      margin-right: 8px;\n      color: ", ";\n    }\n\n    ", " {\n      fill: ", ";\n    }\n  }\n\n  ", " {\n    padding: ", "px;\n  }\n\n  > ", " + ", " {\n    padding-top: 1px; /* to prevent border overflow */\n  }\n\n  ", " {\n    > ", " > div:first-child > & {\n      margin-top: 0;\n    }\n  }\n"], ["\n  border: 1px solid ", ";\n  background: #ffffff;\n\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n\n  > ", " {\n    padding: ", "px;\n    font-size: 18px;\n    line-height: 18px;\n    font-weight: ", ";\n\n    ", " {\n      margin-right: 8px;\n      color: ", ";\n    }\n\n    ", " {\n      fill: ", ";\n    }\n  }\n\n  ", " {\n    padding: ", "px;\n  }\n\n  > ", " + ", " {\n    padding-top: 1px; /* to prevent border overflow */\n  }\n\n  ", " {\n    > ", " > div:first-child > & {\n      margin-top: 0;\n    }\n  }\n"])), function (n) {
  return n.theme.colors.border.light;
}, Header, function (n) {
  return 4 * n.theme.spacing.unit;
}, function (n) {
  return n.theme.typography.fontWeightBold;
}, Title, function (n) {
  return n.theme.colors.text.primary;
}, ShelfIcon, function (n) {
  return n.theme.colors.text.primary;
}, PanelBody, function (n) {
  return 4 * n.theme.spacing.unit;
}, Header, PanelBody, FieldsGroupHeader, PanelBody);
var templateObject_1;