import { __makeTemplateObject } from "tslib";
import { SECTION_ATTR } from "../services/constants";
import styled, { css, mediaCSS, mediaMap, ZERO_BREAKPOINT } from "../styled-components";
import { LayoutVariant } from "../../../services/store-types";
export var MiddlePanel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "}\n"], ["\n  ", "}\n"])), function (t) {
  var n,
      e,
      i,
      a,
      o = t.theme,
      p = t.compact,
      r = t.isStacked,
      l = p ? 0 : o.spacing.sectionVertical,
      c = o.layout.showDarkRightPanel ? o.spacing.sectionHorizontal : o.spacing.sectionHorizontal / 2;
  return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", "\n      ", " {\n        width: 100%;\n        padding-top: ", ";\n        padding-bottom: ", ";\n      }\n    "], ["\n      ", "\n      ", " {\n        width: 100%;\n        padding-top: ", ";\n        padding-bottom: ", ";\n      }\n    "])), mediaCSS({
    width: mediaMap(o.breakpoints, [o.rightPanel.width], (n = {}, n[ZERO_BREAKPOINT] = function () {
      return "100%";
    }, n[o.rightPanel.showAtBreakpoint] = function (t) {
      return "calc(100% - " + t + ")";
    }, n)),
    paddingLeft: o.spacing.sectionHorizontal,
    paddingRight: (e = {}, e[ZERO_BREAKPOINT] = o.spacing.sectionHorizontal, e[o.rightPanel.showAtBreakpoint] = r ? o.spacing.sectionHorizontal : c, e),
    paddingTop: (i = {}, i[ZERO_BREAKPOINT] = l, i[o.rightPanel.showAtBreakpoint] = 0, i),
    paddingBottom: (a = {}, a[ZERO_BREAKPOINT] = l, a[o.rightPanel.showAtBreakpoint] = r ? o.spacing.sectionVertical : 0, a)
  }), o.mediaQueries.print, l, l);
});
export var Section = styled.div.attrs(function (t) {
  var n;
  return (n = {})[SECTION_ATTR] = t.id, n;
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 0;\n  padding-right: 0;\n  width: 100%;\n  &:last-child {\n    min-height: calc(100vh + 1px);\n  }\n\n  & > &:last-child {\n    min-height: initial;\n  }\n\n  ", "\n  ", "\n"], ["\n  padding-left: 0;\n  padding-right: 0;\n  width: 100%;\n  &:last-child {\n    min-height: calc(100vh + 1px);\n  }\n\n  & > &:last-child {\n    min-height: initial;\n  }\n\n  ", "\n  ", "\n"])), function (t) {
  var n,
      e,
      i = t.theme;
  return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", "\n\n    ", " {\n      padding: 0;\n    }\n  "], ["\n    ", "\n\n    ", " {\n      padding: 0;\n    }\n  "])), mediaCSS({
    paddingTop: (n = {}, n[ZERO_BREAKPOINT] = 0, n[i.rightPanel.showAtBreakpoint] = i.spacing.sectionVertical, n),
    paddingBottom: (e = {}, e[ZERO_BREAKPOINT] = 0, e[i.rightPanel.showAtBreakpoint] = i.spacing.sectionVertical, e)
  }), i.mediaQueries.print);
}, function (t) {
  return t.underlined && "\n    position: relative;\n\n    &:not(:last-of-type):after {\n      position: absolute;\n      bottom: 0;\n      width: 100%;\n      display: block;\n      content: '';\n      border-bottom: 1px solid " + t.theme.colors.border.light + "\n    }\n  " || "";
});
export var RightPanel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (t) {
  var n,
      e,
      i,
      a,
      o = t.theme,
      p = o.layout.showDarkRightPanel ? o.spacing.sectionHorizontal : o.spacing.sectionHorizontal / 2;
  return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      ", "\n      ", " {\n        width: 100%;\n        padding-top: ", ";\n        padding-bottom: ", ";\n      }\n    "], ["\n      ", "\n      ", " {\n        width: 100%;\n        padding-top: ", ";\n        padding-bottom: ", ";\n      }\n    "])), mediaCSS({
    color: o.rightPanel.textColor,
    width: mediaMap(o.breakpoints, [o.rightPanel.width], (n = {}, n[ZERO_BREAKPOINT] = function () {
      return "100%";
    }, n[o.rightPanel.showAtBreakpoint] = function (t) {
      return t;
    }, n)),
    paddingLeft: (e = {}, e[ZERO_BREAKPOINT] = o.spacing.sectionHorizontal, e[o.rightPanel.showAtBreakpoint] = p, e),
    paddingRight: o.spacing.sectionHorizontal,
    paddingTop: (i = {}, i[ZERO_BREAKPOINT] = o.spacing.sectionVertical, i[o.rightPanel.showAtBreakpoint] = 0, i),
    paddingBottom: (a = {}, a[ZERO_BREAKPOINT] = o.spacing.sectionVertical, a[o.rightPanel.showAtBreakpoint] = 0, a)
  }), o.mediaQueries.print, o.spacing.sectionVertical, o.spacing.sectionVertical);
});
export var DarkRightPanel = styled(RightPanel)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (t) {
  var n = t.theme;
  return mediaCSS({
    backgroundColor: n.rightPanel.panelBackgroundColor
  });
});
export var Row = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  padding: 0;\n  ", "\n"], ["\n  display: flex;\n  width: 100%;\n  padding: 0;\n  ", "\n"])), function (t) {
  var n,
      e = t.theme,
      i = t.layout;
  return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    ", "\n    ", " {\n      flex-direction: column;\n    }\n  "], ["\n    ", "\n    ", " {\n      flex-direction: column;\n    }\n  "])), mediaCSS({
    flexDirection: (n = {}, n[ZERO_BREAKPOINT] = "column", n[e.rightPanel.showAtBreakpoint] = i === LayoutVariant.STACKED ? "column" : "row", n)
  }), e.mediaQueries.print);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;