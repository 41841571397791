import { __decorate } from "tslib";
import { bind, debounce } from "decko";
import { EventEmitter } from "eventemitter3";
import { IS_BROWSER } from "../../redoc-lib";
import { isSameHash } from "./helpers";

var ProHashHistoryService = function () {
  function e(e, t) {
    var r = this;
    Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "multiSpec", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    }), Object.defineProperty(this, "_emiter", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "basePath", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "emit", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        r._emiter.emit("popstate", r.currentId);
      }
    });
    var i = e.routingBasePath || "";
    this.basePath = i.endsWith("/") ? i.substring(0, i.length - 1) : i, this.basePath.startsWith("/") && (this.basePath = this.basePath.substring(1)), this._emiter = new EventEmitter(), this.bind();
  }

  return Object.defineProperty(e.prototype, "currentId", {
    get: function get() {
      if (!IS_BROWSER) return "";
      var e = decodeURIComponent(window.location.hash.substr(1)),
          t = window.location.pathname.substring(1);
      return t.startsWith(this.basePath + "/") && (t = t.substring(this.basePath.length + 1)), t.endsWith("/") && (t = t.substring(0, t.length - 1)), e && e.startsWith("operation/") ? this.multiSpec ? t.split("/")[0] + "/" + e : e : t ? t + (e ? "/" + e : "") : e;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "makeUrl", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      var r = "";
      return IS_BROWSER && (r = window.location.search), (e.startsWith("/") ? "/" + (this.basePath ? this.basePath + "/" : "") + e.substring(1) : e) + r + (t ? "#" + t : "");
    }
  }), Object.defineProperty(e.prototype, "linkForId", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      if (!e) return "";
      if ("" === e) return this.makeUrl("", "");
      var t = "";
      return this.multiSpec && (t = "/" + e.split("/")[0], e = e.split("/").slice(1).join("/")), this.makeUrl(t, e);
    }
  }), Object.defineProperty(e.prototype, "generateDeepLink", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      if (t) return t + this.options.deepLinkPrefix + e;
    }
  }), Object.defineProperty(e.prototype, "subscribe", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this._emiter.addListener("popstate", e);

      return function () {
        return t.removeListener("popstate", e);
      };
    }
  }), Object.defineProperty(e.prototype, "bind", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      IS_BROWSER && (window.addEventListener("popstate", this.emit, !1), window.addEventListener("hashchange", this.emit, !1));
    }
  }), Object.defineProperty(e.prototype, "dispose", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      IS_BROWSER && (window.removeEventListener("popstate", this.emit), window.removeEventListener("hashchange", this.emit));
    }
  }), Object.defineProperty(e.prototype, "replace", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      if (void 0 === t && (t = !1), t) return this.replaceDebounced(e);
      this.replaceNow(e, t);
    }
  }), Object.defineProperty(e.prototype, "replaceForField", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      var r = this;

      if (void 0 === t && (t = !1), IS_BROWSER && e) {
        var i,
            n = new URL(e, window.location.href);
        i = n, t ? window.history.replaceState(null, "", i) : (window.history.pushState(null, "", i), r.emit());
      }
    }
  }), Object.defineProperty(e.prototype, "replaceNow", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      if (void 0 === t && (t = !1), IS_BROWSER && null != e && !isSameHash(e, this.currentId)) {
        var r = new URL(this.linkForId(e), window.location.href);
        t ? IS_BROWSER && window.history.replaceState(null, "", r.href) : IS_BROWSER && (window.history.pushState(null, "", r.href), this.emit());
      }
    }
  }), Object.defineProperty(e.prototype, "replaceDebounced", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return this.replaceNow(e, !0);
    }
  }), __decorate([bind], e.prototype, "replace", null), __decorate([bind], e.prototype, "replaceForField", null), __decorate([bind, debounce], e.prototype, "replaceDebounced", null), e;
}();

export { ProHashHistoryService };