"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoPathTrailingSlash = void 0;

var NoPathTrailingSlash = function NoPathTrailingSlash() {
  return {
    PathItem: function PathItem(_path, _ref) {
      var report = _ref.report,
          key = _ref.key,
          location = _ref.location;

      if (key.endsWith('/') && key !== '/') {
        report({
          message: "`".concat(key, "` should not have a trailing slash."),
          location: location.key()
        });
      }
    }
  };
};

exports.NoPathTrailingSlash = NoPathTrailingSlash;