"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoContact = void 0;

var utils_1 = require("../utils");

var InfoContact = function InfoContact() {
  return {
    Info: function Info(info, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (!info.contact) {
        report({
          message: utils_1.missingRequiredField('Info', 'contact'),
          location: location.child('contact').key()
        });
      }
    }
  };
};

exports.InfoContact = InfoContact;