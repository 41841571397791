"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestMimeType = void 0;

var utils_1 = require("../../utils");

var RequestMimeType = function RequestMimeType(_ref) {
  var allowedValues = _ref.allowedValues;
  return {
    DefinitionRoot: function DefinitionRoot(root, ctx) {
      utils_1.validateMimeType({
        type: 'consumes',
        value: root
      }, ctx, allowedValues);
    },
    Operation: {
      leave: function leave(operation, ctx) {
        utils_1.validateMimeType({
          type: 'consumes',
          value: operation
        }, ctx, allowedValues);
      }
    }
  };
};

exports.RequestMimeType = RequestMimeType;