import { __makeTemplateObject } from "tslib";
import { darken } from "polished";
import styled, { css, keyframes } from "../../redoc-lib/src/styled-components";

var getBlink = function getBlink(n) {
  return keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    background-color: ", ";\n  }\n\n  50% {\n    background-color: ", ";\n  }\n\n  100% {\n    background-color: ", ";\n  }\n"], ["\n  0% {\n    background-color: ", ";\n  }\n\n  50% {\n    background-color: ", ";\n  }\n\n  100% {\n    background-color: ", ";\n  }\n"])), darken(.1, n), n, darken(.1, n));
};

function getThemeColorVariant(n, e) {
  return n.colors[e || "secondary"] || n.colors.secondary;
}

function getThemeButtonSize(n, e) {
  return n.components.buttons.sizes[e || "medium"] || n.components.buttons.sizes.normal;
}

export var baseButton = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  outline: none;\n  border: none;\n  background-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n  font-weight: ", ";\n  font-size: 14px;\n  padding: 2px 20px;\n  cursor: pointer;\n  transition: background-color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease;\n  line-height: 1;\n\n  font-family: ", ";\n  box-shadow: ", ";\n\n  &:hover {\n    background-color: ", ";\n    ", ";\n    box-shadow: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n    box-shadow: ", ";\n  }\n"], ["\n  outline: none;\n  border: none;\n  background-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n  font-weight: ", ";\n  font-size: 14px;\n  padding: 2px 20px;\n  cursor: pointer;\n  transition: background-color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease;\n  line-height: 1;\n\n  font-family: ", ";\n  box-shadow: ", ";\n\n  &:hover {\n    background-color: ", ";\n    ", ";\n    box-shadow: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n    box-shadow: ", ";\n  }\n"])), function (n) {
  return n.theme.colors.secondary.main;
}, function (n) {
  return n.theme.colors.secondary.contrastText;
}, function (n) {
  return n.theme.components.buttons.borderRadius;
}, function (n) {
  return n.theme.components.buttons.fontWeight;
}, function (n) {
  return n.theme.components.buttons.fontFamily;
}, function (n) {
  return n.theme.components.buttons.boxShadow;
}, function (n) {
  var e = n.theme;
  return darken(.05, e.colors.secondary.main);
}, function (n) {
  return n.theme.components.buttons.hoverStyle;
}, function (n) {
  return n.theme.components.buttons.hoverBoxShadow;
}, function (n) {
  var e = n.theme;
  return darken(.1, e.colors.secondary.main);
}, function (n) {
  return n.theme.components.buttons.hoverBoxShadow;
});

var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    getColorVariant = function getColorVariant(n, e) {
  return "outlined" === e ? "\n  background-color: transparent;\n  border: 2px solid " + n.main + ";\n  color: " + n.main + ";\n  &:hover {\n    border: 2px solid " + darken(.1, n.main) + ";\n    background-color: transparent;\n  }\n\n  &:active {\n    border: 2px solid " + darken(.15, n.main) + ";\n  }\n" : "\n  background-color: " + n.main + ";\n  color: " + n.contrastText + ";\n  &:hover {\n    background-color: " + darken(.05, n.main) + ";\n  }\n\n  &:active {\n    background-color: " + darken(.1, n.main) + ";\n  }\n";
},
    getSize = function getSize(n) {
  return "\n  font-size: " + n.fontSize + ";\n  padding: " + n.padding + ";\n  min-width: " + n.minWidth + ";\n";
};

export var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-decoration: none;\n  text-align: center;\n\n  width: ", ";\n\n  ", "\n\n  ", "\n  ", "\n\n  & + & {\n    margin-left: 0.85em;\n  }\n\n  ", "\n\n  ", "\n"], ["\n  text-decoration: none;\n  text-align: center;\n\n  width: ", ";\n\n  ", "\n\n  ", "\n  ", "\n\n  & + & {\n    margin-left: 0.85em;\n  }\n\n  ", "\n\n  ", "\n"])), function (n) {
  return n.fullWidth ? "100%" : "auto";
}, baseButton, function (n) {
  var e = n.color,
      o = n.theme,
      t = n.variant;
  return getColorVariant(getThemeColorVariant(o, e), t);
}, function (n) {
  var e = n.size,
      o = n.theme;
  return getSize(getThemeButtonSize(o, e));
}, function (n) {
  var e = n.blinking,
      o = n.color,
      t = n.theme;
  return e && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      pointer-events: none;\n      animation: ", " 1.2s infinite;\n    "], ["\n      pointer-events: none;\n      animation: ", " 1.2s infinite;\n    "])), getBlink(getThemeColorVariant(t, o).main));
}, function (n) {
  return n.disabled && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      &,\n      &:hover {\n        cursor: default;\n        color: #999;\n        pointer-events: none;\n      }\n    "], ["\n      &,\n      &:hover {\n        cursor: default;\n        color: #999;\n        pointer-events: none;\n      }\n    "])));
});
export var ButtonLink = Button.withComponent("a");