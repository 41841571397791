"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationOperationId = void 0;

var utils_1 = require("../utils");

var OperationOperationId = function OperationOperationId() {
  return {
    DefinitionRoot: {
      PathItem: {
        Operation: function Operation(operation, ctx) {
          utils_1.validateDefinedAndNonEmpty('operationId', operation, ctx);
        }
      }
    }
  };
};

exports.OperationOperationId = OperationOperationId;