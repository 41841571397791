import { __decorate } from "tslib";
import { bind } from "decko";
import EventEmitter from "eventemitter3";
import { IS_BROWSER, querySelector, Throttle } from "../utils";

var EVENT = "scroll",
    ScrollService = function () {
  function e(e) {
    Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "_scrollParent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "_emiter", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "_prevOffsetY", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: 0
    }), this._scrollParent = IS_BROWSER ? window : void 0, this._emiter = new EventEmitter(), this.bind();
  }

  return Object.defineProperty(e.prototype, "prevOffsetY", {
    set: function set(e) {
      this._prevOffsetY = e;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "bind", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this._prevOffsetY = this.scrollY(), this._scrollParent && this._scrollParent.addEventListener(EVENT, this.handleScroll);
    }
  }), Object.defineProperty(e.prototype, "dispose", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this._scrollParent && this._scrollParent.removeEventListener(EVENT, this.handleScroll), this._emiter.removeAllListeners(EVENT);
    }
  }), Object.defineProperty(e.prototype, "scrollY", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return "undefined" != typeof HTMLElement && this._scrollParent instanceof HTMLElement ? this._scrollParent.scrollTop : void 0 !== this._scrollParent ? this._scrollParent.pageYOffset : 0;
    }
  }), Object.defineProperty(e.prototype, "isElementBellow", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      if (null !== e) return e.getBoundingClientRect().top > this.options.scrollYOffset();
    }
  }), Object.defineProperty(e.prototype, "isElementAbove", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      if (null !== e) {
        var t = e.getBoundingClientRect().top;
        return (t > 0 ? Math.floor(t) : Math.ceil(t)) <= this.options.scrollYOffset();
      }
    }
  }), Object.defineProperty(e.prototype, "subscribe", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this._emiter.addListener(EVENT, e);

      return function () {
        return t.removeListener(EVENT, e);
      };
    }
  }), Object.defineProperty(e.prototype, "scrollIntoView", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      if (null !== e && (e.scrollIntoView(t), this._scrollParent && this._scrollParent.scrollBy)) {
        var r = [0, -Math.floor(this.options.scrollYOffset())],
            l = r[0],
            o = r[1];

        this._scrollParent.scrollBy(l, o);
      }
    }
  }), Object.defineProperty(e.prototype, "scrollIntoViewBySelector", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      var r = querySelector(e);
      this.scrollIntoView(r, t);
    }
  }), Object.defineProperty(e.prototype, "handleScroll", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.scrollY() - this._prevOffsetY > 0;
      this._prevOffsetY = this.scrollY(), this._emiter.emit(EVENT, e);
    }
  }), __decorate([bind, Throttle(100)], e.prototype, "handleScroll", null), e;
}();

export { ScrollService };