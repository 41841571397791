import { __assign } from "tslib";
import { fromStorage, getParameterValue, setParameterValue, setSecurityDetails, toStorage } from "../../utils";
export function normalizeUrlProtocol(e) {
  return e.startsWith("//") ? "https:" + e : e;
}
export function updateStorage(e) {
  if (e.values.auth) for (var r = 0, a = Object.keys(e.values.auth); r < a.length; r++) {
    var t = a[r],
        i = e.values.auth[t];
    setSecurityDetails(t, i);
  }
  updateParameterStorage(e);
}
export function updateParameterStorage(e) {
  for (var r, a = 0, t = ["path", "query", "header", "cookie"]; a < t.length; a++) {
    var i = t[a];
    if (null === (r = e.values) || void 0 === r ? void 0 : r[i]) for (var n = 0, o = Object.keys(e.values[i]); n < o.length; n++) {
      var u = o[n];
      setParameterValue(i, u, e.values[i][u]);
    }
  }
}
export var getParameters = function getParameters(e, r) {
  return e.filter(function (e) {
    return e.in === r;
  }).reduce(function (e, r) {
    var a = r.in,
        t = r.name;
    return e[t] = getParameterValue(a, t), e;
  }, {});
};
var pathTemplateRegExp = /(?:{)(\w+)(?:})/g;
export function getServerWithVariables(e, r) {
  void 0 === r && (r = 0);
  var a = e[r],
      t = getDefaultOrStoredVariables(a);
  return __assign(__assign({}, a), {
    variables: t
  });
}
export function getInitialServerWithVariables(e) {
  var r = fromStorage("serverUrl"),
      a = e.find(function (e) {
    return e.url === r;
  }) || e[0],
      t = getDefaultOrStoredVariables(a);
  return __assign(__assign({}, a), {
    variables: t
  });
}
export function expandVariables(e, r) {
  return void 0 === r && (r = {}), e.replace(pathTemplateRegExp, function (e, a) {
    return r[a] || e;
  });
}
export function expandDefaultServerVariables(e, r) {
  return void 0 === r && (r = {}), e.replace(pathTemplateRegExp, function (e, a) {
    return r[a] && r[a].default || e;
  });
}
export function getDefaultOrStoredVariables(e) {
  if (!(null == e ? void 0 : e.variables)) return {};
  var r = fromStorage("variables"),
      a = r && JSON.parse(r) || {},
      t = {};
  return Object.entries(e.variables || {}).forEach(function (e) {
    var r = e[0],
        i = e[1];
    t[r] = a[r] || i.default || "";
  }), __assign({}, t);
}
export function updateVariablesStorage(e, r) {
  var a,
      t = fromStorage("variables"),
      i = t && JSON.parse(t) || {};
  toStorage("variables", JSON.stringify(__assign(__assign({}, i), ((a = {})[e] = r, a))));
}