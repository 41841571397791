"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extendErrors = exports.resetErrorsCount = exports.reportExtraError = exports.reportError = exports.keyword$DataError = exports.keywordError = void 0;

var codegen_1 = require("./codegen");

var util_1 = require("./util");

var names_1 = require("./names");

exports.keywordError = {
  message: function message(_ref) {
    var keyword = _ref.keyword;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["should pass \"", "\" keyword validation"])), keyword);
  }
};
exports.keyword$DataError = {
  message: function message(_ref2) {
    var keyword = _ref2.keyword,
        schemaType = _ref2.schemaType;
    return schemaType ? codegen_1.str(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\"", "\" keyword must be ", " ($data)"])), keyword, schemaType) : codegen_1.str(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\"", "\" keyword is invalid ($data)"])), keyword);
  }
};

function reportError(cxt) {
  var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : exports.keywordError;
  var errorPaths = arguments.length > 2 ? arguments[2] : undefined;
  var overrideAllErrors = arguments.length > 3 ? arguments[3] : undefined;
  var it = cxt.it;
  var gen = it.gen,
      compositeRule = it.compositeRule,
      allErrors = it.allErrors;
  var errObj = errorObjectCode(cxt, error, errorPaths);

  if (overrideAllErrors !== null && overrideAllErrors !== void 0 ? overrideAllErrors : compositeRule || allErrors) {
    addError(gen, errObj);
  } else {
    returnErrors(it, codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["[", "]"])), errObj));
  }
}

exports.reportError = reportError;

function reportExtraError(cxt) {
  var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : exports.keywordError;
  var errorPaths = arguments.length > 2 ? arguments[2] : undefined;
  var it = cxt.it;
  var gen = it.gen,
      compositeRule = it.compositeRule,
      allErrors = it.allErrors;
  var errObj = errorObjectCode(cxt, error, errorPaths);
  addError(gen, errObj);

  if (!(compositeRule || allErrors)) {
    returnErrors(it, names_1.default.vErrors);
  }
}

exports.reportExtraError = reportExtraError;

function resetErrorsCount(gen, errsCount) {
  gen.assign(names_1.default.errors, errsCount);
  gen.if(codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", " !== null"])), names_1.default.vErrors), function () {
    return gen.if(errsCount, function () {
      return gen.assign(codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["", ".length"])), names_1.default.vErrors), errsCount);
    }, function () {
      return gen.assign(names_1.default.vErrors, null);
    });
  });
}

exports.resetErrorsCount = resetErrorsCount;

function extendErrors(_ref3) {
  var gen = _ref3.gen,
      keyword = _ref3.keyword,
      schemaValue = _ref3.schemaValue,
      data = _ref3.data,
      errsCount = _ref3.errsCount,
      it = _ref3.it;

  /* istanbul ignore if */
  if (errsCount === undefined) throw new Error("ajv implementation error");
  var err = gen.name("err");
  gen.forRange("i", errsCount, names_1.default.errors, function (i) {
    gen.const(err, codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["", "[", "]"])), names_1.default.vErrors, i));
    gen.if(codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", ".instancePath === undefined"])), err), function () {
      return gen.assign(codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["", ".instancePath"])), err), codegen_1.strConcat(names_1.default.instancePath, it.errorPath));
    });
    gen.assign(codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", ".schemaPath"])), err), codegen_1.str(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["", "/", ""])), it.errSchemaPath, keyword));

    if (it.opts.verbose) {
      gen.assign(codegen_1._(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["", ".schema"])), err), schemaValue);
      gen.assign(codegen_1._(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["", ".data"])), err), data);
    }
  });
}

exports.extendErrors = extendErrors;

function addError(gen, errObj) {
  var err = gen.const("err", errObj);
  gen.if(codegen_1._(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["", " === null"])), names_1.default.vErrors), function () {
    return gen.assign(names_1.default.vErrors, codegen_1._(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["[", "]"])), err));
  }, codegen_1._(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["", ".push(", ")"])), names_1.default.vErrors, err));
  gen.code(codegen_1._(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["", "++"])), names_1.default.errors));
}

function returnErrors(it, errs) {
  var gen = it.gen,
      validateName = it.validateName,
      schemaEnv = it.schemaEnv;

  if (schemaEnv.$async) {
    gen.throw(codegen_1._(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["new ", "(", ")"])), it.ValidationError, errs));
  } else {
    gen.assign(codegen_1._(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["", ".errors"])), validateName), errs);
    gen.return(false);
  }
}

var E = {
  keyword: new codegen_1.Name("keyword"),
  schemaPath: new codegen_1.Name("schemaPath"),
  params: new codegen_1.Name("params"),
  propertyName: new codegen_1.Name("propertyName"),
  message: new codegen_1.Name("message"),
  schema: new codegen_1.Name("schema"),
  parentSchema: new codegen_1.Name("parentSchema")
};

function errorObjectCode(cxt, error, errorPaths) {
  var createErrors = cxt.it.createErrors;
  if (createErrors === false) return codegen_1._(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["{}"])));
  return errorObject(cxt, error, errorPaths);
}

function errorObject(cxt, error) {
  var errorPaths = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var gen = cxt.gen,
      it = cxt.it;
  var keyValues = [errorInstancePath(it, errorPaths), errorSchemaPath(cxt, errorPaths)];
  extraErrorProps(cxt, error, keyValues);
  return gen.object.apply(gen, keyValues);
}

function errorInstancePath(_ref4, _ref5) {
  var errorPath = _ref4.errorPath;
  var instancePath = _ref5.instancePath;
  var instPath = instancePath ? codegen_1.str(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["", "", ""])), errorPath, util_1.getErrorPath(instancePath, util_1.Type.Str)) : errorPath;
  return [names_1.default.instancePath, codegen_1.strConcat(names_1.default.instancePath, instPath)];
}

function errorSchemaPath(_ref6, _ref7) {
  var keyword = _ref6.keyword,
      errSchemaPath = _ref6.it.errSchemaPath;
  var schemaPath = _ref7.schemaPath,
      parentSchema = _ref7.parentSchema;
  var schPath = parentSchema ? errSchemaPath : codegen_1.str(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["", "/", ""])), errSchemaPath, keyword);

  if (schemaPath) {
    schPath = codegen_1.str(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["", "", ""])), schPath, util_1.getErrorPath(schemaPath, util_1.Type.Str));
  }

  return [E.schemaPath, schPath];
}

function extraErrorProps(cxt, _ref8, keyValues) {
  var params = _ref8.params,
      message = _ref8.message;
  var keyword = cxt.keyword,
      data = cxt.data,
      schemaValue = cxt.schemaValue,
      it = cxt.it;
  var opts = it.opts,
      propertyName = it.propertyName,
      topSchemaRef = it.topSchemaRef,
      schemaPath = it.schemaPath;
  keyValues.push([E.keyword, keyword], [E.params, typeof params == "function" ? params(cxt) : params || codegen_1._(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["{}"])))]);

  if (opts.messages) {
    keyValues.push([E.message, typeof message == "function" ? message(cxt) : message]);
  }

  if (opts.verbose) {
    keyValues.push([E.schema, schemaValue], [E.parentSchema, codegen_1._(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["", "", ""])), topSchemaRef, schemaPath)], [names_1.default.data, data]);
  }

  if (propertyName) keyValues.push([E.propertyName, propertyName]);
}