import { __makeTemplateObject } from "tslib";
import React from "react";
import { ShelfIcon } from "../../redoc-lib/src/common-elements/icons";
import styled from "../../redoc-lib/src/styled-components";
import { Header } from "../Panel";
import { HttpVerb, Button } from "../shared";
import { Path, PathWrapper } from "./Path";
var templateObject_1,
    templateObject_2,
    TryItButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (t) {
  return t.theme.components.tryItButton.fullWidth ? "100%" : "auto";
}),
    TryItHeader = styled(Header)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (t) {
  return t.theme.components.tryItButton.fullWidth ? "\n    flex-direction: column;\n    align-items: flex-start;" : "";
});
export var Summary = function Summary(t) {
  var e = t.operation,
      n = t.expanded,
      o = t.toggle,
      r = t.onToggleConsole,
      l = t.tryItOverride,
      a = t.showConsole,
      c = (null == l ? void 0 : l.label) || "Try it",
      i = (null == l ? void 0 : l.link) ? TryItButton.withComponent("a") : TryItButton;
  return React.createElement(TryItHeader, {
    onClick: o,
    isExpanded: n
  }, React.createElement(PathWrapper, null, React.createElement(HttpVerb, {
    type: e.httpVerb
  }, e.httpVerb), React.createElement(Path, null, e.path), React.createElement(ShelfIcon, {
    color: "#9AA5B1",
    direction: n ? "down" : "right"
  })), !e.isWebhook && !e.isCallback && a && React.createElement(i, {
    color: "primary",
    onClick: l ? function (t) {
      var e;
      null === (e = null == l ? void 0 : l.onClick) || void 0 === e || e.call(l), t.stopPropagation();
    } : r,
    "data-cy": "try-it",
    href: null == l ? void 0 : l.link
  }, c) || null);
};