import React, { useEffect, useState } from "react";
import { ShelfIcon } from "../../redoc-lib/src/common-elements";
import { Header } from "./Header";
import { Trigger } from "./Trigger";
import { Title } from "./Title";
import { PanelBody } from "./PanelBody";
export function PanelComponent(e) {
  var t = e.collapsed,
      n = void 0 !== t && t,
      r = e.renderChildrenHidden,
      o = void 0 !== r && r,
      a = e.header,
      c = e.className,
      l = e.children,
      i = e.onToggle,
      m = useState(!n),
      d = m[0],
      f = m[1],
      s = useState(!1),
      u = s[0],
      g = s[1],
      p = function p(e) {
    e.target instanceof HTMLAnchorElement || (g(!0), f(!d), null == i || i(!d));
  };

  return useEffect(function () {
    return f(!n);
  }, [n]), React.createElement("div", {
    className: c
  }, a && ("function" == typeof a ? a({
    expanded: d,
    toggle: p
  }) : React.createElement(Header, {
    onClick: p,
    isExpanded: d
  }, React.createElement(Trigger, null, React.createElement(Title, null, a), React.createElement(ShelfIcon, {
    direction: d ? "down" : "right"
  })))), (d || o) && React.createElement(PanelBody, {
    hidden: !d,
    animate: u
  }, l));
}