"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

var _slicedToArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/slicedToArray");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatProblems = exports.getTotals = void 0;

var path = require("path");

var colorette_1 = require("colorette");

var coreVersion = require('../../package.json').version;

var codeframes_1 = require("./codeframes");

var ERROR_MESSAGE = {
  INVALID_SEVERITY_LEVEL: 'Invalid severity level; accepted values: error or warn'
};
var BG_COLORS = {
  warn: function warn(str) {
    return colorette_1.bgYellow(colorette_1.black(str));
  },
  error: colorette_1.bgRed
};
var COLORS = {
  warn: colorette_1.yellow,
  error: colorette_1.red
};
var SEVERITY_NAMES = {
  warn: 'Warning',
  error: 'Error'
};
var MAX_SUGGEST = 5;

function severityToNumber(severity) {
  return severity === 'error' ? 1 : 2;
}

function getTotals(problems) {
  var errors = 0;
  var warnings = 0;
  var ignored = 0;

  var _iterator = _createForOfIteratorHelper(problems),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var m = _step.value;

      if (m.ignored) {
        ignored++;
        continue;
      }

      if (m.severity === 'error') errors++;
      if (m.severity === 'warn') warnings++;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return {
    errors: errors,
    warnings: warnings,
    ignored: ignored
  };
}

exports.getTotals = getTotals;

function formatProblems(problems, opts) {
  var _opts$maxProblems = opts.maxProblems,
      maxProblems = _opts$maxProblems === void 0 ? 100 : _opts$maxProblems,
      _opts$cwd = opts.cwd,
      cwd = _opts$cwd === void 0 ? process.cwd() : _opts$cwd,
      _opts$format = opts.format,
      format = _opts$format === void 0 ? 'codeframe' : _opts$format,
      _opts$color = opts.color,
      color = _opts$color === void 0 ? colorette_1.options.enabled : _opts$color,
      _opts$totals = opts.totals,
      totals = _opts$totals === void 0 ? getTotals(problems) : _opts$totals,
      _opts$version = opts.version,
      version = _opts$version === void 0 ? coreVersion : _opts$version;
  colorette_1.options.enabled = color; // force colors if specified

  var totalProblems = problems.length;
  problems = problems.filter(function (m) {
    return !m.ignored;
  });
  var ignoredProblems = totalProblems - problems.length;
  problems = problems.sort(function (a, b) {
    return severityToNumber(a.severity) - severityToNumber(b.severity);
  }).slice(0, maxProblems);
  if (!totalProblems && format !== 'json') return;

  switch (format) {
    case 'json':
      outputJSON();
      break;

    case 'codeframe':
      for (var i = 0; i < problems.length; i++) {
        var problem = problems[i];
        process.stderr.write("".concat(formatCodeframe(problem, i), "\n"));
      }

      break;

    case 'stylish':
      var groupedByFile = groupByFiles(problems);

      for (var _i = 0, _Object$entries = Object.entries(groupedByFile); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            file = _Object$entries$_i[0],
            _Object$entries$_i$ = _Object$entries$_i[1],
            ruleIdPad = _Object$entries$_i$.ruleIdPad,
            positionPad = _Object$entries$_i$.locationPad,
            fileProblems = _Object$entries$_i$.fileProblems;

        process.stderr.write("".concat(colorette_1.blue(path.relative(cwd, file)), ":\n"));

        for (var _i2 = 0; _i2 < fileProblems.length; _i2++) {
          var _problem = fileProblems[_i2];
          process.stderr.write("".concat(formatStylish(_problem, positionPad, ruleIdPad), "\n"));
        }

        process.stderr.write('\n');
      }

      break;
  }

  if (totalProblems - ignoredProblems > maxProblems) {
    process.stderr.write("< ... ".concat(totalProblems - maxProblems, " more problems hidden > ").concat(colorette_1.gray('increase with `--max-problems N`'), "\n"));
  }

  function outputJSON() {
    var resultObject = {
      totals: totals,
      version: version,
      problems: problems.map(function (p) {
        var _a;

        var problem = Object.assign(Object.assign({}, p), {
          location: p.location.map(function (location) {
            return Object.assign(Object.assign({}, location), {
              source: {
                ref: path.relative(cwd, location.source.absoluteRef)
              }
            });
          }),
          from: p.from ? Object.assign(Object.assign({}, p.from), {
            source: {
              ref: path.relative(cwd, ((_a = p.from) === null || _a === void 0 ? void 0 : _a.source.absoluteRef) || cwd)
            }
          }) : undefined
        });

        if (process.env.FORMAT_JSON_WITH_CODEFRAMES) {
          var location = p.location[0]; // TODO: support multiple locations

          var loc = codeframes_1.getLineColLocation(location);
          problem.codeframe = codeframes_1.getCodeframe(loc, color);
        }

        return problem;
      })
    };
    process.stdout.write(JSON.stringify(resultObject, null, 2));
  }

  function getBgColor(problem) {
    var severity = problem.severity;

    if (!BG_COLORS[severity]) {
      throw new Error(ERROR_MESSAGE.INVALID_SEVERITY_LEVEL);
    }

    return BG_COLORS[severity];
  }

  function formatCodeframe(problem, idx) {
    var bgColor = getBgColor(problem);
    var location = problem.location[0]; // TODO: support multiple locations

    var relativePath = path.relative(cwd, location.source.absoluteRef);
    var loc = codeframes_1.getLineColLocation(location);
    var atPointer = location.pointer ? colorette_1.gray("at ".concat(location.pointer)) : '';
    var fileWithLoc = "".concat(relativePath, ":").concat(loc.start.line, ":").concat(loc.start.col);
    return "[".concat(idx + 1, "] ").concat(bgColor(fileWithLoc), " ").concat(atPointer, "\n\n") + "".concat(problem.message, "\n\n") + formatDidYouMean(problem) + codeframes_1.getCodeframe(loc, color) + '\n\n' + formatFrom(cwd, problem.from) + "".concat(SEVERITY_NAMES[problem.severity], " was generated by the ").concat(colorette_1.blue(problem.ruleId), " rule.\n\n");
  }

  function formatStylish(problem, locationPad, ruleIdPad) {
    var color = COLORS[problem.severity];
    var severityName = color(SEVERITY_NAMES[problem.severity].toLowerCase().padEnd(7));
    var start = problem.location[0].start;
    return "  ".concat("".concat(start.line, ":").concat(start.col).padEnd(locationPad), "  ").concat(severityName, "  ").concat(problem.ruleId.padEnd(ruleIdPad), "  ").concat(problem.message);
  }
}

exports.formatProblems = formatProblems;

function formatFrom(cwd, location) {
  if (!location) return '';
  var relativePath = path.relative(cwd, location.source.absoluteRef);
  var loc = codeframes_1.getLineColLocation(location);
  var fileWithLoc = "".concat(relativePath, ":").concat(loc.start.line, ":").concat(loc.start.col);
  return "referenced from ".concat(colorette_1.blue(fileWithLoc), "\n\n");
}

function formatDidYouMean(problem) {
  if (problem.suggest.length === 0) return '';

  if (problem.suggest.length === 1) {
    return "Did you mean: ".concat(problem.suggest[0], " ?\n\n");
  } else {
    return "Did you mean:\n  - ".concat(problem.suggest.slice(0, MAX_SUGGEST).join('\n  - '), "\n\n");
  }
}

var groupByFiles = function groupByFiles(problems) {
  var fileGroups = {};

  var _iterator2 = _createForOfIteratorHelper(problems),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var problem = _step2.value;
      var absoluteRef = problem.location[0].source.absoluteRef; // TODO: multiple errors

      fileGroups[absoluteRef] = fileGroups[absoluteRef] || {
        fileProblems: [],
        ruleIdPad: 0,
        locationPad: 0
      };
      var mappedProblem = Object.assign(Object.assign({}, problem), {
        location: problem.location.map(codeframes_1.getLineColLocation)
      });
      fileGroups[absoluteRef].fileProblems.push(mappedProblem);
      fileGroups[absoluteRef].ruleIdPad = Math.max(problem.ruleId.length, fileGroups[absoluteRef].ruleIdPad);
      fileGroups[absoluteRef].locationPad = Math.max(Math.max.apply(Math, _toConsumableArray(mappedProblem.location.map(function (loc) {
        return "".concat(loc.start.line, ":").concat(loc.start.col).length;
      }))), fileGroups[absoluteRef].locationPad);
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return fileGroups;
};