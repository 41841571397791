import * as React from "react";
import { CallbackDetails } from "./CallbackDetails";
import { CallbackPanel } from "../../../../components/Panel";
import { CallbackSummary } from "./CallbackSummary";
export var CallbackOperation = function CallbackOperation(a) {
  var e = a.operation;
  return React.createElement(CallbackPanel, {
    header: function header(a) {
      var l = a.expanded,
          t = a.toggle;
      return React.createElement(CallbackSummary, {
        operation: e,
        toggle: t,
        expanded: l
      });
    },
    collapsed: !e.expanded
  }, React.createElement(CallbackDetails, {
    operation: e
  }));
};