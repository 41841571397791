import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
export var Header = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  white-space: nowrap;\n  cursor: pointer;\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  pointer-events: ", ";\n\n  a {\n    pointer-events: all;\n  }\n"], ["\n  position: relative;\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  white-space: nowrap;\n  cursor: pointer;\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n\n  pointer-events: ", ";\n\n  a {\n    pointer-events: all;\n  }\n"])), function (e) {
  return e.theme.components.panels.borderRadius;
}, function (e) {
  return e.theme.components.panels.borderRadius;
}, function (e) {
  var n = e.theme;
  return e.isExpanded ? "0" : n.components.panels.borderRadius;
}, function (e) {
  var n = e.theme;
  return e.isExpanded ? "0" : n.components.panels.borderRadius;
}, function (e) {
  return e.disabled ? "none" : "auto";
});
var templateObject_1;