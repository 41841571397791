"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathParamsDefined = void 0;
var pathRegex = /\{([a-zA-Z0-9_.-]+)\}+/g;

var PathParamsDefined = function PathParamsDefined() {
  var pathTemplateParams;
  var definedPathParams;
  var currentPath;
  return {
    PathItem: {
      enter: function enter(_, _ref) {
        var key = _ref.key;
        definedPathParams = new Set();
        currentPath = key;
        pathTemplateParams = new Set(Array.from(key.toString().matchAll(pathRegex)).map(function (m) {
          return m[1];
        }));
      },
      Parameter: function Parameter(parameter, _ref2) {
        var report = _ref2.report,
            location = _ref2.location;

        if (parameter.in === 'path' && parameter.name) {
          definedPathParams.add(parameter.name);

          if (!pathTemplateParams.has(parameter.name)) {
            report({
              message: "Path parameter `".concat(parameter.name, "` is not used in the path `").concat(currentPath, "`."),
              location: location.child(['name'])
            });
          }
        }
      },
      Operation: {
        leave: function leave(_op, _ref3) {
          var report = _ref3.report,
              location = _ref3.location;

          for (var _i = 0, _Array$from = Array.from(pathTemplateParams.keys()); _i < _Array$from.length; _i++) {
            var templateParam = _Array$from[_i];

            if (!definedPathParams.has(templateParam)) {
              report({
                message: "The operation does not define the path parameter `{".concat(templateParam, "}` expected by path `").concat(currentPath, "`."),
                location: location.child(['parameters']).key() // report on operation

              });
            }
          }
        },
        Parameter: function Parameter(parameter, _ref4) {
          var report = _ref4.report,
              location = _ref4.location;

          if (parameter.in === 'path' && parameter.name) {
            definedPathParams.add(parameter.name);

            if (!pathTemplateParams.has(parameter.name)) {
              report({
                message: "Path parameter `".concat(parameter.name, "` is not used in the path `").concat(currentPath, "`."),
                location: location.child(['name'])
              });
            }
          }
        }
      }
    }
  };
};

exports.PathParamsDefined = PathParamsDefined;