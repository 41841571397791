import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { ExampleValue, FieldLabel } from "../../common-elements/fields";
import { l } from "../../services/Labels";
import { OptionsContext } from "../OptionsProvider";
import styled from "../../styled-components";
import { StyledMarkdownBlock } from "../Markdown/styled.elements";
import { Markdown } from "../Markdown/Markdown";

var DescriptionEnumsBlock = styled(StyledMarkdownBlock)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  table {\n    margin-bottom: 0.2em;\n  }\n"], ["\n  table {\n    margin-bottom: 0.2em;\n  }\n"]))),
    EnumValues = function (e) {
  function t(t) {
    var n = e.call(this, t) || this;
    return Object.defineProperty(n, "state", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: {
        collapsed: !0
      }
    }), n.toggle = n.toggle.bind(n), n;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "toggle", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.setState({
        collapsed: !this.state.collapsed
      });
    }
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.values,
          n = e.type,
          l = this.state.collapsed,
          a = !Array.isArray(t),
          r = Array.isArray(t) && t || Object.entries(t || {}).map(function (e) {
        return {
          value: e[0],
          description: e[1]
        };
      }),
          o = this.context,
          c = o.enumSkipQuotes,
          m = o.maxDisplayedEnumValues;
      if (!r.length) return null;
      var i = this.state.collapsed && m ? r.slice(0, m) : r,
          u = !!m && r.length > m,
          s = m ? l ? "… " + (r.length - m) + " more" : "Hide" : "";
      return React.createElement(React.Fragment, null, a ? React.createElement(DescriptionEnums, {
        enums: i,
        toggle: this.toggle,
        showToggleButton: u,
        toggleButtonText: s,
        type: n
      }) : React.createElement(SimpleEnums, {
        enums: i,
        enumSkipQuotes: c,
        type: n,
        toggle: this.toggle,
        toggleButtonText: s,
        showToggleButton: u
      }));
    }
  }), Object.defineProperty(t, "contextType", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: OptionsContext
  }), t;
}(React.PureComponent);

export { EnumValues };

function SimpleEnums(e) {
  var t = e.enums,
      n = e.enumSkipQuotes,
      a = e.type,
      r = e.toggle,
      o = e.showToggleButton,
      c = e.toggleButtonText;
  return React.createElement(React.Fragment, null, React.createElement(FieldLabel, null, "array" === a ? l("enumArray") : "", " ", 1 === t.length ? l("enumSingleValue") : l("enum"), ":"), " ", t.map(function (e, t) {
    var l = n ? String(e) : JSON.stringify(e);
    return React.createElement(React.Fragment, {
      key: t
    }, React.createElement(ExampleValue, null, l), " ");
  }), o ? React.createElement(ToggleButton, {
    onClick: r
  }, c) : null);
}

function DescriptionEnums(e) {
  var t = e.enums,
      n = e.toggle,
      a = e.showToggleButton,
      r = e.toggleButtonText,
      o = e.type;
  return React.createElement(React.Fragment, null, React.createElement(DescriptionEnumsBlock, null, React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, React.createElement(FieldLabel, null, "array" === o ? l("enumArray") : "", " ", 1 === t.length ? l("enumSingleValue") : l("enum"), ":"), " "), React.createElement("th", null, React.createElement("strong", null, "Description")))), React.createElement("tbody", null, t.map(function (e, t) {
    var n = e.description,
        l = e.value;
    return React.createElement("tr", {
      key: t
    }, React.createElement("td", null, l), React.createElement("td", null, React.createElement(Markdown, {
      source: n,
      compact: !0,
      inline: !0
    })));
  })))), a ? React.createElement(ToggleButton, {
    onClick: n
  }, r) : null);
}

var templateObject_1,
    templateObject_2,
    ToggleButton = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  vertical-align: middle;\n  font-size: 13px;\n  line-height: 20px;\n  padding: 0 5px;\n  cursor: pointer;\n"], ["\n  color: ", ";\n  vertical-align: middle;\n  font-size: 13px;\n  line-height: 20px;\n  padding: 0 5px;\n  cursor: pointer;\n"])), function (e) {
  return e.theme.colors.primary.main;
});