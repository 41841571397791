"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walkDocument = void 0;

var ref_utils_1 = require("./ref-utils");

var resolve_1 = require("./resolve");

var utils_1 = require("./utils");

var types_1 = require("./types");

function collectParents(ctx) {
  var _a;

  var parents = {};

  while (ctx.parent) {
    parents[ctx.parent.type.name] = (_a = ctx.parent.activatedOn) === null || _a === void 0 ? void 0 : _a.value.node;
    ctx = ctx.parent;
  }

  return parents;
}

function collectParentsLocations(ctx) {
  var _a, _b;

  var locations = {};

  while (ctx.parent) {
    if ((_a = ctx.parent.activatedOn) === null || _a === void 0 ? void 0 : _a.value.location) {
      locations[ctx.parent.type.name] = (_b = ctx.parent.activatedOn) === null || _b === void 0 ? void 0 : _b.value.location;
    }

    ctx = ctx.parent;
  }

  return locations;
}

function walkDocument(opts) {
  var document = opts.document,
      rootType = opts.rootType,
      normalizedVisitors = opts.normalizedVisitors,
      resolvedRefMap = opts.resolvedRefMap,
      ctx = opts.ctx;
  var seenNodesPerType = {};
  var seenRefs = new Set();
  walkNode(document.parsed, rootType, new ref_utils_1.Location(document.source, '#/'), undefined, '');

  function walkNode(node, type, location, parent, key) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;

    var currentLocation = location;

    var _resolve = resolve(node),
        resolvedNode = _resolve.node,
        resolvedLocation = _resolve.location,
        error = _resolve.error;

    var enteredContexts = new Set();

    if (ref_utils_1.isRef(node)) {
      var refEnterVisitors = normalizedVisitors.ref.enter;

      var _iterator = _createForOfIteratorHelper(refEnterVisitors),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _step.value,
              visitor = _step$value.visit,
              ruleId = _step$value.ruleId,
              severity = _step$value.severity,
              context = _step$value.context;

          if (!seenRefs.has(node)) {
            enteredContexts.add(context);
            var report = reportFn.bind(undefined, ruleId, severity);
            visitor(node, {
              report: report,
              resolve: resolve,
              location: location,
              type: type,
              parent: parent,
              key: key,
              parentLocations: {},
              oasVersion: ctx.oasVersion,
              getVisitorData: getVisitorDataFn.bind(undefined, ruleId)
            }, {
              node: resolvedNode,
              location: resolvedLocation,
              error: error
            });

            if ((resolvedLocation === null || resolvedLocation === void 0 ? void 0 : resolvedLocation.source.absoluteRef) && ctx.refTypes) {
              ctx.refTypes.set(resolvedLocation === null || resolvedLocation === void 0 ? void 0 : resolvedLocation.source.absoluteRef, type);
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }

    if (resolvedNode !== undefined && resolvedLocation && type.name !== 'scalar') {
      currentLocation = resolvedLocation;
      var isNodeSeen = (_b = (_a = seenNodesPerType[type.name]) === null || _a === void 0 ? void 0 : _a.has) === null || _b === void 0 ? void 0 : _b.call(_a, resolvedNode);
      var visitedBySome = false;
      var anyEnterVisitors = normalizedVisitors.any.enter;
      var currentEnterVisitors = anyEnterVisitors.concat(((_c = normalizedVisitors[type.name]) === null || _c === void 0 ? void 0 : _c.enter) || []);
      var activatedContexts = [];

      var _iterator2 = _createForOfIteratorHelper(currentEnterVisitors),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _step2$value = _step2.value,
              _context = _step2$value.context,
              visit = _step2$value.visit,
              skip = _step2$value.skip,
              _ruleId = _step2$value.ruleId,
              _severity = _step2$value.severity;

          if (_context.isSkippedLevel) {
            if (_context.parent.activatedOn && !_context.parent.activatedOn.value.nextLevelTypeActivated && !_context.seen.has(node)) {
              // TODO: test for walk through duplicated $ref-ed node
              _context.seen.add(node);

              visitedBySome = true;
              activatedContexts.push(_context);
            }
          } else {
            if (_context.parent && // if nested
            _context.parent.activatedOn && ((_d = _context.activatedOn) === null || _d === void 0 ? void 0 : _d.value.withParentNode) !== _context.parent.activatedOn.value.node && // do not enter if visited by parent children (it works thanks because deeper visitors are sorted before)
            ((_e = _context.parent.activatedOn.value.nextLevelTypeActivated) === null || _e === void 0 ? void 0 : _e.value) !== type || !_context.parent && !isNodeSeen // if top-level visit each node just once
            ) {
                activatedContexts.push(_context);
                var activatedOn = {
                  node: resolvedNode,
                  location: resolvedLocation,
                  nextLevelTypeActivated: null,
                  withParentNode: (_g = (_f = _context.parent) === null || _f === void 0 ? void 0 : _f.activatedOn) === null || _g === void 0 ? void 0 : _g.value.node,
                  skipped: (_k = ((_j = (_h = _context.parent) === null || _h === void 0 ? void 0 : _h.activatedOn) === null || _j === void 0 ? void 0 : _j.value.skipped) || (skip === null || skip === void 0 ? void 0 : skip(resolvedNode, key))) !== null && _k !== void 0 ? _k : false
                };
                _context.activatedOn = utils_1.pushStack(_context.activatedOn, activatedOn);
                var _ctx = _context.parent;

                while (_ctx) {
                  _ctx.activatedOn.value.nextLevelTypeActivated = utils_1.pushStack(_ctx.activatedOn.value.nextLevelTypeActivated, type);
                  _ctx = _ctx.parent;
                }

                if (!activatedOn.skipped) {
                  visitedBySome = true;
                  enteredContexts.add(_context);
                  var ignoreNextVisitorsOnNode = visitWithContext(visit, resolvedNode, _context, _ruleId, _severity);

                  if (ignoreNextVisitorsOnNode) {
                    break;
                  }
                }
              }
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      if (visitedBySome || !isNodeSeen) {
        seenNodesPerType[type.name] = seenNodesPerType[type.name] || new Set();
        seenNodesPerType[type.name].add(resolvedNode);

        if (Array.isArray(resolvedNode)) {
          var itemsType = type.items;

          if (itemsType !== undefined) {
            for (var i = 0; i < resolvedNode.length; i++) {
              walkNode(resolvedNode[i], itemsType, resolvedLocation.child([i]), resolvedNode, i);
            }
          }
        } else if (typeof resolvedNode === 'object' && resolvedNode !== null) {
          // visit in order from type-tree first
          var props = Object.keys(type.properties);

          if (type.additionalProperties) {
            props.push.apply(props, _toConsumableArray(Object.keys(resolvedNode).filter(function (k) {
              return !props.includes(k);
            })));
          }

          if (ref_utils_1.isRef(node)) {
            props.push.apply(props, _toConsumableArray(Object.keys(node).filter(function (k) {
              return k !== '$ref' && !props.includes(k);
            }))); // properties on the same level as $ref
          }

          for (var _i = 0, _props = props; _i < _props.length; _i++) {
            var propName = _props[_i];
            var value = resolvedNode[propName];
            var loc = resolvedLocation;

            if (value === undefined) {
              value = node[propName];
              loc = location; // properties on the same level as $ref should resolve against original location, not target
            }

            var propType = type.properties[propName];
            if (propType === undefined) propType = type.additionalProperties;
            if (typeof propType === 'function') propType = propType(value, propName);

            if (!types_1.isNamedType(propType) && (propType === null || propType === void 0 ? void 0 : propType.directResolveAs)) {
              propType = propType.directResolveAs;
              value = {
                $ref: value
              };
            }

            if (propType && propType.name === undefined && propType.resolvable !== false) {
              propType = {
                name: 'scalar',
                properties: {}
              };
            }

            if (!types_1.isNamedType(propType) || propType.name === 'scalar' && !ref_utils_1.isRef(value)) {
              continue;
            }

            walkNode(value, propType, loc.child([propName]), resolvedNode, propName);
          }
        }
      }

      var anyLeaveVisitors = normalizedVisitors.any.leave;
      var currentLeaveVisitors = (((_l = normalizedVisitors[type.name]) === null || _l === void 0 ? void 0 : _l.leave) || []).concat(anyLeaveVisitors);

      var _iterator3 = _createForOfIteratorHelper(activatedContexts.reverse()),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var _context2 = _step3.value;

          if (_context2.isSkippedLevel) {
            _context2.seen.delete(resolvedNode);
          } else {
            _context2.activatedOn = utils_1.popStack(_context2.activatedOn);

            if (_context2.parent) {
              var _ctx2 = _context2.parent;

              while (_ctx2) {
                _ctx2.activatedOn.value.nextLevelTypeActivated = utils_1.popStack(_ctx2.activatedOn.value.nextLevelTypeActivated);
                _ctx2 = _ctx2.parent;
              }
            }
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      var _iterator4 = _createForOfIteratorHelper(currentLeaveVisitors),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var _step4$value = _step4.value,
              _context3 = _step4$value.context,
              _visit = _step4$value.visit,
              _ruleId2 = _step4$value.ruleId,
              _severity2 = _step4$value.severity;

          if (!_context3.isSkippedLevel && enteredContexts.has(_context3)) {
            visitWithContext(_visit, resolvedNode, _context3, _ruleId2, _severity2);
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
    }

    currentLocation = location;

    if (ref_utils_1.isRef(node)) {
      var refLeaveVisitors = normalizedVisitors.ref.leave;

      var _iterator5 = _createForOfIteratorHelper(refLeaveVisitors),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var _step5$value = _step5.value,
              _visitor = _step5$value.visit,
              _ruleId3 = _step5$value.ruleId,
              _severity3 = _step5$value.severity,
              _context4 = _step5$value.context;

          if (enteredContexts.has(_context4)) {
            var _report = reportFn.bind(undefined, _ruleId3, _severity3);

            _visitor(node, {
              report: _report,
              resolve: resolve,
              location: location,
              type: type,
              parent: parent,
              key: key,
              parentLocations: {},
              oasVersion: ctx.oasVersion,
              getVisitorData: getVisitorDataFn.bind(undefined, _ruleId3)
            }, {
              node: resolvedNode,
              location: resolvedLocation,
              error: error
            });
          }
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
    } // returns true ignores all the next visitors on the specific node


    function visitWithContext(visit, node, context, ruleId, severity) {
      var report = reportFn.bind(undefined, ruleId, severity);
      var _ignoreNextVisitorsOnNode = false;
      visit(node, {
        report: report,
        resolve: resolve,
        location: currentLocation,
        type: type,
        parent: parent,
        key: key,
        parentLocations: collectParentsLocations(context),
        oasVersion: ctx.oasVersion,
        ignoreNextVisitorsOnNode: function ignoreNextVisitorsOnNode() {
          _ignoreNextVisitorsOnNode = true;
        },
        getVisitorData: getVisitorDataFn.bind(undefined, ruleId)
      }, collectParents(context), context);
      return _ignoreNextVisitorsOnNode;
    }

    function resolve(ref) {
      var from = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : currentLocation.source.absoluteRef;
      if (!ref_utils_1.isRef(ref)) return {
        location: location,
        node: ref
      };
      var refId = from + '::' + ref.$ref;
      var resolvedRef = resolvedRefMap.get(refId);

      if (!resolvedRef) {
        return {
          location: undefined,
          node: undefined
        };
      }

      var resolved = resolvedRef.resolved,
          node = resolvedRef.node,
          document = resolvedRef.document,
          nodePointer = resolvedRef.nodePointer,
          error = resolvedRef.error;
      var newLocation = resolved ? new ref_utils_1.Location(document.source, nodePointer) : error instanceof resolve_1.YamlParseError ? new ref_utils_1.Location(error.source, '') : undefined;
      return {
        location: newLocation,
        node: node,
        error: error
      };
    }

    function reportFn(ruleId, severity, opts) {
      var loc = opts.location ? Array.isArray(opts.location) ? opts.location : [opts.location] : [Object.assign(Object.assign({}, currentLocation), {
        reportOnKey: false
      })];
      ctx.problems.push(Object.assign(Object.assign({
        ruleId: ruleId,
        severity: opts.forceSeverity || severity
      }, opts), {
        suggest: opts.suggest || [],
        location: loc.map(function (loc) {
          return Object.assign(Object.assign(Object.assign({}, currentLocation), {
            reportOnKey: false
          }), loc);
        })
      }));
    }

    function getVisitorDataFn(ruleId) {
      ctx.visitorsData[ruleId] = ctx.visitorsData[ruleId] || {};
      return ctx.visitorsData[ruleId];
    }
  }
}

exports.walkDocument = walkDocument;