import { __decorate, __extends, __makeTemplateObject } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "../../styled-components";
import { linksCss } from "../Markdown/styled.elements";

var templateObject_1,
    LinkWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", "\n"])), linksCss, function (e) {
  return e.compact ? "" : "margin: 1em 0";
}),
    ExternalDocumentation = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.externalDocs;
      return e && e.url ? React.createElement(LinkWrap, {
        compact: this.props.compact
      }, React.createElement("a", {
        href: e.url,
        target: "_blank",
        rel: "noreferrer"
      }, e.description || e.url)) : null;
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { ExternalDocumentation };