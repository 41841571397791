import * as React from "react";
import throttle from "lodash.throttle";
import scrollLock from "scroll-lock";
import { globalHistory } from "@gatsbyjs/reach-router";
import { getNavbarElement } from "./utils";
import { deleteCookie, getCookie, getUserClaims, REDOCLY_LOGIN_RESULT_COOKIE_NAME } from "./ui/auth/utils";

function getLoginResult() {
  var a = getCookie(REDOCLY_LOGIN_RESULT_COOKIE_NAME) || null;
  return a && deleteCookie(REDOCLY_LOGIN_RESULT_COOKIE_NAME), a;
}

if ("undefined" != typeof window) {
  var a = window.__redoclyRouteChangeListenres || [];
  a.forEach(function (a) {
    return globalHistory.listen(a);
  }), delete window.__redoclyRouteChangeListenres, window._onRedoclyRouteChange = function (a) {
    globalHistory.listen(a);
  };

  var b = function b(a) {
    var b = getLoginResult();
    b && a({
      result: "success",
      idpId: b,
      claims: getUserClaims()
    });
  },
      c = window.__redoclyLoginResultListenres || [];

  c.forEach(function (a) {
    b(a);
  }), delete window.__redoclyLoginResultListenres, window._onRedoclyLoginResult = b;
}

export function useNavbarHeight(a) {
  var _React$useState = React.useState(0),
      b = _React$useState[0],
      c = _React$useState[1];

  return React.useEffect(function () {
    var a = getNavbarElement();
    c(a && a.clientHeight || 0);
  }, [a.pathname]), React.useEffect(function () {
    window.requestAnimationFrame(function () {
      var a = getNavbarElement();
      c(a && a.clientHeight || 0);
    });
  }, []), b;
}
export function useFullHeight(a) {
  var b = React.useCallback(function () {
    if (!a.current) return;
    var b = document.querySelector("footer");
    if (!b) return;
    var c = a.current.getBoundingClientRect().top,
        d = b.offsetTop - window.scrollY - c;
    a.current.style.maxHeight = d > window.innerHeight ? window.innerHeight - c + "px" : d + "px";
  }, [a]),
      c = React.useCallback(throttle(b, 150), [b]);
  React.useEffect(function () {
    return "undefined" != typeof window && a.current ? (window.addEventListener("scroll", c, {
      capture: !1
    }), window.addEventListener("resize", c, {
      capture: !1
    }), b(), function () {
      window.removeEventListener("scroll", c), window.removeEventListener("resize", c);
    }) : function () {};
  }, [c, a]);
}
export function useScrollLock(a) {
  React.useLayoutEffect(function () {
    a ? scrollLock.disablePageScroll() : scrollLock.enablePageScroll();
  }, [a]);
}