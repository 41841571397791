export var DefinitionTypes;
!function (e) {
  e.STRING = "string", e.ARRAY = "array", e.OBJECT = "object";
}(DefinitionTypes || (DefinitionTypes = {}));
export var FileUploadFormats;
!function (e) {
  e.BYTE = "byte", e.BASE64 = "base64", e.BINARY = "binary";
}(FileUploadFormats || (FileUploadFormats = {}));
export var MediaTypes;
!function (e) {
  e.OCTET_STREAM = "application/octet-stream", e.MULTIPART = "multipart/form-data", e.URL_ENCODED = "application/x-www-form-urlencoded";
}(MediaTypes || (MediaTypes = {}));