import { __assign, __decorate, __spreadArray } from "tslib";
import { action, observable, makeObservable } from "mobx";
import { FieldModel } from "./Field";
import { detectType, extractExtensions, humanizeConstraints, isNamedDefinition, isPrimitiveType, JsonPointer, pluralizeType, sortByField, sortByRequired } from "../../utils/";
import { l } from "../Labels";

var SchemaModel = function () {
  function e(e, i, t, r, n, a, s) {
    void 0 === n && (n = !1), void 0 === s && (s = {}), Object.defineProperty(this, "parser", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: r
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: a
    }), Object.defineProperty(this, "deps", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: s
    }), Object.defineProperty(this, "pointer", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "displayType", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "typePrefix", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "title", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "description", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "externalDocs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "isPrimitive", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "isCircular", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "format", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "displayFormat", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "nullable", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "deprecated", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "pattern", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "example", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "enum", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "default", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "readOnly", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "writeOnly", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "constraints", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "_fields", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "items", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "oneOf", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "oneOfType", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "discriminatorProp", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "activeOneOf", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: 0
    }), Object.defineProperty(this, "rawSchema", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "schema", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "extensions", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "x-enumDescriptions", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "const", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "contentEncoding", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "contentMediaType", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "minItems", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "maxItems", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), makeObservable(this), this.pointer = i.$ref || t || "", this.rawSchema = e.shallowDeref(i), this.schema = e.mergeAllOf(this.rawSchema, this.pointer, n), this.init(e, n), e.exitParents(this.schema), r.showExtensions && (this.extensions = extractExtensions(this.schema, r.showExtensions));
  }

  return Object.defineProperty(e.prototype, "activateOneOf", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      this.activeOneOf = e;
    }
  }), Object.defineProperty(e.prototype, "hasType", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return this.type === e || Array.isArray(this.type) && this.type.includes(e);
    }
  }), Object.defineProperty(e.prototype, "fields", {
    get: function get() {
      if (!this.isCircular) return !this._fields && this.hasType("object") && (this._fields = buildFields(this.parser, this.schema, this.pointer, this.options, this, this.deps)), this._fields;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "hasCircularParent", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var i = this;

      if (this.parent) {
        if (e.some(function (e) {
          var t, r;
          if (!e) return !1;
          var n = i.parent;
          return (null == n ? void 0 : n.pointer) === e || !!(null === (r = null === (t = null == n ? void 0 : n.schema.parentRefs) || void 0 === t ? void 0 : t.some) || void 0 === r ? void 0 : r.call(t, function (i) {
            return i === e;
          })) || void 0;
        })) return !0;
        if (this.parent.hasCircularParent(e)) return !0;
      }

      return !1;
    }
  }), Object.defineProperty(e.prototype, "init", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(i, t) {
      var r = this,
          n = this.schema;
      if (this.isCircular = n["x-circular-ref"] || this.hasCircularParent(__spreadArray([this.pointer], this.schema.parentRefs || [], !0)), this.title = n.title || isNamedDefinition(this.pointer) && JsonPointer.baseName(this.pointer) || "", this.description = n.description || "", this.type = n.type || detectType(n), this.format = n.format, this.enum = n.enum || [], this["x-enumDescriptions"] = n["x-enumDescriptions"], this.example = n.example, this.deprecated = !!n.deprecated, this.pattern = n.pattern, this.externalDocs = n.externalDocs, this.constraints = humanizeConstraints(n), this.displayFormat = this.format, this.isPrimitive = isPrimitiveType(n, this.type), this.default = n.default, this.readOnly = !!n.readOnly, this.writeOnly = !!n.writeOnly, this.const = n.const || "", this.contentEncoding = n.contentEncoding, this.contentMediaType = n.contentMediaType, this.minItems = n.minItems, this.maxItems = n.maxItems, (n.nullable || n["x-nullable"]) && (Array.isArray(this.type) && !this.type.some(function (e) {
        return null === e || "null" === e;
      }) ? this.type = __spreadArray(__spreadArray([], this.type, !0), ["null"], !1) : Array.isArray(this.type) || null === this.type && "null" === this.type || (this.type = [this.type, "null"])), this.displayType = Array.isArray(this.type) ? this.type.map(function (e) {
        return null === e ? "null" : e;
      }).join(" or ") : this.type, !this.isCircular) if (t || void 0 === getDiscriminator(n)) {
        if (t && Array.isArray(n.oneOf) && n.oneOf.find(function (e) {
          return e.$ref === r.pointer;
        }) && delete n.oneOf, void 0 !== n.oneOf) return this.initOneOf(n.oneOf, i), this.oneOfType = "One of", void (void 0 !== n.anyOf && console.warn("oneOf and anyOf are not supported on the same level. Skipping anyOf at " + this.pointer));
        if (void 0 !== n.anyOf) return this.initOneOf(n.anyOf, i), void (this.oneOfType = "Any of");

        if (this.hasType("array") && n.items && (this.items = new e(i, n.items, this.pointer + "/items", this.options, !1, this, this.deps), this.displayType = pluralizeType(this.items.displayType), this.displayFormat = this.items.format, this.typePrefix = this.items.typePrefix + l("arrayOf"), this.title = this.title || this.items.title, this.isPrimitive = this.items.isPrimitive, void 0 === this.example && void 0 !== this.items.example && (this.example = [this.items.example]), this.items.isPrimitive && (this.enum = this.items.enum, this["x-enumDescriptions"] = this.items["x-enumDescriptions"]), Array.isArray(this.type))) {
          var a = this.type.filter(function (e) {
            return "array" !== e;
          });
          a.length && (this.displayType += " or " + a.join(" or "));
        }

        this.enum.length && this.options.sortEnumValuesAlphabetically && this.enum.sort();
      } else this.initDiscriminator(n, i);
    }
  }), Object.defineProperty(e.prototype, "initOneOf", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(i, t) {
      var r = this;

      if (this.oneOf = i.map(function (i, n) {
        var a = t.deref(i, !1, !0),
            s = t.mergeAllOf(a, r.pointer + "/oneOf/" + n),
            o = isNamedDefinition(i.$ref) && !s.title ? JsonPointer.baseName(i.$ref) : "" + (s.title || "") + (s.const && JSON.stringify(s.const) || ""),
            l = new e(t, __assign(__assign({}, s), {
          title: o,
          allOf: [__assign(__assign({}, r.schema), {
            oneOf: void 0,
            anyOf: void 0
          })]
        }), r.pointer + "/oneOf/" + n, r.options, !1, r.parent, __assign(__assign({}, r.deps), {
          parentFieldFullPath: r.deps.parentFieldFullPath ? r.deps.parentFieldFullPath + r.options.deepLinkSeparator + n : n.toString()
        }));
        return t.exitRef(i), t.exitParents(s), l;
      }), this.options.simpleOneOfTypeLabel) {
        var n = collectUniqueOneOfTypesDeep(this);
        this.displayType = n.join(" or ");
      } else this.displayType = this.oneOf.map(function (e) {
        var i = e.typePrefix + (e.title ? e.title + " (" + e.displayType + ")" : e.displayType);
        return i.indexOf(" or ") > -1 && (i = "(" + i + ")"), i;
      }).join(" or ");
    }
  }), Object.defineProperty(e.prototype, "initDiscriminator", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(i, t) {
      var r = this,
          n = getDiscriminator(i);
      this.discriminatorProp = null == n ? void 0 : n.propertyName;
      var a = t.findDerived(__spreadArray(__spreadArray([], i.parentRefs || [], !0), [this.pointer], !1));
      if (i.oneOf) for (var s = 0, o = i.oneOf; s < o.length; s++) {
        var l = o[s];

        if (void 0 !== l.$ref) {
          var p = JsonPointer.baseName(l.$ref);
          a[l.$ref] = p;
        }
      }
      var u = (null == n ? void 0 : n.mapping) || {},
          f = (null == n ? void 0 : n["x-explicitMappingOnly"]) || !1;
      0 === Object.keys(u).length && (f = !1);
      var d = {};

      for (var b in u) {
        var c = u[b];
        Array.isArray(d[c]) ? d[c].push(b) : d[c] = [b];
      }

      for (var h = __assign(f ? {} : __assign({}, a), d), m = [], y = 0, v = Object.keys(h); y < v.length; y++) {
        var O = h[c = v[y]];
        if (Array.isArray(O)) for (var g = 0, P = O; g < P.length; g++) {
          var w = P[g];
          m.push({
            $ref: c,
            name: w
          });
        } else m.push({
          $ref: c,
          name: O
        });
      }

      var j = Object.keys(u);
      0 !== j.length && (m = m.sort(function (e, i) {
        var t = j.indexOf(e.name),
            r = j.indexOf(i.name);
        return t < 0 && r < 0 ? e.name.localeCompare(i.name) : t < 0 ? 1 : r < 0 ? -1 : t - r;
      })), this.oneOf = m.map(function (i, n) {
        var a = i.$ref,
            s = i.name,
            o = new e(t, t.byRef(a), a, r.options, !0, r.parent, __assign(__assign({}, r.deps), {
          parentFieldFullPath: r.deps.parentFieldFullPath ? r.deps.parentFieldFullPath + r.options.deepLinkSeparator + n : n.toString()
        }));
        return o.title = s, o;
      });
    }
  }), __decorate([observable], e.prototype, "activeOneOf", void 0), __decorate([action], e.prototype, "activateOneOf", null), e;
}();

export { SchemaModel };

function buildFields(e, i, t, r, n, a) {
  var s = i.properties || {},
      o = i.additionalProperties,
      l = i.default || {},
      p = Object.keys(s || []).map(function (o) {
    var p = s[o];
    p || (console.warn('Field "' + o + '" is invalid, skipping.\n Field must be an object but got ' + typeof p + ' at "' + t + '"'), p = {});
    var u = void 0 !== i.required && i.required.indexOf(o) > -1;
    return new FieldModel(e, {
      name: o,
      required: u,
      schema: __assign(__assign({}, p), {
        default: void 0 === p.default ? l[o] : p.default
      })
    }, t + "/properties/" + o, r, n, a);
  });
  return r.sortPropsAlphabetically && (p = sortByField(p, "name")), r.requiredPropsFirst && (p = sortByRequired(p, r.sortPropsAlphabetically ? void 0 : i.required)), "object" != typeof o && !0 !== o || p.push(new FieldModel(e, {
    name: ("object" == typeof o && o["x-additionalPropertiesName"] || "property name").concat("*"),
    required: !1,
    schema: !0 === o ? {} : o,
    kind: "additionalProperties"
  }, t + "/additionalProperties", r, n, a)), p;
}

function getDiscriminator(e) {
  return e.discriminator || e["x-discriminator"];
}

function collectUniqueOneOfTypesDeep(e) {
  var i = new Set();
  return function e(t) {
    for (var r = 0, n = t.oneOf || []; r < n.length; r++) {
      var a = n[r];
      a.oneOf ? e(a) : a.type && i.add(a.type);
    }
  }(e), Array.from(i.values());
}