import { __assign } from "tslib";
import * as qs from "querystring";
import * as url from "url";
import reducer from "./helpers/reducer";
import targets from "./targets";
import { normalizeMimeType, unescapeServerVariables } from "../../utils";

var HTTPSnippet = function () {
  function e(e) {
    Object.defineProperty(this, "request", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    });

    var r = __assign({}, e);

    this.request = this.prepare(__assign(__assign({}, r), {
      httpVersion: r.httpVersion || "HTTP/1.1",
      queryString: r.queryString || [],
      headers: r.headers || [],
      cookies: r.cookies || [],
      postData: r.postData ? __assign(__assign({}, r.postData), {
        jsonObj: void 0,
        paramsObj: void 0
      }) : void 0,
      bodySize: 0,
      headersSize: 0,
      queryObj: {},
      headersObj: {},
      cookiesObj: {},
      allHeaders: {},
      uriObj: {},
      fullUrl: "",
      pathParameters: r.pathParameters || {},
      serverVariables: r.serverVariables || {}
    }));
  }

  return Object.defineProperty(e.prototype, "prepare", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var r;

      if (e.queryString && e.queryString.length && (e.queryObj = e.queryString.reduce(reducer, {})), e.headers && e.headers.length) {
        var a = /^HTTP\/2/;
        e.headersObj = e.headers.reduce(function (r, t) {
          var i = t.name;
          return e.httpVersion.match(a) && (i = i.toLowerCase()), r[i] = t.value, r;
        }, {});
      }

      e.cookies && e.cookies.length && (e.cookiesObj = e.cookies.reduceRight(function (e, r) {
        return e[r.name] = r.value, e;
      }, {}));
      var t = e.cookies.map(function (e) {
        return encodeURIComponent(e.name) + "=" + encodeURIComponent(e.value);
      });
      if (t.length && (e.allHeaders.cookie = t.join("; ")), e.postData) switch (normalizeMimeType(e.postData.mimeType)) {
        case "multipart/mixed":
        case "multipart/related":
        case "multipart/form-data":
        case "multipart/alternative":
          break;

        case "application/x-www-form-urlencoded":
          (null === (r = e.postData) || void 0 === r ? void 0 : r.params) ? (e.postData.paramsObj = e.postData.params.reduce(reducer, {}), e.postData.text = qs.stringify(e.postData.paramsObj)) : e.postData.text = "";
          break;

        case "text/json":
        case "text/x-json":
        case "application/json":
        case "application/x-json":
          if (e.postData.mimeType = "application/json", e.postData.text) try {
            e.postData.jsonObj = JSON.parse(e.postData.text);
          } catch (r) {
            e.postData.mimeType = "text/plain";
          }
      }
      return e.allHeaders = Object.assign(e.allHeaders, e.headersObj), e.uriObj = url.parse(e.url, !0, !0), e.queryObj = Object.assign(e.queryObj, e.uriObj.query), e.uriObj.query = null, e.uriObj.search = null, e.uriObj.path = e.uriObj.pathname = unescapeServerVariables(e.uriObj.pathname), e.url = unescapeServerVariables(url.format(e.uriObj)), e.uriObj.query = e.queryObj, e.uriObj.search = qs.stringify(e.queryObj), e.uriObj.search && (e.uriObj.path = e.uriObj.pathname + "?" + e.uriObj.search), e.fullUrl = unescapeServerVariables(url.format(e.uriObj)), e;
    }
  }), Object.defineProperty(e.prototype, "convert", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r, a) {
      !a && r && (a = r);

      var t = this._matchTarget(e, r);

      if (t) {
        var i = t(this.request, a, {
          target: e,
          client: r
        });
        return 1 === i.length ? i[0] : i;
      }

      throw new Error("Can not match target");
    }
  }), Object.defineProperty(e.prototype, "_matchTarget", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      return !!Object.prototype.hasOwnProperty.call(targets, e) && ("string" == typeof r && "function" == typeof targets[e][r] ? targets[e][r] : targets[e][targets[e].info.default]);
    }
  }), e;
}();

export { HTTPSnippet };