import { __makeTemplateObject } from "tslib";
import * as React from "react";
import { observer } from "mobx-react";
import { styled, mediaCSS, mediaMap, ZERO_BREAKPOINT, css, Tooltip } from "../redoc-lib";
import { LayoutVariant } from "../services";
var ShowRightPanelSVG = styled(function () {
  return React.createElement("svg", {
    width: "15",
    height: "10",
    viewBox: "0 0 15 10",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    d: "M2.41421 5L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.7071 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292892 5.70711C-0.0976321 5.31658 -0.0976319 4.68342 0.292893 4.29289L4.29289 0.292894C4.68342 -0.0976302 5.31658 -0.09763 5.70711 0.292894C6.09763 0.683418 6.09763 1.31658 5.70711 1.70711L2.41421 5Z",
    fill: "#9AA6B1"
  }), React.createElement("path", {
    d: "M2 5C2 4.44772 2.44771 4 3 4L11 4C11.5523 4 12 4.44772 12 5C12 5.55229 11.5523 6 11 6L3 6C2.44771 6 2 5.55228 2 5Z",
    fill: "#9AA6B1"
  }), React.createElement("rect", {
    x: "15",
    y: "10",
    width: "2",
    height: "10",
    rx: "1",
    transform: "rotate(-180 15 10)",
    fill: "#9AA6B1"
  }));
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n"], ["\n  position: absolute;\n"]))),
    HideRightPanelSVG = styled(function () {
  return React.createElement("svg", {
    width: "15",
    height: "10",
    viewBox: "0 0 15 10",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    d: "M12.5858 5L9.29289 1.70711C8.90237 1.31658 8.90237 0.683417 9.29289 0.292893C9.68342 -0.097631 10.3166 -0.0976309 10.7071 0.292893L14.7071 4.29289C15.0976 4.68342 15.0976 5.31658 14.7071 5.70711L10.7071 9.70711C10.3166 10.0976 9.68342 10.0976 9.29289 9.70711C8.90237 9.31658 8.90237 8.68342 9.29289 8.29289L12.5858 5Z",
    fill: "#9AA6B1"
  }), React.createElement("path", {
    d: "M13 5C13 5.55229 12.5523 6 12 6L4 6C3.44772 6 3 5.55228 3 5C3 4.44771 3.44772 4 4 4L12 4C12.5523 4 13 4.44772 13 5Z",
    fill: "#9AA6B1"
  }), React.createElement("rect", {
    width: "2",
    height: "10",
    rx: "1",
    fill: "#9AA6B1"
  }));
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n"], ["\n  position: absolute;\n"])));
export var StyledToggleRightPanelButton = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: '#f00';\n  cursor: pointer;\n  z-index: 1;\n  /* Rectangle 201 */\n  width: 36px;\n  height: 36px;\n  background: #e5e8eb;\n  border-radius: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: '#f00';\n  cursor: pointer;\n  z-index: 1;\n  /* Rectangle 201 */\n  width: 36px;\n  height: 36px;\n  background: #e5e8eb;\n  border-radius: ", ";\n"])), function (e) {
  return e.theme.shape.borderRadius;
});
export var ToggleRightPanelButton = observer(function (e) {
  var t = e.store,
      n = e.isVisible;
  return t.options.showRightPanelToggle && n ? React.createElement(Tooltip, {
    tip: t.showRightPanel ? "Hide samples" : "Show samples",
    placement: "left"
  }, React.createElement(StyledToggleRightPanelButton, {
    onClick: function onClick() {
      t.toggleRightPanel();
    }
  }, t.showRightPanel ? React.createElement(HideRightPanelSVG, null) : React.createElement(ShowRightPanelSVG, null))) : null;
});

var IntArrowIcon = function IntArrowIcon(e) {
  return React.createElement("svg", {
    className: e.className,
    version: "1.1",
    viewBox: "0 0 24 24",
    x: "0",
    xmlns: "http://www.w3.org/2000/svg",
    y: "0",
    "aria-hidden": "true"
  }, React.createElement("polygon", {
    points: "17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "
  }));
};

export var ArrowIcon = styled(IntArrowIcon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 18px;\n  height: 18px;\n  transform: ", ";\n\n  ", "\n"], ["\n  width: 18px;\n  height: 18px;\n  transform: ", ";\n\n  ", "\n"])), function (e) {
  return "rotate(" + ("left" === e.direction ? "90deg" : "-90deg") + ")";
}, function (e) {
  var t,
      n = e.theme;
  return mediaCSS({
    fill: null === (t = n.rightPanel) || void 0 === t ? void 0 : t.textColor
  });
});
export var RedocWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  text-align: left;\n  tap-highlight-color: rgba(0, 0, 0, 0);\n  text-size-adjust: 100%;\n  ", ";\n\n  ", "\n\n  * {\n    box-sizing: border-box;\n    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);\n  }\n"], ["\n  display: flex;\n  position: relative;\n  text-align: left;\n  tap-highlight-color: rgba(0, 0, 0, 0);\n  text-size-adjust: 100%;\n  ", ";\n\n  ", "\n\n  * {\n    box-sizing: border-box;\n    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);\n  }\n"])), function (e) {
  var t = e.theme;
  return "\n    -webkit-font-smoothing: " + t.typography.smoothing + ";\n    " + (t.typography.optimizeSpeed ? "text-rendering: optimizeSpeed !important" : "") + ";\n  ";
}, function (e) {
  var t = e.theme;
  return mediaCSS({
    fontFamily: t.typography.fontFamily,
    fontSize: t.typography.fontSize,
    lineHeight: t.typography.lineHeight,
    fontWeight: t.typography.fontWeightRegular,
    color: t.colors.text.primary,
    fontSmoothing: t.typography.smoothing
  });
});
export var ApiContentWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  /* contain: layout; */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 auto;\n\n  ", "\n"], ["\n  position: relative;\n  overflow: hidden;\n  /* contain: layout; */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 auto;\n\n  ", "\n"])), function (e) {
  var t,
      n = e.theme,
      a = e.layout;
  return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    ", ";\n\n    ", " {\n      width: 100%;\n      padding: 0;\n    }\n  "], ["\n    ", ";\n\n    ", " {\n      width: 100%;\n      padding: 0;\n    }\n  "])), mediaCSS({
    width: (t = {}, t[ZERO_BREAKPOINT] = "auto", t.small = "calc(100% - " + n.sidebar.width + ")", t),
    maxWidth: n.layout[a || LayoutVariant.THREE_PANEL].maxWidth
  }), n.mediaQueries.print);
});
export var BackgroundStub = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  ", "\n"], ["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  ", "\n"])), function (e) {
  var t,
      n,
      a = e.theme;
  return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    ", "\n    ", " {\n      display: none;\n    }\n  "], ["\n    ", "\n    ", " {\n      display: none;\n    }\n  "])), mediaCSS({
    background: a.rightPanel.backgroundColor,
    display: (t = {}, t[ZERO_BREAKPOINT] = "none", t[a.rightPanel.showAtBreakpoint] = "initial", t),
    left: mediaMap(a.breakpoints, [a.rightPanel.width], (n = {}, n[ZERO_BREAKPOINT] = function () {
      return "0";
    }, n[a.rightPanel.showAtBreakpoint] = function (e) {
      return "calc(100% - " + e + ")";
    }, n))
  }), a.mediaQueries.print);
});
export var BackgroundStubFix = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  ", "\n"], ["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  ", "\n"])), function (e) {
  var t,
      n,
      a = e.theme;
  return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    ", "\n    ", " {\n      display: none;\n    }\n  "], ["\n    ", "\n    ", " {\n      display: none;\n    }\n  "])), mediaCSS({
    background: a.rightPanel.backgroundColor,
    display: (t = {}, t[ZERO_BREAKPOINT] = "none", t[a.rightPanel.showAtBreakpoint] = "initial", t),
    width: mediaMap(a.breakpoints, [a.sidebar.width, a.layout["three-panel"].maxWidth], (n = {}, n[ZERO_BREAKPOINT] = function () {
      return "0";
    }, n[a.rightPanel.showAtBreakpoint] = function (e, t) {
      return "calc((100% - min(100% - " + e + ", " + t + ") - " + e + ")/2 + 50px)";
    }, n))
  }), a.mediaQueries.print);
});
export var NextSectionButtonWrap = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: ", ";\n  align-self: flex-start;\n  text-align: right;\n\n  display: flex;\n  flex-direction: row;\n\n  ", "\n"], ["\n  padding: ", ";\n  align-self: flex-start;\n  text-align: right;\n\n  display: flex;\n  flex-direction: row;\n\n  ", "\n"])), function (e) {
  var t = e.theme;
  return 4 * t.spacing.unit + "px " + 5 * t.spacing.unit + "px";
}, function (e) {
  var t,
      n,
      a = e.theme,
      i = a.layout.showDarkRightPanel ? a.spacing.sectionHorizontal : a.spacing.sectionHorizontal / 2;
  return css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ", "\n    "])), mediaCSS({
    width: mediaMap(a.breakpoints, [a.rightPanel.width], (t = {}, t[ZERO_BREAKPOINT] = function () {
      return "100%";
    }, t[a.rightPanel.showAtBreakpoint] = function (e) {
      return "calc(100% - " + e + ")";
    }, t)),
    paddingLeft: a.spacing.sectionHorizontal,
    paddingRight: (n = {}, n[ZERO_BREAKPOINT] = a.spacing.sectionHorizontal, n[a.rightPanel.showAtBreakpoint] = i, n)
  }));
});
var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    templateObject_6,
    templateObject_7,
    templateObject_8,
    templateObject_9,
    templateObject_10,
    templateObject_11,
    templateObject_12,
    templateObject_13,
    templateObject_14,
    templateObject_15,
    templateObject_16,
    templateObject_17,
    templateObject_18,
    ChangeViewSvgColumn = styled(function () {
  return React.createElement("svg", {
    width: "15",
    height: "20",
    viewBox: "0 0 15 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("line", {
    x1: "13.2858",
    y1: "5.28564",
    x2: "1.00005",
    y2: "5.28564",
    stroke: "#9AA6B1",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), React.createElement("line", {
    x1: "13.2858",
    y1: "1",
    x2: "1.00005",
    y2: "0.999998",
    stroke: "#9AA6B1",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), React.createElement("rect", {
    x: "14.2858",
    y: "20",
    width: "14.2857",
    height: "11.4286",
    rx: "1",
    transform: "rotate(-180 14.2858 20)",
    fill: "#9AA6B1"
  }));
})(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: absolute;\n"], ["\n  position: absolute;\n"]))),
    ChangeViewSvgRow = styled(function () {
  return React.createElement("svg", {
    width: "20",
    height: "16",
    viewBox: "0 0 20 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("line", {
    x1: "1",
    y1: "4",
    x2: "8",
    y2: "4",
    stroke: "#9AA6B1",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), React.createElement("line", {
    x1: "1",
    y1: "8",
    x2: "8",
    y2: "8",
    stroke: "#9AA6B1",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), React.createElement("line", {
    x1: "1",
    y1: "12",
    x2: "8",
    y2: "12",
    stroke: "#9AA6B1",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), React.createElement("rect", {
    x: "11",
    width: "9",
    height: "16",
    rx: "1",
    fill: "#9AA6B1"
  }));
})(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n"], ["\n  position: absolute;\n"]))),
    StyledChangeViewButton = styled.a(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  z-index: 1;\n  /* Rectangle 201 */\n  width: 36px;\n  height: 36px;\n  background: #e5e8eb;\n  border-radius: ", ";\n"], ["\n  display: flex;\n  position: relative;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  z-index: 1;\n  /* Rectangle 201 */\n  width: 36px;\n  height: 36px;\n  background: #e5e8eb;\n  border-radius: ", ";\n"])), function (e) {
  return e.theme.shape.borderRadius;
});
export var ChangeViewButton = function ChangeViewButton(e) {
  var t = e.store,
      n = e.isVisible;
  return t.options.showChangeLayoutButton && n ? React.createElement(Tooltip, {
    tip: "Change layout",
    placement: "left"
  }, React.createElement(StyledChangeViewButton, {
    onClick: function onClick() {
      t.toggleLayout(t.layout === LayoutVariant.STACKED ? LayoutVariant.THREE_PANEL : LayoutVariant.STACKED);
    }
  }, t.layout === LayoutVariant.STACKED ? React.createElement(ChangeViewSvgRow, null) : React.createElement(ChangeViewSvgColumn, null))) : null;
};
export var ControlsWrap = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  position: sticky;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  top: ", ";\n  right: 24px;\n  height: 80px;\n  z-index: 1;\n  ", "\n"], ["\n  position: sticky;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  top: ", ";\n  right: 24px;\n  height: 80px;\n  z-index: 1;\n  ", "\n"])), function (e) {
  return e.theme.components.layoutControls.top;
}, function (e) {
  var t,
      n = e.theme;
  return css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    ", "\n    ", " {\n      display: none;\n    }\n  "], ["\n    ", "\n    ", " {\n      display: none;\n    }\n  "])), mediaCSS({
    display: (t = {}, t[ZERO_BREAKPOINT] = "none", t[n.rightPanel.showAtBreakpoint] = "flex", t)
  }), n.mediaQueries.print);
});