import { mediaCSS } from "@redocly/reference-docs";
import styled from "styled-components";
export var LayoutWrapper = styled.div.withConfig({
  displayName: "LayoutWrapper",
  componentId: "sc-1s9fetx-0"
})(["min-height:100vh;display:flex;flex-direction:column;", " ", ""], function (_ref) {
  var a = _ref.theme;
  return "#ffffff" === a.colors.background ? "" : "background-color: " + a.colors.background + ";";
}, function (_ref2) {
  var a = _ref2.theme;
  return mediaCSS({
    fontFamily: a.typography.fontFamily
  });
});