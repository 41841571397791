import { __assign, __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { MenuItem, MenuExternalLink } from "./MenuItem";
import { MenuItemUl } from "./styled.elements";

var MenuItems = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this,
          t = this.props,
          n = t.items,
          r = t.root,
          o = t.className,
          a = t.linksBefore,
          i = void 0 === a ? [] : a,
          s = t.linksAfter,
          m = void 0 === s ? [] : s,
          l = null == this.props.expanded || this.props.expanded;
      return React.createElement(MenuItemUl, __assign({
        className: o,
        style: this.props.style,
        expanded: l
      }, r ? {
        role: "navigation"
      } : {}), i.map(function (e, t) {
        return React.createElement(MenuExternalLink, {
          key: t,
          link: e
        });
      }), n.map(function (t, n) {
        return React.createElement(MenuItem, {
          key: n,
          item: t,
          onActivate: e.props.onActivate
        });
      }), m.map(function (e, t) {
        return React.createElement(MenuExternalLink, {
          key: t,
          link: e
        });
      }));
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { MenuItems };