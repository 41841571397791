import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
export function getNavbarElement() {
  var a = "undefined" != typeof document && document.querySelectorAll("nav") || [];

  for (var b = 0; b < a.length; b++) {
    if (1 < a[b].getBoundingClientRect().height) return a[b];
  }

  return a[0] || null;
}
export function normalizeRedocBadge(a) {
  return "hook" === a ? "event" : a;
}
export function withPathPrefix(a, b) {
  return b ? (b.startsWith("/") || (b = "/" + b), b.endsWith("/") || (b = b + "/"), "" + b + a) : "/" + a;
}
export function flattenNestedItems(a, b) {
  if (b === void 0) {
    b = [];
  }

  return a.reduce(function (a, b) {
    var _b$items;

    return (_b$items = b.items) !== null && _b$items !== void 0 && _b$items.length ? flattenNestedItems(b.items, [].concat(_toConsumableArray(a), [b])) : [].concat(_toConsumableArray(a), [b]);
  }, b);
}