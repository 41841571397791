var _a, _b;

import { __assign } from "tslib";
import * as util from "util";
import { Lang, NamingVariableConventions, VariableType } from "./constants";
import StringUtility from "./string-utility";

var langToVarNameConvention = ((_a = {})[Lang.NODEJS] = NamingVariableConventions.CAMEL_CASE, _a[Lang.JAVASCRIPT] = NamingVariableConventions.CAMEL_CASE, _a[Lang.PYTHON] = NamingVariableConventions.UNDER_SCORES, _a[Lang.JAVA8] = NamingVariableConventions.CAMEL_CASE, _a[Lang.JAVA] = NamingVariableConventions.CAMEL_CASE, _a[Lang.CSHARP] = NamingVariableConventions.PASCAL_CASE, _a[Lang.CURL] = NamingVariableConventions.CAMEL_CASE, _a[Lang.GO] = NamingVariableConventions.CAMEL_CASE, _a[Lang.PHP] = NamingVariableConventions.CAMEL_CASE, _a[Lang.RUBY] = NamingVariableConventions.UNDER_SCORES, _a),
    langOptions = ((_b = {
  default: {
    joinSymbol: "",
    variableWrapperFn: function variableWrapperFn(e) {
      return e;
    },
    stringLiteral: function stringLiteral(e) {
      return e;
    },
    variableDeclaration: function variableDeclaration(e) {
      return e;
    },
    urlFieldName: "url"
  }
})[Lang.CSHARP] = {
  joinSymbol: " + ",
  stringLiteral: function stringLiteral(e) {
    return '"' + e + '"';
  },
  variableDeclaration: function variableDeclaration(e) {
    return "var " + e + ' = "%s";';
  },
  urlFieldName: "fullUrl"
}, _b[Lang.GO] = {
  joinSymbol: " + ",
  stringLiteral: function stringLiteral(e) {
    return '"' + e + '"';
  },
  variableDeclaration: function variableDeclaration(e) {
    return e + ' := "%s";';
  }
}, _b[Lang.JAVA] = {
  joinSymbol: " + ",
  stringLiteral: function stringLiteral(e) {
    return '"' + e + '"';
  },
  variableDeclaration: function variableDeclaration(e) {
    return "var " + e + ' = "%s";';
  },
  urlFieldName: "pathname"
}, _b[Lang.JAVA8] = {
  joinSymbol: " + ",
  stringLiteral: function stringLiteral(e) {
    return '"' + e + '"';
  },
  variableDeclaration: function variableDeclaration(e) {
    return "String " + e + ' = "%s";';
  },
  urlFieldName: "fullUrl"
}, _b[Lang.JAVASCRIPT] = {
  variableWrapperFn: function variableWrapperFn(e) {
    return "${" + e + "}";
  },
  variableDeclaration: function variableDeclaration(e) {
    return "const " + e + " = '%s';";
  }
}, _b[Lang.NODEJS] = {
  variableWrapperFn: function variableWrapperFn(e) {
    return "${" + e + "}";
  },
  variableDeclaration: function variableDeclaration(e) {
    return "const " + e + " = '%s';";
  }
}, _b[Lang.PHP] = {
  joinSymbol: " . ",
  stringLiteral: function stringLiteral(e) {
    return '"' + e + '"';
  },
  variableDeclaration: function variableDeclaration(e) {
    return "const " + e + ' = "%s";';
  }
}, _b[Lang.PYTHON] = {
  stringLiteral: function stringLiteral(e) {
    return '"' + e + '"';
  },
  joinSymbol: " + ",
  variableDeclaration: function variableDeclaration(e) {
    return e + ' = "%s"';
  }
}, _b[Lang.RUBY] = {
  joinSymbol: " + ",
  stringLiteral: function stringLiteral(e) {
    return "'" + e + "'";
  },
  variableDeclaration: function variableDeclaration(e) {
    return e + " = '%s'";
  }
}, _b[Lang.CURL] = {
  variableWrapperFn: function variableWrapperFn(e, n) {
    return n || ":" + e;
  },
  urlFieldName: "fullUrl",
  rawName: !0
}, _b),
    reservedVariableNames = ["response", "request", "requests", "data", "url", "payload", "main", "accessToken", "resp", "Program", "client", "postData", "App", "httpClient", "params", "pathname", "host", "body", "fetch", "query"],
    CodeBuilder = function () {
  function e(e) {
    var n = e.indentation,
        i = e.lineJoin,
        a = void 0 === i ? "\n" : i,
        r = e.variablesPrefix,
        t = void 0 === r ? "" : r,
        l = e.capitalize,
        o = void 0 !== l && l,
        u = e.lang,
        s = void 0 === u ? Lang.CURL : u;
    Object.defineProperty(this, "code", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "indentation", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "variablesPrefix", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "lineJoin", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "capitalize", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "lang", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "langOptions", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.code = [], this.indentation = n, this.variablesPrefix = t, this.lineJoin = a || "\n", this.capitalize = o, this.lang = s, this.langOptions = __assign(__assign({}, langOptions.default), langOptions[s]);
  }

  return Object.defineProperty(e.prototype, "buildLine", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, n) {
      var i = "",
          a = 2;
      if ("string" == typeof e) a = 1, n = e, e = 0;else if (null === e) return null;

      for (; e;) {
        i += this.indentation, e--;
      }

      var r = Array.prototype.slice.call(arguments, a, arguments.length);
      return r.unshift(i + n), util.format.apply(this, r);
    }
  }), Object.defineProperty(e.prototype, "unshift", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      for (var e = [], n = 0; n < arguments.length; n++) {
        e[n] = arguments[n];
      }

      return this.code.unshift(this.buildLine.apply(this, e)), this;
    }
  }), Object.defineProperty(e.prototype, "push", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      for (var e = [], n = 0; n < arguments.length; n++) {
        e[n] = arguments[n];
      }

      return this.code.push(this.buildLine.apply(this, e)), this;
    }
  }), Object.defineProperty(e.prototype, "blank", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return this.code.push(null), this;
    }
  }), Object.defineProperty(e.prototype, "join", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return this.code.join(this.lineJoin);
    }
  }), Object.defineProperty(e.prototype, "clean", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.code.splice(1);
    }
  }), Object.defineProperty(e.prototype, "var", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, n) {
      if (void 0 === n && (n = VariableType.CUSTOM), n === VariableType.PATH) {
        var i = this.variablesPrefix + e;

        switch (reservedVariableNames.includes(i) && (i += "_path"), langToVarNameConvention[this.lang]) {
          case NamingVariableConventions.UNDER_SCORES:
            i = StringUtility.toSnakeCase(i);
            break;

          case NamingVariableConventions.PASCAL_CASE:
            i = StringUtility.toPascalCase(i);
            break;

          case NamingVariableConventions.CAMEL_CASE:
          default:
            i = StringUtility.toCamelCase(i);
        }

        return i;
      }

      return this.variablesPrefix ? this.variablesPrefix + (this.capitalize ? StringUtility.toPascalCase(e) : e) : e;
    }
  }), e;
}();

export { CodeBuilder };