"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateKeywordUsage = exports.validSchemaType = exports.funcKeywordCode = exports.macroKeywordCode = void 0;

var codegen_1 = require("../codegen");

var names_1 = require("../names");

var code_1 = require("../../vocabularies/code");

var errors_1 = require("../errors");

function macroKeywordCode(cxt, def) {
  var gen = cxt.gen,
      keyword = cxt.keyword,
      schema = cxt.schema,
      parentSchema = cxt.parentSchema,
      it = cxt.it;
  var macroSchema = def.macro.call(it.self, schema, parentSchema, it);
  var schemaRef = useKeyword(gen, keyword, macroSchema);
  if (it.opts.validateSchema !== false) it.self.validateSchema(macroSchema, true);
  var valid = gen.name("valid");
  cxt.subschema({
    schema: macroSchema,
    schemaPath: codegen_1.nil,
    errSchemaPath: "".concat(it.errSchemaPath, "/").concat(keyword),
    topSchemaRef: schemaRef,
    compositeRule: true
  }, valid);
  cxt.pass(valid, function () {
    return cxt.error(true);
  });
}

exports.macroKeywordCode = macroKeywordCode;

function funcKeywordCode(cxt, def) {
  var _a;

  var gen = cxt.gen,
      keyword = cxt.keyword,
      schema = cxt.schema,
      parentSchema = cxt.parentSchema,
      $data = cxt.$data,
      it = cxt.it;
  checkAsyncKeyword(it, def);
  var validate = !$data && def.compile ? def.compile.call(it.self, schema, parentSchema, it) : def.validate;
  var validateRef = useKeyword(gen, keyword, validate);
  var valid = gen.let("valid");
  cxt.block$data(valid, validateKeyword);
  cxt.ok((_a = def.valid) !== null && _a !== void 0 ? _a : valid);

  function validateKeyword() {
    if (def.errors === false) {
      assignValid();
      if (def.modifying) modifyData(cxt);
      reportErrs(function () {
        return cxt.error();
      });
    } else {
      var ruleErrs = def.async ? validateAsync() : validateSync();
      if (def.modifying) modifyData(cxt);
      reportErrs(function () {
        return addErrs(cxt, ruleErrs);
      });
    }
  }

  function validateAsync() {
    var ruleErrs = gen.let("ruleErrs", null);
    gen.try(function () {
      return assignValid(codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["await "]))));
    }, function (e) {
      return gen.assign(valid, false).if(codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", " instanceof ", ""])), e, it.ValidationError), function () {
        return gen.assign(ruleErrs, codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", ".errors"])), e));
      }, function () {
        return gen.throw(e);
      });
    });
    return ruleErrs;
  }

  function validateSync() {
    var validateErrs = codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", ".errors"])), validateRef);

    gen.assign(validateErrs, null);
    assignValid(codegen_1.nil);
    return validateErrs;
  }

  function assignValid() {
    var _await = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : def.async ? codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["await "]))) : codegen_1.nil;

    var passCxt = it.opts.passContext ? names_1.default.this : names_1.default.self;
    var passSchema = !("compile" in def && !$data || def.schema === false);
    gen.assign(valid, codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["", "", ""])), _await, code_1.callValidateCode(cxt, validateRef, passCxt, passSchema)), def.modifying);
  }

  function reportErrs(errors) {
    var _a;

    gen.if(codegen_1.not((_a = def.valid) !== null && _a !== void 0 ? _a : valid), errors);
  }
}

exports.funcKeywordCode = funcKeywordCode;

function modifyData(cxt) {
  var gen = cxt.gen,
      data = cxt.data,
      it = cxt.it;
  gen.if(it.parentData, function () {
    return gen.assign(data, codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["", "[", "]"])), it.parentData, it.parentDataProperty));
  });
}

function addErrs(cxt, errs) {
  var gen = cxt.gen;
  gen.if(codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["Array.isArray(", ")"])), errs), function () {
    gen.assign(names_1.default.vErrors, codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["", " === null ? ", " : ", ".concat(", ")"])), names_1.default.vErrors, errs, names_1.default.vErrors, errs)).assign(names_1.default.errors, codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", ".length"])), names_1.default.vErrors));
    errors_1.extendErrors(cxt);
  }, function () {
    return cxt.error();
  });
}

function checkAsyncKeyword(_ref, def) {
  var schemaEnv = _ref.schemaEnv;
  if (def.async && !schemaEnv.$async) throw new Error("async keyword in sync schema");
}

function useKeyword(gen, keyword, result) {
  if (result === undefined) throw new Error("keyword \"".concat(keyword, "\" failed to compile"));
  return gen.scopeValue("keyword", typeof result == "function" ? {
    ref: result
  } : {
    ref: result,
    code: codegen_1.stringify(result)
  });
}

function validSchemaType(schema, schemaType) {
  var allowUndefined = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  // TODO add tests
  return !schemaType.length || schemaType.some(function (st) {
    return st === "array" ? Array.isArray(schema) : st === "object" ? schema && typeof schema == "object" && !Array.isArray(schema) : typeof schema == st || allowUndefined && typeof schema == "undefined";
  });
}

exports.validSchemaType = validSchemaType;

function validateKeywordUsage(_ref2, def, keyword) {
  var schema = _ref2.schema,
      opts = _ref2.opts,
      self = _ref2.self,
      errSchemaPath = _ref2.errSchemaPath;

  /* istanbul ignore if */
  if (Array.isArray(def.keyword) ? !def.keyword.includes(keyword) : def.keyword !== keyword) {
    throw new Error("ajv implementation error");
  }

  var deps = def.dependencies;

  if (deps === null || deps === void 0 ? void 0 : deps.some(function (kwd) {
    return !Object.prototype.hasOwnProperty.call(schema, kwd);
  })) {
    throw new Error("parent schema must have dependencies of ".concat(keyword, ": ").concat(deps.join(",")));
  }

  if (def.validateSchema) {
    var valid = def.validateSchema(schema[keyword]);

    if (!valid) {
      var msg = "keyword \"".concat(keyword, "\" value is invalid at path \"").concat(errSchemaPath, "\": ") + self.errorsText(def.validateSchema.errors);
      if (opts.validateSchema === "log") self.logger.error(msg);else throw new Error(msg);
    }
  }
}

exports.validateKeywordUsage = validateKeywordUsage;