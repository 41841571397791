import { __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../../styled-components";
export var AnimatedExpandButton = function AnimatedExpandButton(e) {
  var t = e.open ? 8 : -4;
  return React.createElement(ChevronContainer, null, React.createElement(ChevronSvg, {
    size: 15,
    style: {
      transform: "translate(2px, " + t + "px) rotate(180deg)",
      transition: "transform 0.2s ease"
    }
  }), React.createElement(ChevronSvg, {
    size: 15,
    style: {
      transform: "translate(2px, " + (0 - t) + "px)",
      transition: "transform 0.2s ease"
    }
  }));
};

var templateObject_1,
    ChevronSvg = function ChevronSvg(e) {
  var t = e.size,
      n = void 0 === t ? 10 : t,
      r = e.className,
      a = void 0 === r ? "" : r,
      o = e.style;
  return React.createElement("svg", {
    className: a,
    style: o || {},
    viewBox: "0 0 926.23699 573.74994",
    version: "1.1",
    x: "0px",
    y: "0px",
    width: n,
    height: n
  }, React.createElement("g", {
    transform: "translate(904.92214,-879.1482)"
  }, React.createElement("path", {
    d: "\n          m -673.67664,1221.6502 -231.2455,-231.24803 55.6165,\n          -55.627 c 30.5891,-30.59485 56.1806,-55.627 56.8701,-55.627 0.6894,\n          0 79.8637,78.60862 175.9427,174.68583 l 174.6892,174.6858 174.6892,\n          -174.6858 c 96.079,-96.07721 175.253196,-174.68583 175.942696,\n          -174.68583 0.6895,0 26.281,25.03215 56.8701,\n          55.627 l 55.6165,55.627 -231.245496,231.24803 c -127.185,127.1864\n          -231.5279,231.248 -231.873,231.248 -0.3451,0 -104.688,\n          -104.0616 -231.873,-231.248 z\n        ",
    fill: "currentColor"
  })));
},
    ChevronContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 2px;\n  user-select: none;\n  width: 20px;\n  height: 20px;\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  color: ", ";\n"], ["\n  margin-top: 2px;\n  user-select: none;\n  width: 20px;\n  height: 20px;\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  color: ", ";\n"])), function (e) {
  return e.theme.colors.primary.main;
});