"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normalizeVisitors = void 0;

function normalizeVisitors(visitorsConfig, types) {
  var normalizedVisitors = {};
  normalizedVisitors.any = {
    enter: [],
    leave: []
  };

  for (var _i = 0, _Object$keys = Object.keys(types); _i < _Object$keys.length; _i++) {
    var typeName = _Object$keys[_i];
    normalizedVisitors[typeName] = {
      enter: [],
      leave: []
    };
  }

  normalizedVisitors.ref = {
    enter: [],
    leave: []
  };

  var _iterator = _createForOfIteratorHelper(visitorsConfig),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _step.value,
          ruleId = _step$value.ruleId,
          severity = _step$value.severity,
          visitor = _step$value.visitor;
      normalizeVisitorLevel({
        ruleId: ruleId,
        severity: severity
      }, visitor, null);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  for (var _i2 = 0, _Object$keys2 = Object.keys(normalizedVisitors); _i2 < _Object$keys2.length; _i2++) {
    var v = _Object$keys2[_i2];
    normalizedVisitors[v].enter.sort(function (a, b) {
      return b.depth - a.depth;
    });
    normalizedVisitors[v].leave.sort(function (a, b) {
      return a.depth - b.depth;
    });
  }

  return normalizedVisitors;

  function addWeakNodes(ruleConf, from, to, parentContext) {
    var stack = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    if (stack.includes(from)) return;
    stack = [].concat(_toConsumableArray(stack), [from]);
    var possibleChildren = new Set();

    for (var _i3 = 0, _Object$values = Object.values(from.properties); _i3 < _Object$values.length; _i3++) {
      var type = _Object$values[_i3];

      if (type === to) {
        addWeakFromStack(ruleConf, stack);
        continue;
      }

      if (typeof type === 'object' && type !== null && type.name) {
        possibleChildren.add(type);
      }
    }

    if (from.additionalProperties && typeof from.additionalProperties !== 'function') {
      if (from.additionalProperties === to) {
        addWeakFromStack(ruleConf, stack);
      } else if (from.additionalProperties.name !== undefined) {
        possibleChildren.add(from.additionalProperties);
      }
    }

    if (from.items) {
      if (from.items === to) {
        addWeakFromStack(ruleConf, stack);
      } else if (from.items.name !== undefined) {
        possibleChildren.add(from.items);
      }
    }

    for (var _i4 = 0, _Array$from = Array.from(possibleChildren.values()); _i4 < _Array$from.length; _i4++) {
      var fromType = _Array$from[_i4];
      addWeakNodes(ruleConf, fromType, to, parentContext, stack);
    }

    function addWeakFromStack(ruleConf, stack) {
      var _iterator2 = _createForOfIteratorHelper(stack.slice(1)),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var interType = _step2.value;
          normalizedVisitors[interType.name] = normalizedVisitors[interType.name] || {
            enter: [],
            leave: []
          };
          normalizedVisitors[interType.name].enter.push(Object.assign(Object.assign({}, ruleConf), {
            visit: function visit() {
              return undefined;
            },
            depth: 0,
            context: {
              isSkippedLevel: true,
              seen: new Set(),
              parent: parentContext
            }
          }));
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }

  function normalizeVisitorLevel(ruleConf, visitor, parentContext) {
    var depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var visitorKeys = Object.keys(types);

    if (depth === 0) {
      visitorKeys.push('any');
      visitorKeys.push('ref');
    } else {
      if (visitor.any) {
        throw new Error('any() is allowed only on top level');
      }

      if (visitor.ref) {
        throw new Error('ref() is allowed only on top level');
      }
    }

    for (var _i5 = 0, _visitorKeys = visitorKeys; _i5 < _visitorKeys.length; _i5++) {
      var _typeName = _visitorKeys[_i5];
      var typeVisitor = visitor[_typeName];
      var normalizedTypeVisitor = normalizedVisitors[_typeName];
      if (!typeVisitor) continue;
      var visitorEnter = void 0;
      var visitorLeave = void 0;
      var visitorSkip = void 0;
      var isObjectVisitor = typeof typeVisitor === 'object';

      if (_typeName === 'ref' && isObjectVisitor && typeVisitor.skip) {
        throw new Error('ref() visitor does not support skip');
      }

      if (typeof typeVisitor === 'function') {
        visitorEnter = typeVisitor;
      } else if (isObjectVisitor) {
        visitorEnter = typeVisitor.enter;
        visitorLeave = typeVisitor.leave;
        visitorSkip = typeVisitor.skip;
      }

      var context = {
        activatedOn: null,
        type: types[_typeName],
        parent: parentContext,
        isSkippedLevel: false
      };

      if (typeof typeVisitor === 'object') {
        normalizeVisitorLevel(ruleConf, typeVisitor, context, depth + 1);
      }

      if (parentContext) {
        addWeakNodes(ruleConf, parentContext.type, types[_typeName], parentContext);
      }

      if (visitorEnter || isObjectVisitor) {
        if (visitorEnter && typeof visitorEnter !== 'function') {
          throw new Error('DEV: should be function');
        }

        normalizedTypeVisitor.enter.push(Object.assign(Object.assign({}, ruleConf), {
          visit: visitorEnter || function () {
            return undefined;
          },
          skip: visitorSkip,
          depth: depth,
          context: context
        }));
      }

      if (visitorLeave) {
        if (typeof visitorLeave !== 'function') {
          throw new Error('DEV: should be function');
        }

        normalizedTypeVisitor.leave.push(Object.assign(Object.assign({}, ruleConf), {
          visit: visitorLeave,
          depth: depth,
          context: context
        }));
      }
    }
  }
}

exports.normalizeVisitors = normalizeVisitors;