import React from "react";
import { expandDefaultServerVariables, getBasePath } from "../../redoc-lib/src/utils";
import { Markdown } from "../../redoc-lib/src/components/Markdown/Markdown";
import { SelectOnClick } from "../shared";
import { ServerItem } from "./ServerItem";
import { ServerUrl } from "./ServerUrl";
import { CopyLink } from "./CopyLink";
export var ServerList = function ServerList(e) {
  var r = e.operation,
      t = e.expandVariables,
      a = e.hideHostname;
  return React.createElement(React.Fragment, null, r.servers.map(function (e) {
    var n = t ? expandDefaultServerVariables(e.url, e.variables) : e.url,
        o = getBasePath(n),
        l = (a ? "/" === o ? "" : o : n) + r.path;
    return React.createElement(ServerItem, {
      key: n
    }, React.createElement(Markdown, {
      source: e.description || "",
      compact: !0
    }), React.createElement(CopyLink, null, React.createElement(SelectOnClick, null, React.createElement(ServerUrl, null, l))));
  }));
};