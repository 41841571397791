var labels = {
  enum: "Enum",
  enumSingleValue: "Value",
  enumArray: "Items",
  default: "Default",
  deprecated: "Deprecated",
  example: "Example",
  examples: "Examples",
  recursive: "Recursive",
  arrayOf: "Array of ",
  webhook: "Event",
  const: "Value",
  authorizations: "Security",
  tryItBody: "Body",
  tryItParameters: "Parameters",
  tryItAuth: "Security",
  tryItAuthBasicUsername: "Username",
  tryItAuthBasicPassword: "Password",
  download: "Download",
  downloadSpecification: "Download OpenAPI specification",
  request: "Request",
  responses: "Responses",
  callbackResponses: "Callback responses",
  callbacks: "Callbacks",
  requestSamples: "Request samples",
  responseSamples: "Response samples"
};
export function setRedocLabels(e) {
  Object.assign(labels, e);
}
export function l(e, s) {
  var a = labels[e];
  return void 0 !== s ? a[s] : a;
}