import { __extends } from "tslib";
import * as React from "react";
import { highlight } from "../../utils";
import { SampleControls, SampleControlsWrap, StyledPre } from "../../common-elements";
import { CopyButtonWrapper } from "../../common-elements/CopyButtonWrapper";

var SourceCode = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.source,
          r = e.lang;
      return React.createElement(StyledPre, {
        dangerouslySetInnerHTML: {
          __html: highlight(t, r)
        }
      });
    }
  }), t;
}(React.PureComponent);

export { SourceCode };

var SourceCodeWithCopy = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e,
          t,
          r,
          o = this,
          n = this.props.source || (null === (r = null === (t = null === (e = this.props.externalSource) || void 0 === e ? void 0 : e.sample) || void 0 === t ? void 0 : t.get) || void 0 === r ? void 0 : r.call(t, this.props.externalSource)) || "";
      return React.createElement(CopyButtonWrapper, {
        data: n
      }, function (e) {
        var t = e.renderCopyButton;
        return React.createElement(SampleControlsWrap, null, React.createElement(SampleControls, null, t()), React.createElement(SourceCode, {
          lang: o.props.lang,
          source: n
        }));
      });
    }
  }), t;
}(React.Component);

export { SourceCodeWithCopy };