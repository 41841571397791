"use strict";

var _defineProperty = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/defineProperty");

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkStrictMode = exports.getErrorPath = exports.Type = exports.useFunc = exports.setEvaluated = exports.evaluatedPropsToName = exports.mergeEvaluated = exports.eachItem = exports.unescapeJsonPointer = exports.escapeJsonPointer = exports.escapeFragment = exports.unescapeFragment = exports.schemaRefOrVal = exports.schemaHasRulesButRef = exports.schemaHasRules = exports.checkUnknownRules = exports.alwaysValidSchema = exports.toHash = void 0;

var codegen_1 = require("./codegen");

var code_1 = require("./codegen/code"); // TODO refactor to use Set


function toHash(arr) {
  var hash = {};

  var _iterator = _createForOfIteratorHelper(arr),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      hash[item] = true;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return hash;
}

exports.toHash = toHash;

function alwaysValidSchema(it, schema) {
  if (typeof schema == "boolean") return schema;
  if (Object.keys(schema).length === 0) return true;
  checkUnknownRules(it, schema);
  return !schemaHasRules(schema, it.self.RULES.all);
}

exports.alwaysValidSchema = alwaysValidSchema;

function checkUnknownRules(it) {
  var schema = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : it.schema;
  var opts = it.opts,
      self = it.self;
  if (!opts.strictSchema) return;
  if (typeof schema === "boolean") return;
  var rules = self.RULES.keywords;

  for (var key in schema) {
    if (!rules[key]) checkStrictMode(it, "unknown keyword: \"".concat(key, "\""));
  }
}

exports.checkUnknownRules = checkUnknownRules;

function schemaHasRules(schema, rules) {
  if (typeof schema == "boolean") return !schema;

  for (var key in schema) {
    if (rules[key]) return true;
  }

  return false;
}

exports.schemaHasRules = schemaHasRules;

function schemaHasRulesButRef(schema, RULES) {
  if (typeof schema == "boolean") return !schema;

  for (var key in schema) {
    if (key !== "$ref" && RULES.all[key]) return true;
  }

  return false;
}

exports.schemaHasRulesButRef = schemaHasRulesButRef;

function schemaRefOrVal(_ref, schema, keyword, $data) {
  var topSchemaRef = _ref.topSchemaRef,
      schemaPath = _ref.schemaPath;

  if (!$data) {
    if (typeof schema == "number" || typeof schema == "boolean") return schema;
    if (typeof schema == "string") return codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", ""])), schema);
  }

  return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", "", "", ""])), topSchemaRef, schemaPath, codegen_1.getProperty(keyword));
}

exports.schemaRefOrVal = schemaRefOrVal;

function unescapeFragment(str) {
  return unescapeJsonPointer(decodeURIComponent(str));
}

exports.unescapeFragment = unescapeFragment;

function escapeFragment(str) {
  return encodeURIComponent(escapeJsonPointer(str));
}

exports.escapeFragment = escapeFragment;

function escapeJsonPointer(str) {
  if (typeof str == "number") return "".concat(str);
  return str.replace(/~/g, "~0").replace(/\//g, "~1");
}

exports.escapeJsonPointer = escapeJsonPointer;

function unescapeJsonPointer(str) {
  return str.replace(/~1/g, "/").replace(/~0/g, "~");
}

exports.unescapeJsonPointer = unescapeJsonPointer;

function eachItem(xs, f) {
  if (Array.isArray(xs)) {
    var _iterator2 = _createForOfIteratorHelper(xs),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var x = _step2.value;
        f(x);
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  } else {
    f(xs);
  }
}

exports.eachItem = eachItem;

function makeMergeEvaluated(_ref2) {
  var mergeNames = _ref2.mergeNames,
      mergeToName = _ref2.mergeToName,
      mergeValues = _ref2.mergeValues,
      resultToName = _ref2.resultToName;
  return function (gen, from, to, toName) {
    var res = to === undefined ? from : to instanceof codegen_1.Name ? (from instanceof codegen_1.Name ? mergeNames(gen, from, to) : mergeToName(gen, from, to), to) : from instanceof codegen_1.Name ? (mergeToName(gen, to, from), from) : mergeValues(from, to);
    return toName === codegen_1.Name && !(res instanceof codegen_1.Name) ? resultToName(gen, res) : res;
  };
}

exports.mergeEvaluated = {
  props: makeMergeEvaluated({
    mergeNames: function mergeNames(gen, from, to) {
      return gen.if(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " !== true && ", " !== undefined"])), to, from), function () {
        gen.if(codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", " === true"])), from), function () {
          return gen.assign(to, true);
        }, function () {
          return gen.assign(to, codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", " || {}"])), to)).code(codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["Object.assign(", ", ", ")"])), to, from));
        });
      });
    },
    mergeToName: function mergeToName(gen, from, to) {
      return gen.if(codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["", " !== true"])), to), function () {
        if (from === true) {
          gen.assign(to, true);
        } else {
          gen.assign(to, codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", " || {}"])), to));
          setEvaluated(gen, to, from);
        }
      });
    },
    mergeValues: function mergeValues(from, to) {
      return from === true ? true : _objectSpread(_objectSpread({}, from), to);
    },
    resultToName: evaluatedPropsToName
  }),
  items: makeMergeEvaluated({
    mergeNames: function mergeNames(gen, from, to) {
      return gen.if(codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["", " !== true && ", " !== undefined"])), to, from), function () {
        return gen.assign(to, codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", " === true ? true : ", " > ", " ? ", " : ", ""])), from, to, from, to, from));
      });
    },
    mergeToName: function mergeToName(gen, from, to) {
      return gen.if(codegen_1._(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["", " !== true"])), to), function () {
        return gen.assign(to, from === true ? true : codegen_1._(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["", " > ", " ? ", " : ", ""])), to, from, to, from));
      });
    },
    mergeValues: function mergeValues(from, to) {
      return from === true ? true : Math.max(from, to);
    },
    resultToName: function resultToName(gen, items) {
      return gen.var("items", items);
    }
  })
};

function evaluatedPropsToName(gen, ps) {
  if (ps === true) return gen.var("props", true);
  var props = gen.var("props", codegen_1._(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["{}"]))));
  if (ps !== undefined) setEvaluated(gen, props, ps);
  return props;
}

exports.evaluatedPropsToName = evaluatedPropsToName;

function setEvaluated(gen, props, ps) {
  Object.keys(ps).forEach(function (p) {
    return gen.assign(codegen_1._(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["", "", ""])), props, codegen_1.getProperty(p)), true);
  });
}

exports.setEvaluated = setEvaluated;
var snippets = {};

function useFunc(gen, f) {
  return gen.scopeValue("func", {
    ref: f,
    code: snippets[f.code] || (snippets[f.code] = new code_1._Code(f.code))
  });
}

exports.useFunc = useFunc;
var Type;

(function (Type) {
  Type[Type["Num"] = 0] = "Num";
  Type[Type["Str"] = 1] = "Str";
})(Type = exports.Type || (exports.Type = {}));

function getErrorPath(dataProp, dataPropType, jsPropertySyntax) {
  // let path
  if (dataProp instanceof codegen_1.Name) {
    var isNumber = dataPropType === Type.Num;
    return jsPropertySyntax ? isNumber ? codegen_1._(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\"[\" + ", " + \"]\""])), dataProp) : codegen_1._(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\"['\" + ", " + \"']\""])), dataProp) : isNumber ? codegen_1._(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\"/\" + ", ""])), dataProp) : codegen_1._(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\"/\" + ", ".replace(/~/g, \"~0\").replace(/\\//g, \"~1\")"], ["\"/\" + ", ".replace(/~/g, \"~0\").replace(/\\\\//g, \"~1\")"])), dataProp); // TODO maybe use global escapePointer
  }

  return jsPropertySyntax ? codegen_1.getProperty(dataProp).toString() : "/" + escapeJsonPointer(dataProp);
}

exports.getErrorPath = getErrorPath;

function checkStrictMode(it, msg) {
  var mode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : it.opts.strictSchema;
  if (!mode) return;
  msg = "strict mode: ".concat(msg);
  if (mode === true) throw new Error(msg);
  it.self.logger.warn(msg);
}

exports.checkStrictMode = checkStrictMode;