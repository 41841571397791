import { __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../../styled-components";
export var LogoImgEl = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: ", ";\n  max-width: ", ";\n  padding: ", ";\n  width: 100%;\n  display: block;\n"], ["\n  max-height: ", ";\n  max-width: ", ";\n  padding: ", ";\n  width: 100%;\n  display: block;\n"])), function (e) {
  return e.theme.logo.maxHeight;
}, function (e) {
  return e.theme.logo.maxWidth;
}, function (e) {
  return e.theme.logo.gutter;
});
export var LogoWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  line-height: 0;\n"], ["\n  text-align: center;\n  line-height: 0;\n"])));
var templateObject_1,
    templateObject_2,
    templateObject_3,
    Link = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n"], ["\n  display: inline-block;\n"])));
export var LinkWrap = function LinkWrap(e) {
  var t = e.url,
      n = e.children;
  return React.createElement(Link, {
    href: t
  }, n);
};