import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject;

import { theme as userTheme } from "Content/theme";
import { defaultTheme } from "./defaultTheme";
import { typography } from "./common-elements";
import { css, ZERO_BREAKPOINT } from "@redocly/reference-docs";
userTheme.menu && !userTheme.sidebar && (userTheme.sidebar = userTheme.menu, console.warn("Theme setting \"menu\" is deprecated. Rename to \"sidebar\""));
export var appTheme = resolveTheme(mergeObjects({}, defaultTheme, userTheme));
appTheme.mediaQueries = {
  print: "@media print"
};

for (var _i = 0, _Object$entries = Object.entries(appTheme.breakpoints); _i < _Object$entries.length; _i++) {
  var _Object$entries$_i = _Object$entries[_i],
      a = _Object$entries$_i[0],
      b = _Object$entries$_i[1];
  appTheme.mediaQueries[a] = "@media screen and (min-width: " + b + ")";
}

export var gradient = function gradient(_ref) {
  var a = _ref.theme;
  return "\nlinear-gradient(\n  -63.43000000000001deg,\n  " + a.colors.navbar.main + " 15%,\n  " + a.colors.navbar.gradient + " 85%\n);\n";
};
export function replaceHashInColor(a) {
  return a.replace("#", "%23");
}
export function svgToDataUri(a, b) {
  return "data:image/svg+xml," + encodeURIComponent(a.replace(/#000/g, b));
}
export function genTokens(a) {
  if (!a) return "";
  var b = "";

  for (var _i2 = 0, _Object$keys = Object.keys(a); _i2 < _Object$keys.length; _i2++) {
    var c = _Object$keys[_i2];
    "default" !== c && (b += ".token." + c + " { color: " + a[c].color + "; }\n");
  }

  return a.default ? css(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n      pre& {\n        ", "\n      }\n      ", "\n    "])), typography(a.default), b) : b;
}

function resolveTheme(a) {
  var b = {};
  var c = 0;

  var d = function d(e, f) {
    Object.keys(e).forEach(function (g) {
      var h = (f ? f + "." : "") + g,
          i = e[g];
      "function" == typeof i ? Object.defineProperty(e, g, {
        get: function get() {
          if (!b[h]) {
            if (c++, 1e3 < c) throw new Error("Theme probably contains cirucal dependency at " + h + ": " + i.toString());
            b[h] = i(a);
          }

          return b[h];
        },
        enumerable: !0
      }) : "object" == typeof i && d(i, h);
    });
  };

  return d(a, ""), JSON.parse(JSON.stringify(a));
}

function mergeObjects(a) {
  for (var _len = arguments.length, b = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    b[_key - 1] = arguments[_key];
  }

  if (!b.length) return a;
  var c = b.shift();
  return void 0 === c ? a : (isMergebleObject(a) && isMergebleObject(c) && Object.keys(c).forEach(function (b) {
    var _a$b;

    isMergebleObject(c[b]) ? (!a[b] && (a[b] = {}), ["string", "number"].includes(typeof a[b]) && (!c[b][ZERO_BREAKPOINT] && 0 !== c[b][ZERO_BREAKPOINT] && console.warn("Theme resolver: using default value of \"" + a[b] + "\" for \"" + b + "\". This is not recommended. Prefer adding default value by providing \"" + ZERO_BREAKPOINT + "\" property to " + JSON.stringify(c[b]) + "."), a[b] = (_a$b = {}, _a$b[ZERO_BREAKPOINT] = a[b], _a$b)), mergeObjects(a[b], c[b])) : a[b] = c[b];
  }), mergeObjects.apply(void 0, [a].concat(b)));
}

function isObject(a) {
  return null !== a && "object" == typeof a;
}

function isMergebleObject(a) {
  return isObject(a) && !Array.isArray(a);
}