import { __assign } from "tslib";
import { CodeBuilder } from "../../helpers/code-builder";
import stringifyObject from "stringify-object";
import { addIndentation, getPreserveTransformer, buildUrlExpression, printUrlVariablesDeclarations } from "../../helpers/code-helpers";
import { normalizeMimeType } from "../../../../utils";
import { HTTPSnippet } from "../..";
import { Lang } from "../../helpers/constants";

var handler = function handler(e, t, a) {
  var r,
      n,
      i,
      s,
      o,
      l = a.target,
      d = a.client,
      p = __assign({
    indent: "  ",
    credentials: null
  }, t),
      c = new CodeBuilder({
    indentation: p.indent,
    variablesPrefix: p.variablesPrefix,
    capitalize: !0,
    lang: Lang.JAVASCRIPT
  }),
      h = {
    method: e.method.toUpperCase()
  };

  Object.keys(e.headersObj).length && (h.headers = e.headersObj);
  var m = !1;

  if (p.withComments && addComments(c), null === (r = e.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r.length) {
    var u = new HTTPSnippet(null === (n = e.securityOAuth2ExtraCalls) || void 0 === n ? void 0 : n[0]).convert(l, d, __assign(__assign({}, p), {
      withImports: !1,
      withComments: !1,
      variablesPrefix: "oAuth2"
    }));
    c.push(u), c.blank(), h.headers = h.headers || {}, h.headers.Authorization = "'Bearer ' + oAuth2Data.access_token", m = !0;
  }

  null !== p.credentials && (h.credentials = p.credentials);
  var f = Object.getOwnPropertyNames(e.queryObj).length;
  if (f && c.push("const " + c.var("query") + " = new URLSearchParams(" + stringifyObject(e.queryObj, {
    indent: p.indent,
    inlineCharacterLimit: 25
  }) + ").toString();").blank(), e.postData) switch (normalizeMimeType(e.postData.mimeType)) {
    case "application/x-www-form-urlencoded":
      h.body = e.postData.paramsObj ? "new URLSearchParams(" + c.var("formData") + ").toString()" : e.postData.text, e.postData.paramsObj && c.push("const " + c.var("formData") + " = " + addIndentation(stringifyObject(e.postData.paramsObj, {
        indent: p.indent,
        inlineCharacterLimit: 25
      }), {
        level: 0,
        firstLine: !1
      }) + ";").blank();
      break;

    case "application/json":
      e.postData.jsonObj && (h.body = "JSON.stringify(" + addIndentation(stringifyObject(e.postData.jsonObj, {
        indent: p.indent,
        inlineCharacterLimit: 25
      }), {
        level: 1,
        firstLine: !1
      }) + ")");
      break;

    case "multipart/form-data":
      c.push("const " + c.var("form") + " = new FormData();"), (null === (i = null == h ? void 0 : h.headers) || void 0 === i ? void 0 : i["Content-Type"]) && (null === (s = h.headers) || void 0 === s || delete s["Content-Type"]), e.postData.params.forEach(function (e) {
        c.push(c.var("form") + ".append(%s, %s);", JSON.stringify(e.name), JSON.stringify(e.value || e.fileName || ""));
      }), h.body = c.var("form"), c.blank();
      break;

    default:
      e.postData.text && (h.body = "`" + addIndentation(e.postData.text, {
        level: 2,
        indent: p.indent,
        firstLine: !1
      }).trim() + "`");
  }
  var b = h.headers && h.headers.Accept && "application/json" === normalizeMimeType(h.headers.Accept) || "application/json" === normalizeMimeType(null === (o = e.postData) || void 0 === o ? void 0 : o.mimeType);

  if (e.basicAuth) {
    var v = e.basicAuth,
        y = v.username,
        g = v.password;
    h.headers = h.headers || {}, h.headers.Authorization = "'Basic ' + btoa('" + y + ":" + g + "')", m = !0;
  }

  printUrlVariablesDeclarations(e, c);
  var j = buildUrlExpression(e, c);
  return c.push("const " + c.var("resp") + " = await fetch(").push(1, "`" + j + (f ? "?${" + c.var("query") + "}" : "") + "`,").push(addIndentation(stringifyObject(h, {
    indent: p.indent,
    inlineCharacterLimit: 25,
    transform: getPreserveTransformer({
      body: !0,
      authorizationHeader: m
    })
  }), {
    level: 1,
    indent: p.indent
  })).push(");").blank().push("const " + c.var("data") + " = await " + c.var("resp") + "." + (b ? "json()" : "text()") + ";").push("console.log(" + c.var("data") + ");"), c.join();
};

function addComments(e) {
  e.push("/**"), e.push(" * Requires ES8-compatible browser: https://caniuse.com/async-functions"), e.push(" */"), e.blank();
}

export var info = {
  key: "fetch",
  title: "fetch",
  link: "https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch",
  description: "Perform asynchronous HTTP requests with the Fetch API"
};
export default handler;