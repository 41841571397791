"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponseMimeType = void 0;

var utils_1 = require("../../utils");

var ResponseMimeType = function ResponseMimeType(_ref) {
  var allowedValues = _ref.allowedValues;
  return {
    DefinitionRoot: function DefinitionRoot(root, ctx) {
      utils_1.validateMimeType({
        type: 'produces',
        value: root
      }, ctx, allowedValues);
    },
    Operation: {
      leave: function leave(operation, ctx) {
        utils_1.validateMimeType({
          type: 'produces',
          value: operation
        }, ctx, allowedValues);
      }
    }
  };
};

exports.ResponseMimeType = ResponseMimeType;