import _defineProperty from "/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { _samplers } from './openapi-sampler';
import { allOfSample } from './allOf';
import { inferType } from './infer';
import { getResultForCircular, mergeDeep, popSchemaStack } from './utils';
import JsonPointer from 'json-pointer';
var $refCache = {}; // for circular JS references we use additional array and not object as we need to compare entire schemas and not strings

var seenSchemasStack = [];
export function clearCache() {
  $refCache = {};
  seenSchemasStack = [];
}

function inferExample(schema) {
  var example;

  if (schema.const !== undefined) {
    example = schema.const;
  } else if (schema.examples !== undefined && schema.examples.length) {
    example = schema.examples[0];
  } else if (schema.enum !== undefined && schema.enum.length) {
    example = schema.enum[0];
  } else if (schema.default !== undefined) {
    example = schema.default;
  }

  return example;
}

function tryInferExample(schema) {
  var example = inferExample(schema); // case when we don't infer example from schema but take from `const`, `examples`, `default` or `enum` keywords

  if (example !== undefined) {
    return {
      value: example,
      readOnly: schema.readOnly,
      writeOnly: schema.writeOnly,
      type: null
    };
  }

  return;
}

export function traverse(schema, options, spec, context) {
  // checking circular JS references by checking context
  // because context is passed only when traversing through nested objects happens
  if (context) {
    if (seenSchemasStack.includes(schema)) return getResultForCircular(inferType(schema));
    seenSchemasStack.push(schema);
  }

  if (context && context.depth > options.maxSampleDepth) {
    popSchemaStack(seenSchemasStack, context);
    return getResultForCircular(inferType(schema));
  }

  if (schema.$ref) {
    if (!spec) {
      throw new Error('Your schema contains $ref. You must provide full specification in the third parameter.');
    }

    var ref = decodeURIComponent(schema.$ref);

    if (ref.startsWith('#')) {
      ref = ref.substring(1);
    }

    var referenced = JsonPointer.get(spec, ref);
    var result;

    if ($refCache[ref] !== true) {
      $refCache[ref] = true;
      result = traverse(referenced, options, spec, context);
      $refCache[ref] = false;
    } else {
      var referencedType = inferType(referenced);
      result = getResultForCircular(referencedType);
    }

    popSchemaStack(seenSchemasStack, context);
    return result;
  }

  if (schema.example !== undefined) {
    popSchemaStack(seenSchemasStack, context);
    return {
      value: schema.example,
      readOnly: schema.readOnly,
      writeOnly: schema.writeOnly,
      type: schema.type
    };
  }

  if (schema.allOf !== undefined) {
    popSchemaStack(seenSchemasStack, context);
    return tryInferExample(schema) || allOfSample(_objectSpread(_objectSpread({}, schema), {}, {
      allOf: undefined
    }), schema.allOf, options, spec, context);
  }

  if (schema.oneOf && schema.oneOf.length) {
    if (schema.anyOf) {
      if (!options.quiet) console.warn('oneOf and anyOf are not supported on the same level. Skipping anyOf');
    }

    popSchemaStack(seenSchemasStack, context);
    return tryInferExample(schema) || traverse(schema.oneOf[0], options, spec, context);
  }

  if (schema.anyOf && schema.anyOf.length) {
    popSchemaStack(seenSchemasStack, context);
    return tryInferExample(schema) || traverse(schema.anyOf[0], options, spec, context);
  }

  if (schema.if && schema.then) {
    popSchemaStack(seenSchemasStack, context);
    return tryInferExample(schema) || traverse(mergeDeep(schema.if, schema.then), options, spec, context);
  }

  var example = inferExample(schema);
  var type = null;

  if (example === undefined) {
    example = null;
    type = schema.type;

    if (Array.isArray(type) && schema.type.length > 0) {
      type = schema.type[0];
    }

    if (!type) {
      type = inferType(schema);
    }

    var sampler = _samplers[type];

    if (sampler) {
      example = sampler(schema, options, spec, context);
    }
  }

  popSchemaStack(seenSchemasStack, context);
  return {
    value: example,
    readOnly: schema.readOnly,
    writeOnly: schema.writeOnly,
    type: type
  };
}