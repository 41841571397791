import { observer } from "mobx-react";
import * as React from "react";
import { ResponseDetails } from "./ResponseDetails";
import { ResponsePanel } from "../../../../components/Panel";
import { ResponseSummary } from "./ResponseSummary";
export var ResponseView = observer(function (e) {
  var r = e.response;
  return React.createElement(ResponsePanel, {
    header: function header(e) {
      var o = e.expanded,
          s = e.toggle;
      return React.createElement(ResponseSummary, {
        response: r,
        toggle: s,
        expanded: o
      });
    },
    collapsed: !r.expanded,
    type: r.type
  }, React.createElement(ResponseDetails, {
    response: r
  }));
});