import { __assign } from "tslib";
export function useCodeSamples(l) {
  var a = l.allCodeSamples;
  return {
    payload: l.payload,
    samples: a.map(function (l) {
      var a, e;
      return __assign({
        key: l.lang + "_" + (null !== (a = l.label) && void 0 !== a ? a : ""),
        title: null !== (e = l.label) && void 0 !== e ? e : l.lang
      }, l);
    })
  };
}