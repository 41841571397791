import { __makeTemplateObject } from "tslib";
import * as React from "react";
import styled, { css } from "../styled-components";
export function Tooltip(t) {
  var n = t.children,
      e = t.open,
      r = t.tip,
      o = t.placement,
      a = void 0 === o ? "top" : o,
      l = t.variant,
      p = void 0 === l ? "dark" : l,
      i = t.width,
      s = React.useState(e),
      d = s[0],
      b = s[1],
      m = void 0 !== e;
  React.useEffect(function () {
    m && b(e);
  }, [e, m]);
  var c = React.useCallback(function () {
    b(!0);
  }, [b]),
      f = React.useCallback(function () {
    b(!1);
  }, [b]);
  return React.createElement(TooltipWrapper, {
    onMouseEnter: m ? void 0 : c,
    onMouseLeave: m ? void 0 : f,
    onClick: m ? void 0 : f
  }, n, d ? React.createElement(TooltipBody, {
    placement: a,
    variant: p,
    width: i
  }, r) : null);
}
var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    templateObject_6,
    templateObject_7,
    templateObject_8,
    templateObject_9,
    TooltipWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n"], ["\n  position: relative;\n  display: inline-block;\n"]))),
    tooltipBase = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n\n  position: absolute;\n  text-align: center;\n\n  padding: 10px 20px;\n  max-width: 250px;\n  white-space: normal;\n  overflow-wrap: break-word;\n\n  border-radius: ", ";\n  pointer-events: none;\n  transition: opacity 0.3s ease-out;\n\n  display: inline-block;\n  font-size: 13px;\n\n  &::after {\n    position: absolute;\n\n    content: ' ';\n    display: inline-block;\n    width: 0;\n    height: 0;\n  }\n"], ["\n  display: inline-block;\n\n  position: absolute;\n  text-align: center;\n\n  padding: 10px 20px;\n  max-width: 250px;\n  white-space: normal;\n  overflow-wrap: break-word;\n\n  border-radius: ", ";\n  pointer-events: none;\n  transition: opacity 0.3s ease-out;\n\n  display: inline-block;\n  font-size: 13px;\n\n  &::after {\n    position: absolute;\n\n    content: ' ';\n    display: inline-block;\n    width: 0;\n    height: 0;\n  }\n"])), function (t) {
  return t.theme.shape.borderRadius;
}),
    PLACEMENTS = {
  top: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -99%);\n    margin-top: -10px;\n\n    &::after {\n      border-left: 5px solid transparent;\n      border-right: 5px solid transparent;\n      border-top-width: 6px;\n      border-top-style: solid;\n      bottom: 0;\n      left: 50%;\n      transform: translate(-50%, 99%);\n    }\n  "], ["\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -99%);\n    margin-top: -10px;\n\n    &::after {\n      border-left: 5px solid transparent;\n      border-right: 5px solid transparent;\n      border-top-width: 6px;\n      border-top-style: solid;\n      bottom: 0;\n      left: 50%;\n      transform: translate(-50%, 99%);\n    }\n  "]))),
  bottom: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    bottom: 0;\n    left: 50%;\n    transform: translate(-50%, 99%);\n    margin-bottom: -10px;\n\n    &::after {\n      border-left: 5px solid transparent;\n      border-right: 5px solid transparent;\n      border-bottom-width: 6px;\n      border-bottom-style: solid;\n      top: 0;\n      left: 50%;\n      transform: translate(-50%, -99%);\n    }\n  "], ["\n    bottom: 0;\n    left: 50%;\n    transform: translate(-50%, 99%);\n    margin-bottom: -10px;\n\n    &::after {\n      border-left: 5px solid transparent;\n      border-right: 5px solid transparent;\n      border-bottom-width: 6px;\n      border-bottom-style: solid;\n      top: 0;\n      left: 50%;\n      transform: translate(-50%, -99%);\n    }\n  "]))),
  left: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    top: 50%;\n    left: 0;\n    transform: translate(-100%, -50%);\n    margin-left: -10px;\n\n    &::after {\n      border-top: 5px solid transparent;\n      border-bottom: 5px solid transparent;\n      border-left-width: 6px;\n      border-left-style: solid;\n      top: 50%;\n      right: 0;\n      transform: translate(99%, -50%);\n    }\n  "], ["\n    top: 50%;\n    left: 0;\n    transform: translate(-100%, -50%);\n    margin-left: -10px;\n\n    &::after {\n      border-top: 5px solid transparent;\n      border-bottom: 5px solid transparent;\n      border-left-width: 6px;\n      border-left-style: solid;\n      top: 50%;\n      right: 0;\n      transform: translate(99%, -50%);\n    }\n  "]))),
  right: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    top: 50%;\n    right: 0;\n    transform: translate(100%, -50%);\n    margin-right: -10px;\n\n    &::after {\n      border-top: 5px solid transparent;\n      border-bottom: 5px solid transparent;\n      border-right-width: 6px;\n      border-right-style: solid;\n      top: 50%;\n      left: 0;\n      transform: translate(-99%, -50%);\n    }\n  "], ["\n    top: 50%;\n    right: 0;\n    transform: translate(100%, -50%);\n    margin-right: -10px;\n\n    &::after {\n      border-top: 5px solid transparent;\n      border-bottom: 5px solid transparent;\n      border-right-width: 6px;\n      border-right-style: solid;\n      top: 50%;\n      left: 0;\n      transform: translate(-99%, -50%);\n    }\n  "])))
},
    VARIANTS = {
  dark: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    background: #52606d;\n    color: #f5f7fa;\n    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;\n\n    &::after {\n      border-color: #52606d;\n    }\n  "], ["\n    background: #52606d;\n    color: #f5f7fa;\n    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;\n\n    &::after {\n      border-color: #52606d;\n    }\n  "]))),
  light: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    background: white;\n    color: black;\n    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;\n\n    &::after {\n      border-color: white;\n    }\n  "], ["\n    background: white;\n    color: black;\n    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;\n\n    &::after {\n      border-color: white;\n    }\n  "])))
},
    TooltipBody = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n  width:", ";\n  ", "\n"], ["\n  ", "\n  width:", ";\n  ", "\n"])), tooltipBase, function (t) {
  return t.width || "120px";
}, function (t) {
  var n = t.placement,
      e = t.variant;
  return "" + VARIANTS[e] + PLACEMENTS[n];
});