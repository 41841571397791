var level = 1;
export function jsonToHTML(e, t) {
  level = 1;
  var n = "";
  return n += '<div class="redoc-json">', n += "<code>", n += valueToHTML(e, t), n += "</code>", n += "</div>";
}

function htmlEncode(e) {
  return void 0 !== e ? e.toString().replace(/&/g, "&amp;").replace(/"/g, "&quot;").replace(/</g, "&lt;").replace(/>/g, "&gt;") : "";
}

function stringifyStringLiteral(e) {
  return JSON.stringify(e).slice(1, -1);
}

function decorateWithSpan(e, t) {
  return '<span class="' + t + '">' + htmlEncode(e) + "</span>";
}

function punctuation(e) {
  return '<span class="token punctuation">' + e + "</span>";
}

function valueToHTML(e, t) {
  var n = typeof e,
      a = "";
  return null == e ? a += decorateWithSpan("null", "token keyword") : e && e.constructor === Array ? (level++, a += arrayToHTML(e, t), level--) : e && e.constructor === Date ? a += decorateWithSpan('"' + e.toISOString() + '"', "token string") : "object" === n ? (level++, a += objectToHTML(e, t), level--) : "number" === n ? a += decorateWithSpan(e, "token number") : "string" === n ? /^(http|https):\/\/[^\s]+$/.test(e) ? a += decorateWithSpan('"', "token string") + '<a href="' + encodeURI(e) + '">' + htmlEncode(stringifyStringLiteral(e)) + "</a>" + decorateWithSpan('"', "token string") : a += decorateWithSpan('"' + stringifyStringLiteral(e) + '"', "token string") : "boolean" === n && (a += decorateWithSpan(e, "token boolean")), a;
}

function arrayToHTML(e, t) {
  for (var n = level > t ? "collapsed" : "", a = '<button class="collapser" aria-label="' + (level > t + 1 ? "expand" : "collapse") + '"></button>' + punctuation("[") + '<span class="ellipsis"></span><ul class="array collapsible">', l = !1, o = e.length, r = 0; r < o; r++) {
    l = !0, a += '<li><div class="hoverable ' + n + '">', a += valueToHTML(e[r], t), r < o - 1 && (a += ","), a += "</div></li>";
  }

  return a += "</ul>" + punctuation("]"), l || (a = punctuation("[ ]")), a;
}

function objectToHTML(e, t) {
  for (var n = level > t ? "collapsed" : "", a = Object.keys(e), l = a.length, o = '<button class="collapser" aria-label="' + (level > t + 1 ? "expand" : "collapse") + '"></button>' + punctuation("{") + '<span class="ellipsis"></span><ul class="obj collapsible">', r = !1, i = 0; i < l; i++) {
    var c = a[i];
    r = !0, o += '<li><div class="hoverable ' + n + '">', o += '<span class="property token string">"' + htmlEncode(c) + '"</span>: ', o += valueToHTML(e[c], t), i < l - 1 && (o += punctuation(",")), o += "</div></li>";
  }

  return o += "</ul>" + punctuation("}"), r || (o = punctuation("{ }")), o;
}