"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var ops = codegen_1.operators;
var KWDs = {
  maximum: {
    okStr: "<=",
    ok: ops.LTE,
    fail: ops.GT
  },
  minimum: {
    okStr: ">=",
    ok: ops.GTE,
    fail: ops.LT
  },
  exclusiveMaximum: {
    okStr: "<",
    ok: ops.LT,
    fail: ops.GTE
  },
  exclusiveMinimum: {
    okStr: ">",
    ok: ops.GT,
    fail: ops.LTE
  }
};
var error = {
  message: function message(_ref) {
    var keyword = _ref.keyword,
        schemaCode = _ref.schemaCode;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must be ", " ", ""])), KWDs[keyword].okStr, schemaCode);
  },
  params: function params(_ref2) {
    var keyword = _ref2.keyword,
        schemaCode = _ref2.schemaCode;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{comparison: ", ", limit: ", "}"])), KWDs[keyword].okStr, schemaCode);
  }
};
var def = {
  keyword: Object.keys(KWDs),
  type: "number",
  schemaType: "number",
  $data: true,
  error: error,
  code: function code(cxt) {
    var keyword = cxt.keyword,
        data = cxt.data,
        schemaCode = cxt.schemaCode;
    cxt.fail$data(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " ", " ", " || isNaN(", ")"])), data, KWDs[keyword].fail, schemaCode, data));
  }
};
exports.default = def;