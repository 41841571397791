import { __decorate, __extends, __makeTemplateObject } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { RedocNormalizedOptions } from "../../services/RedocNormalizedOptions";
import styled, { css, mediaCSS, ZERO_BREAKPOINT } from "../../styled-components";
import { IS_BROWSER } from "../../utils/index";
import { OptionsContext } from "../OptionsProvider";
import { AnimatedExpandButton } from "./ChevronSvg";
var Stickyfill;
IS_BROWSER && (Stickyfill = require("stickyfill"));

var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    stickyfill = Stickyfill && Stickyfill(),
    StyledStickySidebar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: hidden;\n  flex-direction: column;\n  backface-visibility: hidden;\n  /* contain: strict; TODO: breaks layout since Chrome 80*/\n  height: 100vh;\n  position: sticky;\n  position: -webkit-sticky;\n  top: 0;\n  flex: 0 0 auto;\n  ", "\n"], ["\n  overflow: hidden;\n  flex-direction: column;\n  backface-visibility: hidden;\n  /* contain: strict; TODO: breaks layout since Chrome 80*/\n  height: 100vh;\n  position: sticky;\n  position: -webkit-sticky;\n  top: 0;\n  flex: 0 0 auto;\n  ", "\n"])), function (e) {
  var t,
      n,
      i,
      o,
      r = e.theme,
      l = e.open;
  return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", "\n    ", " {\n      display: none;\n    }\n  "], ["\n    ", "\n    ", " {\n      display: none;\n    }\n  "])), mediaCSS({
    position: (t = {}, t[ZERO_BREAKPOINT] = "fixed", t.small = "sticky", t),
    zIndex: (n = {}, n[ZERO_BREAKPOINT] = 20, n.small = "auto", n),
    width: (i = {}, i[ZERO_BREAKPOINT] = "100%", i.small = r.sidebar.width, i),
    background: r.sidebar.backgroundColor,
    borderRight: "1px solid " + r.sidebar.rightLineColor,
    display: (o = {}, o[ZERO_BREAKPOINT] = l ? "flex" : "none", o.small = "flex", o)
  }), r.mediaQueries.print);
}),
    FloatingButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  outline: none;\n  user-select: none;\n  background-color: #f2f2f2;\n  cursor: pointer;\n  position: fixed;\n  right: 20px;\n  z-index: 100;\n  border-radius: 50%; // round button\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);\n  bottom: 44px;\n  width: 60px;\n  height: 60px;\n  padding: 0 20px;\n  ", "\n"], ["\n  outline: none;\n  user-select: none;\n  background-color: #f2f2f2;\n  cursor: pointer;\n  position: fixed;\n  right: 20px;\n  z-index: 100;\n  border-radius: 50%; // round button\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);\n  bottom: 44px;\n  width: 60px;\n  height: 60px;\n  padding: 0 20px;\n  ", "\n"])), function (e) {
  var t,
      n = e.theme;
  return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", "\n    ", " {\n      display: none;\n    }\n  "], ["\n    ", "\n    ", " {\n      display: none;\n    }\n  "])), mediaCSS({
    color: n.colors.primary.main,
    display: (t = {}, t[ZERO_BREAKPOINT] = "flex", t.small = "none", t)
  }), n.mediaQueries.print);
}),
    StickyResponsiveSidebar = function (e) {
  function t() {
    var t = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(t, "state", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: {
        offsetTop: "0px"
      }
    }), Object.defineProperty(t, "stickyElement", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(t, "toggleNavMenu", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        t.props.menu.toggleSidebar();
      }
    }), t;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "componentDidMount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      stickyfill && stickyfill.add(this.stickyElement), this.setState({
        offsetTop: this.getScrollYOffset(this.context)
      });
    }
  }), Object.defineProperty(t.prototype, "componentWillUnmount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      stickyfill && stickyfill.remove(this.stickyElement);
    }
  }), Object.defineProperty(t.prototype, "getScrollYOffset", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return (void 0 !== this.props.scrollYOffset ? RedocNormalizedOptions.normalizeScrollYOffset(this.props.scrollYOffset)() : e.scrollYOffset()) + "px";
    }
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this,
          t = this.props.menu.sideBarOpened,
          n = this.state.offsetTop;
      return React.createElement(React.Fragment, null, React.createElement(StyledStickySidebar, {
        open: t,
        className: this.props.className,
        style: {
          top: n,
          height: "calc(100vh - " + n + ")"
        },
        ref: function ref(t) {
          e.stickyElement = t;
        }
      }, this.props.children), React.createElement(FloatingButton, {
        onClick: this.toggleNavMenu
      }, React.createElement(AnimatedExpandButton, {
        open: t
      })));
    }
  }), Object.defineProperty(t, "contextType", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: OptionsContext
  }), t = __decorate([observer], t);
}(React.Component);

export { StickyResponsiveSidebar };