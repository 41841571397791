export var IS_BROWSER = "undefined" != typeof window && "HTMLElement" in window;
export function querySelector(t) {
  return "undefined" != typeof document ? document.querySelector(t) : null;
}
export function html2Str(t) {
  return t.split(/<[^>]+>/).map(function (t) {
    return t.trim();
  }).filter(function (t) {
    return t.length > 0;
  }).join(" ");
}
"undefined" == typeof Element || Element.prototype.scrollIntoViewIfNeeded || (Element.prototype.scrollIntoViewIfNeeded = function (t) {
  t = 0 === arguments.length || !!t;
  var e = this.parentNode,
      o = window.getComputedStyle(e, void 0),
      i = parseInt(o.getPropertyValue("border-top-width"), 10),
      f = parseInt(o.getPropertyValue("border-left-width"), 10),
      n = this.offsetTop - e.offsetTop < e.scrollTop,
      l = this.offsetTop - e.offsetTop + this.clientHeight - i > e.scrollTop + e.clientHeight,
      r = this.offsetLeft - e.offsetLeft < e.scrollLeft,
      s = this.offsetLeft - e.offsetLeft + this.clientWidth - f > e.scrollLeft + e.clientWidth,
      p = n && !l;
  (n || l) && t && (e.scrollTop = this.offsetTop - e.offsetTop - e.clientHeight / 2 - i + this.clientHeight / 2), (r || s) && t && (e.scrollLeft = this.offsetLeft - e.offsetLeft - e.clientWidth / 2 - f + this.clientWidth / 2), (n || l || r || s) && !t && this.scrollIntoView(p);
});