"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateUnion = exports.validateArray = exports.usePattern = exports.callValidateCode = exports.schemaProperties = exports.allSchemaProperties = exports.noPropertyInData = exports.propertyInData = exports.isOwnProperty = exports.hasPropFunc = exports.reportMissingProp = exports.checkMissingProp = exports.checkReportMissingProp = void 0;

var codegen_1 = require("../compile/codegen");

var util_1 = require("../compile/util");

var names_1 = require("../compile/names");

function checkReportMissingProp(cxt, prop) {
  var gen = cxt.gen,
      data = cxt.data,
      it = cxt.it;
  gen.if(noPropertyInData(gen, data, prop, it.opts.ownProperties), function () {
    cxt.setParams({
      missingProperty: codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", ""])), prop)
    }, true);
    cxt.error();
  });
}

exports.checkReportMissingProp = checkReportMissingProp;

function checkMissingProp(_ref, properties, missing) {
  var gen = _ref.gen,
      data = _ref.data,
      opts = _ref.it.opts;
  return codegen_1.or.apply(codegen_1, _toConsumableArray(properties.map(function (prop) {
    return codegen_1.and(noPropertyInData(gen, data, prop, opts.ownProperties), codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", " = ", ""])), missing, prop));
  })));
}

exports.checkMissingProp = checkMissingProp;

function reportMissingProp(cxt, missing) {
  cxt.setParams({
    missingProperty: missing
  }, true);
  cxt.error();
}

exports.reportMissingProp = reportMissingProp;

function hasPropFunc(gen) {
  return gen.scopeValue("func", {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    ref: Object.prototype.hasOwnProperty,
    code: codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["Object.prototype.hasOwnProperty"])))
  });
}

exports.hasPropFunc = hasPropFunc;

function isOwnProperty(gen, data, property) {
  return codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", ".call(", ", ", ")"])), hasPropFunc(gen), data, property);
}

exports.isOwnProperty = isOwnProperty;

function propertyInData(gen, data, property, ownProperties) {
  var cond = codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", "", " !== undefined"])), data, codegen_1.getProperty(property));

  return ownProperties ? codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["", " && ", ""])), cond, isOwnProperty(gen, data, property)) : cond;
}

exports.propertyInData = propertyInData;

function noPropertyInData(gen, data, property, ownProperties) {
  var cond = codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["", "", " === undefined"])), data, codegen_1.getProperty(property));

  return ownProperties ? codegen_1.or(cond, codegen_1.not(isOwnProperty(gen, data, property))) : cond;
}

exports.noPropertyInData = noPropertyInData;

function allSchemaProperties(schemaMap) {
  return schemaMap ? Object.keys(schemaMap).filter(function (p) {
    return p !== "__proto__";
  }) : [];
}

exports.allSchemaProperties = allSchemaProperties;

function schemaProperties(it, schemaMap) {
  return allSchemaProperties(schemaMap).filter(function (p) {
    return !util_1.alwaysValidSchema(it, schemaMap[p]);
  });
}

exports.schemaProperties = schemaProperties;

function callValidateCode(_ref2, func, context, passSchema) {
  var schemaCode = _ref2.schemaCode,
      data = _ref2.data,
      _ref2$it = _ref2.it,
      gen = _ref2$it.gen,
      topSchemaRef = _ref2$it.topSchemaRef,
      schemaPath = _ref2$it.schemaPath,
      errorPath = _ref2$it.errorPath,
      it = _ref2.it;
  var dataAndSchema = passSchema ? codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", ", ", ", ", "", ""])), schemaCode, data, topSchemaRef, schemaPath) : data;
  var valCxt = [[names_1.default.instancePath, codegen_1.strConcat(names_1.default.instancePath, errorPath)], [names_1.default.parentData, it.parentData], [names_1.default.parentDataProperty, it.parentDataProperty], [names_1.default.rootData, names_1.default.rootData]];
  if (it.opts.dynamicRef) valCxt.push([names_1.default.dynamicAnchors, names_1.default.dynamicAnchors]);

  var args = codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["", ", ", ""])), dataAndSchema, gen.object.apply(gen, valCxt));

  return context !== codegen_1.nil ? codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", ".call(", ", ", ")"])), func, context, args) : codegen_1._(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["", "(", ")"])), func, args);
}

exports.callValidateCode = callValidateCode;

function usePattern(_ref3, pattern) {
  var gen = _ref3.gen,
      opts = _ref3.it.opts;
  var u = opts.unicodeRegExp ? "u" : "";
  return gen.scopeValue("pattern", {
    key: pattern,
    ref: new RegExp(pattern, u),
    code: codegen_1._(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["new RegExp(", ", ", ")"])), pattern, u)
  });
}

exports.usePattern = usePattern;

function validateArray(cxt) {
  var gen = cxt.gen,
      data = cxt.data,
      keyword = cxt.keyword,
      it = cxt.it;
  var valid = gen.name("valid");

  if (it.allErrors) {
    var validArr = gen.let("valid", true);
    validateItems(function () {
      return gen.assign(validArr, false);
    });
    return validArr;
  }

  gen.var(valid, true);
  validateItems(function () {
    return gen.break();
  });
  return valid;

  function validateItems(notValid) {
    var len = gen.const("len", codegen_1._(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["", ".length"])), data));
    gen.forRange("i", 0, len, function (i) {
      cxt.subschema({
        keyword: keyword,
        dataProp: i,
        dataPropType: util_1.Type.Num
      }, valid);
      gen.if(codegen_1.not(valid), notValid);
    });
  }
}

exports.validateArray = validateArray;

function validateUnion(cxt) {
  var gen = cxt.gen,
      schema = cxt.schema,
      keyword = cxt.keyword,
      it = cxt.it;
  /* istanbul ignore if */

  if (!Array.isArray(schema)) throw new Error("ajv implementation error");
  var alwaysValid = schema.some(function (sch) {
    return util_1.alwaysValidSchema(it, sch);
  });
  if (alwaysValid && !it.opts.unevaluated) return;
  var valid = gen.let("valid", false);
  var schValid = gen.name("_valid");
  gen.block(function () {
    return schema.forEach(function (_sch, i) {
      var schCxt = cxt.subschema({
        keyword: keyword,
        schemaProp: i,
        compositeRule: true
      }, schValid);
      gen.assign(valid, codegen_1._(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["", " || ", ""])), valid, schValid));
      var merged = cxt.mergeValidEvaluated(schCxt, schValid); // can short-circuit if `unevaluatedProperties/Items` not supported (opts.unevaluated !== true)
      // or if all properties and items were evaluated (it.props === true && it.items === true)

      if (!merged) gen.if(codegen_1.not(valid));
    });
  });
  cxt.result(valid, function () {
    return cxt.reset();
  }, function () {
    return cxt.error(true);
  });
}

exports.validateUnion = validateUnion;