import { __assign, __makeTemplateObject } from "tslib";
import * as React from "react";
import { StoreContext } from "../components";
import styled from "../styled-components";
import { SECTION_ATTR } from "../services/constants";
import { linkifyMixin, userIsForcingNavigation } from "./linkify";
import { constructFieldDeepFragment } from "../../../services/history/helpers";
export var LinkToField = function LinkToField(e) {
  var n,
      i,
      t,
      o = React.useContext(StoreContext),
      r = React.useState(!1),
      l = r[0],
      a = r[1],
      s = (null == o ? void 0 : o.menu.history.linkForId(null === (i = e.field.deps.operation) || void 0 === i ? void 0 : i.id)) || "",
      p = constructFieldDeepFragment(e.field),
      c = o && p ? o.menu.history.generateDeepLink(p, s) : null,
      m = o ? ((n = {})[SECTION_ATTR] = (null === (t = e.field.deps.operation) || void 0 === t ? void 0 : t.id) + o.options.deepLinkPrefix + p, n.id = c && c.split("#")[1] || void 0, n) : null;
  return React.useEffect(function () {
    if (o && m) return a(o.menu.history.currentId === m[SECTION_ATTR]), o.menu.history.subscribe(function (e) {
      a(e === m[SECTION_ATTR]);
    });
  }, [null == o ? void 0 : o.menu.history, null == m ? void 0 : m[SECTION_ATTR]]), p && o && c ? React.createElement("a", __assign({
    className: e.className + (l ? " active" : ""),
    href: c,
    onClick: function onClick(e) {
      return function (e, n, i) {
        n.defaultPrevented || userIsForcingNavigation(n) || (n.preventDefault(), e.replaceForField(i));
      }(o.menu.history, e, c), e.stopPropagation(), e.preventDefault(), o.options.onDeepLinkClick && o.options.onDeepLinkClick(c), !1;
    }
  }, m), e.children) : e.children || null;
};
export var ShareLinkToField = styled(LinkToField)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  & {\n    position: relative;\n    left: 0;\n\n    margin-left: calc(-12px - 4px);\n    padding-top: 10px;\n    margin-top: -10px;\n\n    .field-name:hover > &::before,\n    &:hover::before {\n      visibility: visible;\n    }\n  }\n\n  &.active::before {\n    visibility: visible;\n  }\n"], ["\n  ", "\n\n  & {\n    position: relative;\n    left: 0;\n\n    margin-left: calc(-12px - 4px);\n    padding-top: 10px;\n    margin-top: -10px;\n\n    .field-name:hover > &::before,\n    &:hover::before {\n      visibility: visible;\n    }\n  }\n\n  &.active::before {\n    visibility: visible;\n  }\n"])), linkifyMixin("&", "12px"));
var templateObject_1;