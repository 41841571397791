import { __assign, __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { DropdownOrLabel } from "../DropdownOrLabel/DropdownOrLabel";
import { ParametersGroup } from "./ParametersGroup";
import { FieldsGroupHeader } from "../../common-elements";
import { MediaTypesSwitch } from "../MediaTypeSwitch/MediaTypesSwitch";
import { Schema } from "../Schema";
import { Markdown } from "../Markdown/Markdown";
import styled from "../../styled-components";

function safePush(e, t, r) {
  e[t] || (e[t] = []), e[t].push(r);
}

var PARAM_PLACES = ["path", "query", "cookie", "header"],
    Parameters = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "orderParams", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = {};
      return e.forEach(function (e) {
        safePush(t, e.in, e);
      }), t;
    }
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.body,
          r = e.parameters,
          n = void 0 === r ? [] : r;
      if (void 0 === t && void 0 === n) return null;
      var a = this.orderParams(n),
          o = n.length > 0 ? PARAM_PLACES : [],
          c = t && t.content,
          i = t && t.description;
      return React.createElement(React.Fragment, null, o.map(function (e) {
        return React.createElement(ParametersGroup, {
          key: e,
          place: e,
          parameters: a[e]
        });
      }), c && React.createElement(BodyContent, {
        content: c,
        description: i
      }));
    }
  }), t;
}(React.PureComponent);

export { Parameters };

function DropdownWithinHeader(e) {
  return React.createElement(FieldsGroupHeader, {
    key: "header"
  }, "Request Body schema: ", React.createElement(DropdownOrLabel, __assign({}, e)));
}

export function BodyContent(e) {
  var t = e.content,
      r = e.description,
      n = t.isRequestType;
  return React.createElement(MediaTypesSwitch, {
    content: t,
    renderDropdown: DropdownWithinHeader
  }, function (e) {
    var t = e.schema;
    return React.createElement(React.Fragment, null, void 0 !== r && React.createElement(Description, null, React.createElement(Markdown, {
      source: r
    }), " "), React.createElement(Schema, {
      skipReadOnly: n,
      key: "schema",
      schema: t
    }));
  });
}
var templateObject_1,
    Description = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", "px;\n"], ["\n  margin-bottom: ", "px;\n"])), function (e) {
  return 4 * e.theme.spacing.unit;
});