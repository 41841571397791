import * as React from "react";
import merge from "deepmerge";
import { observer } from "mobx-react";
import { NoSampleLabel } from "../../../../components/shared";
import { ExampleSwitch, useExampleKey } from "../../../../components/Samples";
import { arrayMerge } from "../../../../services/utils";
import { Markdown } from "../Markdown/Markdown";
import { Example } from "./Example";
import { ExampleWrapper } from "./styled.elements";
export var MediaTypeSamples = observer(function (e) {
  var a = e.mediaType,
      r = e.onlyDefaultSample,
      t = e.properties,
      m = a.examples || {},
      l = a.name,
      p = Object.keys(m);
  if (!p.length) return React.createElement(NoSampleLabel, null, "No sample");

  if (1 === p.length) {
    var n = Object.values(m)[0];
    return t && (n.value = merge(n.value, t, {
      arrayMerge: arrayMerge
    })), React.createElement(React.Fragment, null, n.description && React.createElement(Markdown, {
      source: n.description
    }), React.createElement(Example, {
      example: n,
      mimeType: l
    }));
  }

  var o = useExampleKey(a.operation, m),
      c = o.exampleKey,
      i = o.defaultExampleName,
      s = m[c],
      u = s.description;
  if (t && (s.value = merge(s.value, t, {
    arrayMerge: arrayMerge
  })), i && r) return React.createElement(React.Fragment, null, s.description && React.createElement(Markdown, {
    source: s.description
  }), React.createElement(Example, {
    example: s,
    mimeType: l
  }));
  return React.createElement(React.Fragment, null, React.createElement(ExampleSwitch, {
    examples: m,
    exampleKey: c,
    onChange: function onChange(e) {
      var r;
      return null === (r = a.operation) || void 0 === r ? void 0 : r.activateExampleName(e);
    }
  }), React.createElement(ExampleWrapper, null, u && React.createElement(Markdown, {
    source: u
  }), React.createElement(Example, {
    example: s,
    mimeType: l
  })));
});