import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../../styled-components";
import { Spinner } from "./Spinner.svg";

var templateObject_1,
    LoadingMessage = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: helvetica, sans;\n  width: 100%;\n  text-align: center;\n  font-size: 25px;\n  margin: 30px 0 20px 0;\n  color: ", ";\n"], ["\n  font-family: helvetica, sans;\n  width: 100%;\n  text-align: center;\n  font-size: 25px;\n  margin: 30px 0 20px 0;\n  color: ", ";\n"])), function (e) {
  return e.color;
}),
    Loading = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return React.createElement("div", {
        style: {
          textAlign: "center"
        }
      }, React.createElement(LoadingMessage, {
        color: this.props.color
      }, "Loading ..."), React.createElement(Spinner, {
        color: this.props.color
      }));
    }
  }), t;
}(React.PureComponent);

export { Loading };