import { __assign } from "tslib";
import { MenuBuilder } from "./MenuBuilder";
import { ApiInfoModel, SecuritySchemesModel, WebhookModel } from "./models";
import { OpenAPIParser } from "./OpenAPIParser";

var SpecStore = function SpecStore(e, i, r) {
  var o, t, n;
  Object.defineProperty(this, "options", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: r
  }), Object.defineProperty(this, "parser", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "info", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "externalDocs", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "contentItems", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "securitySchemes", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "webhooks", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), this.parser = new OpenAPIParser(e, i, r), this.info = new ApiInfoModel(this.parser), this.externalDocs = this.parser.definition.externalDocs, this.contentItems = MenuBuilder.buildStructure(this.parser, this.options), this.securitySchemes = new SecuritySchemesModel(this.parser);

  var s = __assign(__assign({}, null === (t = null === (o = this.parser) || void 0 === o ? void 0 : o.definition) || void 0 === t ? void 0 : t["x-webhooks"]), null === (n = this.parser) || void 0 === n ? void 0 : n.definition.webhooks);

  this.webhooks = new WebhookModel(this.parser, r, s);
};

export { SpecStore };