export function joinWithSeparator(a, b, c) {
  if (a === void 0) {
    a = "";
  }

  if (b === void 0) {
    b = "";
  }

  if (c === void 0) {
    c = "/";
  }

  return a.endsWith(c) && (a = a.slice(0, -c.length)), b.startsWith(c) && (b = b.slice(c.length)), a && b ? a + c + b : a + b;
}
export function formatStubJwt(a) {
  return "stub-jwt-header." + btoa(JSON.stringify(a)) + ".stub-jwt-sign";
}