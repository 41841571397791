"use strict";

var _slicedToArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/slicedToArray");

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var error = {
  message: function message(_ref) {
    var schemaCode = _ref.schemaCode;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must match format \"", "\""])), schemaCode);
  },
  params: function params(_ref2) {
    var schemaCode = _ref2.schemaCode;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{format: ", "}"])), schemaCode);
  }
};
var def = {
  keyword: "format",
  type: ["number", "string"],
  schemaType: "string",
  $data: true,
  error: error,
  code: function code(cxt, ruleType) {
    var gen = cxt.gen,
        data = cxt.data,
        $data = cxt.$data,
        schema = cxt.schema,
        schemaCode = cxt.schemaCode,
        it = cxt.it;
    var opts = it.opts,
        errSchemaPath = it.errSchemaPath,
        schemaEnv = it.schemaEnv,
        self = it.self;
    if (!opts.validateFormats) return;
    if ($data) validate$DataFormat();else validateFormat();

    function validate$DataFormat() {
      var fmts = gen.scopeValue("formats", {
        ref: self.formats,
        code: opts.code.formats
      });
      var fDef = gen.const("fDef", codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", "[", "]"])), fmts, schemaCode));
      var fType = gen.let("fType");
      var format = gen.let("format"); // TODO simplify

      gen.if(codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["typeof ", " == \"object\" && !(", " instanceof RegExp)"])), fDef, fDef), function () {
        return gen.assign(fType, codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", ".type || \"string\""])), fDef)).assign(format, codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["", ".validate"])), fDef));
      }, function () {
        return gen.assign(fType, codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\"string\""])))).assign(format, fDef);
      });
      cxt.fail$data(codegen_1.or(unknownFmt(), invalidFmt()));

      function unknownFmt() {
        if (opts.strictSchema === false) return codegen_1.nil;
        return codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", " && !", ""])), schemaCode, format);
      }

      function invalidFmt() {
        var callFormat = schemaEnv.$async ? codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["(", ".async ? await ", "(", ") : ", "(", "))"])), fDef, format, data, format, data) : codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", "(", ")"])), format, data);

        var validData = codegen_1._(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["(typeof ", " == \"function\" ? ", " : ", ".test(", "))"])), format, callFormat, format, data);

        return codegen_1._(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["", " && ", " !== true && ", " === ", " && !", ""])), format, format, fType, ruleType, validData);
      }
    }

    function validateFormat() {
      var formatDef = self.formats[schema];

      if (!formatDef) {
        unknownFormat();
        return;
      }

      if (formatDef === true) return;

      var _getFormat = getFormat(formatDef),
          _getFormat2 = _slicedToArray(_getFormat, 3),
          fmtType = _getFormat2[0],
          format = _getFormat2[1],
          fmtRef = _getFormat2[2];

      if (fmtType === ruleType) cxt.pass(validCondition());

      function unknownFormat() {
        if (opts.strictSchema === false) {
          self.logger.warn(unknownMsg());
          return;
        }

        throw new Error(unknownMsg());

        function unknownMsg() {
          return "unknown format \"".concat(schema, "\" ignored in schema at path \"").concat(errSchemaPath, "\"");
        }
      }

      function getFormat(fmtDef) {
        var code = fmtDef instanceof RegExp ? codegen_1.regexpCode(fmtDef) : opts.code.formats ? codegen_1._(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["", "", ""])), opts.code.formats, codegen_1.getProperty(schema)) : undefined;
        var fmt = gen.scopeValue("formats", {
          key: schema,
          ref: fmtDef,
          code: code
        });

        if (typeof fmtDef == "object" && !(fmtDef instanceof RegExp)) {
          return [fmtDef.type || "string", fmtDef.validate, codegen_1._(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["", ".validate"])), fmt)];
        }

        return ["string", fmtDef, fmt];
      }

      function validCondition() {
        if (typeof formatDef == "object" && !(formatDef instanceof RegExp) && formatDef.async) {
          if (!schemaEnv.$async) throw new Error("async format in sync schema");
          return codegen_1._(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["await ", "(", ")"])), fmtRef, data);
        }

        return typeof format == "function" ? codegen_1._(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["", "(", ")"])), fmtRef, data) : codegen_1._(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["", ".test(", ")"])), fmtRef, data);
      }
    }
  }
};
exports.default = def;