import { __makeTemplateObject } from "tslib";
import { darken } from "polished";
import styled, { css, mediaCSS } from "../styled-components";
export var OneOfButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  height: 24px;\n  border-radius: ", ";\n  cursor: pointer;\n  outline: none;\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 1em;\n  color: ", ";\n  background-color: transparent;\n  padding: 0 10px;\n  min-width: 90px;\n  transition: background-color 0.2s ease-in, border-color 0.2s ease-in;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n"], ["\n  border: 1px solid ", ";\n  height: 24px;\n  border-radius: ", ";\n  cursor: pointer;\n  outline: none;\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 1em;\n  color: ", ";\n  background-color: transparent;\n  padding: 0 10px;\n  min-width: 90px;\n  transition: background-color 0.2s ease-in, border-color 0.2s ease-in;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n"])), function (n) {
  return n.theme.colors.border.light;
}, function (n) {
  return n.theme.shape.borderRadius;
}, function (n) {
  return n.theme.typography.fontFamily;
}, function (n) {
  return n.theme.colors.text.primary;
}, function (n) {
  return n.theme.colors.border.light;
}, function (n) {
  return n.active && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border-color: ", ";\n      background-color: ", ";\n      color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n      &:active {\n        background-color: ", ";\n      }\n    "], ["\n      border-color: ", ";\n      background-color: ", ";\n      color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n      &:active {\n        background-color: ", ";\n      }\n    "])), function (n) {
    var e = n.theme;
    return darken(.05, e.colors.secondary.main);
  }, function (n) {
    return n.theme.colors.secondary.main;
  }, function (n) {
    return n.theme.colors.secondary.contrastText;
  }, function (n) {
    var e = n.theme;
    return darken(.05, e.colors.secondary.main);
  }, function (n) {
    var e = n.theme;
    return darken(.1, e.colors.secondary.main);
  });
});
export var OneOfList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline;\n\n  flex-wrap: wrap;\n  margin: 4px -3px;\n  margin-left: 10px;\n\n  ", " {\n    margin: 2px;\n  }\n"], ["\n  display: inline;\n\n  flex-wrap: wrap;\n  margin: 4px -3px;\n  margin-left: 10px;\n\n  ", " {\n    margin: 2px;\n  }\n"])), OneOfButton);
export var OneOfWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 1em;\n"], ["\n  margin-bottom: 1em;\n"])));
export var ArrayOpenningLabel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 0.9em;\n\n  ", "\n  &::after {\n    content: ' [';\n  }\n"], ["\n  font-size: 0.9em;\n\n  ", "\n  &::after {\n    content: ' [';\n  }\n"])), function (n) {
  var e = n.theme;
  return mediaCSS({
    fontFamily: e.typography.code.fontFamily
  });
});
export var ArrayClosingLabel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 0.9em;\n  ", "\n\n  &::after {\n    content: ']';\n  }\n"], ["\n  font-size: 0.9em;\n  ", "\n\n  &::after {\n    content: ']';\n  }\n"])), function (n) {
  var e = n.theme;
  return mediaCSS({
    fontFamily: e.typography.code.fontFamily
  });
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;