"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegistryDependencies = void 0;

var redocly_1 = require("../../redocly");

var RegistryDependencies = function RegistryDependencies() {
  var registryDependencies = new Set();
  return {
    DefinitionRoot: {
      leave: function leave(_, ctx) {
        var data = ctx.getVisitorData();
        data.links = Array.from(registryDependencies);
      }
    },
    ref: function ref(node) {
      if (node.$ref) {
        var link = node.$ref.split('#/')[0];

        if (redocly_1.isRedoclyRegistryURL(link)) {
          registryDependencies.add(link);
        }
      }
    }
  };
};

exports.RegistryDependencies = RegistryDependencies;