import { __decorate } from "tslib";
import { action, observable, makeObservable } from "mobx";
import { safeSlugify } from "../../utils";
import { MarkdownRenderer } from "../MarkdownRenderer";

var GroupModel = function () {
  function e(e, t, r) {
    Object.defineProperty(this, "id", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "absoluteIdx", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "description", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "items", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "externalDocs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "active", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "expanded", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "depth", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "level", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), makeObservable(this), this.id = t.id || e + "/" + safeSlugify(t.name), this.type = e, this.name = t["x-displayName"] || t.name, this.level = t.level || 1, this.description = t.description || "";
    var i = t.items;
    i && i.length && (this.description = MarkdownRenderer.getTextBeforeHading(this.description, i[0].name)), this.parent = r, this.externalDocs = t.externalDocs, "group" === this.type && (this.expanded = !0);
  }

  return Object.defineProperty(e.prototype, "activate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.active = !0;
    }
  }), Object.defineProperty(e.prototype, "expand", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.parent && this.parent.expand(), this.expanded = !0;
    }
  }), Object.defineProperty(e.prototype, "collapse", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      "group" !== this.type && (this.expanded = !1);
    }
  }), Object.defineProperty(e.prototype, "deactivate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.active = !1;
    }
  }), __decorate([observable], e.prototype, "active", void 0), __decorate([observable], e.prototype, "expanded", void 0), __decorate([action], e.prototype, "activate", null), __decorate([action], e.prototype, "expand", null), __decorate([action], e.prototype, "collapse", null), __decorate([action], e.prototype, "deactivate", null), e;
}();

export { GroupModel };