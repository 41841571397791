import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2;

import styled from "../../styled-components";
import { transparentize } from "polished";
import { mediaCSS } from "@redocly/reference-docs";
export var TileHeader = styled.h3(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  font-weight: ", ";\n  line-height: 1.25;\n  color: ", ";\n  ", "\n  && {\n    margin: 0 0 0.5em;\n  }\n"])), function (_ref) {
  var a = _ref.theme;
  return a.typography.fontWeightBold;
}, function (a) {
  return a.color || transparentize(.1, a.theme.colors.primary.main);
}, function (_ref2) {
  var a = _ref2.theme;
  return mediaCSS({
    fontFamily: a.typography.headings.fontFamily
  });
});
export var TileText = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  display: inline-block;\n  font-weight: ", ";\n  overflow: hidden;\n  color: ", ";\n  line-height: 1.25;\n  ", "\n"])), function (_ref3) {
  var a = _ref3.theme;
  return a.typography.fontWeightRegular;
}, function (a) {
  return a.color || a.theme.colors.text.primary;
}, function (_ref4) {
  var a = _ref4.theme;
  return mediaCSS({
    fontFamily: a.typography.fontFamily
  });
});