import { __assign, __rest } from "tslib";
import * as React from "react";
import { useContext, useEffect, useLayoutEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { CollapsingTabsBase } from "../Tabs";
import { StoreContext } from "../../redoc-lib";
import { LoadingWrapper } from "./LoadingWrapper";
import { WarnMessage } from "../shared";
export var SamplesTabs = observer(function (e) {
  var a = e.children,
      t = e.onChange,
      r = e.defaultLanguage,
      n = __rest(e, ["children", "onChange", "defaultLanguage"]),
      o = useMemo(function () {
    return n.tabs.map(function (e) {
      return e.lang;
    });
  }, [n.tabs]),
      s = useContext(StoreContext),
      u = useState(r),
      i = u[0],
      c = u[1],
      f = useState(o),
      g = f[0],
      m = f[1];

  useEffect(function () {
    return m(o);
  }, [n.tabs]), useEffect(function () {
    i === s.activeSampleLanguage && c(void 0);
  }, [s.activeSampleLanguage]), useLayoutEffect(function () {
    return s.stopLoader();
  }, [s.activeSampleLanguage]), useEffect(function () {
    i && (s.startLoader(), s.activateSampleLanguage(i));
  }, [i, r]);
  var p;
  return React.createElement(LoadingWrapper, {
    className: i && "updated"
  }, React.createElement(CollapsingTabsBase, __assign({}, n, {
    activeIndex: (p = g.indexOf(i || s.activeSampleLanguage), -1 === p ? 0 : p),
    onChange: function onChange(e) {
      var a = n.tabs[e];
      s.setActiveSampleLanguage(a.lang), t && t(a);
    }
  }), r && -1 === g.indexOf(r) && React.createElement(WarnMessage, null, "Provided language is not supported"), a));
});