"use strict";

var _slicedToArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/slicedToArray");

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateSchemaDeps = exports.validatePropertyDeps = exports.error = void 0;

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var code_1 = require("../code");

exports.error = {
  message: function message(_ref) {
    var _ref$params = _ref.params,
        property = _ref$params.property,
        depsCount = _ref$params.depsCount,
        deps = _ref$params.deps;
    var property_ies = depsCount === 1 ? "property" : "properties";
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must have ", " ", " when property ", " is present"])), property_ies, deps, property);
  },
  params: function params(_ref2) {
    var _ref2$params = _ref2.params,
        property = _ref2$params.property,
        depsCount = _ref2$params.depsCount,
        deps = _ref2$params.deps,
        missingProperty = _ref2$params.missingProperty;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{property: ", ",\n    missingProperty: ", ",\n    depsCount: ", ",\n    deps: ", "}"])), property, missingProperty, depsCount, deps);
  } // TODO change to reference

};
var def = {
  keyword: "dependencies",
  type: "object",
  schemaType: "object",
  error: exports.error,
  code: function code(cxt) {
    var _splitDependencies = splitDependencies(cxt),
        _splitDependencies2 = _slicedToArray(_splitDependencies, 2),
        propDeps = _splitDependencies2[0],
        schDeps = _splitDependencies2[1];

    validatePropertyDeps(cxt, propDeps);
    validateSchemaDeps(cxt, schDeps);
  }
};

function splitDependencies(_ref3) {
  var schema = _ref3.schema;
  var propertyDeps = {};
  var schemaDeps = {};

  for (var key in schema) {
    if (key === "__proto__") continue;
    var deps = Array.isArray(schema[key]) ? propertyDeps : schemaDeps;
    deps[key] = schema[key];
  }

  return [propertyDeps, schemaDeps];
}

function validatePropertyDeps(cxt) {
  var propertyDeps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cxt.schema;
  var gen = cxt.gen,
      data = cxt.data,
      it = cxt.it;
  if (Object.keys(propertyDeps).length === 0) return;
  var missing = gen.let("missing");

  var _loop = function _loop(prop) {
    var deps = propertyDeps[prop];
    if (deps.length === 0) return "continue";
    var hasProperty = code_1.propertyInData(gen, data, prop, it.opts.ownProperties);
    cxt.setParams({
      property: prop,
      depsCount: deps.length,
      deps: deps.join(", ")
    });

    if (it.allErrors) {
      gen.if(hasProperty, function () {
        var _iterator = _createForOfIteratorHelper(deps),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var depProp = _step.value;
            code_1.checkReportMissingProp(cxt, depProp);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      });
    } else {
      gen.if(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " && (", ")"])), hasProperty, code_1.checkMissingProp(cxt, deps, missing)));
      code_1.reportMissingProp(cxt, missing);
      gen.else();
    }
  };

  for (var prop in propertyDeps) {
    var _ret = _loop(prop);

    if (_ret === "continue") continue;
  }
}

exports.validatePropertyDeps = validatePropertyDeps;

function validateSchemaDeps(cxt) {
  var schemaDeps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cxt.schema;
  var gen = cxt.gen,
      data = cxt.data,
      keyword = cxt.keyword,
      it = cxt.it;
  var valid = gen.name("valid");

  var _loop2 = function _loop2(prop) {
    if (util_1.alwaysValidSchema(it, schemaDeps[prop])) return "continue";
    gen.if(code_1.propertyInData(gen, data, prop, it.opts.ownProperties), function () {
      var schCxt = cxt.subschema({
        keyword: keyword,
        schemaProp: prop
      }, valid);
      cxt.mergeValidEvaluated(schCxt, valid);
    }, function () {
      return gen.var(valid, true);
    } // TODO var
    );
    cxt.ok(valid);
  };

  for (var prop in schemaDeps) {
    var _ret2 = _loop2(prop);

    if (_ret2 === "continue") continue;
  }
}

exports.validateSchemaDeps = validateSchemaDeps;
exports.default = def;