import { __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { DropdownLabel, DropdownWrapper } from "../PayloadSamples/styled.elements";

var GenericChildrenSwitcher = function (e) {
  function t(t) {
    var r = e.call(this, t) || this;
    return Object.defineProperty(r, "switchItem", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        var t = e.idx;
        r.props.items && r.setState({
          activeItemIdx: t
        });
      }
    }), r.state = {
      activeItemIdx: 0
    }, r;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this,
          t = this.props.items;
      if (!t || !t.length) return null;
      return React.createElement(React.Fragment, null, React.createElement(function (t) {
        var r = t.children;
        return e.props.label ? React.createElement(DropdownWrapper, null, React.createElement(DropdownLabel, null, e.props.label), r) : r;
      }, null, this.props.renderDropdown({
        value: this.props.options[this.state.activeItemIdx].value,
        options: this.props.options,
        onChange: this.switchItem,
        ariaLabel: this.props.label || "Callback"
      })), this.props.children(t[this.state.activeItemIdx]));
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { GenericChildrenSwitcher };