"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateTuple = void 0;

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var code_1 = require("../code");

var def = {
  keyword: "items",
  type: "array",
  schemaType: ["object", "array", "boolean"],
  before: "uniqueItems",
  code: function code(cxt) {
    var schema = cxt.schema,
        it = cxt.it;
    if (Array.isArray(schema)) return validateTuple(cxt, "additionalItems", schema);
    it.items = true;
    if (util_1.alwaysValidSchema(it, schema)) return;
    cxt.ok(code_1.validateArray(cxt));
  }
};

function validateTuple(cxt, extraItems) {
  var schArr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : cxt.schema;
  var gen = cxt.gen,
      parentSchema = cxt.parentSchema,
      data = cxt.data,
      keyword = cxt.keyword,
      it = cxt.it;
  checkStrictTuple(parentSchema);

  if (it.opts.unevaluated && schArr.length && it.items !== true) {
    it.items = util_1.mergeEvaluated.items(gen, schArr.length, it.items);
  }

  var valid = gen.name("valid");
  var len = gen.const("len", codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", ".length"])), data));
  schArr.forEach(function (sch, i) {
    if (util_1.alwaysValidSchema(it, sch)) return;
    gen.if(codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", " > ", ""])), len, i), function () {
      return cxt.subschema({
        keyword: keyword,
        schemaProp: i,
        dataProp: i
      }, valid);
    });
    cxt.ok(valid);
  });

  function checkStrictTuple(sch) {
    var opts = it.opts,
        errSchemaPath = it.errSchemaPath;
    var l = schArr.length;
    var fullTuple = l === sch.minItems && (l === sch.maxItems || sch[extraItems] === false);

    if (opts.strictTuples && !fullTuple) {
      var msg = "\"".concat(keyword, "\" is ").concat(l, "-tuple, but minItems or maxItems/").concat(extraItems, " are not specified or different at path \"").concat(errSchemaPath, "\"");
      util_1.checkStrictMode(it, msg, opts.strictTuples);
    }
  }
}

exports.validateTuple = validateTuple;
exports.default = def;