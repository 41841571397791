import javascript from "./javascript";
import node from "./node";
import python from "./python";
import shell from "./shell";
import java8 from "./java8";
import java from "./java";
import csharp from "./csharp";
import go from "./go";
import php from "./php";
import ruby from "./ruby";
var targets = {
  javascript: javascript,
  node: node,
  python: python,
  shell: shell,
  java8: java8,
  java: java,
  csharp: csharp,
  go: go,
  php: php,
  ruby: ruby
};
export default targets;