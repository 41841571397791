"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var types_1 = require("../discriminator/types");

var error = {
  message: function message(_ref) {
    var _ref$params = _ref.params,
        discrError = _ref$params.discrError,
        tagName = _ref$params.tagName;
    return discrError === types_1.DiscrError.Tag ? "tag \"".concat(tagName, "\" must be string") : "value of tag \"".concat(tagName, "\" must be in oneOf");
  },
  params: function params(_ref2) {
    var _ref2$params = _ref2.params,
        discrError = _ref2$params.discrError,
        tag = _ref2$params.tag,
        tagName = _ref2$params.tagName;
    return codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["{error: ", ", tag: ", ", tagValue: ", "}"])), discrError, tagName, tag);
  }
};
var def = {
  keyword: "discriminator",
  type: "object",
  schemaType: "object",
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        data = cxt.data,
        schema = cxt.schema,
        parentSchema = cxt.parentSchema,
        it = cxt.it;
    var oneOf = parentSchema.oneOf;

    if (!it.opts.discriminator) {
      throw new Error("discriminator: requires discriminator option");
    }

    var tagName = schema.propertyName;
    if (typeof tagName != "string") throw new Error("discriminator: requires propertyName");
    if (!oneOf) throw new Error("discriminator: requires oneOf keyword");
    var valid = gen.let("valid", false);
    var tag = gen.const("tag", codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", "", ""])), data, codegen_1.getProperty(tagName)));
    gen.if(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["typeof ", " == \"string\""])), tag), function () {
      return validateMapping();
    }, function () {
      return cxt.error(false, {
        discrError: types_1.DiscrError.Tag,
        tag: tag,
        tagName: tagName
      });
    });
    cxt.ok(valid);

    function validateMapping() {
      var mapping = getMapping();
      gen.if(false);

      for (var tagValue in mapping) {
        gen.elseIf(codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", " === ", ""])), tag, tagValue));
        gen.assign(valid, applyTagSchema(mapping[tagValue]));
      }

      gen.else();
      cxt.error(false, {
        discrError: types_1.DiscrError.Mapping,
        tag: tag,
        tagName: tagName
      });
      gen.endIf();
    }

    function applyTagSchema(schemaProp) {
      var _valid = gen.name("valid");

      var schCxt = cxt.subschema({
        keyword: "oneOf",
        schemaProp: schemaProp
      }, _valid);
      cxt.mergeEvaluated(schCxt, codegen_1.Name);
      return _valid;
    }

    function isRef(schema) {
      return schema.hasOwnProperty('$ref');
    }

    function getMapping() {
      var _a;

      var oneOfMapping = {};
      var topRequired = hasRequired(parentSchema);
      var tagRequired = true;

      var _loop = function _loop(i) {
        var sch = oneOf[i];
        var propSch = void 0;

        if (isRef(sch)) {
          // compare the ref pointer to the one in mapping
          if (schema.mapping) {
            var mapping = schema.mapping;
            var matchedKey;
            Object.keys(mapping).forEach(function (key) {
              if (mapping[key] === sch['$ref']) {
                matchedKey = key;
              }
            });

            if (matchedKey) {
              addMapping(matchedKey, i);
            } else {
              throw new Error("".concat(sch['$ref'], " should have corresponding entry in mapping"));
            }
          }

          return "continue";
        } else {
          // find if raw schema contains tagName
          propSch = (_a = sch.properties) === null || _a === void 0 ? void 0 : _a[tagName];
        }

        if (typeof propSch != "object") {
          throw new Error("discriminator: oneOf schemas must have \"properties/".concat(tagName, "\""));
        }

        tagRequired = tagRequired && (topRequired || hasRequired(sch));
        addMappings(propSch, i);
      };

      for (var i = 0; i < oneOf.length; i++) {
        var _ret = _loop(i);

        if (_ret === "continue") continue;
      }

      if (!tagRequired) throw new Error("discriminator: \"".concat(tagName, "\" must be required"));
      return oneOfMapping;

      function hasRequired(_ref3) {
        var required = _ref3.required;
        return Array.isArray(required) && required.includes(tagName);
      }

      function addMappings(sch, i) {
        if (sch.const) {
          addMapping(sch.const, i);
        } else if (sch.enum) {
          var _iterator = _createForOfIteratorHelper(sch.enum),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var tagValue = _step.value;
              addMapping(tagValue, i);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          throw new Error("discriminator: \"properties/".concat(tagName, "\" must have \"const\" or \"enum\""));
        }
      }

      function addMapping(tagValue, i) {
        if (typeof tagValue != "string" || tagValue in oneOfMapping) {
          throw new Error("discriminator: \"".concat(tagName, "\" values must be unique strings"));
        }

        oneOfMapping[tagValue] = i;
      }
    }
  }
};
exports.default = def;