var simpleTypes = ["number", "boolean", "function", "symbol"];

function isRegexp(e) {
  return "[object RegExp]" === Object.prototype.toString.call(e);
}

function isObject(e) {
  var n = typeof e;
  return null !== e && ("object" === n || "function" === n);
}

export function objectToPhpArray(e, n, r) {
  void 0 === r && (r = "");
  var t = [];
  return function e(n, r, i) {
    void 0 === r && (r = {}), void 0 === i && (i = ""), r.indent = r.indent || "\t";

    var a = {
      newLine: "\n",
      newLineOrSpace: "\n",
      pad: i,
      indent: i + r.indent
    },
        p = function p(e) {
      if (void 0 === r.inlineCharacterLimit) return e;
      var n = e.replace(new RegExp(a.newLine, "g"), "").replace(new RegExp(a.newLineOrSpace, "g"), " ").replace(new RegExp(a.pad + "|" + a.indent, "g"), "");
      return n.length <= r.inlineCharacterLimit ? n : e.replace(new RegExp(a.newLine + "|" + a.newLineOrSpace, "g"), "\n").replace(new RegExp(a.pad, "g"), i).replace(new RegExp(a.indent, "g"), i + r.indent);
    };

    if (-1 !== t.indexOf(n)) return '"Circular"';
    if (null === n) return "NULL";
    if (void 0 === n || simpleTypes.includes(typeof n) || isRegexp(n)) return String(n);
    if (n instanceof Date) return "date('" + n.toISOString() + "')";

    if (Array.isArray(n)) {
      if (0 === n.length) return "array()";
      t.push(n);
      var o = "array(" + a.newLine + n.map(function (t, p) {
        var o = n.length - 1 === p ? a.newLine : "," + a.newLineOrSpace,
            c = e(t, r, i + r.indent);
        return r.transform && (c = r.transform(n, p, c)), a.indent + c + o;
      }).join("") + a.pad + ")";
      return t.pop(), p(o);
    }

    if (isObject(n)) {
      var c = Object.keys(n);
      if (r.filter && (c = c.filter(function (e) {
        var t;
        return null === (t = r.filter) || void 0 === t ? void 0 : t.call(r, n, e);
      })), 0 === c.length) return Array.isArray(n) ? "array()" : "(object)[]";
      t.push(n);
      o = "array(" + a.newLine + c.map(function (t, p) {
        var o = c.length - 1 === p ? a.newLine : "," + a.newLineOrSpace,
            u = "symbol" == typeof t,
            l = !u && /^[a-z$_][a-z$_0-9]*$/i.test(t),
            f = u || l ? t : e(t, r),
            g = e(n[t], r, i + r.indent);
        return r.transform && (g = r.transform(n, t, g)), a.indent + '"' + String(f) + '" => ' + g + o;
      }).join("") + a.pad + ")";
      return t.pop(), p(o);
    }

    return n = String(n).replace(/[\r\n]/g, function (e) {
      return "\n" === e ? "\\n" : "\\r";
    }), !1 === r.singleQuotes ? '"' + (n = n.replace(/"/g, '\\"')) + '"' : '"' + (n = n.replace(/\\?'/g, "\\'")) + '"';
  }(e, n, r);
}