"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var code_1 = require("../code");

var additionalItems_1 = require("./additionalItems");

var error = {
  message: function message(_ref) {
    var len = _ref.params.len;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must NOT have more than ", " items"])), len);
  },
  params: function params(_ref2) {
    var len = _ref2.params.len;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{limit: ", "}"])), len);
  }
};
var def = {
  keyword: "items",
  type: "array",
  schemaType: ["object", "boolean"],
  before: "uniqueItems",
  error: error,
  code: function code(cxt) {
    var schema = cxt.schema,
        parentSchema = cxt.parentSchema,
        it = cxt.it;
    var prefixItems = parentSchema.prefixItems;
    it.items = true;
    if (util_1.alwaysValidSchema(it, schema)) return;
    if (prefixItems) additionalItems_1.validateAdditionalItems(cxt, prefixItems);else cxt.ok(code_1.validateArray(cxt));
  }
};
exports.default = def;