import { __assign, __rest } from "tslib";
import * as React from "react";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { CodePanel } from "../../../../components/Panel";
import { TabPanel } from "../../../../components/Tabs";
import { SamplesTabs } from "../../../../components/Samples";
import { isPayloadSample } from "../../services";
import { PayloadSamples } from "../PayloadSamples/PayloadSamples";
import { OptionsContext } from "../OptionsProvider";
import { CodeSample } from "./CodeSample";
import { useCodeSamples } from "./useCodeSamples";
import { createLanguageSwitchEvent, creatPanelToggleEvent } from "./languageSwitchEvent";
import { l } from "../../services/Labels";
export var RequestSamples = observer(function (e) {
  var a = e.operation,
      t = e.defaultLanguage,
      o = e.disableHeader,
      n = (e.content, __rest(e, ["operation", "defaultLanguage", "disableHeader", "content"])),
      r = useContext(OptionsContext),
      s = r.events,
      m = r.hideSingleRequestSampleTab;
  useEffect(function () {}, [a.activeServer]);
  var p = useCodeSamples(a),
      i = p.samples,
      c = p.payload,
      d = 1 === i.length && m;
  return i.length ? React.createElement(CodePanel, {
    header: o ? void 0 : l("requestSamples"),
    onToggle: function onToggle(e) {
      var t,
          o = creatPanelToggleEvent({
        operation: a,
        isExpanded: e,
        panelType: "request-samples"
      });
      null === (t = null == s ? void 0 : s.panelToggle) || void 0 === t || t.call(s, o);
    }
  }, React.createElement(SamplesTabs, {
    operation: a,
    tabs: i,
    onChange: function onChange(e) {
      var t,
          o = createLanguageSwitchEvent({
        operation: a,
        sample: e
      });
      null === (t = null == s ? void 0 : s.codeSamplesLanguageSwitch) || void 0 === t || t.call(s, o);
    },
    hidden: d,
    defaultLanguage: t
  }, i.map(function (e) {
    return React.createElement(TabPanel, {
      key: e.key
    }, isPayloadSample(e) ? React.createElement(PayloadSamples, __assign({
      content: e.requestBodyContent,
      operation: a
    }, n)) : React.createElement(CodeSample, __assign({
      lang: e.lang,
      source: e.source,
      externalSample: e,
      content: null == c ? void 0 : c.requestBodyContent,
      operation: a
    }, n)));
  }))) : null;
});