import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject;

import styled from "../../styled-components";
import { space } from "styled-system";
import { GradientBackground } from "../GradientBackground/GradientBackground";
import { H1, H2 } from "../Typography/Typography";
export var Jumbotron = styled(GradientBackground)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  flex-direction: column;\n  padding-top: ", ";\n  padding-bottom: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n  ", ";\n  ", ";\n\n  ", ";\n  ", "\n\n  ", ", ", " {\n    color: ", ";\n    text-align: center;\n    padding: 0;\n  }\n\n  h1 {\n    font-size: 3.5em;\n    line-height: 1.2;\n    font-weight: ", ";\n    margin: 1.75em 0 0.5em 0;\n  }\n\n  h2 {\n    font-size: 1.875em;\n    line-height: 1;\n    font-weight: ", ";\n    margin: 0;\n    margin-bottom: 2em;\n  }\n"])), function (_ref) {
  var a = _ref.pt;
  return a || "0";
}, function (_ref2) {
  var a = _ref2.pb;
  return a || "8em";
}, function (_ref3) {
  var a = _ref3.pl;
  return a || "0";
}, function (_ref4) {
  var a = _ref4.pr;
  return a || "0";
}, function (_ref5) {
  var a = _ref5.bgColor;
  return a && "background: " + a;
}, function (_ref6) {
  var a = _ref6.bgImage,
      b = _ref6.theme,
      c = _ref6.bgColor;
  return a && "\n      background: " + (c || b.colors.navbar.main) + " url(" + a + ");\n      background-repeat: no-repeat;\n      background-size: cover;\n      background-position: center;\n  ";
}, function (_ref7) {
  var a = _ref7.parallaxEffect;
  return a && "\n      background-attachment: scroll, fixed;\n  ";
}, space, H1, H2, function (_ref8) {
  var a = _ref8.theme,
      b = _ref8.textColor;
  return b || a.colors.navbar.contrastText;
}, function (_ref9) {
  var a = _ref9.theme;
  return a.typography.fontWeightBold;
}, function (_ref10) {
  var a = _ref10.theme;
  return a.typography.fontWeightLight;
});