"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationDescriptionOverride = void 0;

var utils_1 = require("../../utils");

var OperationDescriptionOverride = function OperationDescriptionOverride(_ref) {
  var operationIds = _ref.operationIds;
  return {
    Operation: {
      leave: function leave(operation, _ref2) {
        var report = _ref2.report,
            location = _ref2.location;
        if (!operation.operationId) return;
        if (!operationIds) throw new Error("Parameter \"operationIds\" is not provided for \"operation-description-override\" rule");
        var operationId = operation.operationId;

        if (operationIds[operationId]) {
          try {
            operation.description = utils_1.readFileAsStringSync(operationIds[operationId]);
          } catch (e) {
            report({
              message: "Failed to read markdown override file for operation \"".concat(operationId, "\".\n").concat(e.message),
              location: location.child('operationId').key()
            });
          }
        }
      }
    }
  };
};

exports.OperationDescriptionOverride = OperationDescriptionOverride;