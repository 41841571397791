import { SECURITY_SCHEMES_SECTION_PREFIX } from "../../utils/openapi";

var SecuritySchemeModel = function SecuritySchemeModel(e, t, i) {
  Object.defineProperty(this, "id", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "sectionId", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "type", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "description", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "apiKey", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "http", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "flows", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "openId", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  });
  var r = e.deref(i);
  this.id = t, this.sectionId = SECURITY_SCHEMES_SECTION_PREFIX + t, this.type = r.type, this.description = r.description || "", "apiKey" === r.type && (this.apiKey = {
    name: r.name,
    in: r.in
  }), "http" === r.type && (this.http = {
    scheme: r.scheme,
    bearerFormat: r.bearerFormat
  }), "openIdConnect" === r.type && (this.openId = {
    connectUrl: r.openIdConnectUrl
  }), "oauth2" === r.type && r.flows && (this.flows = r.flows);
};

export { SecuritySchemeModel };

var SecuritySchemesModel = function SecuritySchemesModel(e) {
  Object.defineProperty(this, "schemes", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  });
  var t = e.definition.components && e.definition.components.securitySchemes || {};
  this.schemes = Object.keys(t).map(function (i) {
    return new SecuritySchemeModel(e, i, t[i]);
  });
};

export { SecuritySchemesModel };