"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagsAlphabetical = void 0;

var TagsAlphabetical = function TagsAlphabetical() {
  return {
    DefinitionRoot: function DefinitionRoot(root, _ref) {
      var report = _ref.report,
          location = _ref.location;
      if (!root.tags) return;

      for (var i = 0; i < root.tags.length - 1; i++) {
        if (root.tags[i].name > root.tags[i + 1].name) {
          report({
            message: 'The `tags` array should be in alphabetical order.',
            location: location.child(['tags', i])
          });
        }
      }
    }
  };
};

exports.TagsAlphabetical = TagsAlphabetical;