import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { lighten, invert } from "polished";
import { RightPanel, Markdown, styled, mediaCSS } from "../../redoc-lib";
import { CodePanel } from "../Panel/CodePanel";

var DarkMarkdown = styled(Markdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    ", "\n  }\n\n  table {\n    color: #ffffff;\n    overflow-wrap: break-word;\n    word-break: break-word;\n\n    & tr {\n      td,\n      th {\n        ", "\n      }\n    }\n\n    & tr:nth-child(2n) {\n      td,\n      th {\n        ", "\n      }\n    }\n\n    & thead tr th,\n    & tbody tr td {\n      ", "\n    }\n  }\n\n  code {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  a,\n  a:visited {\n    color: ", ";\n  }\n\n  pre {\n    border: none;\n    padding: ", ";\n    border-radius: ", ";\n    ", "\n  }\n"], ["\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    ", "\n  }\n\n  table {\n    color: #ffffff;\n    overflow-wrap: break-word;\n    word-break: break-word;\n\n    & tr {\n      td,\n      th {\n        ", "\n      }\n    }\n\n    & tr:nth-child(2n) {\n      td,\n      th {\n        ", "\n      }\n    }\n\n    & thead tr th,\n    & tbody tr td {\n      ", "\n    }\n  }\n\n  code {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  a,\n  a:visited {\n    color: ", ";\n  }\n\n  pre {\n    border: none;\n    padding: ", ";\n    border-radius: ", ";\n    ", "\n  }\n"])), function (n) {
  var e,
      t,
      r = n.theme;
  return mediaCSS({
    color: (null === (t = null === (e = r.typography) || void 0 === e ? void 0 : e.rightPanelHeading) || void 0 === t ? void 0 : t.color) || r.rightPanel.textColor
  });
}, function (n) {
  var e = n.theme;
  return mediaCSS({
    backgroundColor: e.rightPanel.panelControlsBackgroundColor
  });
}, function (n) {
  var e = n.theme;
  return mediaCSS({
    backgroundColor: lighten(.05, e.rightPanel.panelControlsBackgroundColor)
  });
}, function (n) {
  var e = n.theme;
  return "\n          background-color: " + lighten(.05, e.rightPanel.panelControlsBackgroundColor) + ";\n          border-color: " + lighten(.1, e.rightPanel.panelControlsBackgroundColor) + " !important;\n      ";
}, function (n) {
  var e = n.theme;
  return lighten(.2, e.typography.code.color);
}, function (n) {
  var e = n.theme;
  return invert(e.typography.code.backgroundColor);
}, function (n) {
  var e = n.theme;
  return lighten(.25, e.typography.links.color);
}, function (n) {
  return 4 * n.theme.spacing.unit;
}, function (n) {
  return n.theme.codeBlock.borderRadius;
}, function (n) {
  var e = n.theme;
  return mediaCSS({
    background: e.codeBlock.backgroundColor
  });
}),
    PullRight = function (n) {
  function e() {
    return null !== n && n.apply(this, arguments) || this;
  }

  return __extends(e, n), Object.defineProperty(e.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return React.createElement(StyledRightPanel, null, React.createElement(CodePanel, null, React.createElement(DarkMarkdown, {
        source: this.props.children
      })));
    }
  }), e;
}(React.Component);

export { PullRight };
var templateObject_1,
    templateObject_2,
    StyledRightPanel = styled(RightPanel)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  float: right;\n  z-index: 1;\n  position: relative;\n  margin-bottom: 10px;\n"], ["\n  float: right;\n  z-index: 1;\n  position: relative;\n  margin-bottom: 10px;\n"])));