var rsAstralRange = "\\ud800-\\udfff",
    rsComboMarksRange = "\\u0300-\\u036f",
    reComboHalfMarksRange = "\\ufe20-\\ufe2f",
    rsComboSymbolsRange = "\\u20d0-\\u20ff",
    rsComboMarksExtendedRange = "\\u1ab0-\\u1aff",
    rsComboMarksSupplementRange = "\\u1dc0-\\u1dff",
    rsComboRange = rsComboMarksRange + reComboHalfMarksRange + rsComboSymbolsRange + rsComboMarksExtendedRange + rsComboMarksSupplementRange,
    rsDingbatRange = "\\u2700-\\u27bf",
    rsLowerRange = "a-z\\xdf-\\xf6\\xf8-\\xff",
    rsMathOpRange = "\\xac\\xb1\\xd7\\xf7",
    rsNonCharRange = "\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf",
    rsPunctuationRange = "\\u2000-\\u206f",
    rsSpaceRange = " \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000",
    rsUpperRange = "A-Z\\xc0-\\xd6\\xd8-\\xde",
    rsVarRange = "\\ufe0e\\ufe0f",
    rsBreakRange = rsMathOpRange + rsNonCharRange + rsPunctuationRange + rsSpaceRange,
    reQuotes = /['\u2019]/g,
    rsApos = "['’]",
    rsBreak = "[" + rsBreakRange + "]",
    rsCombo = "[" + rsComboRange + "]",
    rsDigit = "\\d",
    rsDingbat = "[" + rsDingbatRange + "]",
    rsLower = "[" + rsLowerRange + "]",
    rsMisc = "[^" + rsAstralRange + (rsBreakRange + rsDigit + rsDingbatRange + rsLowerRange + rsUpperRange) + "]",
    rsFitz = "\\ud83c[\\udffb-\\udfff]",
    rsModifier = "(?:" + rsCombo + "|" + rsFitz + ")",
    rsNonAstral = "[^" + rsAstralRange + "]",
    rsRegional = "(?:\\ud83c[\\udde6-\\uddff]){2}",
    rsSurrPair = "[\\ud800-\\udbff][\\udc00-\\udfff]",
    rsUpper = "[" + rsUpperRange + "]",
    rsZWJ = "\\u200d",
    rsMiscLower = "(?:" + rsLower + "|" + rsMisc + ")",
    rsMiscUpper = "(?:" + rsUpper + "|" + rsMisc + ")",
    rsOptContrLower = "(?:" + rsApos + "(?:d|ll|m|re|s|t|ve))?",
    rsOptContrUpper = "(?:" + rsApos + "(?:D|LL|M|RE|S|T|VE))?",
    reOptMod = rsModifier + "?",
    rsOptVar = "[" + rsVarRange + "]?",
    rsOptJoin = "(?:" + rsZWJ + "(?:" + [rsNonAstral, rsRegional, rsSurrPair].join("|") + ")" + (rsOptVar + reOptMod) + ")*",
    rsOrdLower = "\\d*(?:1st|2nd|3rd|(?![123])\\dth)(?=\\b|[A-Z_])",
    rsOrdUpper = "\\d*(?:1ST|2ND|3RD|(?![123])\\dTH)(?=\\b|[a-z_])",
    rsSeq = rsOptVar + reOptMod + rsOptJoin,
    rsEmoji = "(?:" + [rsDingbat, rsRegional, rsSurrPair].join("|") + ")" + rsSeq,
    reUnicodeWords = RegExp([rsUpper + "?" + rsLower + "+" + rsOptContrLower + "(?=" + [rsBreak, rsUpper, "$"].join("|") + ")", rsMiscUpper + "+" + rsOptContrUpper + "(?=" + [rsBreak, rsUpper + rsMiscLower, "$"].join("|") + ")", rsUpper + "?" + rsMiscLower + "+" + rsOptContrLower, rsUpper + "+" + rsOptContrUpper, rsOrdUpper, rsOrdLower, rsDigit + "+", rsEmoji].join("|"), "g"),
    unicodeWords = function unicodeWords(r) {
  return r.match(reUnicodeWords);
},
    reHasUnicode = RegExp("[" + (rsZWJ + rsAstralRange + rsComboRange + rsVarRange) + "]"),
    hasUnicode = function hasUnicode(r) {
  return reHasUnicode.test(r);
},
    rsAstral = "[" + rsAstralRange + "]",
    rsNonAstralCombo = "" + rsNonAstral + rsCombo + "?",
    rsSymbol = "(?:" + [rsNonAstralCombo, rsCombo, rsRegional, rsSurrPair, rsAstral].join("|") + ")",
    reUnicode = RegExp(rsFitz + "(?=" + rsFitz + ")|" + (rsSymbol + rsSeq), "g"),
    unicodeToArray = function unicodeToArray(r) {
  return r.match(reUnicode) || [];
},
    asciiToArray = function asciiToArray(r) {
  return r.split("");
},
    getTag = function getTag(r) {
  var e = Object.prototype.toString;
  return null == r ? void 0 === r ? "[object Undefined]" : "[object Null]" : e.call(r);
},
    isSymbol = function isSymbol(r) {
  var e = typeof r;
  return "symbol" == e || "object" === e && null != r && "[object Symbol]" == getTag(r);
},
    INFINITY = 1 / 0,
    toString = function toString(r) {
  if (null == r) return "";
  if ("string" == typeof r) return r;
  if (Array.isArray(r)) return "" + r.map(function (r) {
    return null == r ? r : toString(r);
  });
  if (isSymbol(r)) return r.toString();
  var e = "" + r;
  return "0" == e && 1 / r == -INFINITY ? "-0" : e;
},
    hasUnicodeWord = RegExp.prototype.test.bind(/[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/),
    reAsciiWord = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g,
    asciiWords = function asciiWords(r) {
  return r.match(reAsciiWord);
},
    slice = function slice(r, e, s) {
  var n = null == r ? 0 : r.length;
  if (!n) return [];
  (e = null == e ? 0 : e) < 0 && (e = -e > n ? 0 : n + e), (s = (s = void 0 === s ? n : s) > n ? n : s) < 0 && (s += n), n = e > s ? 0 : s - e >>> 0, e >>>= 0;

  for (var o = -1, a = new Array(n); ++o < n;) {
    a[o] = r[o + e];
  }

  return a;
},
    words = function words(r) {
  return (hasUnicodeWord(r) ? unicodeWords(r) : asciiWords(r)) || [];
},
    castSlice = function castSlice(r, e) {
  var s = r.length;
  return !e && s >= s ? r : slice(r, e, s);
},
    stringToArray = function stringToArray(r) {
  return hasUnicode(r) ? unicodeToArray(r) : asciiToArray(r);
},
    createCaseFirst = function createCaseFirst(r) {
  return function (e) {
    if (!e) return "";
    var s = hasUnicode(e) ? stringToArray(e) : void 0,
        n = s ? s[0] : e[0],
        o = s ? castSlice(s, 1).join("") : e.slice(1);
    return n[r]() + o;
  };
},
    upperFirst = createCaseFirst("toUpperCase"),
    camelCase = function camelCase(r) {
  return words(toString(r).replace(/['\u2019]/g, "")).reduce(function (r, e, s) {
    return e = e.toLowerCase(), r + (s ? upperFirst(e) : e);
  }, "");
},
    lowerCase = function lowerCase(r) {
  return words(toString(r).replace(reQuotes, "")).reduce(function (r, e, s) {
    return r + (s ? " " : "") + e.toLowerCase();
  }, "");
},
    snakeCase = function snakeCase(r) {
  return words(toString(r).replace(/['\u2019]/g, "")).reduce(function (r, e, s) {
    return r + (s ? "_" : "") + e.toLowerCase();
  }, "");
},
    startCase = function startCase(r) {
  return words(("" + r).replace(/['\u2019]/g, "")).reduce(function (r, e, s) {
    return r + (s ? " " : "") + upperFirst(e);
  }, "");
};

export { camelCase, lowerCase, snakeCase, startCase };