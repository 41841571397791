import { __assign, __awaiter, __generator } from "tslib";
import * as React from "react";
import { useDimensions } from "../../utils";
import { CodeHeader } from "../Panel";
import { ConsoleWrap } from "./styled.components";
export function Console(e) {
  var t = this,
      o = React.useState({}),
      n = o[0],
      r = o[1],
      a = useDimensions(e.rootElement)[0];
  return React.useEffect(function () {
    __awaiter(t, void 0, void 0, function () {
      var e;
      return __generator(this, function (t) {
        switch (t.label) {
          case 0:
            return [4, import("./Console")];

          case 1:
            return e = t.sent(), r({
              Console: e.Console
            }), [2];
        }
      });
    });
  }, []), n.Console ? React.createElement(n.Console, __assign({}, e)) : React.createElement(ConsoleWrap, {
    className: e.className,
    fullWidth: null == a ? void 0 : a.width
  }, React.createElement(CodeHeader, null, "Loading..."));
}