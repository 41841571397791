var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;

import { __makeTemplateObject } from "tslib";
import styled, { css, extensionsHook, mediaCSS, ZERO_BREAKPOINT } from "../styled-components";
import { deprecatedCss } from "./mixins";
export var PropertiesTableCaption = styled.caption(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: right;\n  font-size: 0.9em;\n  font-weight: normal;\n  color: ", ";\n"], ["\n  text-align: right;\n  font-size: 0.9em;\n  font-weight: normal;\n  color: ", ";\n"])), function (n) {
  return n.theme.colors.text.secondary;
});
var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    templateObject_6,
    templateObject_7,
    templateObject_8,
    templateObject_9,
    templateObject_10,
    repeatingGradient = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  repeating-linear-gradient(0deg,\n  ", ",\n  ", " 3px,\n  transparent 3px,\n  transparent 5px,\n  ", " 5px);\n"], ["\n  repeating-linear-gradient(0deg,\n  ", ",\n  ", " 3px,\n  transparent 3px,\n  transparent 5px,\n  ", " 5px);\n"])), function (n) {
  return n.theme.schema.linesColor;
}, function (n) {
  return n.theme.schema.linesColor;
}, function (n) {
  return n.theme.schema.linesColor;
});
export var PropertyCell = styled.td(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  position: relative;\n  padding: 10px;\n  background-image: ", ";\n  background-repeat: no-repeat;\n  background-size: 1px 100%;\n\n  ", "\n\n  tr:first-of-type > & {\n    background-image: ", ";\n    background-position: 0 10px;\n    padding-top: 0;\n  }\n\n  tr.last > & {\n    background-image: ", ";\n    padding-bottom: 0;\n    ", ";\n  }\n\n  tr.last + tr > & {\n    background: none;\n  }\n\n  tr.last:first-child > & {\n    background-image: ", ";\n    ", ";\n  }\n"], ["\n  box-sizing: border-box;\n  position: relative;\n  padding: 10px;\n  background-image: ", ";\n  background-repeat: no-repeat;\n  background-size: 1px 100%;\n\n  ", "\n\n  tr:first-of-type > & {\n    background-image: ", ";\n    background-position: 0 10px;\n    padding-top: 0;\n  }\n\n  tr.last > & {\n    background-image: ", ";\n    padding-bottom: 0;\n    ", ";\n  }\n\n  tr.last + tr > & {\n    background: none;\n  }\n\n  tr.last:first-child > & {\n    background-image: ", ";\n    ", ";\n  }\n"])), repeatingGradient, mediaCSS({
  display: (_a = {}, _a[ZERO_BREAKPOINT] = "block", _a.small = "table-cell", _a),
  overflow: (_b = {}, _b[ZERO_BREAKPOINT] = "hidden", _b.small = "initial", _b),
  padding: (_c = {}, _c[ZERO_BREAKPOINT] = "0 20px", _c.small = "10px", _c),
  marginBottom: (_d = {}, _d[ZERO_BREAKPOINT] = "2px", _d.small = 0, _d)
}), repeatingGradient, repeatingGradient, mediaCSS({
  backgroundSize: (_e = {}, _e[ZERO_BREAKPOINT] = "0.9px 100%", _e.small = "0.9px 22px", _e)
}), repeatingGradient, mediaCSS({
  backgroundSize: (_f = {}, _f[ZERO_BREAKPOINT] = "0.9px 100%", _f.small = "0.9px 20px", _f),
  backgroundPosition: (_g = {}, _g[ZERO_BREAKPOINT] = "0 20px", _g.small = "0 0", _g)
}));
export var PropertyCellWithInner = styled(PropertyCell)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0;\n"], ["\n  padding: 0;\n"])));
export var PropertyNameCell = styled(PropertyCell)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  vertical-align: top;\n  line-height: 23px;\n  white-space: nowrap;\n  font-size: 14px;\n  ", "\n\n  &.deprecated {\n    ", ";\n  }\n\n  ", ";\n\n  ", ";\n"], ["\n  vertical-align: top;\n  line-height: 23px;\n  white-space: nowrap;\n  font-size: 14px;\n  ", "\n\n  &.deprecated {\n    ", ";\n  }\n\n  ", ";\n\n  ", ";\n"])), function (n) {
  var e = n.theme;
  return mediaCSS({
    fontFamily: e.typography.fieldName.fontFamily
  });
}, deprecatedCss, function (n) {
  return "field" !== n.kind ? "font-style: italic" : "";
}, extensionsHook("PropertyNameCell"));
export var PropertyDetailsCell = styled.td(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  box-sizing: border-box;\n\n  tr.expanded & {\n    border-bottom: none;\n  }\n\n  tr.last > & {\n    border: none;\n    background-position: top left;\n    background-repeat: no-repeat;\n    background-size: 1px 100%;\n    padding-bottom: 0;\n\n    /* workaround for discriminator dropdown oveflowing scrolling container,\n    fixes the issue for the last item in the schema only */\n    & > div > div.dropdown > .dropdown-selector-content {\n      position: relative;\n    }\n  }\n\n  tr:first-of-type > & {\n    padding-top: 0;\n  }\n\n  ", "\n\n  ", ";\n"], ["\n  box-sizing: border-box;\n\n  tr.expanded & {\n    border-bottom: none;\n  }\n\n  tr.last > & {\n    border: none;\n    background-position: top left;\n    background-repeat: no-repeat;\n    background-size: 1px 100%;\n    padding-bottom: 0;\n\n    /* workaround for discriminator dropdown oveflowing scrolling container,\n    fixes the issue for the last item in the schema only */\n    & > div > div.dropdown > .dropdown-selector-content {\n      position: relative;\n    }\n  }\n\n  tr:first-of-type > & {\n    padding-top: 0;\n  }\n\n  ", "\n\n  ", ";\n"])), function (n) {
  var e,
      t,
      r,
      a = n.theme;
  return mediaCSS({
    borderBottom: (e = {}, e[ZERO_BREAKPOINT] = "none", e.small = "1px solid " + a.colors.border.light, e),
    borderLeft: (t = {}, t[ZERO_BREAKPOINT] = "1px solid " + a.schema.linesColor, t.small = "none", t),
    padding: (r = {}, r[ZERO_BREAKPOINT] = "0 20px", r.small = "10px 0", r),
    width: a.schema.defaultDetailsWidth,
    minWidth: "200px"
  });
}, extensionsHook("PropertyDetailsCell"));
export var PropertyBullet = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-right: 10px;\n  ", "\n\n  &::before {\n    content: '';\n    display: inline-block;\n    vertical-align: middle;\n    width: 10px;\n    height: 1px;\n    background: ", ";\n  }\n\n  ", "\n\n  ", ";\n"], ["\n  margin-right: 10px;\n  ", "\n\n  &::before {\n    content: '';\n    display: inline-block;\n    vertical-align: middle;\n    width: 10px;\n    height: 1px;\n    background: ", ";\n  }\n\n  ", "\n\n  ", ";\n"])), function (n) {
  var e = n.theme;
  return mediaCSS({
    color: e.schema.linesColor,
    fontFamily: e.typography.code.fontFamily
  });
}, function (n) {
  return n.theme.schema.linesColor;
}, function (n) {
  var e,
      t,
      r = n.theme;
  return mediaCSS({
    width: r.schema.defaultDetailsWidth,
    borderLeft: (e = {}, e[ZERO_BREAKPOINT] = "1px dashed " + r.schema.linesColor, e.small = "none", e),
    padding: (t = {}, t[ZERO_BREAKPOINT] = "5px 10px", t.small = "10px", t)
  });
}, extensionsHook("PropertyDetailsCell"));
export var InnerPropertiesWrap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), function (n) {
  return n.theme.schema.nestingSpacing;
});
export var PropertiesTableWrap = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: block;\n  overflow-x: auto;\n  padding: 5px; /* hack to show overflown anchor icons */\n  margin: -5px;\n"], ["\n  display: block;\n  overflow-x: auto;\n  padding: 5px; /* hack to show overflown anchor icons */\n  margin: -5px;\n"])));
export var PropertiesTable = styled.table(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border-collapse: separate;\n  border-radius: ", ";\n  width: 100%;\n  ", "\n  > tr, > tbody > tr {\n    ", "\n  }\n\n  > tr {\n    vertical-align: middle;\n  }\n\n  ", "\n\n  &\n    ", ",\n    &\n    ", "\n    ", "\n    ", ",\n    &\n    ", "\n    ", "\n    ", "\n    ", "\n    ", " {\n    margin-left: ", ";\n    margin-bottom: ", ";\n    background: ", ";\n    border-radius: ", ";\n  }\n\n  &\n    ", "\n    ", ",\n    &\n    ", "\n    ", "\n    ", "\n    ", ",\n    &\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", " {\n    background: #ffffff;\n  }\n"], ["\n  border-collapse: separate;\n  border-radius: ", ";\n  width: 100%;\n  ", "\n  > tr, > tbody > tr {\n    ", "\n  }\n\n  > tr {\n    vertical-align: middle;\n  }\n\n  ", "\n\n  &\n    ", ",\n    &\n    ", "\n    ", "\n    ", ",\n    &\n    ", "\n    ", "\n    ", "\n    ", "\n    ", " {\n    margin-left: ", ";\n    margin-bottom: ", ";\n    background: ", ";\n    border-radius: ", ";\n  }\n\n  &\n    ", "\n    ", ",\n    &\n    ", "\n    ", "\n    ", "\n    ", ",\n    &\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", " {\n    background: #ffffff;\n  }\n"])), function (n) {
  return n.theme.shape.borderRadius;
}, function (n) {
  var e,
      t = n.theme;
  return mediaCSS({
    fontSize: t.typography.fontSize,
    display: (e = {}, e[ZERO_BREAKPOINT] = "block", e.small = "table", e)
  });
}, mediaCSS({
  display: (_h = {}, _h[ZERO_BREAKPOINT] = "block", _h.small = "table-row", _h),
  marginBottom: (_j = {}, _j[ZERO_BREAKPOINT] = "2px", _j.small = 0, _j),
  borderSpacing: (_k = {}, _k[ZERO_BREAKPOINT] = 0, _k.small = "0 2px", _k)
}), function (n) {
  return "\n    @media screen and (max-width: " + n.theme.breakpoints[0] + ") and (-ms-high-contrast:none) {\n      td {\n        float: left;\n        width: 100%;\n      }\n    }\n  ";
}, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, function (n) {
  return n.theme.schema.nestingSpacing;
}, function (n) {
  return n.theme.schema.nestingSpacing;
}, function (n) {
  return n.theme.schema.nestedBackground;
}, function (n) {
  return n.theme.components.panels.borderRadius;
}, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap);