"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagDescriptionOverride = void 0;

var utils_1 = require("../../utils");

var TagDescriptionOverride = function TagDescriptionOverride(_ref) {
  var tagNames = _ref.tagNames;
  return {
    Tag: {
      leave: function leave(tag, _ref2) {
        var report = _ref2.report;
        if (!tagNames) throw new Error("Parameter \"tagNames\" is not provided for \"tag-description-override\" rule");

        if (tagNames[tag.name]) {
          try {
            tag.description = utils_1.readFileAsStringSync(tagNames[tag.name]);
          } catch (e) {
            report({
              message: "Failed to read markdown override file for tag \"".concat(tag.name, "\".\n").concat(e.message)
            });
          }
        }
      }
    }
  };
};

exports.TagDescriptionOverride = TagDescriptionOverride;