import React, { useState } from "react";
import { OptionsConsumer } from "../../redoc-lib/src/components/OptionsProvider";
import { StoreConsumer, StoreContext } from "../../redoc-lib/src/components/StoreBuilder";
import { Console } from "../console/ConsoleLazy";
import { CodePanel } from "../Panel";
import { ServerList } from "./ServerList";
import { Summary } from "./Summary";
import { useContext } from "react";
import { AnalyticsEventType } from "../../services/store-types";
export var Endpoint = function Endpoint(e) {
  var t = e.operation,
      o = e.hideHostname,
      r = useState(!1),
      n = r[0],
      a = r[1],
      i = useContext(StoreContext),
      l = function l() {
    var e, o;
    a(!n), null === (o = null === (e = null == i ? void 0 : i.options.events) || void 0 === e ? void 0 : e.tryItOpen) || void 0 === o || o.call(e, {
      eventType: AnalyticsEventType.TryItOpen,
      resource: "Redocly_OperationTryIt",
      action: (null == i ? void 0 : i.options.unstable_tryItButtonOverride) ? "NavigatedOverrideLink" : "Opened",
      operationId: t.operationId,
      operationPath: t.path,
      operationHttpVerb: t.httpVerb,
      operationSummary: t.description
    });
  };

  return React.createElement(React.Fragment, null, n ? React.createElement(StoreConsumer, null, function (e) {
    return React.createElement(Console, {
      store: e,
      operation: t,
      onClose: l
    });
  }) : React.createElement(CodePanel, {
    header: function header(e) {
      var o = e.toggle,
          r = e.expanded;
      return React.createElement(Summary, {
        operation: t,
        expanded: r,
        toggle: o,
        showConsole: !!(null == i ? void 0 : i.options.showConsole),
        onToggleConsole: l,
        tryItOverride: null == i ? void 0 : i.options.unstable_tryItButtonOverride
      });
    },
    collapsed: !0
  }, React.createElement(OptionsConsumer, null, function (e) {
    return React.createElement(ServerList, {
      operation: t,
      expandVariables: e.expandDefaultServerVariables,
      hideHostname: o || e.hideHostname
    });
  })));
};