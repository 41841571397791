import { __assign } from "tslib";
import marked from "marked";
import { highlight as _highlight, safeSlugify, unescapeHTMLChars } from "../utils";
var renderer = new marked.Renderer();
marked.setOptions({
  renderer: renderer,
  highlight: function highlight(e, r) {
    return _highlight(e, r);
  }
});
export var LEGACY_REGEXP = "^ {0,3}\x3c!-- ReDoc-Inject:\\s+?<({component}).*?/?>\\s+?--\x3e\\s*$";
export var MDX_COMPONENT_REGEXP = "(?:^ {0,3}<({component})([\\s\\S]*?)>([\\s\\S]*?)</\\2>|^ {0,3}<({component})([\\s\\S]*?)(?:/>|\\n{2,}))";
export var COMPONENT_REGEXP = "(?:" + LEGACY_REGEXP + "|" + MDX_COMPONENT_REGEXP + ")";
export function buildComponentComment(e) {
  return "\x3c!-- ReDoc-Inject: <" + e + "> --\x3e";
}

var MarkdownRenderer = function () {
  function e(e) {
    var r = this;
    Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "headings", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    }), Object.defineProperty(this, "currentTopHeading", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "headingEnhanceRenderer", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "originalHeadingRule", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parser", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "headingRule", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e, n, t, i) {
        return 1 === n ? r.currentTopHeading = r.saveHeading(e, n) : 2 === n && r.saveHeading(e, n, r.currentTopHeading && r.currentTopHeading.items, r.currentTopHeading && r.currentTopHeading.id), r.originalHeadingRule(e, n, t, i);
      }
    }), this.headingEnhanceRenderer = new marked.Renderer(), this.parser = new marked.Parser(), this.originalHeadingRule = this.headingEnhanceRenderer.heading.bind(this.headingEnhanceRenderer), this.headingEnhanceRenderer.heading = this.headingRule;
  }

  return Object.defineProperty(e, "containsComponent", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      return new RegExp(COMPONENT_REGEXP.replace(/{component}/g, r), "gmi").test(e);
    }
  }), Object.defineProperty(e, "getTextBeforeHading", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      var n = e.search(new RegExp("^##?\\s+" + r, "m"));
      return n > -1 ? e.substring(0, n) : e;
    }
  }), Object.defineProperty(e.prototype, "saveHeading", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r, n, t) {
      void 0 === n && (n = this.headings), e = unescapeHTMLChars(e);
      var i = {
        id: t ? t + "/" + safeSlugify(e) : "section/" + safeSlugify(e),
        name: e,
        level: r,
        items: []
      };
      return n.push(i), i;
    }
  }), Object.defineProperty(e.prototype, "flattenHeadings", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      if (void 0 === e) return [];

      for (var r = [], n = 0, t = e; n < t.length; n++) {
        var i = t[n];
        r.push(i), r.push.apply(r, this.flattenHeadings(i.items));
      }

      return r;
    }
  }), Object.defineProperty(e.prototype, "attachHeadingsDescriptions", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var r = function r(e) {
        return new RegExp("##?\\s+" + e.name.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"));
      },
          n = this.flattenHeadings(this.headings);

      if (!(n.length < 1)) {
        for (var t = n[0], i = r(t), a = e.search(i), o = 1; o < n.length; o++) {
          var s = n[o],
              u = r(s),
              d = e.substr(a + 1).search(u) + a + 1;
          t.description = e.substring(a, d).replace(i, "").trim(), t = s, i = u, a = d;
        }

        t.description = e.substring(a).replace(i, "").trim();
      }
    }
  }), Object.defineProperty(e.prototype, "renderMd", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      void 0 === r && (r = !1);
      var n = r ? {
        renderer: this.headingEnhanceRenderer
      } : void 0;
      return marked(e.toString(), n);
    }
  }), Object.defineProperty(e.prototype, "extractHeadings", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      this.renderMd(e, !0), this.attachHeadingsDescriptions(e);
      var r = this.headings;
      return this.headings = [], r;
    }
  }), Object.defineProperty(e.prototype, "renderMdWithComponents", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var r = this.options && this.options.allowedMdComponents;
      if (!r || 0 === Object.keys(r).length) return [this.renderMd(e)];

      for (var n = Object.keys(r).join("|"), t = new RegExp(COMPONENT_REGEXP.replace(/{component}/g, n), "mig"), i = [], a = [], o = t.exec(e), s = 0; o;) {
        i.push(e.substring(s, o.index)), s = t.lastIndex;
        var u = r[o[1] || o[2] || o[5]],
            d = o[3] || o[6],
            l = o[4];
        u && a.push({
          component: u.component,
          propsSelector: u.propsSelector,
          props: __assign(__assign(__assign({}, parseProps(d)), u.props), {
            children: l
          })
        }), o = t.exec(e);
      }

      i.push(e.substring(s));

      for (var c = [], p = 0; p < i.length; p++) {
        var g = i[p];
        g && c.push(this.renderMd(g)), a[p] && c.push(a[p]);
      }

      return c;
    }
  }), e;
}();

export { MarkdownRenderer };

function parseProps(e) {
  if (!e) return {};

  for (var r, n = /([\w-]+)\s*=\s*(?:{([^}]+?)}|"([^"]+?)")/gim, t = {}; null !== (r = n.exec(e));) {
    if (r[3]) t[r[1]] = r[3];else if (r[2]) {
      var i = void 0;

      try {
        i = JSON.parse(r[2]);
      } catch (e) {}

      t[r[1]] = i;
    }
  }

  return t;
}