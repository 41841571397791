import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
export var Path = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 4px;\n  color: ", ";\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  white-space: ", ";\n  overflow-wrap: ", ";\n  display: inline-block;\n"], ["\n  margin: 0 4px;\n  color: ", ";\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  white-space: ", ";\n  overflow-wrap: ", ";\n  display: inline-block;\n"])), function (e) {
  return e.theme.colors.text.light;
}, function (e) {
  return e.wrap ? "normal" : "nowrap";
}, function (e) {
  return e.wrap ? "anywhere" : "normal";
});
export var PathWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  overflow-x: hidden;\n  padding-right: 4px;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  overflow-x: hidden;\n  padding-right: 4px;\n"])));
var templateObject_1, templateObject_2;