"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _classCallCheck = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/classCallCheck");

var _templateObject, _templateObject2;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolveSchema = exports.getCompilingSchema = exports.resolveRef = exports.compileSchema = exports.SchemaEnv = void 0;

var codegen_1 = require("./codegen");

var validation_error_1 = require("../runtime/validation_error");

var names_1 = require("./names");

var resolve_1 = require("./resolve");

var util_1 = require("./util");

var validate_1 = require("./validate");

var URI = require("uri-js");

var SchemaEnv = function SchemaEnv(env) {
  _classCallCheck(this, SchemaEnv);

  var _a;

  this.refs = {};
  this.dynamicAnchors = {};
  var schema;
  if (typeof env.schema == "object") schema = env.schema;
  this.schema = env.schema;
  this.schemaId = env.schemaId;
  this.root = env.root || this;
  this.baseId = (_a = env.baseId) !== null && _a !== void 0 ? _a : resolve_1.normalizeId(schema === null || schema === void 0 ? void 0 : schema[env.schemaId || "$id"]);
  this.schemaPath = env.schemaPath;
  this.localRefs = env.localRefs;
  this.meta = env.meta;
  this.$async = schema === null || schema === void 0 ? void 0 : schema.$async;
  this.refs = {};
};

exports.SchemaEnv = SchemaEnv; // let codeSize = 0
// let nodeCount = 0
// Compiles schema in SchemaEnv

function compileSchema(sch) {
  // TODO refactor - remove compilations
  var _sch = getCompilingSchema.call(this, sch);

  if (_sch) return _sch;
  var rootId = resolve_1.getFullPath(sch.root.baseId); // TODO if getFullPath removed 1 tests fails

  var _this$opts$code = this.opts.code,
      es5 = _this$opts$code.es5,
      lines = _this$opts$code.lines;
  var ownProperties = this.opts.ownProperties;
  var gen = new codegen_1.CodeGen(this.scope, {
    es5: es5,
    lines: lines,
    ownProperties: ownProperties
  });

  var _ValidationError;

  if (sch.$async) {
    _ValidationError = gen.scopeValue("Error", {
      ref: validation_error_1.default,
      code: codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["require(\"ajv/dist/runtime/validation_error\").default"])))
    });
  }

  var validateName = gen.scopeName("validate");
  sch.validateName = validateName;
  var schemaCxt = {
    gen: gen,
    allErrors: this.opts.allErrors,
    data: names_1.default.data,
    parentData: names_1.default.parentData,
    parentDataProperty: names_1.default.parentDataProperty,
    dataNames: [names_1.default.data],
    dataPathArr: [codegen_1.nil],
    dataLevel: 0,
    dataTypes: [],
    definedProperties: new Set(),
    topSchemaRef: gen.scopeValue("schema", this.opts.code.source === true ? {
      ref: sch.schema,
      code: codegen_1.stringify(sch.schema)
    } : {
      ref: sch.schema
    }),
    validateName: validateName,
    ValidationError: _ValidationError,
    schema: sch.schema,
    schemaEnv: sch,
    rootId: rootId,
    baseId: sch.baseId || rootId,
    schemaPath: codegen_1.nil,
    errSchemaPath: sch.schemaPath || (this.opts.jtd ? "" : "#"),
    errorPath: codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\"\""]))),
    opts: this.opts,
    self: this
  };
  var sourceCode;

  try {
    this._compilations.add(sch);

    validate_1.validateFunctionCode(schemaCxt);
    gen.optimize(this.opts.code.optimize); // gen.optimize(1)

    var validateCode = gen.toString();
    sourceCode = "const visitedNodesForRef = new WeakMap(); ".concat(gen.scopeRefs(names_1.default.scope), "return ").concat(validateCode); // console.log((codeSize += sourceCode.length), (nodeCount += gen.nodeCount))

    if (this.opts.code.process) sourceCode = this.opts.code.process(sourceCode, sch); // console.log("\n\n\n *** \n", sourceCode)

    var makeValidate = new Function("".concat(names_1.default.self), "".concat(names_1.default.scope), sourceCode);
    var validate = makeValidate(this, this.scope.get());
    this.scope.value(validateName, {
      ref: validate
    });
    validate.errors = null;
    validate.schema = sch.schema;
    validate.schemaEnv = sch;
    if (sch.$async) validate.$async = true;

    if (this.opts.code.source === true) {
      validate.source = {
        validateName: validateName,
        validateCode: validateCode,
        scopeValues: gen._values
      };
    }

    if (this.opts.unevaluated) {
      var props = schemaCxt.props,
          items = schemaCxt.items;
      validate.evaluated = {
        props: props instanceof codegen_1.Name ? undefined : props,
        items: items instanceof codegen_1.Name ? undefined : items,
        dynamicProps: props instanceof codegen_1.Name,
        dynamicItems: items instanceof codegen_1.Name
      };
      if (validate.source) validate.source.evaluated = codegen_1.stringify(validate.evaluated);
    }

    sch.validate = validate;
    return sch;
  } catch (e) {
    delete sch.validate;
    delete sch.validateName;
    if (sourceCode) this.logger.error("Error compiling schema, function code:", sourceCode); // console.log("\n\n\n *** \n", sourceCode, this.opts)

    throw e;
  } finally {
    this._compilations.delete(sch);
  }
}

exports.compileSchema = compileSchema;

function resolveRef(root, baseId, origRef) {
  var _a;

  var ref = resolve_1.resolveUrl(baseId, origRef);
  var schOrFunc = root.refs[ref];
  if (schOrFunc) return schOrFunc;

  var _sch = resolve.call(this, root, ref);

  if (_sch === undefined) {
    var schema = (_a = root.localRefs) === null || _a === void 0 ? void 0 : _a[ref]; // TODO maybe localRefs should hold SchemaEnv

    var schemaId = this.opts.schemaId;
    if (schema) _sch = new SchemaEnv({
      schema: schema,
      schemaId: schemaId,
      root: root,
      baseId: baseId
    });
  }

  if (_sch === undefined && this.opts.loadSchemaSync) {
    var remoteSchema = this.opts.loadSchemaSync(baseId, origRef, ref);

    if (remoteSchema && !(this.refs[ref] || this.schemas[ref])) {
      this.addSchema(remoteSchema, ref, undefined);
      _sch = resolve.call(this, root, ref);
    }
  }

  if (_sch === undefined) return;
  return root.refs[ref] = inlineOrCompile.call(this, _sch);
}

exports.resolveRef = resolveRef;

function inlineOrCompile(sch) {
  if (resolve_1.inlineRef(sch.schema, this.opts.inlineRefs)) return sch.schema;
  return sch.validate ? sch : compileSchema.call(this, sch);
} // Index of schema compilation in the currently compiled list


function getCompilingSchema(schEnv) {
  var _iterator = _createForOfIteratorHelper(this._compilations),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var sch = _step.value;
      if (sameSchemaEnv(sch, schEnv)) return sch;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

exports.getCompilingSchema = getCompilingSchema;

function sameSchemaEnv(s1, s2) {
  return s1.schema === s2.schema && s1.root === s2.root && s1.baseId === s2.baseId;
} // resolve and compile the references ($ref)
// TODO returns AnySchemaObject (if the schema can be inlined) or validation function


function resolve(root, // information about the root schema for the current schema
ref // reference to resolve
) {
  var sch;

  while (typeof (sch = this.refs[ref]) == "string") {
    ref = sch;
  }

  return sch || this.schemas[ref] || resolveSchema.call(this, root, ref);
} // Resolve schema, its root and baseId


function resolveSchema(root, // root object with properties schema, refs TODO below SchemaEnv is assigned to it
ref // reference to resolve
) {
  var p = URI.parse(ref);

  var refPath = resolve_1._getFullPath(p);

  var baseId = resolve_1.getFullPath(root.baseId); // TODO `Object.keys(root.schema).length > 0` should not be needed - but removing breaks 2 tests

  if (Object.keys(root.schema).length > 0 && refPath === baseId) {
    return getJsonPointer.call(this, p, root);
  }

  var id = resolve_1.normalizeId(refPath);
  var schOrRef = this.refs[id] || this.schemas[id];

  if (typeof schOrRef == "string") {
    var sch = resolveSchema.call(this, root, schOrRef);
    if (typeof (sch === null || sch === void 0 ? void 0 : sch.schema) !== "object") return;
    return getJsonPointer.call(this, p, sch);
  }

  if (typeof (schOrRef === null || schOrRef === void 0 ? void 0 : schOrRef.schema) !== "object") return;
  if (!schOrRef.validate) compileSchema.call(this, schOrRef);

  if (id === resolve_1.normalizeId(ref)) {
    var schema = schOrRef.schema;
    var schemaId = this.opts.schemaId;
    var schId = schema[schemaId];
    if (schId) baseId = resolve_1.resolveUrl(baseId, schId);
    return new SchemaEnv({
      schema: schema,
      schemaId: schemaId,
      root: root,
      baseId: baseId
    });
  }

  return getJsonPointer.call(this, p, schOrRef);
}

exports.resolveSchema = resolveSchema;
var PREVENT_SCOPE_CHANGE = new Set(["properties", "patternProperties", "enum", "dependencies", "definitions"]);

function getJsonPointer(parsedRef, _ref) {
  var baseId = _ref.baseId,
      schema = _ref.schema,
      root = _ref.root;

  var _a;

  if (((_a = parsedRef.fragment) === null || _a === void 0 ? void 0 : _a[0]) !== "/") return;

  var _iterator2 = _createForOfIteratorHelper(parsedRef.fragment.slice(1).split("/")),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var part = _step2.value;
      if (typeof schema == "boolean") return;
      schema = schema[util_1.unescapeFragment(part)];
      if (schema === undefined) return; // TODO PREVENT_SCOPE_CHANGE could be defined in keyword def?

      var schId = typeof schema == "object" && schema[this.opts.schemaId];

      if (!PREVENT_SCOPE_CHANGE.has(part) && schId) {
        baseId = resolve_1.resolveUrl(baseId, schId);
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  var env;

  if (typeof schema != "boolean" && schema.$ref && !util_1.schemaHasRulesButRef(schema, this.RULES)) {
    var $ref = resolve_1.resolveUrl(baseId, schema.$ref);
    env = resolveSchema.call(this, root, $ref);
  } // even though resolution failed we need to return SchemaEnv to throw exception
  // so that compileAsync loads missing schema.


  var schemaId = this.opts.schemaId;
  env = env || new SchemaEnv({
    schema: schema,
    schemaId: schemaId,
    root: root,
    baseId: baseId
  });
  if (env.schema !== env.root.schema) return env;
  return undefined;
}