var isSupported = "undefined" != typeof document && document.queryCommandSupported && document.queryCommandSupported("copy"),
    ClipboardService = function () {
  function e() {}

  return Object.defineProperty(e, "isSupported", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return isSupported;
    }
  }), Object.defineProperty(e, "selectElement", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t, n;
      document.body.createTextRange ? ((t = document.body.createTextRange()).moveToElementText(e), t.select()) : document.createRange && window.getSelection && (n = window.getSelection(), (t = document.createRange()).selectNodeContents(e), n.removeAllRanges(), n.addRange(t));
    }
  }), Object.defineProperty(e, "deselect", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      if (document.selection) document.selection.empty();else if (window.getSelection) {
        var e = window.getSelection();
        e && e.removeAllRanges();
      }
    }
  }), Object.defineProperty(e, "copySelected", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e;

      try {
        e = document.execCommand("copy");
      } catch (t) {
        e = !1;
      }

      return e;
    }
  }), Object.defineProperty(e, "copyElement", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(t) {
      e.selectElement(t);
      var n = e.copySelected();
      return n && e.deselect(), n;
    }
  }), Object.defineProperty(e, "copyCustom", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(t) {
      var n = document.createElement("textarea");
      n.style.position = "fixed", n.style.top = "0", n.style.left = "0", n.style.width = "2em", n.style.height = "2em", n.style.padding = "0", n.style.border = "none", n.style.outline = "none", n.style.boxShadow = "none", n.style.background = "transparent", n.value = t, document.body.appendChild(n), n.select();
      var o = e.copySelected();
      return document.body.removeChild(n), o;
    }
  }), e;
}();

export { ClipboardService };