"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var error = {
  message: "must match exactly one schema in oneOf",
  params: function params(_ref) {
    var _params = _ref.params;
    return codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["{passingSchemas: ", "}"])), _params.passing);
  }
};
var def = {
  keyword: "oneOf",
  schemaType: "array",
  trackErrors: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        schema = cxt.schema,
        parentSchema = cxt.parentSchema,
        it = cxt.it;
    /* istanbul ignore if */

    if (!Array.isArray(schema)) throw new Error("ajv implementation error");
    if (it.opts.discriminator && parentSchema.discriminator) return;
    var schArr = schema;
    var valid = gen.let("valid", false);
    var passing = gen.let("passing", null);
    var schValid = gen.name("_valid");
    cxt.setParams({
      passing: passing
    }); // TODO possibly fail straight away (with warning or exception) if there are two empty always valid schemas

    gen.block(validateOneOf);
    cxt.result(valid, function () {
      return cxt.reset();
    }, function () {
      return cxt.error(true);
    });

    function validateOneOf() {
      schArr.forEach(function (sch, i) {
        var schCxt;

        if (util_1.alwaysValidSchema(it, sch)) {
          gen.var(schValid, true);
        } else {
          schCxt = cxt.subschema({
            keyword: "oneOf",
            schemaProp: i,
            compositeRule: true
          }, schValid);
        }

        if (i > 0) {
          gen.if(codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", " && ", ""])), schValid, valid)).assign(valid, false).assign(passing, codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["[", ", ", "]"])), passing, i)).else();
        }

        gen.if(schValid, function () {
          gen.assign(valid, true);
          gen.assign(passing, i);
          if (schCxt) cxt.mergeEvaluated(schCxt, codegen_1.Name);
        });
      });
    }
  }
};
exports.default = def;