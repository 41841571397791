"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagDescription = void 0;

var utils_1 = require("../utils");

var TagDescription = function TagDescription() {
  return {
    Tag: function Tag(tag, ctx) {
      utils_1.validateDefinedAndNonEmpty('description', tag, ctx);
    }
  };
};

exports.TagDescription = TagDescription;