"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var code_1 = require("../code");

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var error = {
  message: function message(_ref) {
    var missingProperty = _ref.params.missingProperty;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must have required property '", "'"])), missingProperty);
  },
  params: function params(_ref2) {
    var missingProperty = _ref2.params.missingProperty;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{missingProperty: ", "}"])), missingProperty);
  }
};
var def = {
  keyword: "required",
  type: "object",
  schemaType: "array",
  $data: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        schema = cxt.schema,
        schemaCode = cxt.schemaCode,
        data = cxt.data,
        $data = cxt.$data,
        it = cxt.it;
    var opts = it.opts;
    if (!$data && schema.length === 0) return;
    var useLoop = schema.length >= opts.loopRequired;
    if (it.allErrors) allErrorsMode();else exitOnErrorMode();

    if (opts.strictRequired) {
      var props = cxt.parentSchema.properties;
      var definedProperties = cxt.it.definedProperties;

      var _iterator = _createForOfIteratorHelper(schema),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var requiredKey = _step.value;

          if ((props === null || props === void 0 ? void 0 : props[requiredKey]) === undefined && !definedProperties.has(requiredKey)) {
            var schemaPath = it.schemaEnv.baseId + it.errSchemaPath;
            var msg = "required property \"".concat(requiredKey, "\" is not defined at \"").concat(schemaPath, "\" (strictRequired)");
            util_1.checkStrictMode(it, msg, it.opts.strictRequired);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }

    function allErrorsMode() {
      if (useLoop || $data) {
        cxt.block$data(codegen_1.nil, loopAllRequired);
      } else {
        var _iterator2 = _createForOfIteratorHelper(schema),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var prop = _step2.value;
            code_1.checkReportMissingProp(cxt, prop);
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    }

    function exitOnErrorMode() {
      var missing = gen.let("missing");

      if (useLoop || $data) {
        var valid = gen.let("valid", true);
        cxt.block$data(valid, function () {
          return loopUntilMissing(missing, valid);
        });
        cxt.ok(valid);
      } else {
        gen.if(code_1.checkMissingProp(cxt, schema, missing));
        code_1.reportMissingProp(cxt, missing);
        gen.else();
      }
    }

    function loopAllRequired() {
      gen.forOf("prop", schemaCode, function (prop) {
        cxt.setParams({
          missingProperty: prop
        });
        gen.if(code_1.noPropertyInData(gen, data, prop, opts.ownProperties), function () {
          return cxt.error();
        });
      });
    }

    function loopUntilMissing(missing, valid) {
      cxt.setParams({
        missingProperty: missing
      });
      gen.forOf(missing, schemaCode, function () {
        gen.assign(valid, code_1.propertyInData(gen, data, missing, opts.ownProperties));
        gen.if(codegen_1.not(valid), function () {
          cxt.error();
          gen.break();
        });
      }, codegen_1.nil);
    }
  }
};
exports.default = def;