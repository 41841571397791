import { __assign, __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { PatternLabel, RecursiveLabel, TypeFormat, TypeName, TypePrefix, TypeTitle, ToggleButton, FieldLabel, ExampleValue } from "../../common-elements/fields";
import { serializeParameterValue } from "../../utils";
import { ExternalDocumentation } from "../ExternalDocumentation/ExternalDocumentation";
import { Markdown } from "../Markdown/Markdown";
import { EnumValues } from "./EnumValues";
import { Extensions } from "./Extensions";
import { ConstraintsView } from "./FieldContstraints";
import { FieldDetail } from "./FieldDetail";
import { Badge } from "../../common-elements/";
import { l } from "../../services/Labels";
import { OptionsContext } from "../OptionsProvider";
import styled, { mediaCSS } from "../../styled-components";

var MAX_PATTERN_LENGTH = 45,
    FieldDetails = function (e) {
  function t() {
    var t = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(t, "state", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: {
        patternShown: !1
      }
    }), Object.defineProperty(t, "togglePattern", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        t.setState({
          patternShown: !t.state.patternShown
        });
      }
    }), t;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.showExamples,
          a = e.field,
          n = e.renderDiscriminatorSwitch,
          r = this.state.patternShown,
          i = this.context,
          m = i.enumSkipQuotes,
          o = i.hideSchemaTitles,
          c = i.hideSchemaPattern,
          p = a.schema,
          s = a.description,
          u = a.example,
          d = a.deprecated,
          E = a.examples,
          b = !!m || "header" === a.in,
          f = null;
      if (t && (void 0 !== u || void 0 !== E)) if (void 0 !== E) f = React.createElement(Examples, {
        field: a
      });else {
        var y = l("example") + ":",
            x = !!a.in;
        f = React.createElement(FieldDetail, {
          label: y,
          value: getSerializedValue(a, a.example),
          raw: x
        });
      }
      return React.createElement("div", null, React.createElement(TypeWrapper, null, React.createElement(TypePrefix, null, p.typePrefix), React.createElement(TypeName, null, p.displayType), p.displayFormat && React.createElement(TypeFormat, null, " ", "<", p.displayFormat, ">", " "), p.contentEncoding && React.createElement(TypeFormat, null, " ", "<", p.contentEncoding, ">", " "), p.contentMediaType && React.createElement(TypeFormat, null, " ", "<", p.contentMediaType, ">", " "), p.title && !o && React.createElement(TypeTitle, null, " (", p.title, ") "), React.createElement(ConstraintsView, {
        constraints: p.constraints
      }), p.pattern && !c && React.createElement(React.Fragment, null, React.createElement(PatternLabel, null, r || p.pattern.length < MAX_PATTERN_LENGTH ? p.pattern : p.pattern.substr(0, MAX_PATTERN_LENGTH) + "..."), p.pattern.length > MAX_PATTERN_LENGTH && React.createElement(ToggleButton, {
        onClick: this.togglePattern
      }, r ? "Hide pattern" : "Show pattern")), p.isCircular && React.createElement(RecursiveLabel, null, " ", l("recursive"), " ")), d && React.createElement("div", null, React.createElement(Badge, {
        type: "warning",
        style: {
          marginLeft: 0
        }
      }, " ", l("deprecated"), " ")), React.createElement(FieldDetail, {
        raw: b,
        label: l("default") + ":",
        value: p.default
      }), React.createElement("div", null, React.createElement(Markdown, {
        compact: !0,
        source: s
      })), !n && React.createElement(EnumValues, {
        type: p.type,
        values: p["x-enumDescriptions"] || p.enum
      }), " ", f, React.createElement(Extensions, {
        extensions: __assign(__assign({}, a.extensions), p.extensions)
      }), p.externalDocs && React.createElement(ExternalDocumentation, {
        externalDocs: p.externalDocs,
        compact: !0
      }), n && n(this.props) || null, a.const && React.createElement(FieldDetail, {
        label: l("const") + ":",
        value: a.const
      }) || null);
    }
  }), Object.defineProperty(t, "contextType", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: OptionsContext
  }), t;
}(React.PureComponent);

export { FieldDetails };

function Examples(e) {
  var t = e.field;
  return t.examples ? React.createElement(React.Fragment, null, React.createElement(FieldLabel, null, " ", l("examples"), ": "), React.createElement(ExamplesList, null, Object.entries(t.examples).map(function (e) {
    var a = e[0],
        n = e[1];
    return React.createElement(ExampleWrap, {
      key: a
    }, React.createElement(ExampleSummary, null, n.summary || a, " "), n.description && React.createElement(ExampleDescription, null, n.description, " "), React.createElement(ExampleValue, null, getSerializedValue(t, n.value)));
  }))) : null;
}

function getSerializedValue(e, t) {
  return e.in ? decodeURIComponent(serializeParameterValue(e, t)) : t;
}

var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    ExampleDescription = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""]))),
    ExampleSummary = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  font-size: inherit;\n  margin-bottom: 0.2em;\n  font-weight: ", ";\n"], ["\n  display: block;\n  font-size: inherit;\n  margin-bottom: 0.2em;\n  font-weight: ", ";\n"])), function (e) {
  return e.theme.typography.fontWeightBold;
}),
    ExampleWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 1em;\n  ", "\n"], ["\n  margin-top: 1em;\n  ", "\n"])), function (e) {
  var t = e.theme;
  return mediaCSS({
    fontSize: t.typography.fieldName.fontSize
  });
}),
    ExamplesList = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 1.5em;\n  margin-bottom: 1em;\n"], ["\n  padding-left: 1.5em;\n  margin-bottom: 1em;\n"]))),
    TypeWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  line-height: 20px;\n"], ["\n  line-height: 20px;\n"])));