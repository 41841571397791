"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoUndefinedServerVariable = void 0;

var NoUndefinedServerVariable = function NoUndefinedServerVariable() {
  return {
    Server: function Server(server, _ref) {
      var report = _ref.report,
          location = _ref.location;

      var _a;

      var urlVariables = ((_a = server.url.match(/{[^}]+}/g)) === null || _a === void 0 ? void 0 : _a.map(function (e) {
        return e.slice(1, e.length - 1);
      })) || [];
      var definedVariables = (server === null || server === void 0 ? void 0 : server.variables) && Object.keys(server.variables) || [];

      var _iterator = _createForOfIteratorHelper(urlVariables),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var serverVar = _step.value;

          if (!definedVariables.includes(serverVar)) {
            report({
              message: "The `".concat(serverVar, "` variable is not defined in the `variables` objects."),
              location: location.child(['url'])
            });
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var _iterator2 = _createForOfIteratorHelper(definedVariables),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var definedServerVar = _step2.value;

          if (!urlVariables.includes(definedServerVar)) {
            report({
              message: "The `".concat(definedServerVar, "` variable is not used in the server's `url` field."),
              location: location.child(['variables', definedServerVar]).key(),
              from: location.child('url')
            });
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  };
};

exports.NoUndefinedServerVariable = NoUndefinedServerVariable;