import { __makeTemplateObject } from "tslib";
import styled, { css, extensionsHook, mediaCSS } from "../styled-components";
var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    templateObject_6,
    headerFontSize = {
  1: "36px",
  2: "28px",
  3: "18px"
};
export var headerCommonMixin = function headerCommonMixin(e) {
  return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (t) {
    var n = t.theme;
    return mediaCSS({
      fontSize: headerFontSize[e],
      fontFamily: n.typography.headings.fontFamily,
      fontWeight: n.typography.headings.fontWeight,
      lineHeight: headerFontSize[e]
    });
  });
};
export var H1 = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  margin: 0 0 25px;\n\n  ", ";\n"], ["\n  ", ";\n  color: ", ";\n  margin: 0 0 25px;\n\n  ", ";\n"])), headerCommonMixin(1), function (e) {
  return e.theme.colors.text.primary;
}, extensionsHook("H1"));
export var H2 = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  margin: 0 0 20px;\n\n  ", ";\n"], ["\n  ", ";\n  color: ", ";\n  margin: 0 0 20px;\n\n  ", ";\n"])), headerCommonMixin(2), function (e) {
  return e.theme.colors.text.primary;
}, extensionsHook("H2"));
export var H3 = styled.h2(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n\n  ", ";\n"], ["\n  ", ";\n  color: ", ";\n\n  ", ";\n"])), headerCommonMixin(3), function (e) {
  return e.theme.colors.text.primary;
}, extensionsHook("H3"));
export var RightPanelHeader = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n\n  ", ";\n"], ["\n  ", "\n\n  ", ";\n"])), function (e) {
  var t = e.theme;
  return mediaCSS({
    color: t.rightPanel.textColor
  });
}, extensionsHook("RightPanelHeader"));
export var FieldsGroupHeader = styled.h5(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: bold;\n  text-transform: uppercase;\n  font-size: 14px;\n  line-height: 20px;\n\n  ", ";\n"], ["\n  color: ", ";\n  font-weight: bold;\n  text-transform: uppercase;\n  font-size: 14px;\n  line-height: 20px;\n\n  ", ";\n"])), function (e) {
  return e.theme.colors.text.secondary;
}, extensionsHook("FieldsGroupHeader"));