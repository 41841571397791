import { __assign } from "tslib";
import { format } from "util";
import { CodeBuilder } from "../../helpers/code-builder";
import { normalizeMimeType } from "../../../../utils";
import { addIndentation, buildUrlExpression, printUrlVariablesDeclarations } from "../../helpers/code-helpers";
import { HTTPSnippet } from "../..";
import { Lang } from "../../helpers/constants";

var handler = function handler(e, t, a) {
  var r,
      s,
      o,
      n = a.target,
      i = a.client,
      l = Object.assign({
    indent: "  "
  }, t),
      p = new CodeBuilder({
    indentation: l.indent,
    variablesPrefix: l.variablesPrefix,
    lang: Lang.PYTHON
  });
  l.withComments && addComments(p), l.withImports && p.push("import requests").blank();
  var u,
      d,
      h = !1,
      m = e.allHeaders;

  if (null === (r = e.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r.length) {
    var c = new HTTPSnippet(null === (s = e.securityOAuth2ExtraCalls) || void 0 === s ? void 0 : s[0]).convert(n, i, __assign(__assign({}, l), {
      withImports: !1,
      withComments: !1,
      variablesPrefix: "oauth2_"
    }));
    p.push(c), p.blank(), (m = m || {}).Authorization = '"Bearer " + oauth2_data["access_token"]', h = !0;
  }

  (printUrlVariablesDeclarations(e, p), p.push(p.var("url").toLowerCase() + " = %s", buildUrlExpression(e, p)).blank(), Object.keys(e.queryObj || {}).length) && (u = "query = " + jsObjectToPythonDict(e.queryObj, l.indent), p.push(u).blank());
  var b,
      f = normalizeMimeType(null === (o = e.postData) || void 0 === o ? void 0 : o.mimeType);
  if (e.postData) switch (f) {
    case "application/x-www-form-urlencoded":
      d = e.postData.paramsObj ? "" + addIndentation(jsObjectToPythonDict(e.postData.paramsObj, l.indent), {
        level: 0,
        firstLine: !1
      }) : e.postData.text;
      break;

    case "application/json":
      e.postData.jsonObj && (d = addIndentation(jsObjectToPythonDict(e.postData.jsonObj, l.indent), {
        level: 0,
        firstLine: !1
      }));
      break;

    case "multipart/form-data":
      p.unshift("# multipart/form-data is not supported yet\n");
      break;

    default:
      e.postData.text && (console.log(e.postData.text), d = "'''\n" + addIndentation(e.postData.text.trim(), {
        level: 1,
        indent: l.indent,
        firstLine: !0
      }) + "\n'''");
  }
  d && p.push(p.var("payload").toLowerCase() + " = %s", d).blank();

  var v = Object.keys(m).length,
      _ = function _(e, t) {
    return "Authorization" === e && h ? t : '"' + t + '"';
  };

  if (1 === v) for (b in m) {
    p.push(p.var("headers").toLowerCase() + ' = {"' + b + '": ' + _(b, m[b]) + "}").blank();
  } else if (v > 1) {
    var w = 1;

    for (b in p.push(p.var("headers").toLowerCase() + " = {"), m) {
      w++ !== v ? p.push(1, '"' + b + '": ' + _(b, m[b]) + ",") : p.push(1, '"' + b + '": ' + _(b, m[b]));
    }

    p.push(0, "}").blank();
  }
  var C = e.method.toLowerCase(),
      j = format(p.var("response").toLowerCase() + " = requests." + C + "(" + p.var("url").toLowerCase());
  d && (j += ", " + ("application/json" === f ? "json" : "data") + "=" + p.var("payload").toLowerCase());
  return v > 0 && (j += ", headers=" + p.var("headers").toLowerCase()), u && (j += ", params=" + p.var("query").toLowerCase()), e.basicAuth && (j += ", auth=('" + e.basicAuth.username + "','" + e.basicAuth.password + "')"), j += ")", p.push(j).blank().push(p.var("data").toLowerCase() + " = " + p.var("response").toLowerCase() + ".json()").push("print(" + p.var("data").toLowerCase() + ")"), p.join();
};

export default handler;

function addComments(e) {
  e.push('"""'), e.push("Requires python >= 3"), e.blank(), e.push('Requires module "requests" >= 2.25.1'), e.push("See here for installation details:"), e.push("  https://requests.readthedocs.io/"), e.push('"""'), e.blank();
}

function jsObjectToPythonDict(e, t) {
  return JSON.stringify(e, function (e, t) {
    return e && "boolean" == typeof t ? t ? "__REDOCLY_<True>__" : "__REDOCLY_<False>__" : null === t ? "__REDOCLY_<None>__" : t;
  }, t).replace(/"__REDOCLY_<(\w+)>__"/g, "$1");
}