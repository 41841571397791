import * as React from "react";
import { useState } from "react";
export var Accordion = function Accordion(e) {
  var t = e.initialActiveIdx,
      n = e.children,
      c = useState(t),
      a = c[0],
      r = c[1],
      i = React.useState(!1),
      l = i[0],
      o = i[1];
  React.useEffect(function () {
    setTimeout(function () {
      o(!0);
    }, 500);
  }, []), React.useEffect(function () {
    l || r(t);
  }, [t, l]);
  var u = React.useCallback(function (e) {
    r(e);
  }, [r]),
      f = React.Children.toArray(n).filter(React.isValidElement);
  return React.createElement(React.Fragment, null, React.Children.map(f, function (e, t) {
    return React.cloneElement(e, {
      key: t,
      collapsed: t !== a,
      onToggle: function onToggle() {
        return u(t);
      }
    }) || null;
  }) || null);
};