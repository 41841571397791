import * as Prism from "prismjs";
import "prismjs/components/prism-bash.js";
import "prismjs/components/prism-c.js";
import "prismjs/components/prism-clike.js";
import "prismjs/components/prism-coffeescript.js";
import "prismjs/components/prism-cpp.js";
import "prismjs/components/prism-csharp.js";
import "prismjs/components/prism-go.js";
import "prismjs/components/prism-http.js";
import "prismjs/components/prism-java.js";
import "prismjs/components/prism-lua.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-objectivec.js";
import "prismjs/components/prism-perl.js";
import "prismjs/components/prism-php.js";
import "prismjs/components/prism-python.js";
import "prismjs/components/prism-ruby.js";
import "prismjs/components/prism-scala.js";
import "prismjs/components/prism-sql.js";
import "prismjs/components/prism-swift.js";
var DEFAULT_LANG = "clike";
Prism.languages.insertBefore("javascript", "string", {
  "property string": {
    pattern: /([{,]\s*)"(?:\\.|[^\\"\r\n])*"(?=\s*:)/i,
    lookbehind: !0
  }
}, void 0), Prism.languages.insertBefore("javascript", "punctuation", {
  property: {
    pattern: /([{,]\s*)[a-z]\w*(?=\s*:)/i,
    lookbehind: !0
  }
}, void 0);
export function mapLang(s) {
  return {
    json: "js",
    "c++": "cpp",
    "c#": "csharp",
    "objective-c": "objectivec",
    shell: "bash",
    viml: "vim",
    curl: "bash",
    "node.js": "js"
  }[s] || DEFAULT_LANG;
}
export function highlight(s, p) {
  void 0 === p && (p = DEFAULT_LANG), p = p.toLowerCase();
  var r = Prism.languages[p];
  return r || (r = Prism.languages[mapLang(p)]), Prism.highlight(s.toString(), r, p);
}