import merge from "deepmerge";
export function normalizePath(e) {
  return e.startsWith("/") || (e = "/" + e), e.endsWith("/") && (e = e.slice(0, -1)), e;
}
export function isSameMime(e, r) {
  return !(!e || !r) && normalizeMimeType(e) === normalizeMimeType(r);
}
export function normalizeMimeType(e) {
  if (e) return e.split(";")[0].toLowerCase();
}
export function unescapeServerVariables(e) {
  var r = new RegExp("%7B", "g"),
      t = new RegExp("%7D", "g");
  return e.replace(r, "{").replace(t, "}").replace("///", "//");
}
export function arrayMerge(e, r) {
  if (e.length) {
    var t = e.map(function (e, t) {
      return merge(e, r[t] || {});
    });
    return r.length > e.length ? t.concat(r.slice(e.length)) : t;
  }

  return r;
}
export function isEmptyObject(e) {
  return !!e && 0 === Object.keys(e).length;
}