"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var code_1 = require("../code");

var codegen_1 = require("../../compile/codegen");

var names_1 = require("../../compile/names");

var util_1 = require("../../compile/util");

var error = {
  message: "must NOT have additional properties",
  params: function params(_ref) {
    var _params = _ref.params;
    return codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["{additionalProperty: ", "}"])), _params.additionalProperty);
  }
};
var def = {
  keyword: "additionalProperties",
  type: ["object"],
  schemaType: ["boolean", "object"],
  allowUndefined: true,
  trackErrors: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        parentSchema = cxt.parentSchema,
        data = cxt.data,
        errsCount = cxt.errsCount,
        it = cxt.it;
    var _cxt$schema = cxt.schema,
        schema = _cxt$schema === void 0 ? it.opts.defaultAdditionalProperties : _cxt$schema;
    /* istanbul ignore if */

    if (!errsCount) throw new Error("ajv implementation error");
    var allErrors = it.allErrors,
        opts = it.opts;
    it.props = true;
    if (opts.removeAdditional !== "all" && util_1.alwaysValidSchema(it, schema)) return;
    var props = code_1.allSchemaProperties(parentSchema.properties);
    var patProps = code_1.allSchemaProperties(parentSchema.patternProperties);
    checkAdditionalProperties();
    cxt.ok(codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", " === ", ""])), errsCount, names_1.default.errors));

    function checkAdditionalProperties() {
      gen.forIn("key", data, function (key) {
        if (!props.length && !patProps.length) additionalPropertyCode(key);else gen.if(isAdditional(key), function () {
          return additionalPropertyCode(key);
        });
      });
    }

    function isAdditional(key) {
      var definedProp;

      if (props.length > 8) {
        // TODO maybe an option instead of hard-coded 8?
        var propsSchema = util_1.schemaRefOrVal(it, parentSchema.properties, "properties");
        definedProp = code_1.isOwnProperty(gen, propsSchema, key);
      } else if (props.length) {
        definedProp = codegen_1.or.apply(codegen_1, _toConsumableArray(props.map(function (p) {
          return codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " === ", ""])), key, p);
        })));
      } else {
        definedProp = codegen_1.nil;
      }

      if (patProps.length) {
        definedProp = codegen_1.or.apply(codegen_1, [definedProp].concat(_toConsumableArray(patProps.map(function (p) {
          return codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", ".test(", ")"])), code_1.usePattern(cxt, p), key);
        }))));
      }

      return codegen_1.not(definedProp);
    }

    function deleteAdditional(key) {
      gen.code(codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["delete ", "[", "]"])), data, key));
    }

    function additionalPropertyCode(key) {
      if (opts.removeAdditional === "all" || opts.removeAdditional && schema === false) {
        deleteAdditional(key);
        return;
      }

      if (schema === false) {
        cxt.setParams({
          additionalProperty: key
        });
        cxt.error();
        if (!allErrors) gen.break();
        return;
      }

      if (typeof schema == "object" && !util_1.alwaysValidSchema(it, schema)) {
        var valid = gen.name("valid");

        if (opts.removeAdditional === "failing") {
          applyAdditionalSchema(key, valid, false);
          gen.if(codegen_1.not(valid), function () {
            cxt.reset();
            deleteAdditional(key);
          });
        } else {
          applyAdditionalSchema(key, valid);
          if (!allErrors) gen.if(codegen_1.not(valid), function () {
            return gen.break();
          });
        }
      }
    }

    function applyAdditionalSchema(key, valid, errors) {
      var subschema = {
        keyword: "additionalProperties",
        dataProp: key,
        dataPropType: util_1.Type.Str
      };

      if (errors === false) {
        Object.assign(subschema, {
          compositeRule: true,
          createErrors: false,
          allErrors: false
        });
      }

      cxt.subschema(subschema, valid);
    }
  }
};
exports.default = def;