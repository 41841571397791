import { __assign, __decorate } from "tslib";
import { flattenByProp, SECURITY_SCHEMES_SECTION_PREFIX, querySelector, GROUP_DEPTH } from "../redoc-lib";
import { action, observable, makeObservable } from "mobx";
import { VersionedSpecStore } from "./VersionedSpecStore";
import { SECTION_ATTR } from "../redoc-lib/src/services/constants";
import { history as historyInst } from "../redoc-lib/src/services/HistoryService";

var ProMenu = function () {
  function e(e, t, i, r) {
    var n = this;
    Object.defineProperty(this, "definition", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "scroll", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    }), Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: i
    }), Object.defineProperty(this, "history", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: r
    }), Object.defineProperty(this, "flatItems", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "items", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "activeRenderItemIdx", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: -1
    }), Object.defineProperty(this, "renderItems", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    }), Object.defineProperty(this, "activeItemIdx", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: -1
    }), Object.defineProperty(this, "sideBarOpened", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "_unsubscribe", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "_hashUnsubscribe", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "getItemById", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        return n.flatItems.find(function (t) {
          return t.id === e;
        });
      }
    }), Object.defineProperty(this, "initOnHistory", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        void 0 === e && (e = n.history.currentId);
        var t,
            i = e.split(n.options.deepLinkPrefix)[0];
        i && ((t = n.flatItems.find(function (e) {
          return e.id === i;
        })) ? (n.activate(t, !1, !1, !1, e), t.active = !1) : i.startsWith(SECURITY_SCHEMES_SECTION_PREFIX) && (t = n.flatItems.find(function (e) {
          return SECURITY_SCHEMES_SECTION_PREFIX.startsWith(e.id);
        }), n.activate(t, !1, !1, !1, e), t && (t.active = !1)));
      }
    }), Object.defineProperty(this, "updateOnHistory", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        void 0 === e && (e = n.history.currentId);
        var t,
            i = e.split(n.options.deepLinkPrefix)[0];
        if (i) if (t = n.flatItems.find(function (e) {
          return e.id === i;
        })) n.activateAndScroll(t, !1, void 0, e);else {
          var r = (n.history.basePath ? n.history.basePath + "/" : "") + SECURITY_SCHEMES_SECTION_PREFIX;
          i.startsWith(r) && (t = n.flatItems.find(function (e) {
            return SECURITY_SCHEMES_SECTION_PREFIX.startsWith(e.id);
          }), n.activate(t)), n.scroll.prevOffsetY = 0, n.scroll.scrollIntoViewBySelector("[" + SECTION_ATTR + '="' + i + '"]');
        }
      }
    }), Object.defineProperty(this, "updateVersionOnHistory", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        var t = e.split("/")[0],
            i = n.definition.versionsInfo.findIndex(function (e) {
          return e.id === t;
        });
        i < 0 && (i = n.definition.versionsInfo.findIndex(function (e) {
          return e.isDefault;
        })), n.definition.activeVersionIdx !== i && (n.definition.changeVersion(i < 0 ? 0 : i), n.updateItemsByVersionChange(!1, e));
        var r = t + "/" + SECURITY_SCHEMES_SECTION_PREFIX;

        if (e.startsWith(r)) {
          var o = n.flatItems.find(function (e) {
            return r.startsWith(e.id);
          });
          n.activate(o, !1, !0), setTimeout(function () {
            return n.scroll.scrollIntoViewBySelector("[" + SECTION_ATTR + '="' + e + '"]');
          }, 0);
        }
      }
    }), Object.defineProperty(this, "updateOnScroll", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        for (var t = e ? 1 : -1, i = n.activeItemIdx; (-1 !== i || e) && !(i >= n.flatItems.length - 1 && e);) {
          if (e) {
            var r = !1,
                o = n.flatItems[i + 1];

            switch (n.options.pagination) {
              case "section":
                r = o && ("spec" === o.type || "group" === o.type || "tag" === o.type);
                break;

              case "item":
                r = "section" !== (null == o ? void 0 : o.type);
            }

            var a = n.getElementAt(i + 1);
            if (-1 === i && "tag" === n.flatItems[0].type && (r = !1), n.scroll.isElementBellow(a) || r) break;
          } else {
            var s = n.flatItems[i] && n.flatItems[i].type;
            r = "section" === n.options.pagination && ("spec" === s || "group" === s || "tag" === s) || "item" === n.options.pagination && "section" !== s, a = n.getElementAt(i);
            if (n.scroll.isElementAbove(a) || r) break;
          }

          i += t;
        }

        var l = n.flatItems[i];
        "none" === n.options.pagination || "section" === n.options.pagination ? l && l.active || n.activate(l, !0, !0, !1) : l && "section" === l.type && (l.active || n.activate(l, !0, !0, !1));
      }
    }), makeObservable(this), this.items = e.contentItems, this.flatItems = flattenByProp(this.items || [], "items"), this.flatItems.forEach(function (e, t) {
      return e.absoluteIdx = t;
    }), i.disableSidebar || this.subscribe(), this.flatItems.forEach(function (e, t) {
      return normalizeItem(e, t, i.pagination, n.definition instanceof VersionedSpecStore, n.history);
    }), this.renderItems = this.buildRenderItems(0), this.activeItemIdx = -1, this.activeRenderItemIdx = -1, this.definition instanceof VersionedSpecStore && this.history.subscribe(this.updateVersionOnHistory);
  }

  return Object.defineProperty(e, "updateOnHistory", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      void 0 === e && (e = historyInst.currentId), e && t.scrollIntoViewBySelector("[" + SECTION_ATTR + '="' + e + '"]');
    }
  }), Object.defineProperty(e.prototype, "subscribe", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this._unsubscribe = this.scroll.subscribe(this.updateOnScroll), this._hashUnsubscribe = this.history.subscribe(this.updateOnHistory);
    }
  }), Object.defineProperty(e.prototype, "toggleSidebar", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.sideBarOpened = !this.sideBarOpened;
    }
  }), Object.defineProperty(e.prototype, "closeSidebar", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.sideBarOpened = !1;
    }
  }), Object.defineProperty(e.prototype, "getElementAt", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this.flatItems[e];
      return t && querySelector("[" + SECTION_ATTR + '="' + t.id + '"]') || null;
    }
  }), Object.defineProperty(e.prototype, "getElementAtOrFirstChild", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this.flatItems[e];
      return t && "group" === t.type && (t = t.items[0]), t && querySelector("[" + SECTION_ATTR + '="' + t.id + '"]') || null;
    }
  }), Object.defineProperty(e.prototype, "activeItem", {
    get: function get() {
      return this.flatItems[this.activeItemIdx] || void 0;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "updateItemsByVersionChange", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      var i = this,
          r = this.activeItem && this.activeItem.active ? this.activeItem.absoluteIdx : -1;
      t = t && withoutVersion(t) || this.activeItem && this.activeItem.active && withoutVersion(this.activeItem.id) || "", this.deactivate(this.activeItem), this.items = this.definition.contentItems, this.flatItems = flattenByProp(this.items || [], "items"), this.flatItems.forEach(function (e, t) {
        return normalizeItem(e, t, i.options.pagination, i.definition instanceof VersionedSpecStore, i.history);
      });
      var n = t ? (this.flatItems.find(function (e) {
        return withoutVersion(e.id) === t;
      }) || {
        absoluteIdx: -1
      }).absoluteIdx : -1;
      if (-1 !== n && "none" !== this.options.pagination || (this.renderItems = this.buildRenderItems(0)), this.activeItemIdx = -1, -1 !== n) this.activate(this.flatItems[n], e);else if (-1 !== r) this.activateAndScroll(this.flatItems[0], e);else if (e) {
        var o = this.definition.versionsInfo[this.definition.activeVersionIdx];
        if (!this.history.currentId && o.isDefault) return;
        this.history.replace(o.id);
      }
    }
  }), Object.defineProperty(e.prototype, "buildRenderItems", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      if ("none" === this.options.pagination) return this.items;
      var t = this.flatItems[e];
      if (t || (t = this.flatItems[0]), "spec" === t.type) return this.buildRenderItems(e + 1);

      if ("section" === t.type) {
        var i = [],
            r = getParentOfType(t, "tag");
        if ("section" === this.options.pagination && r) return [r];
        if ("item" === this.options.pagination && r) return [__assign(__assign({}, r), {
          items: getLeadingSectionItems(r)
        })];

        for (var n = getParentSpecItem(t), o = (null == n ? void 0 : n.items) || this.items, a = 0; a < o.length && "section" === o[a].type; a++) {
          i.push(o[a]);
        }

        return i;
      }

      switch (this.options.pagination) {
        case "section":
          if ("operation" === t.type && t.parent) return [t.parent];
          if ("group" !== t.type) return [t];

        case "item":
          return "operation" === t.type ? [t] : [__assign(__assign({}, t), {
            items: getLeadingSectionItems(t)
          })];

        default:
          return [];
      }
    }
  }), Object.defineProperty(e.prototype, "activateBase", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t, i, r) {
      var n,
          o,
          a,
          s = this;
      if (void 0 === t && (t = !0), void 0 === i && (i = !1), (null === (n = this.activeItem) || void 0 === n ? void 0 : n.id) === (null == e ? void 0 : e.id) && !r) return t && e && this.history.replace(e.id, i), void (e && !e.active && e.activate());
      if (!e || "group" !== e.type) if (this.deactivate(this.activeItem), e) {
        if (!(e.depth <= GROUP_DEPTH)) {
          this.activeItemIdx = e.absoluteIdx, t && this.history.replace(e.id, i), e.activate(), e.expand();
          var l = new URLSearchParams(r),
              c = "request" === l.get("t"),
              u = "response" === l.get("t"),
              d = l.get("c"),
              f = l.get("ct"),
              p = l.get("in"),
              v = l.get("path"),
              b = (null == v ? void 0 : v.split(this.options.deepLinkSeparator)) || [],
              h = e;
          if (p) for (var m = h.parameters, I = function I(e) {
            var t = null == m ? void 0 : m.find(function (t) {
              return t.name === e;
            });
            null == t || t.expand(), m = null === (o = null == t ? void 0 : t.schema) || void 0 === o ? void 0 : o.fields;
          }, y = 0, g = b; y < g.length; y++) {
            I(g[y]);
          } else if (c) {
            var O = null === (a = h.requestBody) || void 0 === a ? void 0 : a.content;
            h.activateMimeName(f || h.activeMimeName), setTimeout(function () {
              var e;
              return s.activateField(b, null === (e = null == O ? void 0 : O.active) || void 0 === e ? void 0 : e.schema);
            }, 0);
          } else if (u) {
            var S = h.responses.find(function (e) {
              return e.code === d;
            });
            (null == S ? void 0 : S.expanded) || null == S || S.expand(), h.activateMimeName(f || h.activeMimeName), setTimeout(function () {
              var e, t;
              return s.activateField(b, null === (t = null === (e = null == S ? void 0 : S.content) || void 0 === e ? void 0 : e.active) || void 0 === t ? void 0 : t.schema);
            }, 0);
          }
        }
      } else this.history.replace("", i);
    }
  }), Object.defineProperty(e.prototype, "activate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t, i, r, n) {
      var o = this;
      if (void 0 === t && (t = !0), void 0 === i && (i = !1), void 0 === r && (r = !0), "none" === this.options.pagination) return this.activateBase(e, t, i, n), void (!e && this.definition instanceof VersionedSpecStore && this.history.replace(this.definition.currentVersionId, !0));

      if ("group" !== (null == e ? void 0 : e.type)) {
        if (e && "item" === this.options.pagination && "tag" === e.type && !e.description) {
          if (e.expanded) return void e.collapse();
          e.expand(), e = this.flatItems[e.absoluteIdx + 1];
        }

        if (this.activateBase(e, e && t, i, n), this.activeRenderItemIdx = e ? e.absoluteIdx : this.activeRenderItemIdx, this.activeRenderItemIdx >= 0) {
          var a = this.buildRenderItems(this.activeRenderItemIdx);
          a.some(function (e, t) {
            return e.id !== o.renderItems[t].id;
          }) && (this.renderItems = a), this.renderItems[0] && (this.activeRenderItemIdx = this.renderItems[0].absoluteIdx);
        }

        r && setTimeout(function () {
          return o.scrollToActive();
        }), !e && this.definition instanceof VersionedSpecStore && this.history.replace(this.definition.currentVersionId, !0);
      }
    }
  }), Object.defineProperty(e.prototype, "activateField", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      for (var i, r = function r(_r) {
        var n = e[_r],
            o = _r === e.length - 1;

        if ((null == t ? void 0 : t.hasType("array")) && (null == t ? void 0 : t.items) && (t = null == t ? void 0 : t.items), null == t ? void 0 : t.oneOf) {
          var a = Number(n);
          return null == t || t.activateOneOf(a), t = null == t ? void 0 : t.oneOf[a], "continue";
        }

        (null == t ? void 0 : t.hasType("array")) && (null == t ? void 0 : t.items) && (t = null == t ? void 0 : t.items);
        var s = null === (i = null == t ? void 0 : t.fields) || void 0 === i ? void 0 : i.find(function (e) {
          return e.name === n;
        });
        o || null == s || s.expand(), t = null == s ? void 0 : s.schema;
      }, n = 0; n < e.length - 1; n++) {
        r(n);
      }
    }
  }), Object.defineProperty(e.prototype, "deactivate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      if (void 0 !== e) for (e.deactivate(); void 0 !== e;) {
        e.collapse(), e = e.parent;
      }
    }
  }), Object.defineProperty(e.prototype, "activateAndScroll", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t, i, r) {
      void 0 === r && (r = "");
      var n = r.split(this.options.deepLinkPrefix)[1],
          o = e && this.getItemById(e.id) || e;
      this.activate(o, t, i, !1, n), this.scrollToActive(n ? r : ""), o && o.items.length || this.closeSidebar();
    }
  }), Object.defineProperty(e.prototype, "scrollToActive", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this;
      e ? setTimeout(function () {
        return t.scroll.scrollIntoViewBySelector("[" + SECTION_ATTR + '="' + e + '"]');
      }, 0) : this.scroll.scrollIntoView(this.getElementAt(this.activeItemIdx));
    }
  }), Object.defineProperty(e.prototype, "dispose", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e, t;
      null === (e = this._unsubscribe) || void 0 === e || e.call(this), null === (t = this._hashUnsubscribe) || void 0 === t || t.call(this);
    }
  }), __decorate([observable], e.prototype, "flatItems", void 0), __decorate([observable], e.prototype, "items", void 0), __decorate([observable], e.prototype, "activeRenderItemIdx", void 0), __decorate([observable.ref], e.prototype, "renderItems", void 0), __decorate([observable], e.prototype, "activeItemIdx", void 0), __decorate([observable], e.prototype, "sideBarOpened", void 0), __decorate([action], e.prototype, "toggleSidebar", null), __decorate([action], e.prototype, "closeSidebar", null), __decorate([action], e.prototype, "activateBase", null), __decorate([action], e.prototype, "activate", null), __decorate([action.bound], e.prototype, "activateAndScroll", null), e;
}();

export { ProMenu };

function withoutVersion(e) {
  return e.split("/").slice(1).join("/");
}

function getParentSpecItem(e) {
  for (; e;) {
    if ("spec" === e.type) return e;
    e = e.parent;
  }
}

function getParentOfType(e, t) {
  if (e && e.parent) return e.parent.type === t ? e.parent : getParentOfType(e.parent, t);
}

function getLeadingSectionItems(e) {
  for (var t = [], i = 0, r = e.items; i < r.length; i++) {
    var n = r[i];
    if ("section" !== n.type) break;
    t.push(n);
  }

  return t;
}

function normalizeItem(e, t, i, r, n) {
  if (e.absoluteIdx = t, "none" !== i) {
    var o = getParentOfType(e, "tag");

    if (e.parent && o && !("_id_normalized" in e)) {
      if (r) {
        var a = e.id.split("/"),
            s = a[0],
            l = a[1],
            c = withoutVersion(e.id);
        if ("operation" === e.type && !l.startsWith("operation")) return;
        e.id = s + "/" + withoutVersion(o.id) + "/" + c;
      } else {
        if ("operation" === e.type && !e.id.startsWith("operation")) return;
        e.id = o.id + "/" + e.id;
      }

      e._id_normalized = !0;
    }
  }

  "group" !== e.type && (e.href = n.linkForId(e.id));
}