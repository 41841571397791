import { __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../styled-components";

var templateObject_1,
    templateObject_2,
    templateObject_3,
    directionMap = {
  right: "0deg",
  down: "90deg",
  left: "180deg",
  up: "270deg"
},
    IntShelfIcon = function IntShelfIcon(e) {
  var t = e.className,
      n = e.style;
  return React.createElement("svg", {
    className: t,
    style: n,
    viewBox: "0 0 4.25 7",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-hidden": "true"
  }, React.createElement("path", {
    d: "M0.205025 1.19467L3.00503 3.99467C3.27839 4.26804 3.72161 4.26804 3.99497 3.99467C4.26834 3.7213 4.26834 3.27809 3.99497 3.00472L1.19497 0.20472C0.921608 -0.0686469 0.478392 -0.0686469 0.205025 0.20472C-0.0683418 0.478087 -0.0683417 0.921303 0.205025 1.19467Z"
  }), React.createElement("path", {
    d: "M3.00503 3.00501L0.205025 5.80501C-0.0683417 6.07838 -0.0683417 6.5216 0.205025 6.79496C0.478392 7.06833 0.921608 7.06833 1.19497 6.79496L3.99497 3.99496C4.26834 3.7216 4.26834 3.27838 3.99497 3.00501C3.72161 2.73165 3.27839 2.73165 3.00503 3.00501Z"
  }));
};

export var ShelfIcon = styled(IntShelfIcon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  float: ", ";\n  transition: transform 0.2s ease-out;\n  transform: rotateZ(", ");\n  fill: ", ";\n"], ["\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  float: ", ";\n  transition: transform 0.2s ease-out;\n  transform: rotateZ(", ");\n  fill: ", ";\n"])), function (e) {
  return e.size || "9px";
}, function (e) {
  return e.size || "9px";
}, function (e) {
  return e.float || "";
}, function (e) {
  return directionMap[e.direction || "down"];
}, function (e) {
  var t = e.color,
      n = e.theme;
  return t && n.colors.responses[t] && n.colors.responses[t].color || t;
});

function CrossIconInt(e) {
  var t = e.className;
  return React.createElement("svg", {
    className: t,
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    width: "122.879px",
    height: "122.879px",
    viewBox: "0 0 122.879 122.879"
  }, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z"
  }));
}

export var CrossIcon = styled(CrossIconInt)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  float: ", ";\n  transition: transform 0.2s ease-out;\n  path {\n    fill: ", ";\n  }\n"], ["\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  float: ", ";\n  transition: transform 0.2s ease-out;\n  path {\n    fill: ", ";\n  }\n"])), function (e) {
  return e.size || "9px";
}, function (e) {
  return e.size || "9px";
}, function (e) {
  return e.float || "";
}, function (e) {
  var t = e.color,
      n = e.theme;
  return t && n.colors.responses[t] && n.colors.responses[t].color || t;
});

function LockIconInt(e) {
  var t = e.className;
  return React.createElement("svg", {
    className: t,
    version: "1.1",
    viewBox: "0 0 100 100"
  }, React.createElement("g", {
    display: "none"
  }, React.createElement("path", {
    display: "inline",
    d: "M94.5 49.7c0-7.8-5-14.5-12-16.9C78 19.1 65.2 9.3 50 9.3c-15.1 0-27.9 9.9-32.4 23.5C10.6 35.1 5.5 41.8 5.5 49.7c0 9.9 8 17.9 17.9 17.9V34.9c3.6-11.3 14.2-19.4 26.6-19.4 12.4 0 22.9 8.1 26.6 19.2v30.7c-1.3 2.7-8.8 17.6-18.7 18 -0.8-2.1-2.8-3.6-5.1-3.6 -3 0-5.5 2.5-5.5 5.5 0 3 2.5 5.5 5.5 5.5 2.4 0 4.4-1.5 5.1-3.6 11.8-0.4 20-16 21.9-19.8C88.2 65.7 94.5 58.4 94.5 49.7z"
  })), React.createElement("g", {
    display: "none"
  }, React.createElement("path", {
    display: "inline",
    d: "M63.3 12.4V8.7c0-1.2-1.2-2.2-2.7-2.2 -1.5 0-2.7 1-2.7 2.2v1.9c-0.8-0.1-1.5-0.2-2.3-0.2H44.4c-9.2 0-16.7 7.6-16.7 16.9v49.3c0 9.3 7.5 16.9 16.7 16.9h11.1c9.2 0 16.7-7.6 16.7-16.9V27.3C72.3 20.8 68.6 15.2 63.3 12.4zM56.6 84.9H43.3v-4.2h13.4V84.9zM65.2 67.4c-0.2 0.2-4.7 5.4-15.4 5.4 -10.5 0-16.4-5-16.7-5.2 -0.7-0.6-0.8-1.8-0.2-2.5 0.6-0.7 1.8-0.8 2.5-0.2 0 0 5.2 4.3 14.3 4.3 8.9 0 12.5-4 12.7-4.1 0.6-0.7 1.8-0.8 2.5-0.2C65.8 65.5 65.9 66.6 65.2 67.4zM66.4 51.6c0 3.1-2.4 5.6-5.3 5.6H39.2c-3 0-5.3-2.5-5.3-5.6v-25c0-3.1 2.4-5.6 5.3-5.6h21.9c3 0 5.3 2.5 5.3 5.6V51.6z"
  })), React.createElement("g", {
    display: "none"
  }, React.createElement("path", {
    display: "inline",
    d: "M63.4 9.7H36.6c-5.4 0-9.8 4.5-9.8 10v60.7c0 5.5 4.4 10 9.8 10h26.8c5.4 0 9.8-4.5 9.8-10V19.7C73.2 14.2 68.8 9.7 63.4 9.7zM50 85c-2.6 0-4.8-2.1-4.8-4.8s2.1-4.8 4.8-4.8c2.6 0 4.8 2.1 4.8 4.8S52.6 85 50 85zM67.1 71.5H32.9V20.8h34.3V71.5z"
  })), React.createElement("path", {
    d: "M16.1 45.4V92.5h67.8V45.4H16.1zM53.9 70.9v12.4h-7.4V70.9c-3.2-1.4-5.4-4.6-5.4-8.2 0-5 4-9 9-9 5 0 9 4.1 9 9C59.3 66.3 57.1 69.5 53.9 70.9zM37.2 39.8c-0.3 0-1.4-2.9-1.4-2.9 -0.5-1.5-0.7-3-0.7-4.6 0-8.4 6.8-15.2 15.2-15.2 8.4 0 15.2 6.8 15.2 15.2 0 1.6-0.2 3.1-0.7 4.6l0 0 0 0c0 0-1.1 2.9-1.4 2.9 -0.3 0 10.4 0 10.6 0l0 0c0.8-2.4 1.2-4.9 1.2-7.5C75.1 18.6 64 7.5 50.3 7.5c-13.7 0-24.8 11.1-24.8 24.8 0 2.5 0.4 5.1 1.2 7.5C26.6 39.8 37.5 39.8 37.2 39.8z"
  }));
}

export var LockIcon = styled(LockIconInt)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  float: ", ";\n  transition: transform 0.2s ease-out;\n  path {\n    fill: ", ";\n  }\n"], ["\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  float: ", ";\n  transition: transform 0.2s ease-out;\n  path {\n    fill: ", ";\n  }\n"])), function (e) {
  return e.size || "9px";
}, function (e) {
  return e.size || "9px";
}, function (e) {
  return e.float || "";
}, function (e) {
  var t = e.color,
      n = e.theme;
  return t && n.colors.responses[t] && n.colors.responses[t].color || t;
});