exports.onClientEntry = function () {
  window.copyCodeToClipboard = function (a, b) {
    var c = document.createElement("textarea");
    c.className = "gatsby-code-button-buffer", c.innerHTML = a, document.body.appendChild(c);
    var d = document.createRange();
    d.selectNode(c), window.getSelection().removeAllRanges(), window.getSelection().addRange(d), document.execCommand("copy"), document.activeElement.blur(), setTimeout(function () {
      document.getSelection().removeAllRanges(), document.body.removeChild(c);
    }, 100), b && window.showClipboardToaster(b);
  }, window.showClipboardToaster = function (a) {
    var b = document.querySelector("[data-toaster-id=\"" + a + "\"]");
    if (!b) return;
    var c = b.querySelector(".gatsby-code-button"),
        d = b.querySelector(".done-indicator-" + a);
    c.style.display = "none", d.style.display = "block", setTimeout(function () {
      d.style.display = "none", c.style.display = "";
    }, b.dataset.toasterDuration);
  };
};