import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
export var HttpVerb = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  color: ", ";\n  text-transform: uppercase;\n  text-align: center;\n  font-family: ", ";\n  border-radius: ", ";\n  background-color: ", ";\n  min-width: 58px;\n  padding: 0 8px;\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: ", ";\n"], ["\n  display: inline-block;\n  color: ", ";\n  text-transform: uppercase;\n  text-align: center;\n  font-family: ", ";\n  border-radius: ", ";\n  background-color: ", ";\n  min-width: 58px;\n  padding: 0 8px;\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: ", ";\n"])), function (t) {
  return t.theme.components.httpBadges.color;
}, function (t) {
  return t.theme.components.httpBadges.fontFamily;
}, function (t) {
  return t.theme.components.httpBadges.borderRadius;
}, function (t) {
  var e = t.type;
  return t.theme.colors.http[e] || "#707070";
}, function (t) {
  return t.theme.components.httpBadges.sizes.medium.fontSize;
}, function (t) {
  return t.theme.components.httpBadges.sizes.medium.lineHeight;
}, function (t) {
  return t.theme.components.httpBadges.fontWeight;
});
var templateObject_1;