import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

import styled from "../../styled-components";
import { transparentize } from "polished";
import { UniversalLink } from "../UniversalLink/UniversalLink";
import { replaceHashInColor, svgToDataUri } from "../../appTheme";
import { mediaCSS } from "@redocly/reference-docs";
export var SearchWrap = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  position: relative;\n  vertical-align: middle;\n\n  @media print {\n    display: none;\n  }\n"])));
export var SearchResultsWrap = styled.ul(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  position: absolute;\n  display: ", ";\n  top: calc(100% + 20px);\n  right: 0;\n  z-index: 100;\n  width: 550px;\n  max-width: 90vw;\n  max-height: 400px;\n  overflow: auto;\n  padding: 0;\n  margin: 0;\n  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),\n    0 8px 10px -5px rgba(0, 0, 0, 0.4);\n  background: ", ";\n  list-style: none;\n  border-radius: ", ";\n\n  @media only screen and (max-width: ", ") {\n    top: 100%;\n    width: 90%;\n    max-width: 90%;\n    left: 5%;\n    margin-bottom: 16px;\n    padding: 8px 0;\n  }\n"])), function (a) {
  return a.show ? "block" : "none";
}, function (a) {
  return a.theme.colors.primary.contrastText;
}, function (a) {
  return a.theme.shape.borderRadius;
}, function (_ref) {
  var a = _ref.theme;
  return a.breakpoints.medium;
});
export var SearchResultItemWrap = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  width: 100%;\n  padding: 8px 24px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  &:hover {\n    background-color: ", ";\n  }\n  background-color: ", ";\n\n  @media only screen and (max-width: ", ") {\n    padding: 8px 16px;\n  }\n\n  font-size: 16px;\n"])), function (_ref2) {
  var a = _ref2.theme;
  return transparentize(.9, a.colors.primary.main);
}, function (a) {
  return a.active && transparentize(.9, a.theme.colors.primary.main);
}, function (_ref3) {
  var a = _ref3.theme;
  return a.breakpoints.medium;
});
export var SearchResultLink = styled(UniversalLink)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  &&& {\n    color: ", ";\n    font-weight: ", ";\n    text-decoration: none;\n    &:hover {\n      color: ", ";\n    }\n  }\n"])), function (_ref4) {
  var a = _ref4.theme;
  return a.colors.text.primary;
}, function (_ref5) {
  var a = _ref5.theme;
  return a.typography.fontWeightBold;
}, function (_ref6) {
  var a = _ref6.theme;
  return a.colors.text.primary;
});
export var SearchItemDescription = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  font-size: 0.875em;\n  line-height: 1.2;\n  font-weight: ", ";\n  margin-top: 8px;\n"])), function (_ref7) {
  var a = _ref7.theme;
  return a.colors.text.secondary;
}, function (_ref8) {
  var a = _ref8.theme;
  return a.typography.fontWeightLight;
});
export var SearchHighlightedText = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  font-weight: bold;\n"])), function (a) {
  return a.theme.colors.primary.main;
});
export var SearchInputWrap = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteralLoose(["\n  display: inline-flex;\n  justify-content: flex-end;\n  align-items: center;\n  flex: 1;\n\n  height: 2em;\n  line-height: 2em;\n\n  margin-left: 5px; // margin to avoid sticking with active navbar items\n\n  @media only screen and (max-width: ", ") {\n    padding: 0;\n    flex: 0 1 auto;\n    width: 90%;\n\n    margin: 32px auto;\n    line-height: 1.2;\n    font-size: 1rem;\n    position: relative;\n    display: block;\n    height: auto;\n  }\n"])), function (_ref9) {
  var a = _ref9.theme;
  return a.breakpoints.medium;
});
var InputSearchIcon = styled.span(_templateObject8 || (_templateObject8 = _taggedTemplateLiteralLoose(["\n  position: absolute;\n  cursor: pointer;\n  width: 1em;\n  height: 1em;\n  right: 1em;\n  transform: translateY(-50%);\n  top: 50%;\n  background-size: contain;\n  background-repeat: no-repeat;\n\n  @media only screen and (max-width: ", ") {\n    width: 1.2em;\n    height: 1.2em;\n  }\n"])), function (_ref10) {
  var a = _ref10.theme;
  return a.breakpoints.medium;
});
export var SearchInputField = styled.input(_templateObject9 || (_templateObject9 = _taggedTemplateLiteralLoose(["\n  max-width: 275px;\n  display: inline-block;\n  border-radius: ", ";\n  flex: 1 1 auto;\n  padding: 1em 2.5em 1em 1em;\n  border: none;\n  background-color: ", ";\n  box-sizing: border-box;\n  outline: none;\n  color: ", ";\n  font-size: ", ";\n\n  ", "\n\n  @media only screen and (max-width: ", ") {\n    display: inline-block;\n    width: 100%;\n    max-width: 100%;\n    margin: 0 auto;\n    padding-right: 2.6em;\n  }\n\n  ::placeholder {\n    color: ", ";\n    font-weight: ", ";\n  }\n"])), function (a) {
  return a.theme.shape.borderRadius;
}, function (_ref11) {
  var a = _ref11.theme;
  return transparentize(.9, a.colors.navbar.contrastText);
}, function (_ref12) {
  var a = _ref12.theme;
  return a.colors.navbar.contrastText;
}, function (_ref13) {
  var a = _ref13.theme;
  return a.navbar.fontSize;
}, function (_ref14) {
  var a = _ref14.theme;
  return mediaCSS({
    fontFamily: a.typography.headings.fontFamily
  });
}, function (_ref15) {
  var a = _ref15.theme;
  return a.breakpoints.medium;
}, function (_ref16) {
  var a = _ref16.theme;
  return a.colors.navbar.contrastText;
}, function (_ref17) {
  var a = _ref17.theme;
  return a.typography.fontWeightRegular;
});
export var SearchIcon = styled(InputSearchIcon)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteralLoose(["\n  background-image: url('", "');\n"])), function (_ref18) {
  var a = _ref18.theme;
  return svgToDataUri(a.components.search.icon, a.components.search.iconColor);
});
export var SearchClearIcon = styled(InputSearchIcon)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteralLoose(["\n  ", "\n"])), function (_ref19) {
  var a = _ref19.theme;
  return "\n  background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0' y='0' viewBox='0 0 298.7 298.7' xml:space='preserve'%3E%3Cpolygon fill='" + replaceHashInColor(a.colors.navbar.contrastText) + "' points='298.7 30.2 268.5 0 149.3 119.1 30.2 0 0 30.2 119.1 149.3 0 268.5 30.2 298.7 149.3 179.5 268.5 298.7 298.7 268.5 179.5 149.3 '/%3E%3C/svg%3E%0A\");\n  width: 0.5em;\n  height: 0.5em;\n  right: 1.2em;\n  @media only screen and (max-width: " + a.breakpoints.medium + ") {\n    width: 0.625em;\n    height: 0.625em;\n  }\n  ";
});
export var SearchLoadingIcon = styled(InputSearchIcon)(_templateObject12 || (_templateObject12 = _taggedTemplateLiteralLoose(["\n  background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' background='none'%3E%3Ccircle cx='50' cy='50' fill='none' stroke='%23fdfdfd' stroke-width='15' r='35' stroke-dasharray='164.93361431346415 56.97787143782138' transform='rotate(257.745 50 50)'%3E%3CanimateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='1s' begin='0s' repeatCount='indefinite'/%3E%3C/circle%3E%3C/svg%3E\");\n"])));