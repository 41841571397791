"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoDescription = void 0;

var utils_1 = require("../utils");

var InfoDescription = function InfoDescription() {
  return {
    Info: function Info(info, ctx) {
      utils_1.validateDefinedAndNonEmpty('description', info, ctx);
    }
  };
};

exports.InfoDescription = InfoDescription;