"use strict";

var _slicedToArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNamedType = exports.normalizeTypes = exports.mapOf = exports.listOf = void 0;

function listOf(typeName) {
  return {
    name: typeName + '_List',
    properties: {},
    items: typeName
  };
}

exports.listOf = listOf;

function mapOf(typeName) {
  return {
    name: typeName + '_Map',
    properties: {},
    additionalProperties: function additionalProperties() {
      return typeName;
    }
  };
}

exports.mapOf = mapOf;

function normalizeTypes(types) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var normalizedTypes = {};

  for (var _i = 0, _Object$keys = Object.keys(types); _i < _Object$keys.length; _i++) {
    var typeName = _Object$keys[_i];
    normalizedTypes[typeName] = Object.assign(Object.assign({}, types[typeName]), {
      name: typeName
    });
  }

  for (var _i2 = 0, _Object$values = Object.values(normalizedTypes); _i2 < _Object$values.length; _i2++) {
    var type = _Object$values[_i2];
    normalizeType(type);
  }

  return normalizedTypes;

  function normalizeType(type) {
    if (type.additionalProperties) {
      type.additionalProperties = resolveType(type.additionalProperties);
    }

    if (type.items) {
      type.items = resolveType(type.items);
    }

    if (type.properties) {
      var mappedProps = {};

      for (var _i3 = 0, _Object$entries = Object.entries(type.properties); _i3 < _Object$entries.length; _i3++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i3], 2),
            propName = _Object$entries$_i[0],
            prop = _Object$entries$_i[1];

        mappedProps[propName] = resolveType(prop);

        if (options.doNotResolveExamples && prop && prop.isExample) {
          mappedProps[propName] = Object.assign(Object.assign({}, prop), {
            resolvable: false
          });
        }
      }

      type.properties = mappedProps;
    }
  } // typings are painful here...


  function resolveType(type) {
    if (typeof type === 'string') {
      if (!normalizedTypes[type]) {
        throw new Error("Unknown type name found: ".concat(type));
      }

      return normalizedTypes[type];
    } else if (typeof type === 'function') {
      return function (value, key) {
        return resolveType(type(value, key));
      };
    } else if (type && type.name) {
      type = Object.assign({}, type);
      normalizeType(type);
      return type;
    } else if (type && type.directResolveAs) {
      return Object.assign(Object.assign({}, type), {
        directResolveAs: resolveType(type.directResolveAs)
      });
    } else {
      return type;
    }
  }
}

exports.normalizeTypes = normalizeTypes;

function isNamedType(t) {
  return typeof (t === null || t === void 0 ? void 0 : t.name) === 'string';
}

exports.isNamedType = isNamedType;