import { __assign, __spreadArray } from "tslib";
import { dirname } from "path";
import URLtemplate from "url-template";
import { IS_BROWSER } from "./dom";
import { isNumeric, removeQueryString, resolveUrl } from "./helpers";

function isWildcardStatusCode(e) {
  return "string" == typeof e && /\dxx/i.test(e);
}

export function isStatusCode(e) {
  return "default" === e || isNumeric(e) || isWildcardStatusCode(e);
}
export function getStatusCodeType(e, r) {
  if (void 0 === r && (r = !1), "default" === e) return r ? "error" : "success";
  var t = "string" == typeof e ? parseInt(e, 10) : e;
  if (isWildcardStatusCode(e) && (t *= 100), t < 100 || t > 599) throw new Error("invalid HTTP code");
  var i = "success";
  return t >= 300 && t < 400 ? i = "redirect" : t >= 400 ? i = "error" : t < 200 && (i = "info"), i;
}
var operationNames = {
  get: !0,
  post: !0,
  put: !0,
  head: !0,
  patch: !0,
  delete: !0,
  options: !0,
  $ref: !0
};
export function isOperationName(e) {
  return e in operationNames;
}
export function getOperationSummary(e) {
  return e.summary || e.operationId || e.description && e.description.substring(0, 50) || "<no summary>";
}
var schemaKeywordTypes = {
  multipleOf: "number",
  maximum: "number",
  exclusiveMaximum: "number",
  minimum: "number",
  exclusiveMinimum: "number",
  maxLength: "string",
  minLength: "string",
  pattern: "string",
  contentEncoding: "string",
  contentMediaType: "string",
  items: "array",
  maxItems: "array",
  minItems: "array",
  uniqueItems: "array",
  maxProperties: "object",
  minProperties: "object",
  required: "object",
  additionalProperties: "object",
  properties: "object"
};
export function detectType(e) {
  if (void 0 !== e.type && !Array.isArray(e.type)) return e.type;

  for (var r = 0, t = Object.keys(schemaKeywordTypes); r < t.length; r++) {
    var i = t[r],
        n = schemaKeywordTypes[i];
    if (void 0 !== e[i]) return n;
  }

  return "any";
}
export function isPrimitiveType(e, r) {
  if (void 0 === r && (r = e.type), void 0 !== e.oneOf || void 0 !== e.anyOf) return !1;
  var t = !0,
      i = Array.isArray(r);
  return ("object" === r || i && (null == r ? void 0 : r.includes("object"))) && (t = void 0 !== e.properties ? 0 === Object.keys(e.properties).length : void 0 === e.additionalProperties), void 0 !== e.items && ("array" === r || i && (null == r ? void 0 : r.includes("array"))) && (t = isPrimitiveType(e.items, e.items.type)), t;
}
export function isJsonLike(e) {
  return -1 !== e.search(/json/i);
}
export function isFormUrlEncoded(e) {
  return "application/x-www-form-urlencoded" === e;
}

function delimitedEncodeField(e, r, t) {
  return Array.isArray(e) ? e.map(function (e) {
    return e.toString();
  }).join(t) : "object" == typeof e ? Object.keys(e).map(function (r) {
    return "" + r + t + e[r];
  }).join(t) : r + "=" + e.toString();
}

function deepObjectEncodeField(e, r) {
  return Array.isArray(e) ? (console.warn("deepObject style cannot be used with array value:" + e.toString()), "") : "object" == typeof e ? Object.keys(e).map(function (t) {
    return e[t] ? r + "[" + t + "]=" + e[t] : void 0;
  }).filter(Boolean).join("&") : (console.warn("deepObject style cannot be used with non-object value:" + e.toString()), "");
}

function serializeFormValue(e, r, t) {
  var i,
      n = "__redoc_param_name__",
      a = r ? "*" : "";
  return URLtemplate.parse("{?" + n + a + "}").expand((i = {}, i[n] = t, i)).substring(1).replace(/__redoc_param_name__/g, e);
}

export function urlFormEncodePayload(e, r) {
  if (void 0 === r && (r = {}), Array.isArray(e)) throw new Error("Payload must have fields: " + e.toString());
  return Object.keys(e).map(function (t) {
    var i = e[t],
        n = r[t] || {},
        a = n.style,
        o = void 0 === a ? "form" : a,
        s = n.explode,
        u = void 0 === s || s;

    switch (o) {
      case "form":
        return serializeFormValue(t, u, i);

      case "spaceDelimited":
        return delimitedEncodeField(i, t, "%20");

      case "pipeDelimited":
        return delimitedEncodeField(i, t, "|");

      case "deepObject":
        return deepObjectEncodeField(i, t);

      default:
        return console.warn("Incorrect or unsupported encoding style: " + o), "";
    }
  }).join("&");
}

function serializePathParameter(e, r, t, i) {
  var n,
      a = t ? "*" : "",
      o = "";
  "label" === r ? o = "." : "matrix" === r && (o = ";");
  var s = "__redoc_param_name__";
  return URLtemplate.parse("{" + o + s + a + "}").expand((n = {}, n[s] = i, n)).replace(/__redoc_param_name__/g, e);
}

export function serializeQueryParameter(e, r, t, i) {
  switch (r) {
    case "form":
      return serializeFormValue(e, t, i);

    case "spaceDelimited":
      return Array.isArray(i) ? t ? serializeFormValue(e, t, i) : e + "=" + i.join("%20") : (console.warn("The style spaceDelimited is only applicable to arrays"), "");

    case "pipeDelimited":
      return Array.isArray(i) ? t ? serializeFormValue(e, t, i) : e + "=" + i.join("|") : (console.warn("The style pipeDelimited is only applicable to arrays"), "");

    case "deepObject":
      return !t || Array.isArray(i) || "object" != typeof i ? (console.warn("The style deepObject is only applicable for objects with explode=true"), "") : deepObjectEncodeField(i, e);

    default:
      return console.warn("Unexpected style for query: " + r), "";
  }
}

function serializeHeaderParameter(e, r, t) {
  var i;

  switch (e) {
    case "simple":
      var n = r ? "*" : "",
          a = "__redoc_param_name__",
          o = URLtemplate.parse("{" + a + n + "}");
      return decodeURIComponent(o.expand(((i = {})[a] = t, i)));

    default:
      return console.warn("Unexpected style for header: " + e), "";
  }
}

function serializeCookieParameter(e, r, t, i) {
  switch (r) {
    case "form":
      return serializeFormValue(e, t, i);

    default:
      return console.warn("Unexpected style for cookie: " + r), "";
  }
}

export function serializeParameterValueWithMime(e, r) {
  return isJsonLike(r) ? JSON.stringify(e) : (console.warn("Parameter serialization as " + r + " is not supported"), "");
}
export function serializeParameterValue(e, r) {
  var t = e.name,
      i = e.style,
      n = e.explode,
      a = void 0 !== n && n,
      o = e.serializationMime;
  if (o) switch (e.in) {
    case "path":
    case "header":
      return serializeParameterValueWithMime(r, o);

    case "cookie":
    case "query":
      return t + "=" + serializeParameterValueWithMime(r, o);

    default:
      return console.warn("Unexpected parameter location: " + e.in), "";
  }
  if (!i) return console.warn("Missing style attribute or content for parameter " + t), "";

  switch (e.in) {
    case "path":
      return serializePathParameter(t, i, a, r);

    case "query":
      return serializeQueryParameter(t, i, a, r);

    case "header":
      return serializeHeaderParameter(i, a, r);

    case "cookie":
      return serializeCookieParameter(t, i, a, r);

    default:
      return console.warn("Unexpected parameter location: " + e.in), "";
  }
}
export function langFromMime(e) {
  return -1 !== e.search(/xml/i) ? "xml" : "clike";
}
export function isNamedDefinition(e) {
  return /^#\/components\/(schemas|pathItems)\/[^/]+$/.test(e || "");
}
export function getDefinitionName(e) {
  if (e) {
    var r = e.match(/^#\/components\/(schemas|pathItems)\/([^/]+)$/);
    return null === r ? void 0 : r[1];
  }
}

function humanizeMultipleOfConstraint(e) {
  if (void 0 !== e) {
    var r = e.toString(10);
    return /^0\.0*1$/.test(r) ? "decimal places <= " + r.split(".")[1].length : "multiple of " + r;
  }
}

function humanizeRangeConstraint(e, r, t) {
  var i;
  return void 0 !== r && void 0 !== t ? i = r === t ? r + " " + e : "[ " + r + " .. " + t + " ] " + e : void 0 !== t ? i = "<= " + t + " " + e : void 0 !== r && (i = 1 === r ? "non-empty" : ">= " + r + " " + e), i;
}

export function humanizeConstraints(e) {
  var r = [],
      t = humanizeRangeConstraint("characters", e.minLength, e.maxLength);
  void 0 !== t && r.push(t);
  var i = humanizeRangeConstraint("items", e.minItems, e.maxItems);
  void 0 !== i && r.push(i);
  var n,
      a = humanizeMultipleOfConstraint(e.multipleOf);

  if (void 0 !== a && r.push(a), void 0 !== e.minimum && void 0 !== e.maximum ? (n = e.exclusiveMinimum ? "( " : "[ ", n += e.minimum, n += " .. ", n += e.maximum, n += e.exclusiveMaximum ? " )" : " ]") : void 0 !== e.maximum ? (n = e.exclusiveMaximum ? "< " : "<= ", n += e.maximum) : void 0 !== e.minimum && (n = e.exclusiveMinimum ? "> " : ">= ", n += e.minimum), "number" == typeof e.exclusiveMinimum || "number" == typeof e.exclusiveMaximum) {
    var o = 0,
        s = 0;
    e.minimum && (o = e.minimum), "number" == typeof e.exclusiveMinimum && (o = o <= e.exclusiveMinimum ? o : e.exclusiveMinimum), e.maximum && (s = e.maximum), "number" == typeof e.exclusiveMaximum && (s = s > e.exclusiveMaximum ? s : e.exclusiveMaximum), n = "[" + o + " .. " + s + "]";
  }

  return void 0 !== n && r.push(n), e.uniqueItems && r.push("unique"), r;
}
export function sortByRequired(e, r) {
  void 0 === r && (r = []);
  var t = [],
      i = [],
      n = [];
  return e.forEach(function (e) {
    e.required ? r.includes(e.name) ? i.push(e) : n.push(e) : t.push(e);
  }), i.sort(function (e, t) {
    return r.indexOf(e.name) - r.indexOf(t.name);
  }), __spreadArray(__spreadArray(__spreadArray([], i, !0), n, !0), t, !0);
}
export function sortByField(e, r) {
  return __spreadArray([], e, !0).sort(function (e, t) {
    return e[r].localeCompare(t[r]);
  });
}
export function mergeParams(e, r, t) {
  void 0 === r && (r = []), void 0 === t && (t = []);
  var i = {};
  return t.forEach(function (r) {
    r = e.shallowDeref(r), i[r.name + "_" + r.in] = !0;
  }), (r = r.filter(function (r) {
    return r = e.shallowDeref(r), !i[r.name + "_" + r.in];
  })).concat(t);
}
export function mergeSimilarMediaTypes(e) {
  var r = {};
  return Object.keys(e).forEach(function (t) {
    var i = e[t],
        n = t.split(";")[0].trim();
    r[n] ? r[n] = __assign(__assign({}, r[n]), i) : r[n] = i;
  }), r;
}
export function expandDefaultServerVariables(e, r) {
  return void 0 === r && (r = {}), e.replace(/(?:{)([\w-.]+)(?:})/g, function (e, t) {
    return r[t] && r[t].default || e;
  });
}
export function normalizeServers(e, r) {
  var t = void 0 === e ? removeQueryString(function () {
    if (!IS_BROWSER) return "";
    var e = window.location.href;
    return e.endsWith(".html") ? dirname(e) : e;
  }()) : dirname(e);
  return 0 === r.length && (r = [{
    url: "/"
  }]), r.map(function (e) {
    return __assign(__assign({}, e), {
      url: (r = e.url, resolveUrl(t, r)),
      description: e.description || ""
    });
    var r;
  });
}
export var SECURITY_DEFINITIONS_COMPONENT_NAME = "security-definitions";
export var SECURITY_DEFINITIONS_JSX_NAME = "SecurityDefinitions";
export var SCHEMA_DEFINITION_JSX_NAME = "SchemaDefinition";
export var SECURITY_SCHEMES_SECTION_PREFIX = "section/Authentication/";
export function setSecuritySchemePrefix(e) {
  SECURITY_SCHEMES_SECTION_PREFIX = e;
}
export var shortenHTTPVerb = function shortenHTTPVerb(e) {
  return {
    delete: "del",
    options: "opts"
  }[e] || e;
};
export function isRedocExtension(e) {
  return e in {
    "x-circular-ref": !0,
    "x-code-samples": !0,
    "x-codeSamples": !0,
    "x-displayName": !0,
    "x-examples": !0,
    "x-ignoredHeaderParameters": !0,
    "x-logo": !0,
    "x-nullable": !0,
    "x-servers": !0,
    "x-tagGroups": !0,
    "x-traitTag": !0,
    "x-additionalPropertiesName": !0,
    "x-explicitMappingOnly": !0,
    "x-enumDescriptions": !0
  };
}
export function extractExtensions(e, r) {
  return Object.keys(e).filter(function (e) {
    return !0 === r ? e.startsWith("x-") && !isRedocExtension(e) : e.startsWith("x-") && r.indexOf(e) > -1;
  }).reduce(function (r, t) {
    return r[t] = e[t], r;
  }, {});
}
export function pluralizeType(e) {
  return e.split(" or ").map(function (e) {
    return e.replace(/^(string|object|number|integer|array|boolean)s?( ?.*)/, "$1s$2");
  }).join(" or ");
}
export function getContentWithLegacyExamples(e) {
  var r = e.content,
      t = e["x-examples"],
      i = e["x-example"];

  if (t) {
    r = __assign({}, r);

    for (var n = 0, a = Object.keys(t); n < a.length; n++) {
      var o = t[m = a[n]];
      r[m] = __assign(__assign({}, r[m]), {
        examples: o
      });
    }
  } else if (i) {
    r = __assign({}, r);

    for (var s = 0, u = Object.keys(i); s < u.length; s++) {
      var m,
          c = i[m = u[s]];
      r[m] = __assign(__assign({}, r[m]), {
        example: c
      });
    }
  }

  return r;
}