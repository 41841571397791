"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var equal_1 = require("../../runtime/equal");

var error = {
  message: "must be equal to one of the allowed values",
  params: function params(_ref) {
    var schemaCode = _ref.schemaCode;
    return codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["{allowedValues: ", "}"])), schemaCode);
  }
};
var def = {
  keyword: "enum",
  schemaType: "array",
  $data: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        data = cxt.data,
        $data = cxt.$data,
        schema = cxt.schema,
        schemaCode = cxt.schemaCode,
        it = cxt.it;
    if (!$data && schema.length === 0) throw new Error("enum must have non-empty array");
    var useLoop = schema.length >= it.opts.loopEnum;
    var eql = util_1.useFunc(gen, equal_1.default);
    var valid;

    if (useLoop || $data) {
      valid = gen.let("valid");
      cxt.block$data(valid, loopEnum);
    } else {
      /* istanbul ignore if */
      if (!Array.isArray(schema)) throw new Error("ajv implementation error");
      var vSchema = gen.const("vSchema", schemaCode);
      valid = codegen_1.or.apply(codegen_1, _toConsumableArray(schema.map(function (_x, i) {
        return equalCode(vSchema, i);
      })));
    }

    cxt.pass(valid);

    function loopEnum() {
      gen.assign(valid, false);
      gen.forOf("v", schemaCode, function (v) {
        return gen.if(codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", "(", ", ", ")"])), eql, data, v), function () {
          return gen.assign(valid, true).break();
        });
      });
    }

    function equalCode(vSchema, i) {
      var sch = schema[i];
      return typeof sch === "object" && sch !== null ? codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", "(", ", ", "[", "])"])), eql, data, vSchema, i) : codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", " === ", ""])), data, sch);
    }
  }
};
exports.default = def;