"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationSummary = void 0;

var utils_1 = require("../utils");

var OperationSummary = function OperationSummary() {
  return {
    Operation: function Operation(operation, ctx) {
      utils_1.validateDefinedAndNonEmpty('summary', operation, ctx);
    }
  };
};

exports.OperationSummary = OperationSummary;