import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
import { ShelfIcon } from "../../redoc-lib/src/common-elements/icons";
import { Panel } from "./Panel";
import { Header } from "./Header";
import { Title } from "./Title";
import { Trigger } from "./Trigger";
export var CallbackPanel = styled(Panel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n\n  > ", " {\n    padding: 10px 20px;\n    font-size: 14px;\n    line-height: 20px;\n\n    ", " {\n      font-weight: 600;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      color: ", ";\n    }\n\n    ", " {\n      flex: 1;\n      max-width: 100%;\n      justify-content: space-between;\n    }\n\n    ", " {\n      fill: ", ";\n    }\n  }\n"], ["\n  background: ", ";\n\n  > ", " {\n    padding: 10px 20px;\n    font-size: 14px;\n    line-height: 20px;\n\n    ", " {\n      font-weight: 600;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      color: ", ";\n    }\n\n    ", " {\n      flex: 1;\n      max-width: 100%;\n      justify-content: space-between;\n    }\n\n    ", " {\n      fill: ", ";\n    }\n  }\n"])), function (n) {
  return n.theme.colors.secondary.light;
}, Header, Title, function (n) {
  return n.theme.colors.text.primary;
}, Trigger, ShelfIcon, function (n) {
  return n.theme.colors.text.primary;
});
var templateObject_1;