import { __decorate } from "tslib";
import { action, computed } from "mobx";
import { MediaTypeModel } from "./MediaType";
import { mergeSimilarMediaTypes } from "../../utils";

var MediaContentModel = function () {
  function e(e, t, i, r, o) {
    var a = void 0 === o ? {} : o,
        n = a.operation,
        p = a.type,
        l = a.response;
    Object.defineProperty(this, "isRequestType", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: i
    }), Object.defineProperty(this, "mediaTypes", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "operation", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.operation = n, r.unstable_ignoreMimeParameters && (t = mergeSimilarMediaTypes(t)), this.mediaTypes = Object.keys(t).map(function (o) {
      var a = t[o];
      return e.resetVisited(), new MediaTypeModel(e, o, i, a, r, {
        operation: n,
        type: p,
        response: l
      });
    });
  }

  return Object.defineProperty(e.prototype, "activate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t;
      null === (t = this.operation) || void 0 === t || t.activateMimeName(e);
    }
  }), Object.defineProperty(e.prototype, "active", {
    get: function get() {
      var e = this;
      return this.mediaTypes.find(function (t) {
        var i;
        return t.name === (null === (i = e.operation) || void 0 === i ? void 0 : i.activeMimeName);
      }) || this.mediaTypes[0];
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "hasSample", {
    get: function get() {
      return this.mediaTypes.filter(function (e) {
        return !!e.examples;
      }).length > 0;
    },
    enumerable: !1,
    configurable: !0
  }), __decorate([action], e.prototype, "activate", null), __decorate([computed], e.prototype, "active", null), e;
}();

export { MediaContentModel };