"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateJsonSchema = exports.releaseAjvInstance = void 0;

var ajv_1 = require("@redocly/ajv");

var ref_utils_1 = require("../ref-utils");

var ajvInstance = null;

function releaseAjvInstance() {
  ajvInstance = null;
}

exports.releaseAjvInstance = releaseAjvInstance;

function getAjv(resolve, disallowAdditionalProperties) {
  if (!ajvInstance) {
    ajvInstance = new ajv_1.default({
      schemaId: '$id',
      meta: true,
      allErrors: true,
      strictSchema: false,
      inlineRefs: false,
      validateSchema: false,
      discriminator: true,
      allowUnionTypes: true,
      validateFormats: false,
      defaultAdditionalProperties: !disallowAdditionalProperties,
      loadSchemaSync: function loadSchemaSync(base, $ref) {
        var resolvedRef = resolve({
          $ref: $ref
        }, base.split('#')[0]);
        if (!resolvedRef || !resolvedRef.location) return undefined;
        return Object.assign({
          $id: resolvedRef.location.absolutePointer
        }, resolvedRef.node);
      },
      logger: false
    });
  }

  return ajvInstance;
}

function getAjvValidator(schema, loc, resolve, disallowAdditionalProperties) {
  var ajv = getAjv(resolve, disallowAdditionalProperties);

  if (!ajv.getSchema(loc.absolutePointer)) {
    ajv.addSchema(Object.assign({
      $id: loc.absolutePointer
    }, schema), loc.absolutePointer);
  }

  return ajv.getSchema(loc.absolutePointer);
}

function validateJsonSchema(data, schema, schemaLoc, instancePath, resolve, disallowAdditionalProperties) {
  var validate = getAjvValidator(schema, schemaLoc, resolve, disallowAdditionalProperties);
  if (!validate) return {
    valid: true,
    errors: []
  }; // unresolved refs are reported

  var valid = validate(data, {
    instancePath: instancePath,
    parentData: {
      fake: {}
    },
    parentDataProperty: 'fake',
    rootData: {},
    dynamicAnchors: {}
  });
  return {
    valid: !!valid,
    errors: (validate.errors || []).map(beatifyErrorMessage)
  };

  function beatifyErrorMessage(error) {
    var message = error.message;
    var suggest = error.keyword === 'enum' ? error.params.allowedValues : undefined;

    if (suggest) {
      message += " ".concat(suggest.map(function (e) {
        return "\"".concat(e, "\"");
      }).join(', '));
    }

    if (error.keyword === 'type') {
      message = "type ".concat(message);
    }

    var relativePath = error.instancePath.substring(instancePath.length + 1);
    var propName = relativePath.substring(relativePath.lastIndexOf('/') + 1);

    if (propName) {
      message = "`".concat(propName, "` property ").concat(message);
    }

    if (error.keyword === 'additionalProperties') {
      var property = error.params.additionalProperty;
      message = "".concat(message, " `").concat(property, "`");
      error.instancePath += '/' + ref_utils_1.escapePointer(property);
    }

    return Object.assign(Object.assign({}, error), {
      message: message,
      suggest: suggest
    });
  }
}

exports.validateJsonSchema = validateJsonSchema;