import { __assign, __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { ClickablePropertyNameCell, RequiredLabel } from "../../common-elements/fields";
import { FieldDetails } from "./FieldDetails";
import { InnerPropertiesWrap, PropertyCellWithInner, PropertyDetailsCell, PropertyNameCell } from "../../common-elements/fields-layout";
import { ShelfIcon } from "../../common-elements/";
import { ShareLinkToField } from "../../common-elements/LinkToField";
import { Schema } from "../Schema/Schema";
import { OptionsContext } from "../OptionsProvider";

var Field = function (e) {
  function t() {
    var t = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(t, "toggle", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        void 0 === t.props.field.expanded && t.props.expandByDefault ? t.props.field.collapse() : t.props.field.toggle();
      }
    }), Object.defineProperty(t, "handleKeyPress", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        "Enter" === e.key && (e.preventDefault(), t.toggle());
      }
    }), t;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.className,
          r = void 0 === t ? "" : t,
          l = e.field,
          a = e.isLast,
          n = e.expandByDefault,
          i = l.name,
          o = l.deprecated,
          c = l.required,
          s = l.kind,
          m = !l.schema.isPrimitive && !l.schema.isCircular,
          p = void 0 === l.expanded ? n : l.expanded,
          d = m ? React.createElement(ClickablePropertyNameCell, {
        className: o ? "deprecated" : "",
        kind: s,
        title: i
      }, React.createElement("button", {
        onClick: this.toggle,
        onKeyPress: this.handleKeyPress,
        "aria-label": "expand properties"
      }, React.createElement("span", {
        className: "field-name"
      }, this.context.disableDeepLinks ? null : React.createElement(ShareLinkToField, {
        field: l
      }), i), React.createElement(ShelfIcon, {
        direction: p ? "down" : "right"
      })), c && React.createElement(RequiredLabel, null, " required ")) : React.createElement(PropertyNameCell, {
        className: o ? "deprecated" : void 0,
        kind: s,
        title: i
      }, React.createElement("span", {
        className: "field-name"
      }, this.context.disableDeepLinks ? null : React.createElement(ShareLinkToField, {
        field: l
      }), i), c && React.createElement(RequiredLabel, null, " required "));
      return React.createElement(React.Fragment, null, React.createElement("tr", {
        className: a ? "last " + r : r
      }, d, React.createElement(PropertyDetailsCell, null, React.createElement(FieldDetails, __assign({}, this.props)))), p && m && React.createElement("tr", {
        key: l.name + "inner"
      }, React.createElement(PropertyCellWithInner, {
        colSpan: 2
      }, React.createElement(InnerPropertiesWrap, null, React.createElement(Schema, {
        schema: l.schema,
        skipReadOnly: this.props.skipReadOnly,
        skipWriteOnly: this.props.skipWriteOnly,
        showTitle: this.props.showTitle,
        level: this.props.level
      })))));
    }
  }), Object.defineProperty(t, "contextType", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: OptionsContext
  }), t = __decorate([observer], t);
}(React.Component);

export { Field };