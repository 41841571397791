import * as React from "react";
import { StyledPre } from "../../common-elements/samples";
import { ExampleValue } from "./ExampleValue";
import { useExternalExample } from "./exernalExampleHook";
export function Example(e) {
  var a = e.example,
      l = e.mimeType;
  return void 0 === a.value && a.externalValueUrl ? React.createElement(ExternalExample, {
    example: a,
    mimeType: l
  }) : React.createElement(ExampleValue, {
    value: a.value,
    mimeType: l
  });
}
export function ExternalExample(e) {
  var a = e.example,
      l = e.mimeType,
      r = useExternalExample(a, l);
  return void 0 === r ? React.createElement("span", null, "Loading...") : r instanceof Error ? React.createElement(StyledPre, null, "Error loading external example: ", React.createElement("br", null), React.createElement("a", {
    className: "token string",
    href: a.externalValueUrl,
    target: "_blank",
    rel: "noopener noreferrer"
  }, a.externalValueUrl)) : React.createElement(ExampleValue, {
    value: r,
    mimeType: l
  });
}