import { camelCase, lowerCase, snakeCase, startCase } from "./lodash-int";

var StringUtility = function () {
  function e() {}

  return Object.defineProperty(e, "toCamelCase", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return camelCase(e);
    }
  }), Object.defineProperty(e, "toTitleCase", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return startCase(camelCase(e));
    }
  }), Object.defineProperty(e, "toPascalCase", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return startCase(camelCase(e)).replace(/ /g, "");
    }
  }), Object.defineProperty(e, "toLowerCase", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return lowerCase(e).replace(/ /g, "");
    }
  }), Object.defineProperty(e, "toSnakeCase", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return snakeCase(e);
    }
  }), e;
}();

export default StringUtility;