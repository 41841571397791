import { __assign, __decorate } from "tslib";
import { action, observable, makeObservable } from "mobx";
import { isOperationName, JsonPointer } from "../../utils";
import { OperationModel } from "./Operation";

var CallbackModel = function () {
  function e(e, r, t, a, o) {
    Object.defineProperty(this, "expanded", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "operations", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    }), makeObservable(this), this.name = r;
    var i = e.deref(t);
    e.exitRef(t);

    for (var n = 0, l = Object.keys(i); n < l.length; n++) {
      for (var p = l[n], s = i[p], b = 0, d = Object.keys(s).filter(isOperationName); b < d.length; b++) {
        var m = d[b],
            c = s[m],
            f = new OperationModel(e, __assign(__assign({}, c), {
          pathName: p,
          pointer: JsonPointer.compile([a, r, p, m]),
          httpVerb: m,
          pathParameters: s.parameters || [],
          pathServers: s.servers
        }), void 0, o, !0);
        this.operations.push(f);
      }
    }
  }

  return Object.defineProperty(e.prototype, "toggle", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.expanded = !this.expanded;
    }
  }), __decorate([observable], e.prototype, "expanded", void 0), __decorate([action], e.prototype, "toggle", null), e;
}();

export { CallbackModel };