"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidContentExamples = void 0;

var ajv_1 = require("../ajv");

var ref_utils_1 = require("../../ref-utils");

var ValidContentExamples = function ValidContentExamples(opts) {
  var _a;

  var disallowAdditionalProperties = (_a = opts.disallowAdditionalProperties) !== null && _a !== void 0 ? _a : true;
  return {
    MediaType: {
      leave: function leave(mediaType, _ref) {
        var report = _ref.report,
            location = _ref.location,
            resolve = _ref.resolve;
        if (!mediaType.schema) return;

        if (mediaType.example) {
          validateExample(mediaType.example, location.child('example'));
        } else if (mediaType.examples) {
          for (var _i = 0, _Object$keys = Object.keys(mediaType.examples); _i < _Object$keys.length; _i++) {
            var exampleName = _Object$keys[_i];
            var example = mediaType.examples[exampleName];
            var dataLoc = location.child(['examples', exampleName, 'value']);

            if (ref_utils_1.isRef(example)) {
              var resolved = resolve(example);
              if (!resolved.location) continue;
              dataLoc = resolved.location.child('value');
              example = resolved.node;
            }

            validateExample(example.value, dataLoc);
          }
        }

        function validateExample(example, dataLoc) {
          try {
            var _ajv_1$validateJsonSc = ajv_1.validateJsonSchema(example, mediaType.schema, location.child('schema'), dataLoc.pointer, resolve, disallowAdditionalProperties),
                valid = _ajv_1$validateJsonSc.valid,
                errors = _ajv_1$validateJsonSc.errors;

            if (!valid) {
              var _iterator = _createForOfIteratorHelper(errors),
                  _step;

              try {
                for (_iterator.s(); !(_step = _iterator.n()).done;) {
                  var error = _step.value;
                  report({
                    message: "Example value must conform to the schema: ".concat(error.message, "."),
                    location: Object.assign(Object.assign({}, new ref_utils_1.Location(dataLoc.source, error.instancePath)), {
                      reportOnKey: error.keyword === 'additionalProperties'
                    }),
                    from: location,
                    suggest: error.suggest
                  });
                }
              } catch (err) {
                _iterator.e(err);
              } finally {
                _iterator.f();
              }
            }
          } catch (e) {
            report({
              message: "Example validation errored: ".concat(e.message, "."),
              location: location.child('schema'),
              from: location
            });
          }
        }
      }
    }
  };
};

exports.ValidContentExamples = ValidContentExamples;