import { __makeTemplateObject } from "tslib";
import * as React from "react";
import styled, { keyframes } from "../../styled-components";

var templateObject_1,
    templateObject_2,
    _Spinner = function _Spinner(e) {
  return React.createElement("svg", {
    className: e.className,
    version: "1.1",
    width: "512",
    height: "512",
    viewBox: "0 0 512 512"
  }, React.createElement("path", {
    d: "M275.682 147.999c0 10.864-8.837 19.661-19.682 19.661v0c-10.875 0-19.681-8.796-19.681-19.661v-96.635c0-10.885 8.806-19.661 19.681-19.661v0c10.844 0 19.682 8.776 19.682 19.661v96.635z"
  }), React.createElement("path", {
    d: "M275.682 460.615c0 10.865-8.837 19.682-19.682 19.682v0c-10.875 0-19.681-8.817-19.681-19.682v-96.604c0-10.885 8.806-19.681 19.681-19.681v0c10.844 0 19.682 8.796 19.682 19.682v96.604z"
  }), React.createElement("path", {
    d: "M147.978 236.339c10.885 0 19.681 8.755 19.681 19.641v0c0 10.885-8.796 19.702-19.681 19.702h-96.624c-10.864 0-19.661-8.817-19.661-19.702v0c0-10.885 8.796-19.641 19.661-19.641h96.624z"
  }), React.createElement("path", {
    d: "M460.615 236.339c10.865 0 19.682 8.755 19.682 19.641v0c0 10.885-8.817 19.702-19.682 19.702h-96.584c-10.885 0-19.722-8.817-19.722-19.702v0c0-10.885 8.837-19.641 19.722-19.641h96.584z"
  }), React.createElement("path", {
    d: "M193.546 165.703c7.69 7.66 7.68 20.142 0 27.822v0c-7.701 7.701-20.162 7.701-27.853 0.020l-68.311-68.322c-7.68-7.701-7.68-20.142 0-27.863v0c7.68-7.68 20.121-7.68 27.822 0l68.342 68.342z"
  }), React.createElement("path", {
    d: "M414.597 386.775c7.7 7.68 7.7 20.163 0.021 27.863v0c-7.7 7.659-20.142 7.659-27.843-0.062l-68.311-68.26c-7.68-7.7-7.68-20.204 0-27.863v0c7.68-7.7 20.163-7.7 27.842 0l68.291 68.322z"
  }), React.createElement("path", {
    d: "M165.694 318.464c7.69-7.7 20.153-7.7 27.853 0v0c7.68 7.659 7.69 20.163 0 27.863l-68.342 68.322c-7.67 7.659-20.142 7.659-27.822-0.062v0c-7.68-7.68-7.68-20.122 0-27.801l68.311-68.322z"
  }), React.createElement("path", {
    d: "M386.775 97.362c7.7-7.68 20.142-7.68 27.822 0v0c7.7 7.68 7.7 20.183 0.021 27.863l-68.322 68.311c-7.68 7.68-20.163 7.68-27.843-0.020v0c-7.68-7.68-7.68-20.162 0-27.822l68.322-68.332z"
  }));
},
    rotate = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg);\n  }\n"], ["\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg);\n  }\n"])));

export var Spinner = styled(_Spinner)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: 2s ", " linear infinite;\n  width: 50px;\n  height: 50px;\n  content: '';\n  display: inline-block;\n  margin-left: -25px;\n\n  path {\n    fill: ", ";\n  }\n"], ["\n  animation: 2s ", " linear infinite;\n  width: 50px;\n  height: 50px;\n  content: '';\n  display: inline-block;\n  margin-left: -25px;\n\n  path {\n    fill: ", ";\n  }\n"])), rotate, function (e) {
  return e.color;
});