import { __extends } from "tslib";
import * as React from "react";
import { ClipboardService } from "../services/ClipboardService";
import { SamplesControlButton } from "./samples";
import { Tooltip } from "./Tooltip";

var CopyButtonWrapper = function (t) {
  function e(e) {
    var o = t.call(this, e) || this;
    return Object.defineProperty(o, "copy", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        var t = "string" == typeof o.props.data ? o.props.data : JSON.stringify(o.props.data, null, 2);
        ClipboardService.copyCustom(t), o.showTooltip();
      }
    }), Object.defineProperty(o, "renderCopyButton", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        return React.createElement(Tooltip, {
          variant: "light",
          placement: "top",
          tip: ClipboardService.isSupported() ? "Copied" : "Not supported in your browser",
          open: o.state.tooltipShown
        }, React.createElement(SamplesControlButton, {
          onClick: o.copy
        }, "Copy"));
      }
    }), o.state = {
      tooltipShown: !1
    }, o;
  }

  return __extends(e, t), Object.defineProperty(e.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return this.props.children({
        renderCopyButton: this.renderCopyButton
      });
    }
  }), Object.defineProperty(e.prototype, "showTooltip", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var t = this;
      this.setState({
        tooltipShown: !0
      }), setTimeout(function () {
        t.setState({
          tooltipShown: !1
        });
      }, 1500);
    }
  }), e;
}(React.PureComponent);

export { CopyButtonWrapper };