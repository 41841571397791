import { __makeTemplateObject } from "tslib";
import { lighten } from "polished";
import styled, { mediaCSS } from "../styled-components";
import { PrismDiv } from "./PrismDiv";
export var SamplesControlButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  outline: 0;\n  border-radius: ", ";\n  height: 20px;\n  color: ", ";\n  padding: 2px 20px;\n  font-size: 12px;\n  line-height: 12px;\n  cursor: pointer;\n  min-width: 90px;\n\n  :hover,\n  :focus {\n    background: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border: 0;\n  outline: 0;\n  border-radius: ", ";\n  height: 20px;\n  color: ", ";\n  padding: 2px 20px;\n  font-size: 12px;\n  line-height: 12px;\n  cursor: pointer;\n  min-width: 90px;\n\n  :hover,\n  :focus {\n    background: ", ";\n  }\n"])), function (n) {
  return n.theme.rightPanel.panelControlsBackgroundColor;
}, function (n) {
  return n.theme.shape.borderRadius;
}, function (n) {
  return n.theme.colors.text.light;
}, function (n) {
  var t = n.theme;
  return lighten(.05, t.rightPanel.panelControlsBackgroundColor);
});
export var SampleControls = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px 0;\n  opacity: 0.7;\n  transition: opacity 0.3s ease;\n  text-align: right;\n\n  &:focus-within {\n    opacity: 1;\n  }\n\n  > div,\n  > ", " {\n    /* can have tooltip wrapper div also */\n    margin-right: 10px;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n"], ["\n  padding: 10px 0;\n  opacity: 0.7;\n  transition: opacity 0.3s ease;\n  text-align: right;\n\n  &:focus-within {\n    opacity: 1;\n  }\n\n  > div,\n  > ", " {\n    /* can have tooltip wrapper div also */\n    margin-right: 10px;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n"])), SamplesControlButton);
export var SampleControlsWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &:hover ", " {\n    opacity: 1;\n  }\n"], ["\n  &:hover ", " {\n    opacity: 1;\n  }\n"])), SampleControls);
export var StyledPre = styled(PrismDiv.withComponent("pre"))(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow-x: auto;\n  margin: 0;\n  font-family: ", ";\n  padding: 20px;\n  ", ";\n"], ["\n  overflow-x: auto;\n  margin: 0;\n  font-family: ", ";\n  padding: 20px;\n  ", ";\n"])), function (n) {
  return n.theme.typography.code.fontFamily;
}, function (n) {
  var t = n.theme;
  return mediaCSS({
    borderRadius: t.shape.borderRadius,
    backgroundColor: t.rightPanel.panelControlsBackgroundColor,
    color: t.colors.text.light,
    fontSize: t.typography.code.fontSize,
    whiteSpace: t.typography.code.wrap ? "pre-wrap" : "pre"
  });
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;