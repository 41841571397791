"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var validate_1 = require("../../compile/validate");

var code_1 = require("../code");

var util_1 = require("../../compile/util");

var additionalProperties_1 = require("./additionalProperties");

var def = {
  keyword: "properties",
  type: "object",
  schemaType: "object",
  code: function code(cxt) {
    var gen = cxt.gen,
        schema = cxt.schema,
        parentSchema = cxt.parentSchema,
        data = cxt.data,
        it = cxt.it;

    if (it.opts.removeAdditional === "all" && parentSchema.additionalProperties === undefined || it.opts.defaultAdditionalProperties === false) {
      additionalProperties_1.default.code(new validate_1.KeywordCxt(it, additionalProperties_1.default, "additionalProperties"));
    }

    var allProps = code_1.allSchemaProperties(schema);

    var _iterator = _createForOfIteratorHelper(allProps),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var prop = _step.value;
        it.definedProperties.add(prop);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (it.opts.unevaluated && allProps.length && it.props !== true) {
      it.props = util_1.mergeEvaluated.props(gen, util_1.toHash(allProps), it.props);
    }

    var properties = allProps.filter(function (p) {
      return !util_1.alwaysValidSchema(it, schema[p]);
    });
    if (properties.length === 0) return;
    var valid = gen.name("valid");

    var _iterator2 = _createForOfIteratorHelper(properties),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _prop = _step2.value;

        if (hasDefault(_prop)) {
          applyPropertySchema(_prop);
        } else {
          gen.if(code_1.propertyInData(gen, data, _prop, it.opts.ownProperties));
          applyPropertySchema(_prop);
          if (!it.allErrors) gen.else().var(valid, true);
          gen.endIf();
        }

        cxt.it.definedProperties.add(_prop);
        cxt.ok(valid);
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    function hasDefault(prop) {
      return it.opts.useDefaults && !it.compositeRule && schema[prop].default !== undefined;
    }

    function applyPropertySchema(prop) {
      cxt.subschema({
        keyword: "properties",
        schemaProp: prop,
        dataProp: prop
      }, valid);
    }
  }
};
exports.default = def;