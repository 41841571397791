import { __makeTemplateObject } from "tslib";
import styled, { css } from "../../redoc-lib/src/styled-components";
export var Table = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: table;\n  width: 100%;\n"], ["\n  display: table;\n  width: 100%;\n"])));
export var GroupLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #969a9a;\n  font-size: 1em;\n  font-weight: normal;\n  text-align: left;\n  position: absolute;\n"], ["\n  color: #969a9a;\n  font-size: 1em;\n  font-weight: normal;\n  text-align: left;\n  position: absolute;\n"])));
export var FormControl = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  font-family: ", ";\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  width: 100%;\n  font-family: ", ";\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), function (e) {
  return e.theme.typography.code.fontFamily;
});
export var FormWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 20px;\n"], ["\n  width: 100%;\n  margin-bottom: 20px;\n"])));
export var FormLabel = styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding-bottom: 6px;\n  display: block;\n  white-space: nowrap;\n  line-height: 1em;\n\n  ", "\n"], ["\n  padding-bottom: 6px;\n  display: block;\n  white-space: nowrap;\n  line-height: 1em;\n\n  ", "\n"])), function (e) {
  var t = e.required,
      n = e.theme;
  return t && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      &:after {\n        display: inline-block;\n        content: '*';\n        margin-left: 4px;\n        color: ", ";\n      }\n    "], ["\n      &:after {\n        display: inline-block;\n        content: '*';\n        margin-left: 4px;\n        color: ", ";\n      }\n    "])), n.colors.error.main);
});
export var FormError = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 0.9em;\n  & pre {\n    word-break: break-word;\n    white-space: pre-wrap;\n  }\n"], ["\n  color: ", ";\n  font-size: 0.9em;\n  & pre {\n    word-break: break-word;\n    white-space: pre-wrap;\n  }\n"])), function (e) {
  return e.theme.colors.error.main;
});
export var FormWarning = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 0.9em;\n"], ["\n  color: ", ";\n  font-size: 0.9em;\n"])), function (e) {
  return e.theme.colors.warning.main;
});

function placeholder(e, t) {
  for (var n = "", a = 0; a < e; a++) {
    n += t;
  }

  return '"' + n + '"';
}

export var TokenField = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  margin-bottom: 1em;\n  display: flex;\n"], ["\n  position: relative;\n  margin-bottom: 1em;\n  display: flex;\n"])));
export var TokenValue = styled.code(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex: 1;\n  overflow-wrap: anywhere;\n  ", "\n"], ["\n  flex: 1;\n  overflow-wrap: anywhere;\n  ", "\n"])), function (e) {
  var t = e.masked,
      n = e.length;
  return t && "\n  color: transparent;\n\n  &:before {\n    content: " + placeholder(n, "*") + ";\n    color: white;\n    position: absolute;\n    right: 100px;\n  }\n  " || "";
});
export var TokenShowHide = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 0 5px;\n  cursor: pointer;\n"], ["\n  padding: 0 5px;\n  cursor: pointer;\n"])));
export var TokenClear = styled(TokenShowHide)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (e) {
  return e.theme.colors.error.main;
});
export var FormHeader = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 1.2em;\n  margin: 1em 0;\n"], ["\n  font-weight: bold;\n  font-size: 1.2em;\n  margin: 1em 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;