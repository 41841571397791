"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assignDefaults = void 0;

var codegen_1 = require("../codegen");

var util_1 = require("../util");

function assignDefaults(it, ty) {
  var _it$schema = it.schema,
      properties = _it$schema.properties,
      items = _it$schema.items;

  if (ty === "object" && properties) {
    for (var key in properties) {
      assignDefault(it, key, properties[key].default);
    }
  } else if (ty === "array" && Array.isArray(items)) {
    items.forEach(function (sch, i) {
      return assignDefault(it, i, sch.default);
    });
  }
}

exports.assignDefaults = assignDefaults;

function assignDefault(it, prop, defaultValue) {
  var gen = it.gen,
      compositeRule = it.compositeRule,
      data = it.data,
      opts = it.opts;
  if (defaultValue === undefined) return;

  var childData = codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", "", ""])), data, codegen_1.getProperty(prop));

  if (compositeRule) {
    util_1.checkStrictMode(it, "default is ignored for: ".concat(childData));
    return;
  }

  var condition = codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", " === undefined"])), childData);

  if (opts.useDefaults === "empty") {
    condition = codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " || ", " === null || ", " === \"\""])), condition, childData, childData);
  } // `${childData} === undefined` +
  // (opts.useDefaults === "empty" ? ` || ${childData} === null || ${childData} === ""` : "")


  gen.if(condition, codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", " = ", ""])), childData, codegen_1.stringify(defaultValue)));
}