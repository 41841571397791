import { __assign } from "tslib";
import stringifyObject from "stringify-object";
import { CodeBuilder } from "../../helpers/code-builder";
import { addIndentation, getPreserveTransformer, buildUrlExpression, printUrlVariablesDeclarations } from "../../helpers/code-helpers";
import { Lang } from "../../helpers/constants";
import { normalizeMimeType } from "../../../../utils";
import { HTTPSnippet } from "../..";

var handler = function handler(e, t, a) {
  var r,
      n,
      i,
      o,
      s,
      l = a.target,
      p = a.client,
      d = Object.assign({
    indent: "  ",
    showBoilerplate: !0,
    level: 1
  }, t),
      h = d.level,
      m = !1,
      c = new CodeBuilder({
    indentation: d.indent,
    variablesPrefix: d.variablesPrefix,
    capitalize: !0,
    lang: Lang.NODEJS
  });
  d.withComments && addComments(c), d.withImports && (c.push("import fetch from 'node-fetch';"), c.blank()), d.showBoilerplate && c.push("async function run() {");
  var f = {
    method: e.method.toUpperCase()
  };
  Object.keys(e.headersObj).length && (f.headers = e.headersObj);
  var u = !1;

  if (null === (r = e.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r.length) {
    var b = new HTTPSnippet(null === (n = e.securityOAuth2ExtraCalls) || void 0 === n ? void 0 : n[0]).convert(l, p, __assign(__assign({}, d), {
      withImports: !1,
      withComments: !1,
      variablesPrefix: "oAuth2",
      showBoilerplate: !1,
      level: 1
    }));
    c.push(b), c.blank(), f.headers = f.headers || {}, f.headers.Authorization = "'Bearer ' + oAuth2Data.access_token", u = !0;
  }

  var v = Object.getOwnPropertyNames(e.queryObj).length;
  if (v && c.push(h, "const " + c.var("query") + " = new URLSearchParams(" + addIndentation(stringifyObject(e.queryObj, {
    indent: d.indent,
    inlineCharacterLimit: 25
  }), {
    level: h,
    indent: d.indent,
    firstLine: !1
  }) + ").toString();").blank(), e.postData) switch (normalizeMimeType(e.postData.mimeType)) {
    case "application/x-www-form-urlencoded":
      f.body = e.postData.paramsObj ? "new URLSearchParams(" + c.var("formData") + ").toString()" : e.postData.text, e.postData.paramsObj && c.push(h, "const " + c.var("formData") + " = " + addIndentation(stringifyObject(e.postData.paramsObj, {
        indent: d.indent,
        inlineCharacterLimit: 25
      }), {
        level: h,
        firstLine: !1
      }) + ";").blank();
      break;

    case "application/json":
      e.postData.jsonObj && (f.body = "JSON.stringify(" + addIndentation(stringifyObject(e.postData.jsonObj, {
        indent: d.indent,
        inlineCharacterLimit: 25
      }), {
        level: h,
        firstLine: !1
      }) + ")");
      break;

    case "multipart/form-data":
      (null === (i = null == f ? void 0 : f.headers) || void 0 === i ? void 0 : i["Content-Type"]) && (null === (o = f.headers) || void 0 === o || delete o["Content-Type"]), d.withImports && c.unshift("import FormData from 'form-data';"), c.push(h, "const " + c.var("form") + " = new FormData();"), e.postData.params.forEach(function (e) {
        e.fileName || e.fileName || e.contentType ? e.fileName && (m = !0, c.blank(), c.push(h, c.var("form") + ".append('\" + param.name + \"', fs.createReadStream('\" + param.fileName + \"'));")) : c.push(h, "form.append('" + e.name + "','" + e.value + "');");
      }), f.body = c.var("form"), c.blank();
      break;

    default:
      e.postData.text && (f.body = "`" + addIndentation(e.postData.text, {
        level: h + 1,
        indent: d.indent,
        firstLine: !1
      }).trim() + "`");
  }

  if (e.cookies.length) {
    var y = "";
    e.cookies.forEach(function (e) {
      y = y + encodeURIComponent(e.name) + "=" + encodeURIComponent(e.value) + "; ";
    }), y = y.trim(), f.headers || (f.headers = {}), f.headers.cookie = y;
  }

  if (e.basicAuth) {
    var g = e.basicAuth,
        j = g.username,
        w = g.password;
    f.headers = f.headers || {}, f.headers.Authorization = "'Basic ' + Buffer.from('" + j + ":" + w + "').toString('base64')", u = !0;
  }

  m && d.withImports && c.unshift("import fs from 'fs';");
  var O = f.headers && f.headers.Accept && "application/json" === normalizeMimeType(f.headers.Accept) || "application/json" === normalizeMimeType(null === (s = e.postData) || void 0 === s ? void 0 : s.mimeType);
  printUrlVariablesDeclarations(e, c, h);
  var k = buildUrlExpression(e, c);
  return c.push(h, "const " + c.var("resp") + " = await fetch(").push(h + 1, "`" + k + (v ? "?${" + c.var("query") + "}" : "") + "`,").push(h + 1, addIndentation(stringifyObject(f, {
    indent: d.indent,
    inlineCharacterLimit: 25,
    transform: getPreserveTransformer({
      body: !0,
      authorizationHeader: u
    })
  }), {
    level: h + 1,
    indent: d.indent,
    firstLine: !1
  })).push(h, ");").blank().push(h, "const " + c.var("data") + " = await " + c.var("resp") + "." + (O ? "json()" : "text()") + ";").push(h, "console.log(" + c.var("data") + ");"), d.showBoilerplate && c.push("}").blank().push("run();"), c.join().replace(/"fs\.createReadStream\(\\"(.+)\\"\)"/, 'fs.createReadStream("$1")');
};

function addComments(e) {
  e.push("/**"), e.push(" * Requires Node.js >= 14"), e.push(" *"), e.push(' * Requires module "node-fetch" >= 2.6.1'), e.push(" * See here for installation details:"), e.push(" *   https://www.npmjs.com/package/node-fetch"), e.push(" */"), e.blank();
}

export var info = {
  key: "fetch",
  title: "Fetch",
  link: "https://github.com/bitinn/node-fetch",
  description: "Simplified HTTP node-fetch client"
};
export default handler;