"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestMimeType = void 0;

var utils_1 = require("../../utils");

var RequestMimeType = function RequestMimeType(_ref) {
  var allowedValues = _ref.allowedValues;
  return {
    PathMap: {
      RequestBody: {
        leave: function leave(requestBody, ctx) {
          utils_1.validateMimeTypeOAS3({
            type: 'consumes',
            value: requestBody
          }, ctx, allowedValues);
        }
      },
      Callback: {
        RequestBody: function RequestBody() {},
        Response: {
          leave: function leave(response, ctx) {
            utils_1.validateMimeTypeOAS3({
              type: 'consumes',
              value: response
            }, ctx, allowedValues);
          }
        }
      }
    },
    WebhooksMap: {
      Response: {
        leave: function leave(response, ctx) {
          utils_1.validateMimeTypeOAS3({
            type: 'consumes',
            value: response
          }, ctx, allowedValues);
        }
      }
    }
  };
};

exports.RequestMimeType = RequestMimeType;