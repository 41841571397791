import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import styled from "../../styled-components";
import { replaceHashInColor } from "../../appTheme";
import { Flex } from "@redocly/developer-portal/ui";
import { mediaCSS } from "@redocly/reference-docs";
export var ColumnTitle = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: inline-block;\n  font-weight: ", ";\n  margin-bottom: 1.5em;\n  ", "\n\n  @media only screen and (min-width: ", ") {\n    margin-bottom: 2.5em;\n  }\n"])), function (_ref) {
  var a = _ref.theme;
  return a.typography.fontWeightRegular;
}, function (_ref2) {
  var a = _ref2.theme;
  return mediaCSS({
    fontFamily: a.typography.headings.fontFamily
  });
}, function (_ref3) {
  var a = _ref3.theme;
  return a.breakpoints.large;
});
export var ColumnList = styled.ul(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"])));
export var ColumnListItem = styled.li(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  font-weight: ", ";\n  padding-bottom: 0.75em;\n  a {\n    color: ", ";\n    text-decoration: none;\n  }\n"])), function (_ref4) {
  var a = _ref4.theme;
  return a.typography.fontWeightBold;
}, function (a) {
  return a.theme.colors.primary.contrastText;
});
export var Footer = styled(Flex)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  background-color: ", ";\n  color: ", ";\n  ", "\n  a,\n  a:hover {\n    color: ", ";\n  }\n\n  .external-url {\n    &::after {\n      background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0' y='0' viewBox='0 0 100 125' enable-background='new 0 0 100 100' xml:space='preserve'%3E%3Cpath fill='", "' d='M-408.2-318.5 98.6 4.8c0-0.1 0-0.2-0.1-0.3 0-0.1-0.1-0.2-0.1-0.3 0-0.1 0-0.1-0.1-0.2 0 0 0-0.1 0-0.1 0-0.1-0.1-0.2-0.2-0.3 0-0.1-0.1-0.2-0.2-0.3 -0.1-0.1-0.1-0.2-0.2-0.3C97.7 2.9 97.6 2.8 97.5 2.7c-0.1-0.1-0.2-0.2-0.3-0.3 -0.1-0.1-0.1-0.1-0.2-0.2 -0.1-0.1-0.2-0.2-0.3-0.2 -0.1-0.1-0.2-0.1-0.2-0.2 -0.1-0.1-0.2-0.1-0.3-0.2 -0.1 0-0.2-0.1-0.3-0.1 0 0-0.1 0-0.1-0.1 -0.1 0-0.2 0-0.2-0.1 -0.1 0-0.2-0.1-0.3-0.1 -0.1 0-0.2-0.1-0.4-0.1 -0.1 0-0.2 0-0.3 0 -0.1 0-0.3 0-0.4 0 -0.1 0-0.1 0-0.2 0L63.8 1.3c-2.5 0-4.6 2.1-4.6 4.6 0 2.5 2.1 4.6 4.6 4.5l19.3-0.1 -29.8 30.2c-1.1 1.1-1.5 2.7-1.2 4.1 0.2 0.9 0.6 1.7 1.3 2.4 0.9 0.9 2.1 1.3 3.2 1.3 1.2 0 2.3-0.5 3.2-1.4l0 0 29.8-30.2 0.1 19.3c0 2.5 2.1 4.6 4.6 4.5 1.3 0 2.4-0.5 3.2-1.4 0.8-0.8 1.3-2 1.3-3.2L98.7 5.7c0-0.1 0-0.1 0-0.2 0-0.1 0-0.2 0-0.4C98.6 5 98.6 4.9 98.6 4.8zM6.5 83.3c0 5.7 4.6 10.4 10.4 10.4l55.6 0.1c5.7 0 10.4-4.6 10.4-10.4l-0.1-40.8h6.1v40.8c0 9-7.3 16.3-16.3 16.3l-56.4-0.1c-8.6 0-15.6-7-15.6-15.6L0.6 27.8c0-9 7.3-16.3 16.3-16.3l41.1-0.1v6.1L17 17.5c-5.7 0-10.4 4.6-10.4 10.4L6.5 83.3z'/%3E%3C/svg%3E\");\n    }\n  }\n"])), function (_ref5) {
  var a = _ref5.theme;
  return a.colors.footer.main;
}, function (_ref6) {
  var a = _ref6.theme;
  return a.colors.footer.contrastText;
}, function (_ref7) {
  var a = _ref7.theme;
  return mediaCSS({
    fontFamily: a.typography.headings.fontFamily
  });
}, function (_ref8) {
  var a = _ref8.theme;
  return a.colors.footer.contrastText;
}, function (_ref9) {
  var a = _ref9.theme;
  return replaceHashInColor(a.colors.footer.contrastText);
});
export var FooterInfo = styled.section(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  justify-content: center;\n  font-size: 0.875em;\n  padding: 1.5em 3em;\n  font-weight: ", ";\n  background-color: ", ";\n  color: ", ";\n  span {\n    max-width: 1200px;\n  }\n"])), function (_ref10) {
  var a = _ref10.theme;
  return a.typography.fontWeightBold;
}, function (_ref11) {
  var a = _ref11.theme;
  return a.colors.footer.main;
}, function (_ref12) {
  var a = _ref12.theme;
  return a.colors.footer.contrastText;
});
export var FooterSeparator = styled.li(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  opacity: 0.75;\n  margin: 10px 0 5px 0;\n  font-size: 0.75em;\n  text-transform: uppercase;\n  ", "\n"])), function (_ref13) {
  var a = _ref13.theme;
  return mediaCSS({
    fontFamily: a.typography.headings.fontFamily
  });
});
export var FooterCopyright = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteralLoose(["\n  text-align: center;\n"])));