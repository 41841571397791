import { __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { Dropdown } from "../../common-elements/dropdown";

var DiscriminatorDropdown = function (e) {
  function r() {
    var r = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(r, "changeActiveChild", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        r.props.parent.activateOneOf(e.idx);
      }
    }), r;
  }

  return __extends(r, e), Object.defineProperty(r.prototype, "sortOptions", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      if (0 !== r.length) {
        var t = {};
        r.forEach(function (e, r) {
          t[e] = r;
        }), e.sort(function (e, r) {
          return t[e.value] > t[r.value] ? 1 : -1;
        });
      }
    }
  }), Object.defineProperty(r.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          r = e.parent,
          t = e.enumValues;
      if (void 0 === r.oneOf) return null;
      var n = r.oneOf.map(function (e, r) {
        return {
          value: e.title,
          idx: r
        };
      }),
          o = n[r.activeOneOf].value;
      return this.sortOptions(n, t), React.createElement(Dropdown, {
        dense: !0,
        value: o,
        options: n,
        onChange: this.changeActiveChild,
        ariaLabel: "Example"
      });
    }
  }), r = __decorate([observer], r);
}(React.Component);

export { DiscriminatorDropdown };