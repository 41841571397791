import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { FieldsGroupHeader } from "../../common-elements";
import { PropertiesTable, PropertiesTableWrap } from "../../common-elements/fields-layout";
import { Field } from "../Fields/Field";
import { mapWithLast } from "../../utils";
import styled from "../../styled-components";

var templateObject_1,
    ParametersGroup = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.place,
          r = e.parameters;
      return r && r.length ? React.createElement(Wrap, {
        key: t
      }, React.createElement(FieldsGroupHeader, null, t, " Parameters"), React.createElement(PropertiesTableWrap, null, React.createElement(PropertiesTable, null, React.createElement("tbody", null, mapWithLast(r, function (e, t) {
        return React.createElement(Field, {
          key: e.name,
          isLast: t,
          field: e,
          showExamples: !0
        });
      }))))) : null;
    }
  }), t;
}(React.PureComponent);

export { ParametersGroup };
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 0 0 0;\n  padding-bottom: 20px;\n  border-bottom: 1px solid ", ";\n\n  &:first-child {\n    margin: 0;\n  }\n\n  &:last-child,\n  &:only-child {\n    border-bottom: none;\n    padding-bottom: 0;\n  }\n"], ["\n  margin: 20px 0 0 0;\n  padding-bottom: 20px;\n  border-bottom: 1px solid ", ";\n\n  &:first-child {\n    margin: 0;\n  }\n\n  &:last-child,\n  &:only-child {\n    border-bottom: none;\n    padding-bottom: 0;\n  }\n"])), function (e) {
  return e.theme.colors.border.light;
});