import { __assign, __decorate, __extends, __makeTemplateObject } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { ExternalDocumentation } from "../ExternalDocumentation/ExternalDocumentation";
import { AdvancedMarkdown } from "../Markdown/AdvancedMarkdown";
import { H1, H2, MiddlePanel, Row, Section, ShareLink } from "../../common-elements";
import { Operation } from "../Operation/Operation";
import styled, { keyframes } from "../../styled-components";
import { IS_BROWSER } from "../../utils";
var shimmer = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  100% {\n      transform: translateX(100%);\n  }\n"], ["\n  100% {\n      transform: translateX(100%);\n  }\n"]))),
    SkeletonBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  height: 1em;\n  position: relative;\n  overflow: hidden;\n  background-color: #eeeeee;\n  border-radius: ", ";\n\n  &::after {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    transform: translateX(-100%);\n    background-image: linear-gradient(\n      90deg,\n      rgba(255, 255, 255, 0) 0,\n      rgba(255, 255, 255, 0.2) 20%,\n      rgba(255, 255, 255, 0.5) 60%,\n      rgba(255, 255, 255, 0)\n    );\n    animation: ", " 2s infinite;\n    content: '';\n  }\n"], ["\n  display: inline-block;\n  height: 1em;\n  position: relative;\n  overflow: hidden;\n  background-color: #eeeeee;\n  border-radius: ", ";\n\n  &::after {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    transform: translateX(-100%);\n    background-image: linear-gradient(\n      90deg,\n      rgba(255, 255, 255, 0) 0,\n      rgba(255, 255, 255, 0.2) 20%,\n      rgba(255, 255, 255, 0.5) 60%,\n      rgba(255, 255, 255, 0)\n    );\n    animation: ", " 2s infinite;\n    content: '';\n  }\n"])), function (e) {
  return e.theme.shape.borderRadius;
}, shimmer);

function Skeleton() {
  return React.createElement(Section, null, React.createElement(MiddlePanel, null, React.createElement(H2, null, React.createElement(SkeletonBox, {
    style: {
      width: "40%"
    }
  })), React.createElement(SkeletonBox, {
    style: {
      width: "80%"
    }
  }), React.createElement(SkeletonBox, {
    style: {
      width: "90%"
    }
  }), React.createElement(SkeletonBox, {
    style: {
      width: "83%"
    }
  }), React.createElement(SkeletonBox, {
    style: {
      width: "80%"
    }
  })));
}

var isInitialHydrate = !0;
export var ContentItems = observer(function (e) {
  var t = e.items,
      n = e.store,
      a = useState(IS_BROWSER && !isInitialHydrate && !n.options.disablePaginationLoadingAnimation),
      r = a[0],
      o = a[1];
  return useEffect(function () {
    setTimeout(function () {
      o(!1);
    }, 200), isInitialHydrate = !1;
  }, []), useEffect(function () {
    n.options.disablePaginationLoadingAnimation || r || n.onDidMount();
  }, [r, n.options.disablePaginationLoadingAnimation]), t.length ? React.createElement(React.Fragment, null, r ? React.createElement("div", {
    style: {
      flex: 1,
      width: "100%"
    }
  }, React.createElement(Skeleton, null)) : t.map(function (e) {
    return React.createElement(ContentItem, {
      key: e.id,
      item: e,
      store: n
    });
  })) : null;
});

var ContentItem = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e,
          t = this.props,
          n = t.item,
          a = t.store;

      switch (n.type) {
        case "group":
          e = null;
          break;

        case "tag":
        case "section":
          e = React.createElement(SectionItem, __assign({}, this.props, {
            layout: a.layout
          }));
          break;

        case "operation":
          e = React.createElement(OperationItem, {
            item: n,
            layout: a.layout
          });
          break;

        default:
          e = React.createElement(SectionItem, __assign({}, this.props, {
            layout: a.layout
          }));
      }

      return React.createElement(React.Fragment, null, e && React.createElement(Section, {
        id: n.id,
        underlined: "operation" === n.type
      }, e), n.items && n.items.length && React.createElement(ContentItems, {
        items: n.items,
        store: a
      }) || null);
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { ContentItem };

var middlePanelWrap = function middlePanelWrap(e) {
  return React.createElement(MiddlePanel, {
    compact: !0
  }, e);
},
    SectionItem = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.item,
          t = e.name,
          n = e.description,
          a = e.externalDocs,
          r = 2 === e.level ? H2 : H1;
      return React.createElement(React.Fragment, null, React.createElement(Row, null, React.createElement(MiddlePanel, {
        compact: !1
      }, React.createElement(r, null, React.createElement(ShareLink, {
        to: this.props.item.id
      }), t))), React.createElement(AdvancedMarkdown, {
        source: n || "",
        htmlWrap: middlePanelWrap
      }), a && React.createElement(Row, null, React.createElement(MiddlePanel, null, React.createElement(ExternalDocumentation, {
        externalDocs: a
      }))));
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { SectionItem };

var templateObject_1,
    templateObject_2,
    OperationItem = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return React.createElement(Operation, {
        operation: this.props.item,
        layout: this.props.layout
      });
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { OperationItem };