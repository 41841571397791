import { __assign, __spreadArray } from "tslib";
import slugify from "slugify";
import { format, parse } from "url";
import { ZERO_BREAKPOINT } from "./media-css";
export function mapWithLast(e, r) {
  for (var t = [], n = 0; n < e.length - 1; n++) {
    t.push(r(e[n], !1));
  }

  return 0 !== e.length && t.push(r(e[e.length - 1], !0)), t;
}
export function mapValues(e, r) {
  var t = {};

  for (var n in e) {
    Object.prototype.hasOwnProperty.call(e, n) && (t[n] = r(e[n], n, e));
  }

  return t;
}
export function flattenByProp(e, r) {
  var t = [],
      n = function n(e) {
    for (var i = 0, a = e; i < a.length; i++) {
      var s = a[i];
      t.push(s), s[r] && n(s[r]);
    }
  };

  return n(e), t;
}
export function stripTrailingSlash(e) {
  return e.endsWith("/") ? e.substring(0, e.length - 1) : e;
}
export function isNumeric(e) {
  return !isNaN(parseFloat(e)) && isFinite(e);
}
export function appendToMdHeading(e, r, t) {
  var n = new RegExp("(^|\\n)#\\s?" + r + "\\s*\\n", "i"),
      i = new RegExp("((\\n|^)#\\s*" + r + "\\s*(\\n|$)(?:.|\\n)*?)(\\n#|$)", "i");
  if (n.test(e)) return e.replace(i, "$1\n\n" + t + "\n$4");
  var a = "" === e || e.endsWith("\n\n") ? "" : e.endsWith("\n") ? "\n" : "\n\n";
  return "" + e + a + "# " + r + "\n\n" + t;
}
export var mergeObjects = function mergeObjects(e) {
  for (var r = [], t = 1; t < arguments.length; t++) {
    r[t - 1] = arguments[t];
  }

  if (!r.length) return e;
  var n = r.shift();
  return void 0 === n ? e : (isMergebleObject(e) && isMergebleObject(n) && Object.keys(n).forEach(function (r) {
    var t;
    isMergebleObject(n[r]) ? (e[r] || (e[r] = {}), ["string", "number"].includes(typeof e[r]) && (n[r][ZERO_BREAKPOINT] || 0 === n[r][ZERO_BREAKPOINT] || console.warn('Theme resolver: using default value of "' + e[r] + '" for "' + r + '". This is not recommended. Prefer adding default value by providing "' + ZERO_BREAKPOINT + '" property to ' + JSON.stringify(n[r]) + "."), e[r] = ((t = {})[ZERO_BREAKPOINT] = e[r], t)), mergeObjects(e[r], n[r])) : e[r] = n[r];
  }), mergeObjects.apply(void 0, __spreadArray([e], r, !1)));
};

var isObject = function isObject(e) {
  return null !== e && "object" == typeof e;
},
    isMergebleObject = function isMergebleObject(e) {
  return isObject(e) && !Array.isArray(e);
};

export function safeSlugify(e) {
  return slugify(e) || e.toString().toLowerCase().replace(/\s+/g, "-").replace(/&/g, "-and-").replace(/--+/g, "-").replace(/^-+/, "").replace(/-+$/, "");
}
export function isAbsoluteUrl(e) {
  return /(?:^[a-z][a-z0-9+.-]*:|\/\/)/i.test(e);
}
export function resolveUrl(e, r) {
  var t;
  if (r.startsWith("//")) t = "" + (parse(e).protocol || "https:") + r;else if (isAbsoluteUrl(r)) t = r;else if (r.startsWith("/")) {
    var n = parse(e);
    t = format(__assign(__assign({}, n), {
      pathname: r
    }));
  } else t = stripTrailingSlash(e) + "/" + r;
  return stripTrailingSlash(t);
}
export function getBasePath(e) {
  try {
    return parseURL(e).pathname;
  } catch (r) {
    return e;
  }
}
export function titleize(e) {
  return e.charAt(0).toUpperCase() + e.slice(1);
}
export function removeQueryString(e) {
  try {
    var r = parseURL(e);
    return r.search = "", r.toString();
  } catch (r) {
    return e;
  }
}

function parseURL(e) {
  return "undefined" == typeof URL ? new (require("url").URL)(e) : new URL(e);
}

export function unescapeHTMLChars(e) {
  return e.replace(/&#(\d+);/g, function (e, r) {
    return String.fromCharCode(parseInt(r, 10));
  }).replace(/&amp;/g, "&");
}
export function sanitizeItemId(e) {
  return e && e.replace(/#/g, "_");
}