import { __makeTemplateObject } from "tslib";
import styled, { extensionsHook, mediaCSS } from "../styled-components";
import { PropertyNameCell } from "./fields-layout";
import { ShelfIcon } from "./icons";
export var ClickablePropertyNameCell = styled(PropertyNameCell)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  button {\n    background-color: transparent;\n    border: 0;\n    outline: 0;\n    cursor: pointer;\n    padding: 0;\n    ", "\n    &:focus {\n      font-weight: ", ";\n    }\n  }\n  ", " {\n    margin-left: 4px;\n    height: ", ";\n    width: ", ";\n    fill: ", ";\n    margin-top: -1px;\n  }\n"], ["\n  button {\n    background-color: transparent;\n    border: 0;\n    outline: 0;\n    cursor: pointer;\n    padding: 0;\n    ", "\n    &:focus {\n      font-weight: ", ";\n    }\n  }\n  ", " {\n    margin-left: 4px;\n    height: ", ";\n    width: ", ";\n    fill: ", ";\n    margin-top: -1px;\n  }\n"])), function (e) {
  var t = e.theme;
  return mediaCSS({
    fontFamily: t.typography.fieldName.fontFamily,
    fontSize: t.typography.fieldName.fontSize,
    color: t.colors.text.primary
  });
}, function (e) {
  return e.theme.typography.fontWeightBold;
}, ShelfIcon, function (e) {
  return e.theme.schema.caretSize;
}, function (e) {
  return e.theme.schema.caretSize;
}, function (e) {
  return e.theme.schema.caretColor;
});
export var FieldLabel = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  vertical-align: middle;\n  line-height: 20px;\n  ", ";\n"], ["\n  vertical-align: middle;\n  line-height: 20px;\n  ", ";\n"])), function (e) {
  var t = e.theme;
  return mediaCSS({
    fontSize: t.typography.fieldName.fontSize
  });
});
export var TypePrefix = styled(FieldLabel)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (e) {
  return e.theme.schema.typeNameColor;
});
export var TypeName = styled(FieldLabel)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (e) {
  return e.theme.schema.typeNameColor;
});
export var TypeTitle = styled(FieldLabel)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  word-break: break-word;\n"], ["\n  color: ", ";\n  word-break: break-word;\n"])), function (e) {
  return e.theme.schema.typeTitleColor;
});
export var TypeFormat = TypeName;
export var RequiredLabel = styled(FieldLabel.withComponent("div"))(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: normal;\n  line-height: 1;\n  display: block;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: normal;\n  line-height: 1;\n  display: block;\n"])), function (e) {
  return e.theme.schema.requireLabelColor;
}, function (e) {
  return e.theme.schema.labelsTextSize;
});
export var RecursiveLabel = styled(FieldLabel)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 13px;\n"], ["\n  color: ", ";\n  font-size: 13px;\n"])), function (e) {
  return e.theme.colors.warning.main;
});
export var ExampleValue = styled(FieldLabel)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: ", ";\n  ", "\n\n  ", "\n\n  & + & {\n    margin-left: 0;\n  }\n  ", ";\n  line-height: 1.2em;\n  display: inline-flex;\n  white-space: pre-wrap;\n  overflow-wrap: anywhere;\n"], ["\n  border-radius: ", ";\n  ", "\n\n  ", "\n\n  & + & {\n    margin-left: 0;\n  }\n  ", ";\n  line-height: 1.2em;\n  display: inline-flex;\n  white-space: pre-wrap;\n  overflow-wrap: anywhere;\n"])), function (e) {
  return e.theme.shape.borderRadius;
}, function (e) {
  var t = e.theme;
  return "\n    background-color: " + t.colors.secondary.light + ";\n    color: " + t.colors.text.primary + ";\n    padding: 1px " + t.spacing.unit + "px;\n    border: 1px solid " + t.colors.border.light + ";\n    font-size: 12px;\n  ";
}, function (e) {
  var t = e.theme;
  return mediaCSS({
    fontFamily: t.typography.fieldName.fontFamily
  });
}, extensionsHook("ExampleValue"));
export var ExtensionValue = styled(ExampleValue)(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
export var ConstraintItem = styled(FieldLabel)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border-radius: ", ";\n  ", "\n\n  ", "\n\n  & + & {\n    margin-left: 0;\n  }\n  ", ";\n"], ["\n  border-radius: ", ";\n  ", "\n\n  ", "\n\n  & + & {\n    margin-left: 0;\n  }\n  ", ";\n"])), function (e) {
  return e.theme.shape.borderRadius;
}, function (e) {
  var t = e.theme;
  return "\n    background-color: " + t.colors.secondary.light + ";\n    color: " + t.colors.accent.dark + ";\n    margin: 0 " + t.spacing.unit + "px;\n    padding: 1px " + t.spacing.unit + "px;\n    border: 1px solid " + t.colors.border.light + ";\n    font-size: 12px;\n  ";
}, function (e) {
  var t = e.theme;
  return mediaCSS({
    fontFamily: t.typography.fieldName.fontFamily
  });
}, extensionsHook("ConstraintItem"));
export var PatternLabel = styled(ConstraintItem)(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
export var ToggleButton = styled.button(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: transparent;\n  border: 0;\n  color: ", ";\n  margin-left: ", "px;\n  border-radius: ", ";\n  cursor: pointer;\n  outline-color: ", ";\n  font-size: 12px;\n"], ["\n  background-color: transparent;\n  border: 0;\n  color: ", ";\n  margin-left: ", "px;\n  border-radius: ", ";\n  cursor: pointer;\n  outline-color: ", ";\n  font-size: 12px;\n"])), function (e) {
  return e.theme.colors.text.secondary;
}, function (e) {
  return e.theme.spacing.unit;
}, function (e) {
  return e.theme.shape.borderRadius;
}, function (e) {
  return e.theme.colors.text.secondary;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;