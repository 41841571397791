import * as React from "react";
import { isJsonLike, langFromMime } from "../../utils/openapi";
import { JsonViewer } from "../JsonViewer/JsonViewer";
import { SourceCodeWithCopy } from "../SourceCode/SourceCode";
export function ExampleValue(e) {
  var o = e.value,
      r = e.mimeType;
  return isJsonLike(r) ? React.createElement(JsonViewer, {
    data: o
  }) : ("object" == typeof o && (o = JSON.stringify(o, null, 2)), React.createElement(SourceCodeWithCopy, {
    lang: langFromMime(r),
    source: o
  }));
}