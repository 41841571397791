"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponseMimeType = void 0;

var utils_1 = require("../../utils");

var ResponseMimeType = function ResponseMimeType(_ref) {
  var allowedValues = _ref.allowedValues;
  return {
    PathMap: {
      Response: {
        leave: function leave(response, ctx) {
          utils_1.validateMimeTypeOAS3({
            type: 'produces',
            value: response
          }, ctx, allowedValues);
        }
      },
      Callback: {
        Response: function Response() {},
        RequestBody: {
          leave: function leave(requestBody, ctx) {
            utils_1.validateMimeTypeOAS3({
              type: 'produces',
              value: requestBody
            }, ctx, allowedValues);
          }
        }
      }
    },
    WebhooksMap: {
      RequestBody: {
        leave: function leave(requestBody, ctx) {
          utils_1.validateMimeTypeOAS3({
            type: 'produces',
            value: requestBody
          }, ctx, allowedValues);
        }
      }
    }
  };
};

exports.ResponseMimeType = ResponseMimeType;