import { __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { LinkWrap, LogoImgEl, LogoWrap } from "./styled.elements";

var ApiLogo = function (e) {
  function r() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(r, e), Object.defineProperty(r.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.info,
          r = e["x-logo"];
      if (!r || !r.url) return null;
      var t = r.href || e.contact && e.contact.url,
          o = r.altText ? r.altText : "logo",
          a = React.createElement(LogoImgEl, {
        src: r.url,
        alt: o
      });
      return React.createElement(LogoWrap, {
        style: {
          backgroundColor: r.backgroundColor
        }
      }, t ? React.createElement(LinkWrap, {
        url: t
      }, a) : a);
    }
  }), r = __decorate([observer], r);
}(React.Component);

export { ApiLogo };