"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extendSubschemaMode = exports.extendSubschemaData = exports.getSubschema = void 0;

var codegen_1 = require("../codegen");

var util_1 = require("../util");

function getSubschema(it, _ref) {
  var keyword = _ref.keyword,
      schemaProp = _ref.schemaProp,
      schema = _ref.schema,
      schemaPath = _ref.schemaPath,
      errSchemaPath = _ref.errSchemaPath,
      topSchemaRef = _ref.topSchemaRef;

  if (keyword !== undefined && schema !== undefined) {
    throw new Error('both "keyword" and "schema" passed, only one allowed');
  }

  if (keyword !== undefined) {
    var sch = it.schema[keyword];
    return schemaProp === undefined ? {
      schema: sch,
      schemaPath: codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", "", ""])), it.schemaPath, codegen_1.getProperty(keyword)),
      errSchemaPath: "".concat(it.errSchemaPath, "/").concat(keyword)
    } : {
      schema: sch[schemaProp],
      schemaPath: codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", "", "", ""])), it.schemaPath, codegen_1.getProperty(keyword), codegen_1.getProperty(schemaProp)),
      errSchemaPath: "".concat(it.errSchemaPath, "/").concat(keyword, "/").concat(util_1.escapeFragment(schemaProp))
    };
  }

  if (schema !== undefined) {
    if (schemaPath === undefined || errSchemaPath === undefined || topSchemaRef === undefined) {
      throw new Error('"schemaPath", "errSchemaPath" and "topSchemaRef" are required with "schema"');
    }

    return {
      schema: schema,
      schemaPath: schemaPath,
      topSchemaRef: topSchemaRef,
      errSchemaPath: errSchemaPath
    };
  }

  throw new Error('either "keyword" or "schema" must be passed');
}

exports.getSubschema = getSubschema;

function extendSubschemaData(subschema, it, _ref2) {
  var dataProp = _ref2.dataProp,
      dpType = _ref2.dataPropType,
      data = _ref2.data,
      dataTypes = _ref2.dataTypes,
      propertyName = _ref2.propertyName;

  if (data !== undefined && dataProp !== undefined) {
    throw new Error('both "data" and "dataProp" passed, only one allowed');
  }

  var gen = it.gen;

  if (dataProp !== undefined) {
    var errorPath = it.errorPath,
        dataPathArr = it.dataPathArr,
        opts = it.opts;
    var nextData = gen.let("data", codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", "", ""])), it.data, codegen_1.getProperty(dataProp)), true);
    dataContextProps(nextData);
    subschema.errorPath = codegen_1.str(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", "", ""])), errorPath, util_1.getErrorPath(dataProp, dpType, opts.jsPropertySyntax));
    subschema.parentDataProperty = codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", ""])), dataProp);
    subschema.dataPathArr = [].concat(_toConsumableArray(dataPathArr), [subschema.parentDataProperty]);
  }

  if (data !== undefined) {
    var _nextData2 = data instanceof codegen_1.Name ? data : gen.let("data", data, true); // replaceable if used once?


    dataContextProps(_nextData2);
    if (propertyName !== undefined) subschema.propertyName = propertyName; // TODO something is possibly wrong here with not changing parentDataProperty and not appending dataPathArr
  }

  if (dataTypes) subschema.dataTypes = dataTypes;

  function dataContextProps(_nextData) {
    subschema.data = _nextData;
    subschema.dataLevel = it.dataLevel + 1;
    subschema.dataTypes = [];
    it.definedProperties = new Set();
    subschema.parentData = it.data;
    subschema.dataNames = [].concat(_toConsumableArray(it.dataNames), [_nextData]);
  }
}

exports.extendSubschemaData = extendSubschemaData;

function extendSubschemaMode(subschema, _ref3) {
  var jtdDiscriminator = _ref3.jtdDiscriminator,
      jtdMetadata = _ref3.jtdMetadata,
      compositeRule = _ref3.compositeRule,
      createErrors = _ref3.createErrors,
      allErrors = _ref3.allErrors;
  if (compositeRule !== undefined) subschema.compositeRule = compositeRule;
  if (createErrors !== undefined) subschema.createErrors = createErrors;
  if (allErrors !== undefined) subschema.allErrors = allErrors;
  subschema.jtdDiscriminator = jtdDiscriminator; // not inherited

  subschema.jtdMetadata = jtdMetadata; // not inherited
}

exports.extendSubschemaMode = extendSubschemaMode;