import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import styled from "../../styled-components";
import { typography } from "../../common-elements";
import { color, typography as typographySystem } from "styled-system";
import { mediaCSS } from "@redocly/reference-docs";
export var Emphasis = styled.strong(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  font-weight: ", ";\n"])), function (_ref) {
  var a = _ref.theme;
  return a.typography.fontWeightBold;
});
export var H1 = styled.h1(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  ", "\n  margin: 1.35em 0 0.9em;\n  && {\n    ", "\n    ", "\n    ", "\n    ", "\n  }\n"])), function (_ref2) {
  var a = _ref2.theme;
  return typography(a.typography.heading1);
}, function (_ref3) {
  var a = _ref3.mt;
  return a && "margin-top: " + a + ";";
}, function (_ref4) {
  var a = _ref4.mb;
  return a && "margin-bottom: " + a + ";";
}, color, typographySystem);
export var H2 = styled.h2(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  ", "\n  margin: 1.25em 0 0.8em;\n  && {\n    ", "\n    ", "\n    ", "\n    ", "\n  }\n"])), function (_ref5) {
  var a = _ref5.theme;
  return typography(a.typography.heading2);
}, function (_ref6) {
  var a = _ref6.mt;
  return a && "margin-top: " + a + ";";
}, function (_ref7) {
  var a = _ref7.mb;
  return a && "margin-bottom: " + a + ";";
}, color, typographySystem);
export var H3 = styled.h3(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  ", "\n  margin: 1.25em 0 0.8em;\n  && {\n    ", "\n    ", "\n    ", "\n    ", "\n  }\n"])), function (_ref8) {
  var a = _ref8.theme;
  return typography(a.typography.heading3);
}, function (_ref9) {
  var a = _ref9.mt;
  return a && "margin-top: " + a + ";";
}, function (_ref10) {
  var a = _ref10.mb;
  return a && "margin-bottom: " + a + ";";
}, color, typographySystem);
export var SectionHeader = styled.h2(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  font-size: 1.75em;\n  font-weight: ", ";\n  text-align: center;\n  margin: 2.65em 0;\n  padding: 0px 20px;\n  ", "\n"])), function (a) {
  return a.theme.colors.primary.main;
}, function (_ref11) {
  var a = _ref11.theme;
  return a.typography.fontWeightLight;
}, function (_ref12) {
  var a = _ref12.theme;
  return mediaCSS({
    fontFamily: a.typography.headings.fontFamily
  });
});
export var Typography = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  ", ";\n  color: ", ";\n  ", "\n  margin-top: ", ";\n  margin-bottom: ", ";\n  ", "\n"])), function (_ref13) {
  var a = _ref13.theme;
  return typography(a.typography);
}, function (_ref14) {
  var a = _ref14.theme;
  return a.colors.text.primary;
}, color, function (_ref15) {
  var a = _ref15.mt;
  return a || "1em";
}, function (_ref16) {
  var a = _ref16.mb;
  return a || "1em";
}, typographySystem);
export var CompactTypography = styled(Typography).attrs(function () {
  return {
    mt: "0em",
    mb: "0em"
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteralLoose([""])));