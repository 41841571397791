import { __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { PropertiesTable, PropertiesTableWrap, PropertiesTableCaption } from "../../common-elements/fields-layout";
import { Field } from "../Fields/Field";
import { DiscriminatorDropdown } from "./DiscriminatorDropdown";
import { mapWithLast } from "../../utils";
import { OptionsContext } from "../OptionsProvider";

var ObjectSchema = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "parentSchema", {
    get: function get() {
      return this.props.discriminator.parentSchema;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this,
          t = this.props,
          r = t.schema.fields,
          i = void 0 === r ? [] : r,
          n = t.showTitle,
          o = t.discriminator,
          a = t.level,
          p = this.props.skipReadOnly || this.props.skipWriteOnly ? i.filter(function (t) {
        return !(e.props.skipReadOnly && t.schema.readOnly || e.props.skipWriteOnly && t.schema.writeOnly);
      }) : i,
          l = this.context.expandSingleSchemaField && 1 === p.length || this.context.schemaExpansionLevel >= a;
      return React.createElement(PropertiesTableWrap, null, React.createElement(PropertiesTable, null, n && React.createElement(PropertiesTableCaption, null, this.props.schema.title), React.createElement("tbody", null, mapWithLast(p, function (t, r) {
        return React.createElement(Field, {
          key: t.name,
          isLast: r,
          field: t,
          expandByDefault: l,
          renderDiscriminatorSwitch: o && o.fieldName === t.name && function () {
            return React.createElement(DiscriminatorDropdown, {
              parent: e.parentSchema,
              enumValues: t.schema.enum
            });
          } || void 0,
          className: t.expanded ? "expanded" : void 0,
          showExamples: !1,
          skipReadOnly: e.props.skipReadOnly,
          skipWriteOnly: e.props.skipWriteOnly,
          showTitle: e.props.showTitle,
          level: e.props.level
        });
      }))));
    }
  }), Object.defineProperty(t, "contextType", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: OptionsContext
  }), t = __decorate([observer], t);
}(React.Component);

export { ObjectSchema };