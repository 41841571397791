var _a;

import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import styled, { mediaCSS, ZERO_BREAKPOINT } from "../../styled-components";
import { Link, FieldsGroupHeader } from "../../common-elements/";
import { linksCss } from "../Markdown/styled.elements";
import { l } from "../../services/Labels";
import { Wrap as WrapParameters } from "../Parameters/ParametersGroup";

var ScopeName = styled.code(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  margin: 0 3px;\n  padding: 0.2em;\n  line-height: 1;\n  display: inline-block;\n  word-break: break-word;\n  ", "\n"], ["\n  border: 1px solid ", ";\n  margin: 0 3px;\n  padding: 0.2em;\n  line-height: 1;\n  display: inline-block;\n  word-break: break-word;\n  ", "\n"])), function (e) {
  return e.theme.colors.border.light;
}, function (e) {
  var t = e.theme;
  return mediaCSS({
    fontFamily: t.typography.code.fontFamily,
    fontSize: t.typography.code.fontSize
  });
}),
    SecurityRequirementAndWrap = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:after {\n    content: ' AND ';\n    font-weight: bold;\n  }\n\n  &:last-child:after {\n    content: none;\n  }\n\n  ", ";\n"], ["\n  &:after {\n    content: ' AND ';\n    font-weight: bold;\n  }\n\n  &:last-child:after {\n    content: none;\n  }\n\n  ", ";\n"])), linksCss),
    SecurityRequirementOrWrap = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), linksCss),
    SecurityRequirement = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.security;
      return React.createElement(SecurityRequirementOrWrap, null, e.schemes.map(function (e) {
        return React.createElement(SecurityRequirementAndWrap, {
          key: e.id
        }, React.createElement(Link, {
          to: e.sectionId
        }, e.id), e.scopes.length > 0 && " (", e.scopes.map(function (e) {
          return React.createElement(ScopeName, {
            key: e
          }, e);
        }), e.scopes.length > 0 && ") ");
      }));
    }
  }), t;
}(React.PureComponent);

export { SecurityRequirement };

var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    templateObject_6,
    templateObject_7,
    AuthHeaderColumn = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1 1 auto;\n"], ["\n  flex: 1 1 auto;\n"]))),
    SecuritiesColumn = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (e) {
  var t,
      n = e.theme;
  return mediaCSS({
    width: n.schema.defaultDetailsWidth,
    marginTop: (t = {}, t[ZERO_BREAKPOINT] = "10px", t.small = "initial", t)
  });
}),
    AuthHeader = styled(FieldsGroupHeader)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  margin: 0;\n"], ["\n  display: inline-block;\n  margin: 0;\n"]))),
    Wrap = styled(WrapParameters)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n\n  ", ";\n"], ["\n  width: 100%;\n  display: flex;\n\n  ", ";\n"])), mediaCSS({
  flexDirection: (_a = {}, _a[ZERO_BREAKPOINT] = "column", _a.small = "row", _a)
})),
    SecurityRequirements = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.securities;
      return e.length ? React.createElement(Wrap, null, React.createElement(AuthHeaderColumn, null, React.createElement(AuthHeader, null, l("authorizations"), ": ")), React.createElement(SecuritiesColumn, null, e.map(function (t, n) {
        var r = "",
            a = "";
        e.length > 1 && t.schemes.length > 1 && (r = 0 === n ? "(" : "", a = ")"), n !== e.length - 1 && (a += " or ");
        var l = r ? React.createElement("strong", null, r) : null,
            i = a ? React.createElement("strong", null, a) : null;
        return React.createElement(React.Fragment, {
          key: n
        }, l, React.createElement(SecurityRequirement, {
          key: n,
          security: t
        }), i);
      }))) : null;
    }
  }), t;
}(React.PureComponent);

export { SecurityRequirements };