import { __assign, __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import * as PerfectScrollbarNamespace from "perfect-scrollbar";
import { OptionsContext } from "../components/OptionsProvider";
import styled, { createGlobalStyle } from "../styled-components";
import { IS_BROWSER } from "../utils";
var psStyles;
IS_BROWSER && (psStyles = require("perfect-scrollbar/css/perfect-scrollbar.css"));

var templateObject_1,
    templateObject_2,
    PerfectScrollbarConstructor = PerfectScrollbarNamespace.default || PerfectScrollbarNamespace,
    PSStyling = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", ""], ["", ""])), psStyles && "function" == typeof psStyles.toString && psStyles.toString() || ""),
    StyledScrollWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"]))),
    PerfectScrollbar = function (e) {
  function t() {
    var t = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(t, "_container", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(t, "inst", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(t, "handleRef", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        t._container = e;
      }
    }), t;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "componentDidMount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this._container.parentElement && this._container.parentElement.scrollTop || 0;
      this.inst = new PerfectScrollbarConstructor(this._container, this.props.options || {}), this._container.scrollTo && this._container.scrollTo(0, e);
    }
  }), Object.defineProperty(t.prototype, "componentDidUpdate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.inst.update();
    }
  }), Object.defineProperty(t.prototype, "componentWillUnmount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.inst.destroy();
    }
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.children,
          r = e.className,
          n = e.updateFn;
      return n && n(this.componentDidUpdate.bind(this)), React.createElement(React.Fragment, null, React.createElement(PSStyling, null), React.createElement(StyledScrollWrapper, {
        className: "scrollbar-container " + r,
        ref: this.handleRef
      }, t));
    }
  }), t;
}(React.Component);

export { PerfectScrollbar };
export function PerfectScrollbarWrap(e) {
  return React.createElement(OptionsContext.Consumer, null, function (t) {
    return t.nativeScrollbars ? React.createElement("div", {
      style: {
        overflow: "auto",
        overscrollBehavior: "contain",
        msOverflowStyle: "-ms-autohiding-scrollbar"
      }
    }, e.children) : React.createElement(PerfectScrollbar, __assign({}, e), e.children);
  });
}