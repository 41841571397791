import { __extends } from "tslib";
import * as React from "react";
import { ConstraintItem } from "../../common-elements/fields";

var ConstraintsView = function (t) {
  function e() {
    return null !== t && t.apply(this, arguments) || this;
  }

  return __extends(e, t), Object.defineProperty(e.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return 0 === this.props.constraints.length ? null : React.createElement("span", null, " ", this.props.constraints.map(function (t) {
        return React.createElement(ConstraintItem, {
          key: t
        }, " ", t, " ");
      }));
    }
  }), e;
}(React.PureComponent);

export { ConstraintsView };