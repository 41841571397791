import { observer } from "mobx-react";
import * as React from "react";
import { DropdownLabel, DropdownWrapper } from "../PayloadSamples/styled.elements";
import { FormControl } from "../../../../components/common/form";
export var MediaTypesSwitch = observer(function (e) {
  var r = e.content,
      t = e.withLabel,
      n = e.renderDropdown,
      a = e.children,
      o = React.useCallback(function (e) {
    var t = e.value;
    if (!r || !l.current) return null;
    var n = l.current.getBoundingClientRect().y;
    r.activate(t), requestAnimationFrame(function () {
      if (l.current && null != n) {
        var e = l.current.getBoundingClientRect().y;
        window.scrollBy(0, e - n);
      }
    });
  }, [r]),
      l = React.useRef(null);
  if (!r || !r.mediaTypes || !r.mediaTypes.length) return null;
  var c = r.mediaTypes.map(function (e, r) {
    return {
      value: e.name,
      idx: r
    };
  }),
      i = r.active.name;
  return React.createElement(React.Fragment, null, React.createElement(function (e) {
    var r = e.children;
    return t ? React.createElement(DropdownWrapper, {
      ref: l
    }, React.createElement(DropdownLabel, null, "Content type"), r) : React.createElement(FormControl, {
      ref: l
    }, r);
  }, null, n({
    value: i,
    options: c,
    onChange: o,
    ariaLabel: "Content type"
  })), a(r.active));
});