import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2;

import styled from "../../../styled-components";

var sideBarTriggerIcon = require("../../../../images/sidebar.svg");

export var MenuMobileButton = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  background-color: ", ";\n  width: 55px;\n  user-select: none;\n  height: 55px;\n  bottom: 44px;\n  cursor: pointer;\n  position: fixed;\n  right: 20px;\n  z-index: 3;\n  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;\n  border-radius: 50%;\n\n  transform: ", ";\n  transition: transform 0.75s;\n\n  :after {\n    content: '';\n    display: inline-block;\n    position: absolute;\n    transform: translate(-50%, -50%);\n    top: 50%;\n    left: 50%;\n    width: 25px;\n    height: 25px;\n    background-color: ", ";\n    -webkit-mask: url(", ") no-repeat 50% 50%;\n    mask: url(", ") no-repeat 50% 50%;\n  }\n\n  @media only screen and (min-width: ", ") {\n    display: none;\n    transform: rotate(180deg);\n  }\n\n  @media print {\n    display: none !important;\n  }\n"])), function (a) {
  return a.theme.colors.primary.main;
}, function (a) {
  return a.opened ? "rotate(180deg)" : "rotate(0deg)";
}, function (_ref) {
  var a = _ref.theme;
  return a.colors.navbar.contrastText;
}, sideBarTriggerIcon, sideBarTriggerIcon, function (_ref2) {
  var a = _ref2.theme;
  return a.breakpoints[a.sidebar.showAtBreakpoint];
});
export var MenuWrapper = styled.aside(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  border-right: 1px solid ", ";\n  opacity: ", ";\n  pointer-events: ", ";\n  position: fixed;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 2;\n  background: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  color: ", ";\n  top: ", "px;\n  height: calc(100vh - ", "px);\n\n  display: flex;\n  flex-direction: column;\n\n  width: 100%;\n  -webkit-font-smoothing: antialiased;\n\n  & > * {\n    transform: ", ";\n    transition: ", ";\n  }\n\n  @media screen and (min-width: ", ") {\n    position: sticky;\n    pointer-events: auto;\n    display: flex;\n    opacity: 1;\n    flex-shrink: 0;\n    width: ", ";\n    & > * {\n      transform: none;\n    }\n  }\n\n  @media only screen and (min-width: ", ") {\n    transform: none;\n  }\n"])), function (_ref3) {
  var a = _ref3.theme;
  return a.sidebar.rightLineColor;
}, function (a) {
  return a.opened ? "1" : "0";
}, function (a) {
  return a.opened ? "auto" : "none";
}, function (_ref4) {
  var a = _ref4.theme;
  return a.sidebar.backgroundColor;
}, function (_ref5) {
  var a = _ref5.theme;
  return a.sidebar.fontSize;
}, function (_ref6) {
  var a = _ref6.theme;
  return a.sidebar.fontFamily;
}, function (_ref7) {
  var a = _ref7.theme;
  return a.sidebar.textColor;
}, function (_ref8) {
  var a = _ref8.navBarHeight;
  return a;
}, function (_ref9) {
  var a = _ref9.navBarHeight;
  return a;
}, function (a) {
  return a.opened ? "translate(0, 0)" : "translate(0, 40px)";
}, function (a) {
  return a.animate ? "transform 0.65s ease, opacity 0.25s ease;" : "none";
}, function (_ref10) {
  var a = _ref10.theme;
  return a.breakpoints[a.sidebar.showAtBreakpoint];
}, function (_ref11) {
  var a = _ref11.theme;
  return a.sidebar.width;
}, function (_ref12) {
  var a = _ref12.theme;
  return a.breakpoints.large;
});