import * as styledComponents from "styled-components";
var _a = styledComponents,
    styled = _a.default,
    css = _a.css,
    createGlobalStyle = _a.createGlobalStyle,
    keyframes = _a.keyframes,
    ThemeProvider = _a.ThemeProvider;
export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
export function extensionsHook(e) {
  return function (t) {
    if (t.theme.extensionsHook) return t.theme.extensionsHook(e, t);
  };
}
export { mediaCSS, mediaMap, ZERO_BREAKPOINT } from "./utils/media-css";