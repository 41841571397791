"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Operation4xxResponse = void 0;

var Operation4xxResponse = function Operation4xxResponse() {
  return {
    ResponsesMap: function ResponsesMap(responses, _ref) {
      var report = _ref.report;
      var codes = Object.keys(responses);

      if (!codes.some(function (code) {
        return /4[Xx0-9]{2}/.test(code);
      })) {
        report({
          message: 'Operation must have at least one `4xx` response.',
          location: {
            reportOnKey: true
          }
        });
      }
    }
  };
};

exports.Operation4xxResponse = Operation4xxResponse;