"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigTypes = void 0;

var _1 = require(".");

var utils_1 = require("../utils");

var ConfigRoot = {
  properties: {
    apiDefinitions: {
      type: 'object',
      properties: {},
      additionalProperties: {
        properties: {
          type: 'string'
        }
      }
    },
    lint: 'ConfigLint',
    referenceDocs: 'ConfigReferenceDocs'
  }
};
var ConfigHTTP = {
  properties: {
    headers: {
      type: 'array',
      items: {
        type: 'string'
      }
    }
  }
};
var ConfigLint = {
  properties: {
    plugins: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    extends: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    doNotResolveExamples: {
      type: 'boolean'
    },
    rules: {
      type: 'object'
    },
    oas2Rules: {
      type: 'object'
    },
    oas3_0Rules: {
      type: 'object'
    },
    oas3_1Rules: {
      type: 'object'
    },
    preprocessors: {
      type: 'object'
    },
    oas2Preprocessors: {
      type: 'object'
    },
    oas3_0Preprocessors: {
      type: 'object'
    },
    oas3_1Preprocessors: {
      type: 'object'
    },
    decorators: {
      type: 'object'
    },
    oas2Decorators: {
      type: 'object'
    },
    oas3_0Decorators: {
      type: 'object'
    },
    oas3_1Decorators: {
      type: 'object'
    },
    resolve: {
      properties: {
        http: 'ConfigHTTP'
      }
    }
  }
};
var ConfigLanguage = {
  properties: {
    label: {
      type: 'string'
    },
    lang: {
      type: 'string'
    }
  }
};
var ConfigLabels = {
  properties: {
    enum: {
      type: 'string'
    },
    enumSingleValue: {
      type: 'string'
    },
    enumArray: {
      type: 'string'
    },
    default: {
      type: 'string'
    },
    deprecated: {
      type: 'string'
    },
    example: {
      type: 'string'
    },
    examples: {
      type: 'string'
    },
    nullable: {
      type: 'string'
    },
    recursive: {
      type: 'string'
    },
    arrayOf: {
      type: 'string'
    },
    webhook: {
      type: 'string'
    },
    authorizations: {
      type: 'string'
    },
    tryItAuthBasicUsername: {
      type: 'string'
    },
    tryItAuthBasicPassword: {
      type: 'string'
    }
  }
};
var ConfigSidebarLinks = {
  properties: {
    placement: {
      type: 'string'
    },
    label: {
      type: 'string'
    },
    link: {
      type: 'string'
    },
    target: {
      type: 'string'
    }
  }
};
var CommonThemeColors = {
  properties: {
    main: {
      type: 'string'
    },
    light: {
      type: 'string'
    },
    dark: {
      type: 'string'
    },
    contrastText: {
      type: 'string'
    }
  }
};
var CommonColorProps = {
  properties: {
    backgroundColor: {
      type: 'string'
    },
    borderColor: {
      type: 'string'
    },
    color: {
      type: 'string'
    },
    tabTextColor: {
      type: 'string'
    }
  }
};
var BorderThemeColors = {
  properties: utils_1.pickObjectProps(CommonThemeColors.properties, ['light', 'dark'])
};
var HttpColors = {
  properties: {
    basic: {
      type: 'string'
    },
    delete: {
      type: 'string'
    },
    get: {
      type: 'string'
    },
    head: {
      type: 'string'
    },
    link: {
      type: 'string'
    },
    options: {
      type: 'string'
    },
    patch: {
      type: 'string'
    },
    post: {
      type: 'string'
    },
    put: {
      type: 'string'
    }
  }
};
var ResponseColors = {
  properties: {
    errors: 'CommonColorProps',
    info: 'CommonColorProps',
    redirect: 'CommonColorProps',
    success: 'CommonColorProps'
  }
};
var SecondaryColors = {
  properties: utils_1.omitObjectProps(CommonThemeColors.properties, ['dark'])
};
var TextThemeColors = {
  properties: {
    primary: {
      type: 'string'
    },
    secondary: {
      type: 'string'
    },
    light: {
      type: 'string'
    }
  }
};
var ThemeColors = {
  properties: {
    accent: 'CommonThemeColors',
    border: 'BorderThemeColors',
    error: 'CommonThemeColors',
    http: 'HttpColors',
    primary: 'CommonThemeColors',
    responses: 'ResponseColors',
    secondary: 'SecondaryColors',
    success: 'CommonThemeColors',
    text: 'TextThemeColors',
    tonalOffset: {
      type: 'number'
    },
    warning: 'CommonThemeColors'
  }
};
var SizeProps = {
  properties: {
    fontSize: {
      type: 'string'
    },
    padding: {
      type: 'string'
    },
    minWidth: {
      type: 'string'
    }
  }
};
var Sizes = {
  properties: {
    small: 'SizeProps',
    medium: 'SizeProps',
    large: 'SizeProps',
    xlarge: 'SizeProps'
  }
};
var FontConfig = {
  properties: {
    fontFamily: {
      type: 'string'
    },
    fontSize: {
      type: 'string'
    },
    fontWeight: {
      type: 'string'
    },
    lineHeight: {
      type: 'string'
    }
  }
};
var ButtonsConfig = {
  properties: Object.assign(Object.assign({}, utils_1.omitObjectProps(FontConfig.properties, ['fontSize', 'lineHeight'])), {
    borderRadius: {
      type: 'string'
    },
    hoverStyle: {
      type: 'string'
    },
    boxShadow: {
      type: 'string'
    },
    hoverBoxShadow: {
      type: 'string'
    },
    sizes: 'Sizes'
  })
};
var BadgeFontConfig = {
  properties: utils_1.pickObjectProps(FontConfig.properties, ['fontSize', 'lineHeight'])
};
var BadgeSizes = {
  properties: {
    medium: 'BadgeFontConfig',
    small: 'BadgeFontConfig'
  }
};
var HttpBadgesConfig = {
  properties: Object.assign(Object.assign({}, utils_1.omitObjectProps(FontConfig.properties, ['fontSize', 'lineHeight'])), {
    borderRadius: {
      type: 'string'
    },
    color: {
      type: 'string'
    },
    sizes: 'BadgeSizes'
  })
};
var LabelControls = {
  properties: {
    top: {
      type: 'string'
    }
  }
};
var Panels = {
  properties: {
    borderRadius: {
      type: 'string'
    }
  }
};
var TryItButton = {
  properties: {
    fullWidth: {
      type: 'boolean'
    }
  }
};
var Components = {
  properties: {
    buttons: 'ButtonsConfig',
    httpBadges: 'HttpBadgesConfig',
    layoutControls: 'LabelControls',
    panels: 'Panels',
    tryItButton: 'TryItButton',
    tryItSendButton: 'TryItButton'
  }
};
var Breakpoints = {
  properties: {
    small: {
      type: 'string'
    },
    medium: {
      type: 'string'
    },
    large: {
      type: 'string'
    }
  }
};
var StackedConfig = {
  properties: {
    maxWidth: 'Breakpoints'
  }
};
var ThreePanelConfig = {
  properties: {
    maxWidth: 'Breakpoints'
  }
};
var Layout = {
  properties: {
    showDarkRightPanel: {
      type: 'boolean'
    },
    stacked: 'StackedConfig',
    'three-panel': 'ThreePanelConfig'
  }
};
var SchemaColorsConfig = {
  properties: {
    backgroundColor: {
      type: 'string'
    },
    border: {
      type: 'string'
    }
  }
};
var Schema = {
  properties: {
    breakFieldNames: {
      type: 'boolean'
    },
    caretColor: {
      type: 'string'
    },
    caretSize: {
      type: 'string'
    },
    constraints: 'SchemaColorsConfig',
    defaultDetailsWidth: {
      type: 'string'
    },
    examples: 'SchemaColorsConfig',
    labelsTextSize: {
      type: 'string'
    },
    linesColor: {
      type: 'string'
    },
    nestedBackground: {
      type: 'string'
    },
    nestingSpacing: {
      type: 'string'
    },
    requireLabelColor: {
      type: 'string'
    },
    typeNameColor: {
      type: 'string'
    },
    typeTitleColor: {
      type: 'string'
    }
  }
};
var GroupItemsConfig = {
  properties: {
    subItemsColor: {
      type: 'string'
    },
    textTransform: {
      type: 'string'
    },
    fontWeight: {
      type: 'string'
    }
  }
};
var Level1Items = {
  properties: utils_1.pickObjectProps(GroupItemsConfig.properties, ['textTransform'])
};
var SpacingConfig = {
  properties: {
    unit: {
      type: 'number'
    },
    paddingHorizontal: {
      type: 'string'
    },
    paddingVertical: {
      type: 'string'
    },
    offsetTop: {
      type: 'string'
    },
    offsetLeft: {
      type: 'string'
    },
    offsetNesting: {
      type: 'string'
    }
  }
};
var Sidebar = {
  properties: Object.assign(Object.assign({}, utils_1.omitObjectProps(FontConfig.properties, ['fontWeight', 'lineHeight'])), {
    activeBgColor: {
      type: 'string'
    },
    activeTextColor: {
      type: 'string'
    },
    backgroundColor: {
      type: 'string'
    },
    borderRadius: {
      type: 'string'
    },
    breakPath: {
      type: 'boolean'
    },
    caretColor: {
      type: 'string'
    },
    caretSize: {
      type: 'string'
    },
    groupItems: 'GroupItemsConfig',
    level1items: 'Level1Items',
    rightLineColor: {
      type: 'string'
    },
    separatorLabelColor: {
      type: 'string'
    },
    showAtBreakpoint: {
      type: 'string'
    },
    spacing: 'SpacingConfig',
    textColor: {
      type: 'string'
    },
    width: {
      type: 'string'
    }
  })
};
var Heading = {
  properties: Object.assign(Object.assign({}, FontConfig.properties), {
    color: {
      type: 'string'
    },
    transform: {
      type: 'string'
    }
  })
};
var CodeConfig = {
  properties: Object.assign(Object.assign({}, FontConfig.properties), {
    backgroundColor: {
      type: 'string'
    },
    color: {
      type: 'string'
    },
    wordBreak: {
      type: 'string'
    },
    wrap: {
      type: 'boolean'
    }
  })
};
var HeadingsConfig = {
  properties: utils_1.omitObjectProps(FontConfig.properties, ['fontSize'])
};
var LinksConfig = {
  properties: {
    color: {
      type: 'string'
    },
    hover: {
      type: 'string'
    },
    textDecoration: {
      type: 'string'
    },
    visited: {
      type: 'string'
    }
  }
};
var Typography = {
  properties: Object.assign(Object.assign({
    code: 'CodeConfig',
    fieldName: 'FontConfig'
  }, utils_1.pickObjectProps(FontConfig.properties, ['fontSize', 'fontFamily'])), {
    fontWeightBold: {
      type: 'string'
    },
    fontWeightLight: {
      type: 'string'
    },
    fontWeightRegular: {
      type: 'string'
    },
    heading1: 'Heading',
    heading2: 'Heading',
    heading3: 'Heading',
    headings: 'HeadingsConfig',
    lineHeight: {
      type: 'string'
    },
    links: 'LinksConfig',
    optimizeSpeed: {
      type: 'boolean'
    },
    rightPanelHeading: 'Heading',
    smoothing: {
      type: 'string'
    }
  })
};
var TokenProps = {
  properties: Object.assign({
    color: {
      type: 'string'
    }
  }, utils_1.omitObjectProps(FontConfig.properties, ['fontWeight']))
};
var CodeBlock = {
  properties: {
    backgroundColor: {
      type: 'string'
    },
    borderRadius: {
      type: 'string'
    },
    tokens: 'TokenProps'
  }
};
var Logo = {
  properties: {
    gutter: {
      type: 'string'
    },
    maxHeight: {
      type: 'string'
    },
    maxWidth: {
      type: 'string'
    }
  }
};
var ButtonOverrides = {
  properties: {
    custom: {
      type: 'string'
    }
  }
};
var Overrides = {
  properties: {
    DownloadButton: 'ButtonOverrides',
    NextSectionButton: 'ButtonOverrides'
  }
};
var RightPanel = {
  properties: {
    backgroundColor: {
      type: 'string'
    },
    panelBackgroundColor: {
      type: 'string'
    },
    panelControlsBackgroundColor: {
      type: 'string'
    },
    showAtBreakpoint: {
      type: 'string'
    },
    textColor: {
      type: 'string'
    },
    width: {
      type: 'string'
    }
  }
};
var Shape = {
  properties: {
    borderRadius: {
      type: 'string'
    }
  }
};
var ThemeSpacing = {
  properties: {
    sectionHorizontal: {
      type: 'number'
    },
    sectionVertical: {
      type: 'number'
    },
    unit: {
      type: 'number'
    }
  }
};
var ConfigTheme = {
  properties: {
    breakpoints: 'Breakpoints',
    codeBlock: 'CodeBlock',
    colors: 'ThemeColors',
    components: 'Components',
    layout: 'Layout',
    logo: 'Logo',
    overrides: 'Overrides',
    rightPanel: 'RightPanel',
    schema: 'Schema',
    shape: 'Shape',
    sidebar: 'Sidebar',
    spacing: 'ThemeSpacing',
    typography: 'Typography',
    links: {
      properties: {
        color: {
          type: 'string'
        }
      }
    },
    codeSample: {
      properties: {
        backgroundColor: {
          type: 'string'
        }
      }
    }
  }
};
var GenerateCodeSamples = {
  properties: {
    skipOptionalParameters: {
      type: 'boolean'
    },
    languages: _1.listOf('ConfigLanguage')
  }
};
var ConfigReferenceDocs = {
  properties: {
    theme: 'ConfigTheme',
    corsProxyUrl: {
      type: 'string'
    },
    ctrlFHijack: {
      type: 'boolean'
    },
    defaultSampleLanguage: {
      type: 'string'
    },
    disableDeepLinks: {
      type: 'boolean'
    },
    disableSearch: {
      type: 'boolean'
    },
    disableSidebar: {
      type: 'boolean'
    },
    downloadDefinitionUrl: {
      type: 'string'
    },
    expandDefaultServerVariables: {
      type: 'boolean'
    },
    expandResponses: {
      type: 'string'
    },
    expandSingleSchemaField: {
      type: 'boolean'
    },
    generateCodeSamples: 'GenerateCodeSamples',
    generatedPayloadSamplesMaxDepth: {
      type: 'number'
    },
    hideDownloadButton: {
      type: 'boolean'
    },
    hideHostname: {
      type: 'boolean'
    },
    hideInfoSection: {
      type: 'boolean'
    },
    hideLoading: {
      type: 'boolean'
    },
    hideLogo: {
      type: 'boolean'
    },
    hideRequestPayloadSample: {
      type: 'boolean'
    },
    hideSchemaPattern: {
      type: 'boolean'
    },
    hideSchemaTitles: {
      type: 'boolean'
    },
    hideSingleRequestSampleTab: {
      type: 'boolean'
    },
    htmlTemplate: {
      type: 'string'
    },
    jsonSampleExpandLevel: {
      type: 'string'
    },
    labels: 'ConfigLabels',
    layout: {
      type: 'string'
    },
    maxDisplayedEnumValues: {
      type: 'number'
    },
    menuToggle: {
      type: 'boolean'
    },
    nativeScrollbars: {
      type: 'boolean'
    },
    noAutoAuth: {
      type: 'boolean'
    },
    oAuth2RedirectURI: {
      type: 'string'
    },
    onDeepLinkClick: {
      type: 'object'
    },
    onlyRequiredInSamples: {
      type: 'boolean'
    },
    pagination: {
      type: 'string'
    },
    pathInMiddlePanel: {
      type: 'boolean'
    },
    payloadSampleIdx: {
      type: 'number'
    },
    requestInterceptor: {
      type: 'object'
    },
    requiredPropsFirst: {
      type: 'boolean'
    },
    routingBasePath: {
      type: 'string'
    },
    samplesTabsMaxCount: {
      type: 'number'
    },
    schemaExpansionLevel: {
      type: 'string'
    },
    scrollYOffset: {
      type: 'string'
    },
    searchAutoExpand: {
      type: 'boolean'
    },
    searchFieldLevelBoost: {
      type: 'number'
    },
    searchMode: {
      type: 'string'
    },
    searchOperationTitleBoost: {
      type: 'number'
    },
    searchTagTitleBoost: {
      type: 'number'
    },
    showChangeLayoutButton: {
      type: 'boolean'
    },
    showConsole: {
      type: 'boolean'
    },
    showExtensions: {
      type: 'boolean'
    },
    showNextButton: {
      type: 'boolean'
    },
    showRightPanelToggle: {
      type: 'boolean'
    },
    sidebarLinks: 'ConfigSidebarLinks',
    sideNavStyle: {
      type: 'string'
    },
    simpleOneOfTypeLabel: {
      type: 'boolean'
    },
    sortEnumValuesAlphabetically: {
      type: 'boolean'
    },
    sortOperationsAlphabetically: {
      type: 'boolean'
    },
    sortPropsAlphabetically: {
      type: 'boolean'
    },
    sortTagsAlphabetically: {
      type: 'boolean'
    },
    unstable_ignoreMimeParameters: {
      type: 'boolean'
    },
    untrustedDefinition: {
      type: 'boolean'
    }
  },
  additionalProperties: {
    type: 'string'
  }
};
exports.ConfigTypes = {
  ConfigRoot: ConfigRoot,
  ConfigLint: ConfigLint,
  ConfigReferenceDocs: ConfigReferenceDocs,
  ConfigHTTP: ConfigHTTP,
  ConfigLanguage: ConfigLanguage,
  ConfigLabels: ConfigLabels,
  ConfigSidebarLinks: ConfigSidebarLinks,
  ConfigTheme: ConfigTheme,
  ThemeColors: ThemeColors,
  CommonThemeColors: CommonThemeColors,
  BorderThemeColors: BorderThemeColors,
  HttpColors: HttpColors,
  ResponseColors: ResponseColors,
  SecondaryColors: SecondaryColors,
  TextThemeColors: TextThemeColors,
  Sizes: Sizes,
  ButtonsConfig: ButtonsConfig,
  CommonColorProps: CommonColorProps,
  BadgeFontConfig: BadgeFontConfig,
  BadgeSizes: BadgeSizes,
  HttpBadgesConfig: HttpBadgesConfig,
  LabelControls: LabelControls,
  Panels: Panels,
  TryItButton: TryItButton,
  Breakpoints: Breakpoints,
  StackedConfig: StackedConfig,
  ThreePanelConfig: ThreePanelConfig,
  SchemaColorsConfig: SchemaColorsConfig,
  SizeProps: SizeProps,
  Level1Items: Level1Items,
  SpacingConfig: SpacingConfig,
  FontConfig: FontConfig,
  CodeConfig: CodeConfig,
  HeadingsConfig: HeadingsConfig,
  LinksConfig: LinksConfig,
  TokenProps: TokenProps,
  CodeBlock: CodeBlock,
  Logo: Logo,
  ButtonOverrides: ButtonOverrides,
  Overrides: Overrides,
  RightPanel: RightPanel,
  Shape: Shape,
  ThemeSpacing: ThemeSpacing,
  GenerateCodeSamples: GenerateCodeSamples,
  GroupItemsConfig: GroupItemsConfig,
  Components: Components,
  Layout: Layout,
  Schema: Schema,
  Sidebar: Sidebar,
  Heading: Heading,
  Typography: Typography
};