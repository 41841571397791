import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { ExtensionValue, FieldLabel } from "../../common-elements/fields";
import styled from "../../styled-components";
import { OptionsContext } from "../OptionsProvider";
import { StyledMarkdownBlock } from "../Markdown/styled.elements";

var Extension = styled(StyledMarkdownBlock)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 2px 0;\n"], ["\n  margin: 2px 0;\n"]))),
    Extensions = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.extensions;
      return React.createElement(OptionsContext.Consumer, null, function (t) {
        return React.createElement(React.Fragment, null, t.showExtensions ? React.createElement(ExtensionsWrap, null, Object.keys(e).map(function (t) {
          return React.createElement(Extension, {
            key: t
          }, React.createElement(FieldLabel, null, " ", t.substring(2), ": "), " ", React.createElement(ExtensionValue, null, "string" == typeof e[t] ? e[t] : JSON.stringify(e[t])));
        })) : null);
      });
    }
  }), t;
}(React.PureComponent);

export { Extensions };
var templateObject_1,
    templateObject_2,
    ExtensionsWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  /* margin: 15px 0; */ // TODO: fix margin for Extensions\n"], ["\n  /* margin: 15px 0; */ // TODO: fix margin for Extensions\n"])));