import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../../styled-components";
import { PayloadSamples } from "../PayloadSamples/PayloadSamples";

var templateObject_1,
    CallbackPayloadSample = function (e) {
  function a() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(a, e), Object.defineProperty(a.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.callback.payload;
      return e ? React.createElement(PayloadSampleWrapper, null, React.createElement(PayloadSamples, {
        content: e.requestBodyContent
      })) : null;
    }
  }), a;
}(React.Component);

export { CallbackPayloadSample };
export var PayloadSampleWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 15px;\n"], ["\n  margin-top: 15px;\n"])));