"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateAdditionalItems = void 0;

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var error = {
  message: function message(_ref) {
    var len = _ref.params.len;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must NOT have more than ", " items"])), len);
  },
  params: function params(_ref2) {
    var len = _ref2.params.len;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{limit: ", "}"])), len);
  }
};
var def = {
  keyword: "additionalItems",
  type: "array",
  schemaType: ["boolean", "object"],
  before: "uniqueItems",
  error: error,
  code: function code(cxt) {
    var parentSchema = cxt.parentSchema,
        it = cxt.it;
    var items = parentSchema.items;

    if (!Array.isArray(items)) {
      util_1.checkStrictMode(it, '"additionalItems" is ignored when "items" is not an array of schemas');
      return;
    }

    validateAdditionalItems(cxt, items);
  }
};

function validateAdditionalItems(cxt, items) {
  var gen = cxt.gen,
      schema = cxt.schema,
      data = cxt.data,
      keyword = cxt.keyword,
      it = cxt.it;
  it.items = true;
  var len = gen.const("len", codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", ".length"])), data));

  if (schema === false) {
    cxt.setParams({
      len: items.length
    });
    cxt.pass(codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", " <= ", ""])), len, items.length));
  } else if (typeof schema == "object" && !util_1.alwaysValidSchema(it, schema)) {
    var valid = gen.var("valid", codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", " <= ", ""])), len, items.length)); // TODO var

    gen.if(codegen_1.not(valid), function () {
      return validateItems(valid);
    });
    cxt.ok(valid);
  }

  function validateItems(valid) {
    gen.forRange("i", items.length, len, function (i) {
      cxt.subschema({
        keyword: keyword,
        dataProp: i,
        dataPropType: util_1.Type.Num
      }, valid);
      if (!it.allErrors) gen.if(codegen_1.not(valid), function () {
        return gen.break();
      });
    });
  }
}

exports.validateAdditionalItems = validateAdditionalItems;
exports.default = def;