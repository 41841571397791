import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../../styled-components";
import { SampleControls, SamplesControlButton } from "../../common-elements";
import { CopyButtonWrapper } from "../../common-elements/CopyButtonWrapper";
import { PrismDiv } from "../../common-elements/PrismDiv";
import { jsonToHTML } from "../../utils/jsonToHtml";
import { OptionsContext } from "../OptionsProvider";
import { jsonStyles } from "./style";

var templateObject_1,
    templateObject_2,
    templateObject_3,
    JsonViewerWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  &:hover > ", " {\n    opacity: 1;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  &:hover > ", " {\n    opacity: 1;\n  }\n"])), SampleControls),
    PrismFlex = styled(PrismDiv)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n\n  code {\n    font-family: ", ";\n    font-size: 14px;\n    line-height: 18px;\n  }\n"], ["\n  flex: 1;\n\n  code {\n    font-family: ", ";\n    font-size: 14px;\n    line-height: 18px;\n  }\n"])), function (e) {
  return e.theme.typography.code.fontFamily;
}),
    Json = function (e) {
  function t() {
    var t = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(t, "node", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(t, "renderInner", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        var n = e.renderCopyButton;
        return React.createElement(JsonViewerWrap, null, React.createElement(SampleControls, null, n(), React.createElement(SamplesControlButton, {
          onClick: t.expandAll
        }, "Expand all"), React.createElement(SamplesControlButton, {
          onClick: t.collapseAll
        }, "Collapse all")), React.createElement(OptionsContext.Consumer, null, function (e) {
          return React.createElement(PrismFlex, {
            className: t.props.className,
            ref: function ref(e) {
              return t.node = e;
            },
            dangerouslySetInnerHTML: {
              __html: jsonToHTML(t.props.data, e.jsonSampleExpandLevel)
            }
          });
        }));
      }
    }), Object.defineProperty(t, "expandAll", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        for (var e = t.node.getElementsByClassName("collapsible"), n = 0, l = Array.prototype.slice.call(e); n < l.length; n++) {
          var r = l[n].parentNode;
          r.classList.remove("collapsed"), r.querySelector(".collapser").setAttribute("aria-label", "collapse");
        }
      }
    }), Object.defineProperty(t, "collapseAll", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        for (var e = t.node.getElementsByClassName("collapsible"), n = 0, l = Array.prototype.slice.call(e, 1); n < l.length; n++) {
          var r = l[n].parentNode;
          r.classList.add("collapsed"), r.querySelector(".collapser").setAttribute("aria-label", "expand");
        }
      }
    }), Object.defineProperty(t, "collapseElement", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        var t;
        "collapser" === e.className && ((t = e.parentElement.getElementsByClassName("collapsible")[0]).parentElement.classList.contains("collapsed") ? (t.parentElement.classList.remove("collapsed"), e.setAttribute("aria-label", "collapse")) : (t.parentElement.classList.add("collapsed"), e.setAttribute("aria-label", "expand")));
      }
    }), Object.defineProperty(t, "clickListener", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        t.collapseElement(e.target);
      }
    }), Object.defineProperty(t, "focusListener", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        "Enter" === e.key && t.collapseElement(e.target);
      }
    }), t;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return React.createElement(CopyButtonWrapper, {
        data: this.props.data
      }, this.renderInner);
    }
  }), Object.defineProperty(t.prototype, "componentDidMount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.node.addEventListener("click", this.clickListener), this.node.addEventListener("focus", this.focusListener);
    }
  }), Object.defineProperty(t.prototype, "componentWillUnmount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.node.removeEventListener("click", this.clickListener), this.node.removeEventListener("focus", this.focusListener);
    }
  }), t;
}(React.PureComponent);

export var JsonViewer = styled(Json)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), jsonStyles);