"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var code_1 = require("../code");

var codegen_1 = require("../../compile/codegen");

var error = {
  message: function message(_ref) {
    var schemaCode = _ref.schemaCode;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must match pattern \"", "\""])), schemaCode);
  },
  params: function params(_ref2) {
    var schemaCode = _ref2.schemaCode;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{pattern: ", "}"])), schemaCode);
  }
};
var def = {
  keyword: "pattern",
  type: "string",
  schemaType: "string",
  $data: true,
  error: error,
  code: function code(cxt) {
    var data = cxt.data,
        $data = cxt.$data,
        schema = cxt.schema,
        schemaCode = cxt.schemaCode,
        it = cxt.it; // TODO regexp should be wrapped in try/catchs

    var u = it.opts.unicodeRegExp ? "u" : "";
    var regExp = $data ? codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["(new RegExp(", ", ", "))"])), schemaCode, u) : code_1.usePattern(cxt, schema);
    cxt.fail$data(codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["!", ".test(", ")"])), regExp, data));
  }
};
exports.default = def;