import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
import { FieldsGroupHeader, ShelfIcon } from "../../redoc-lib/src/common-elements";
import { StyledMarkdownSpan } from "../../redoc-lib/src/components/Markdown/styled.elements";
import { Panel } from "./Panel";
import { Header } from "./Header";
import { Trigger } from "./Trigger";
import { Title } from "./Title";
export var ResponsePanel = styled(Panel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  background-color: ", ";\n\n  > ", " {\n    padding: 10px 20px;\n    font-size: 14px;\n    line-height: 20px;\n    white-space: normal;\n\n    ", " {\n      font-weight: 600;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      line-height: normal;\n      color: ", ";\n\n      ", " > p:first-child {\n        margin-left: 4px;\n        display: inline;\n      }\n    }\n\n    ", " {\n      flex: 1;\n      max-width: 100%;\n      justify-content: space-between;\n    }\n\n    ", " {\n      fill: ", ";\n    }\n  }\n\n  ", " {\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n"], ["\n  border: 1px solid ", ";\n  background-color: ", ";\n\n  > ", " {\n    padding: 10px 20px;\n    font-size: 14px;\n    line-height: 20px;\n    white-space: normal;\n\n    ", " {\n      font-weight: 600;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      line-height: normal;\n      color: ", ";\n\n      ", " > p:first-child {\n        margin-left: 4px;\n        display: inline;\n      }\n    }\n\n    ", " {\n      flex: 1;\n      max-width: 100%;\n      justify-content: space-between;\n    }\n\n    ", " {\n      fill: ", ";\n    }\n  }\n\n  ", " {\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n"])), function (n) {
  var e = n.theme,
      r = n.type;
  return e.colors.responses[r].borderColor;
}, function (n) {
  var e = n.theme,
      r = n.type;
  return e.colors.responses[r].backgroundColor;
}, Header, Title, function (n) {
  var e = n.theme,
      r = n.type;
  return e.colors.responses[r].color || e.colors.text.primary;
}, StyledMarkdownSpan, Trigger, ShelfIcon, function (n) {
  return n.theme.colors.text.primary;
}, FieldsGroupHeader);
var templateObject_1;