import { __assign } from "tslib";
import { OperationModel } from "./Operation";
import { isOperationName } from "../..";

var WebhookModel = function () {
  function e(e, o, r) {
    Object.defineProperty(this, "operations", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    });
    var i = e.deref(r || {});
    e.exitRef(r), this.initWebhooks(e, i, o);
  }

  return Object.defineProperty(e.prototype, "initWebhooks", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, o, r) {
      for (var i, t = 0, n = Object.keys(o); t < n.length; t++) {
        for (var a = o[n[t]], s = 0, f = Object.keys(a).filter(isOperationName); s < f.length; s++) {
          var b = f[s],
              p = a[b];

          if (a.$ref) {
            var l = e.deref(a || {});
            this.initWebhooks(e, ((i = {})[b] = l, i), r);
          }

          if (p) {
            var h = new OperationModel(e, __assign(__assign({}, p), {
              httpVerb: b
            }), void 0, r, !1);
            this.operations.push(h);
          }
        }
      }
    }
  }), e;
}();

export { WebhookModel };