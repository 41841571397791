import * as React from "react";
import { Header, Trigger } from "../../../../components/Panel";
import { HttpVerb } from "../../../../components/shared";
import { ShelfIcon } from "../../common-elements/icons";
import { shortenHTTPVerb } from "../../utils/openapi";
import { Badge } from "../../common-elements/";
import { l } from "../../services/Labels";
import { CallbackTitle } from "./CallbackTitle";
export var CallbackSummary = function CallbackSummary(e) {
  var t = e.expanded,
      r = e.toggle,
      a = e.operation,
      o = a.name,
      n = a.httpVerb,
      c = a.deprecated;
  return React.createElement(Header, {
    onClick: r
  }, React.createElement(Trigger, null, React.createElement("div", null, React.createElement(HttpVerb, {
    type: n
  }, shortenHTTPVerb(n)), React.createElement(CallbackTitle, {
    deprecated: c
  }, o), c ? React.createElement(Badge, {
    type: "warning"
  }, l("deprecated")) : null), React.createElement(ShelfIcon, {
    direction: t ? "down" : "right"
  })));
};