"use strict";

import JsonPointerLib from "json-pointer";

var origParse = JsonPointerLib.parse,
    JsonPointer = function () {
  function e() {}

  return Object.defineProperty(e, "baseName", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(r, n) {
      void 0 === r && (r = ""), void 0 === n && (n = 1);
      var i = e.parse(r);
      return i[i.length - n];
    }
  }), Object.defineProperty(e, "dirName", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(r, n) {
      void 0 === n && (n = 1);
      var i = e.parse(r);
      return JsonPointerLib.compile(i.slice(0, i.length - n));
    }
  }), Object.defineProperty(e, "relative", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(r, n) {
      var i = e.parse(r);
      return e.parse(n).slice(i.length);
    }
  }), Object.defineProperty(e, "parse", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      void 0 === e && (e = "");
      var r = e;
      return "#" === r.charAt(0) && (r = r.substring(1)), origParse(r);
    }
  }), Object.defineProperty(e, "join", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(r, n) {
      var i = e.parse(r).concat(n);
      return JsonPointerLib.compile(i);
    }
  }), Object.defineProperty(e, "get", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      return JsonPointerLib.get(e, r);
    }
  }), Object.defineProperty(e, "compile", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return JsonPointerLib.compile(e);
    }
  }), Object.defineProperty(e, "escape", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return JsonPointerLib.escape(e);
    }
  }), e;
}();

export { JsonPointer };
JsonPointerLib.parse = JsonPointer.parse, Object.assign(JsonPointer, JsonPointerLib);
export default JsonPointer;