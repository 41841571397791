"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var error = {
  message: function message(_ref) {
    var keyword = _ref.keyword,
        schemaCode = _ref.schemaCode;
    var comp = keyword === "maxItems" ? "more" : "fewer";
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must NOT have ", " than ", " items"])), comp, schemaCode);
  },
  params: function params(_ref2) {
    var schemaCode = _ref2.schemaCode;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{limit: ", "}"])), schemaCode);
  }
};
var def = {
  keyword: ["maxItems", "minItems"],
  type: "array",
  schemaType: "number",
  $data: true,
  error: error,
  code: function code(cxt) {
    var keyword = cxt.keyword,
        data = cxt.data,
        schemaCode = cxt.schemaCode;
    var op = keyword === "maxItems" ? codegen_1.operators.GT : codegen_1.operators.LT;
    cxt.fail$data(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", ".length ", " ", ""])), data, op, schemaCode));
  }
};
exports.default = def;