import { __decorate } from "tslib";
import { bind, debounce } from "decko";
import { EventEmitter } from "eventemitter3";

var EVENT = "itemchange",
    FakeHistoryService = function () {
  function e(e) {
    var r = this;
    Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "_emitter", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: new EventEmitter()
    }), Object.defineProperty(this, "basePath", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "currentId", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "emit", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        r._emitter.emit(EVENT, r.currentId);
      }
    }), this.bind();
  }

  return Object.defineProperty(e.prototype, "linkForId", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return this.options.linkForId ? this.options.linkForId(e) : "";
    }
  }), Object.defineProperty(e.prototype, "generateDeepLink", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      return e && this.options.generateDeepLink ? this.options.generateDeepLink(e, r) : null;
    }
  }), Object.defineProperty(e.prototype, "subscribe", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var r = this._emitter.addListener(EVENT, e);

      return function () {
        return r.removeListener(EVENT, e);
      };
    }
  }), Object.defineProperty(e.prototype, "bind", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {}
  }), Object.defineProperty(e.prototype, "dispose", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {}
  }), Object.defineProperty(e.prototype, "replace", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      void 0 === r && (r = !1);
    }
  }), Object.defineProperty(e.prototype, "replaceForField", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      void 0 === r && (r = !1), this.currentId = (e || "").replace(/^#/, ""), this.emit();
    }
  }), Object.defineProperty(e.prototype, "replaceNow", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, r) {
      void 0 === r && (r = !1);
    }
  }), Object.defineProperty(e.prototype, "replaceDebounced", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {}
  }), __decorate([bind], e.prototype, "replace", null), __decorate([bind], e.prototype, "replaceForField", null), __decorate([bind, debounce], e.prototype, "replaceDebounced", null), e;
}();

export { FakeHistoryService };