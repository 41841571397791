"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoAmbiguousPaths = void 0;

var NoAmbiguousPaths = function NoAmbiguousPaths() {
  return {
    PathMap: function PathMap(pathMap, _ref) {
      var report = _ref.report,
          location = _ref.location;
      var seenPaths = [];

      var _loop = function _loop() {
        var currentPath = _Object$keys[_i];
        var ambiguousPath = seenPaths.find(function (seenPath) {
          return arePathsAmbiguous(seenPath, currentPath);
        });

        if (ambiguousPath) {
          report({
            message: "Paths should resolve unambiguously. Found two ambiguous paths: `".concat(ambiguousPath, "` and `").concat(currentPath, "`."),
            location: location.child([currentPath]).key()
          });
        }

        seenPaths.push(currentPath);
      };

      for (var _i = 0, _Object$keys = Object.keys(pathMap); _i < _Object$keys.length; _i++) {
        _loop();
      }
    }
  };
};

exports.NoAmbiguousPaths = NoAmbiguousPaths;

function arePathsAmbiguous(a, b) {
  var partsA = a.split('/');
  var partsB = b.split('/');
  if (partsA.length !== partsB.length) return false;
  var aVars = 0;
  var bVars = 0;
  var ambiguous = true;

  for (var i = 0; i < partsA.length; i++) {
    var aIsVar = partsA[i].match(/^{.+?}$/);
    var bIsVar = partsB[i].match(/^{.+?}$/);

    if (aIsVar || bIsVar) {
      if (aIsVar) aVars++;
      if (bIsVar) bVars++;
      continue;
    } else if (partsA[i] !== partsB[i]) {
      ambiguous = false;
    }
  }

  return ambiguous && aVars === bVars;
}