import { __extends } from "tslib";
import * as React from "react";
import { PropertiesTable, PropertiesTableWrap } from "../../common-elements/fields-layout";
import { mapWithLast } from "../../utils";
import { Field } from "../Fields/Field";
import { HeadersCaption } from "./styled.elements";

var ResponseHeaders = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.headers;
      return void 0 === e || 0 === e.length ? null : React.createElement(PropertiesTableWrap, null, React.createElement(PropertiesTable, null, React.createElement(HeadersCaption, null, " Response Headers "), React.createElement("tbody", null, mapWithLast(e, function (e, t) {
        return React.createElement(Field, {
          isLast: t,
          key: e.name,
          field: e,
          showExamples: !0
        });
      }))));
    }
  }), t;
}(React.PureComponent);

export { ResponseHeaders };