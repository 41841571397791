import { __decorate } from "tslib";
import { SpecStore } from "../redoc-lib";
import { action, observable, makeObservable } from "mobx";

var SpecGroupModel = function () {
  function e(e, t, r, i) {
    var a = this;
    void 0 === i && (i = 0), Object.defineProperty(this, "id", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "absoluteIdx", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "description", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "items", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "externalDocs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "active", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "expanded", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "depth", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "level", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), makeObservable(this), this.id = e, this.type = "spec", this.name = r || "", this.level = 0, this.depth = i, this.description = "", this.parent = void 0, this.expanded = !0, this.items = t, t.forEach(function (e) {
      return e.parent = a;
    });
  }

  return Object.defineProperty(e.prototype, "activate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {}
  }), Object.defineProperty(e.prototype, "expand", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {}
  }), Object.defineProperty(e.prototype, "collapse", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {}
  }), Object.defineProperty(e.prototype, "deactivate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {}
  }), __decorate([observable], e.prototype, "active", void 0), __decorate([observable], e.prototype, "expanded", void 0), __decorate([action], e.prototype, "activate", null), __decorate([action], e.prototype, "expand", null), __decorate([action], e.prototype, "collapse", null), __decorate([action], e.prototype, "deactivate", null), e;
}();

export { SpecGroupModel };

var SpecItemModel = function () {
  function e(e, t, r, i) {
    var a = this;
    void 0 === i && (i = 1), Object.defineProperty(this, "id", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "absoluteIdx", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "description", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "items", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: []
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "externalDocs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "active", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "expanded", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(this, "depth", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "level", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), makeObservable(this), this.id = e, this.type = "spec", this.name = r || "", this.level = 0, this.depth = i, this.description = "", this.parent = void 0, this.expanded = !1, this.items = t, t.forEach(function (e) {
      return e.parent = a;
    });
  }

  return Object.defineProperty(e.prototype, "activate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.active = !0;
    }
  }), Object.defineProperty(e.prototype, "expand", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.parent && this.parent.expand(), this.expanded = !0;
    }
  }), Object.defineProperty(e.prototype, "collapse", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.expanded = !1;
    }
  }), Object.defineProperty(e.prototype, "deactivate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.active = !1;
    }
  }), __decorate([observable], e.prototype, "active", void 0), __decorate([observable], e.prototype, "expanded", void 0), __decorate([action], e.prototype, "activate", null), __decorate([action], e.prototype, "expand", null), __decorate([action], e.prototype, "collapse", null), __decorate([action], e.prototype, "deactivate", null), e;
}();

export { SpecItemModel };

function assignSpecIdxDeep(e, t, r) {
  e.forEach(function (e) {
    e.items && assignSpecIdxDeep(e.items, t, r), e.id = r + "/" + e.id, "operation" === e.type && (e.specIdx = t);
  });
}

var MultipleSpecsStore = function () {
  function e(e, t) {
    var r;
    Object.defineProperty(this, "config", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    }), Object.defineProperty(this, "specs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "contentItems", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "info", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "externalDocs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "securitySchemes", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.specs = [], this.contentItems = [];

    for (var i = 0, a = 0, o = e; a < o.length; a++) {
      var n = o[a],
          l = [];
      if (n.url) this.addSpec(n, t, i, n, l), i++, (r = this.contentItems).push.apply(r, l);else {
        for (var c = 0, b = n.items || []; c < b.length; c++) {
          var p = b[c];
          this.addSpec(p, t, i, n, l), i++;
        }

        var u = new SpecGroupModel(n.id, l, n.title);
        this.contentItems.push(u);
      }
    }

    this.info = this.specs[0].info, this.externalDocs = this.specs[0].externalDocs, this.securitySchemes = this.specs[0].securitySchemes;
  }

  return Object.defineProperty(e.prototype, "addSpec", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t, r, i, a) {
      var o = new SpecStore(e.definition, e.url, t);
      this.specs.push(o), assignSpecIdxDeep(o.contentItems, r, i.id), e.title ? (increaseDepthDeep(o.contentItems), a.push(new SpecItemModel(e.id, o.contentItems, e.title, 1))) : a.push.apply(a, o.contentItems);
    }
  }), Object.defineProperty(e.prototype, "parser", {
    get: function get() {
      return this.specs[0].parser;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "toJS", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return this.config;
    }
  }), e;
}();

export { MultipleSpecsStore };

function increaseDepthDeep(e) {
  Array.isArray(e) && e.forEach(function (e) {
    e.depth++, increaseDepthDeep(e.items);
  });
}