"use strict";

var _defineProperty = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRules = exports.isJSONType = void 0;
var _jsonTypes = ["string", "number", "integer", "boolean", "null", "object", "array"];
var jsonTypes = new Set(_jsonTypes);

function isJSONType(x) {
  return typeof x == "string" && jsonTypes.has(x);
}

exports.isJSONType = isJSONType;

function getRules() {
  var groups = {
    number: {
      type: "number",
      rules: []
    },
    string: {
      type: "string",
      rules: []
    },
    array: {
      type: "array",
      rules: []
    },
    object: {
      type: "object",
      rules: []
    }
  };
  return {
    types: _objectSpread(_objectSpread({}, groups), {}, {
      integer: true,
      boolean: true,
      null: true
    }),
    rules: [{
      rules: []
    }, groups.number, groups.string, groups.array, groups.object],
    post: {
      rules: []
    },
    all: {},
    keywords: {}
  };
}

exports.getRules = getRules;