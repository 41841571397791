"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationDescription = void 0;

var utils_1 = require("../utils");

var OperationDescription = function OperationDescription() {
  return {
    Operation: function Operation(operation, ctx) {
      utils_1.validateDefinedAndNonEmpty('description', operation, ctx);
    }
  };
};

exports.OperationDescription = OperationDescription;