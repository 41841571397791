import { __decorate, __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import { observer } from "mobx-react";
import { configure, reaction } from "mobx";
import { ApiInfo, ApiLogo, argValueToBoolean, ContentItems, createGlobalStyle, ErrorBoundary, Loading, mediaCSS, mediaMap, MiddlePanel, RedocNormalizedOptions, RightPanel, SideMenu, StickyResponsiveSidebar, ZERO_BREAKPOINT } from "../redoc-lib";
import { ProSearchBox, SearchField } from "./Search";
import { ContinueButton } from "./NextSectionButton";
import { ProStoreProvider } from "./StoreProvider";
import { LayoutVariant } from "../services/store-types";
import { Console } from "./console/ConsoleLazy";
import { MultipleSpecsStore } from "../services/MultipleSpecsStore";
import { ProProviders } from "./ProProviders";
import { Overlay } from "./Overlay";
import { VersionedSpecStore } from "../services/VersionedSpecStore";
import { VersionSwitcher } from "./VersionSwitcher";
import { BackgroundStub, BackgroundStubFix, RedocWrap, ApiContentWrap, NextSectionButtonWrap, ToggleRightPanelButton, ChangeViewButton, ControlsWrap } from "./styled.elements";
configure({
  useProxies: "ifavailable"
});
export var CssFixes = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(['\n  div[data-section-id]::after {\n    content: " ";\n    display: block;\n    height: 0;\n    clear: both;\n    visibility: hidden;\n  }\n\n  ', "  {\n    ", "\n  }\n\n  ", ", ", "  {\n    ", "\n  }\n\n  ", ", ", " {\n    ", "\n  }\n\n  ", " {\n    ", ";\n  }\n"], ['\n  div[data-section-id]::after {\n    content: " ";\n    display: block;\n    height: 0;\n    clear: both;\n    visibility: hidden;\n  }\n\n  ', "  {\n    ", "\n  }\n\n  ", ", ", "  {\n    ", "\n  }\n\n  ", ", ", " {\n    ", "\n  }\n\n  ", " {\n    ", ";\n  }\n"])), RightPanel, function (e) {
  var t,
      n = e.theme,
      o = e.showRightPanel,
      r = e.layout;
  return mediaCSS({
    width: r === LayoutVariant.STACKED ? "100%" : "",
    paddingLeft: r === LayoutVariant.STACKED ? n.spacing.sectionHorizontal : "",
    display: o ? "" : (t = {}, t[ZERO_BREAKPOINT] = "", t[n.rightPanel.showAtBreakpoint] = "none", t)
  });
}, BackgroundStub, BackgroundStubFix, function (e) {
  var t,
      n = e.theme,
      o = e.showRightPanel,
      r = e.layout;
  return mediaCSS({
    display: o && r !== LayoutVariant.STACKED ? "" : (t = {}, t[ZERO_BREAKPOINT] = "", t[n.rightPanel.showAtBreakpoint] = "none", t)
  });
}, MiddlePanel, NextSectionButtonWrap, function (e) {
  var t,
      n,
      o,
      r = e.theme,
      a = e.showRightPanel,
      i = e.layout;
  return mediaCSS({
    maxWidth: a && i === LayoutVariant.THREE_PANEL ? mediaMap(r.breakpoints, [r.layout["three-panel"].middlePanelMaxWidth], (t = {}, t[ZERO_BREAKPOINT] = function () {
      return "none";
    }, t[r.rightPanel.showAtBreakpoint] = function (e) {
      return e;
    }, t)) : "none",
    margin: a && i === LayoutVariant.THREE_PANEL ? mediaMap(r.breakpoints, [r.rightPanel.width, r.layout["three-panel"].middlePanelMaxWidth], (n = {}, n[ZERO_BREAKPOINT] = function () {
      return "0";
    }, n[r.rightPanel.showAtBreakpoint] = function (e, t) {
      return t && "none" !== t ? "0 max(calc((100% - " + e + " - " + t + "}) / 2), 0px)" : "0";
    }, n)) : "0",
    paddingRight: i === LayoutVariant.STACKED ? r.spacing.sectionHorizontal : "",
    width: a && i === LayoutVariant.THREE_PANEL ? "" : (o = {}, o[ZERO_BREAKPOINT] = "", o[r.rightPanel.showAtBreakpoint] = "100%", o)
  });
}, ApiContentWrap, function (e) {
  var t,
      n = e.theme,
      o = e.showRightPanel,
      r = e.layout;
  return mediaCSS({
    width: (t = {}, t[ZERO_BREAKPOINT] = "none", t.small = "calc(100% - " + n.sidebar.width + ")", t),
    maxWidth: n.layout[r === LayoutVariant.THREE_PANEL && o ? LayoutVariant.THREE_PANEL : LayoutVariant.STACKED].maxWidth
  });
});

var RedoclyReferenceDocs = function (e) {
  function t(t) {
    var n = e.call(this, t) || this;
    return Object.defineProperty(n, "_observer", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(n, "_updatedItems", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), Object.defineProperty(n, "rootElementRef", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: React.createRef()
    }), Object.defineProperty(n, "handleClose", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        return n.props.store.tryItOut();
      }
    }), n.state = {
      searchPopUpShown: !1,
      searchPopUpValue: ""
    }, n;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "componentDidMount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this;
      this.props.store.onDidMount(), this._observer = reaction(function () {
        return e.props.store.menu.renderItems;
      }, function () {
        e._updatedItems = !0;
      });
    }
  }), Object.defineProperty(t.prototype, "componentDidUpdate", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this._updatedItems && (this._updatedItems = !1, this.props.store.menu.scrollToActive());
    }
  }), Object.defineProperty(t.prototype, "componentWillUnmount", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      this.props.store.dispose(), this._observer();
    }
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this,
          t = this.props.store,
          n = t.definition,
          o = t.menu,
          r = t.options,
          a = t.search,
          i = t.marker,
          l = this.props.store,
          c = o.renderItems,
          s = !r.hideInfoSection && ("none" === r.pagination || l.menu.activeRenderItemIdx <= 0);
      return s && "none" !== r.pagination && r.disableSidebar && c[0] && "section" !== c[0].type && (s = !1), n instanceof MultipleSpecsStore && (s = !1), this.props._forceRenderInfo && (s = !0), React.createElement(ProProviders, {
        store: l
      }, React.createElement(RedocWrap, {
        className: "redoc-wrap",
        ref: this.rootElementRef
      }, React.createElement(Overlay, {
        l: l.l
      }), React.createElement(CssFixes, {
        whiteLabel: r.whiteLabel,
        showRightPanel: l.showRightPanel,
        layout: l.layout
      }), l.tryItOperation && React.createElement(Console, {
        rootElement: this.rootElementRef.current,
        store: l,
        operation: l.tryItOperation,
        onClose: this.handleClose,
        specIdx: l.tryItOperation.specIdx
      }), !r.disableSidebar && React.createElement(StickyResponsiveSidebar, {
        menu: o,
        className: "menu-content"
      }, !l.options.hideLogo && React.createElement(ApiLogo, {
        info: n.info
      }), n instanceof VersionedSpecStore && React.createElement(VersionSwitcher, {
        spec: n,
        onChange: function onChange(e) {
          n.changeVersion(e.idx), o.updateItemsByVersionChange(!0), l.tryItOut(void 0);
        }
      }), !l.options.disableSearch && React.createElement(SearchField, {
        showPopUp: function showPopUp() {
          return e.setState({
            searchPopUpShown: !0
          });
        },
        searchValue: this.state.searchPopUpValue,
        marker: i,
        setSearchValue: function setSearchValue(t) {
          return e.setState({
            searchPopUpValue: t
          });
        }
      }), React.createElement(SideMenu, {
        menu: o
      })), React.createElement(ApiContentWrap, {
        className: (l.isLoading ? "loading " : "") + "api-content",
        layout: l.layout === LayoutVariant.THREE_PANEL && l.showRightPanel ? LayoutVariant.THREE_PANEL : LayoutVariant.STACKED
      }, r.theme.layout.showDarkRightPanel && l.showRightPanel && l.layout !== LayoutVariant.STACKED && React.createElement(BackgroundStub, null), s && React.createElement(ApiInfo, {
        store: l
      }), React.createElement(ContentItems, {
        items: c,
        store: l
      }), r.showNextButton && React.createElement(ContinueButton, {
        pagination: r.pagination,
        menu: o
      }), this.props.children), React.createElement(ControlsWrap, null, React.createElement(ToggleRightPanelButton, {
        store: l,
        isVisible: l.layout === LayoutVariant.THREE_PANEL
      }), React.createElement(ChangeViewButton, {
        store: l,
        isVisible: l.showRightPanel
      })), React.createElement(ProSearchBox, {
        search: a,
        marker: i,
        menu: o,
        onActivate: o.activateAndScroll.bind(o),
        options: r,
        shown: this.state.searchPopUpShown,
        hidePopUp: function hidePopUp() {
          return e.setState({
            searchPopUpShown: !1
          });
        },
        showPopUp: function showPopUp() {
          return e.setState({
            searchPopUpShown: !0
          });
        },
        setSearchValue: function setSearchValue(t) {
          return e.setState({
            searchPopUpValue: t
          });
        },
        searchValue: this.state.searchPopUpValue
      }), r.theme.layout.showDarkRightPanel && l.showRightPanel && l.layout !== LayoutVariant.STACKED && React.createElement(BackgroundStubFix, null)));
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { RedoclyReferenceDocs };
export function RedoclyReferenceDocsStandalone(e) {
  var t = e.definition,
      n = e.definitionUrl,
      o = e.options,
      r = void 0 === o ? {} : o,
      a = e.activeItemId,
      i = e.activeSampleLanguage,
      l = e.activeDeepLink,
      c = argValueToBoolean(r.hideLoading, !1),
      s = new RedocNormalizedOptions(r),
      u = React.createElement(ProStoreProvider, {
    definition: t,
    definitionUrl: n,
    options: r,
    activeItemId: a,
    activeSampleLanguage: i,
    activeDeepLink: l
  }, function (e) {
    var t = e.loading,
        n = e.store;
    return t ? c ? null : React.createElement(Loading, {
      color: s.theme.colors.primary.main
    }) : React.createElement(RedoclyReferenceDocs, {
      store: n
    });
  });
  return r.disableErrorBoundary ? u : React.createElement(ErrorBoundary, null, u);
}

var templateObject_1,
    RedoclyReferenceDocsMultiStandalone = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.definitions,
          n = e.options,
          o = void 0 === n ? {} : n,
          r = argValueToBoolean(o.hideLoading, !1),
          a = new RedocNormalizedOptions(o);
      return React.createElement(ErrorBoundary, null, React.createElement(ProStoreProvider, {
        definitions: t,
        options: o
      }, function (e) {
        var t = e.loading,
            n = e.store;
        return t ? r ? null : React.createElement(Loading, {
          color: a.theme.colors.primary.main
        }) : React.createElement(RedoclyReferenceDocs, {
          store: n
        });
      }));
    }
  }), t;
}(React.Component);

export { RedoclyReferenceDocsMultiStandalone };