'use strict';

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var sjs = require('fast-safe-stringify');

var colour = process.env.NODE_DISABLE_COLORS ? {
  red: '',
  yellow: '',
  green: '',
  normal: ''
} : {
  red: '\x1b[31m',
  yellow: '\x1b[33;1m',
  green: '\x1b[32m',
  normal: '\x1b[0m'
};

function uniqueOnly(value, index, self) {
  return self.indexOf(value) === index;
}

function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

function allSame(array) {
  return new Set(array).size <= 1;
}

function deepEquals(obj1, obj2) {
  function _equals(obj1, obj2) {
    return sjs.stringify(obj1) === sjs.stringify(Object.assign({}, obj1, obj2));
  }

  return _equals(obj1, obj2) && _equals(obj2, obj1);
}

function compressArray(arr) {
  var result = [];

  var _iterator = _createForOfIteratorHelper(arr),
      _step;

  try {
    var _loop = function _loop() {
      var candidate = _step.value;
      var dupe = result.find(function (e, i, a) {
        return deepEquals(e, candidate);
      });
      if (!dupe) result.push(candidate);
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return result;
}

function distinctArray(arr) {
  return arr.length === compressArray(arr).length;
}

function firstDupe(arr) {
  return arr.find(function (e, i, a) {
    return arr.indexOf(e) < i;
  });
}
/**
 * simple hash implementation based on https://stackoverflow.com/a/7616484/1749888
 * @param {string} s - string to hash
 * @returns {number} numerical hash code
 */


function hash(s) {
  var hash = 0;
  var chr;
  if (s.length === 0) return hash;

  for (var i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

String.prototype.toCamelCase = function camelize() {
  return this.toLowerCase().replace(/[-_ \/\.](.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
};

var parameterTypeProperties = ['format', 'minimum', 'maximum', 'exclusiveMinimum', 'exclusiveMaximum', 'minLength', 'maxLength', 'multipleOf', 'minItems', 'maxItems', 'uniqueItems', 'minProperties', 'maxProperties', 'additionalProperties', 'pattern', 'enum', 'default'];
var arrayProperties = ['items', 'minItems', 'maxItems', 'uniqueItems'];
var httpMethods = ['get', 'post', 'put', 'delete', 'patch', 'head', 'options', 'trace'];

function sanitise(s) {
  s = s.replace('[]', 'Array');
  var components = s.split('/');
  components[0] = components[0].replace(/[^A-Za-z0-9_\-\.]+|\s+/gm, '_');
  return components.join('/');
}

function sanitiseAll(s) {
  return sanitise(s.split('/').join('_'));
}

module.exports = {
  colour: colour,
  uniqueOnly: uniqueOnly,
  hasDuplicates: hasDuplicates,
  allSame: allSame,
  distinctArray: distinctArray,
  firstDupe: firstDupe,
  hash: hash,
  parameterTypeProperties: parameterTypeProperties,
  arrayProperties: arrayProperties,
  httpMethods: httpMethods,
  sanitise: sanitise,
  sanitiseAll: sanitiseAll
};