import * as React from "react";
import { useState, useEffect } from "react";
import { WarnMessage } from "../shared";
import { Dropdown } from "../../redoc-lib/src/common-elements";
import { FormControl } from "../common/form";
export var ExampleSwitch = function ExampleSwitch(e) {
  var r = e.exampleKey,
      t = e.examples,
      a = e.onChange,
      o = Object.keys(t),
      n = r ? o.indexOf(r) : 0,
      m = useState(n > -1 ? n : 0),
      c = m[0],
      l = m[1];
  useEffect(function () {
    return l(n > -1 ? n : 0);
  }, [r, n]);
  var u = o.map(function (e, r) {
    return {
      value: t[e].summary || e,
      idx: r
    };
  });
  return React.createElement(React.Fragment, null, React.createElement(FormControl, null, React.createElement(Dropdown, {
    variant: "dark",
    fullWidth: !0,
    prefix: "Example: ",
    options: u,
    value: u[c].value,
    onChange: function onChange(e) {
      var r = e.idx;
      l(r), a(o[r]);
    }
  })), r && -1 === n && React.createElement(WarnMessage, null, "Provided sample not found"));
};