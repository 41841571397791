"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.callRef = exports.getValidate = void 0;

var ref_error_1 = require("../../compile/ref_error");

var code_1 = require("../code");

var codegen_1 = require("../../compile/codegen");

var names_1 = require("../../compile/names");

var compile_1 = require("../../compile");

var util_1 = require("../../compile/util");

var def = {
  keyword: "$ref",
  schemaType: "string",
  code: function code(cxt) {
    var gen = cxt.gen,
        $ref = cxt.schema,
        it = cxt.it;
    var baseId = it.baseId,
        env = it.schemaEnv,
        validateName = it.validateName,
        opts = it.opts,
        self = it.self;
    var root = env.root;
    if (($ref === "#" || $ref === "#/") && baseId === root.baseId) return callRootRef();
    var schOrEnv = compile_1.resolveRef.call(self, root, baseId, $ref);
    if (schOrEnv === undefined) throw new ref_error_1.default(baseId, $ref);
    if (schOrEnv instanceof compile_1.SchemaEnv) return callValidate(schOrEnv);
    return inlineRefSchema(schOrEnv);

    function callRootRef() {
      if (env === root) return callRef(cxt, validateName, env, env.$async);
      var rootName = gen.scopeValue("root", {
        ref: root
      });
      return callRef(cxt, codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", ".validate"])), rootName), root, root.$async);
    }

    function callValidate(sch) {
      var v = getValidate(cxt, sch);
      callRef(cxt, v, sch, sch.$async);
    }

    function inlineRefSchema(sch) {
      var schName = gen.scopeValue("schema", opts.code.source === true ? {
        ref: sch,
        code: codegen_1.stringify(sch)
      } : {
        ref: sch
      });
      var valid = gen.name("valid");
      var schCxt = cxt.subschema({
        schema: sch,
        dataTypes: [],
        schemaPath: codegen_1.nil,
        topSchemaRef: schName,
        errSchemaPath: $ref
      }, valid);
      cxt.mergeEvaluated(schCxt);
      cxt.ok(valid);
    }
  }
};

function getValidate(cxt, sch) {
  var gen = cxt.gen;
  return sch.validate ? gen.scopeValue("validate", {
    ref: sch.validate
  }) : codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", ".validate"])), gen.scopeValue("wrapper", {
    ref: sch
  }));
}

exports.getValidate = getValidate;

function callRef(cxt, v, sch, $async) {
  var gen = cxt.gen,
      it = cxt.it;
  var allErrors = it.allErrors,
      env = it.schemaEnv,
      opts = it.opts;
  var passCxt = opts.passContext ? names_1.default.this : codegen_1.nil;
  if ($async) callAsyncRef();else callSyncRef();

  function callAsyncRef() {
    if (!env.$async) throw new Error("async schema referenced by sync schema");
    var valid = gen.let("valid");
    gen.try(function () {
      gen.code(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["await ", ""])), code_1.callValidateCode(cxt, v, passCxt)));
      addEvaluatedFrom(v); // TODO will not work with async, it has to be returned with the result

      if (!allErrors) gen.assign(valid, true);
    }, function (e) {
      gen.if(codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["!(", " instanceof ", ")"])), e, it.ValidationError), function () {
        return gen.throw(e);
      });
      addErrorsFrom(e);
      if (!allErrors) gen.assign(valid, false);
    });
    cxt.ok(valid);
  }

  function callSyncRef() {
    var visitedNodes = gen.name("visitedNodes");
    gen.code(codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["const ", " = visitedNodesForRef.get(", ") || new Set()"])), visitedNodes, v));
    gen.if(codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["!", ".has(", ")"])), visitedNodes, cxt.data), function () {
      gen.code(codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["visitedNodesForRef.set(", ", ", ")"])), v, visitedNodes));
      gen.code(codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["const dataNode = ", ""])), cxt.data));
      gen.code(codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["", ".add(dataNode)"])), visitedNodes));
      var res = cxt.result(code_1.callValidateCode(cxt, v, passCxt), function () {
        return addEvaluatedFrom(v);
      }, function () {
        return addErrorsFrom(v);
      });
      gen.code(codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", ".delete(dataNode)"])), visitedNodes));
      return res;
    });
  }

  function addErrorsFrom(source) {
    var errs = codegen_1._(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["", ".errors"])), source);

    gen.assign(names_1.default.vErrors, codegen_1._(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["", " === null ? ", " : ", ".concat(", ")"])), names_1.default.vErrors, errs, names_1.default.vErrors, errs)); // TODO tagged

    gen.assign(names_1.default.errors, codegen_1._(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["", ".length"])), names_1.default.vErrors));
  }

  function addEvaluatedFrom(source) {
    var _a;

    if (!it.opts.unevaluated) return;
    var schEvaluated = (_a = sch === null || sch === void 0 ? void 0 : sch.validate) === null || _a === void 0 ? void 0 : _a.evaluated; // TODO refactor

    if (it.props !== true) {
      if (schEvaluated && !schEvaluated.dynamicProps) {
        if (schEvaluated.props !== undefined) {
          it.props = util_1.mergeEvaluated.props(gen, schEvaluated.props, it.props);
        }
      } else {
        var props = gen.var("props", codegen_1._(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["", ".evaluated.props"])), source));
        it.props = util_1.mergeEvaluated.props(gen, props, it.props, codegen_1.Name);
      }
    }

    if (it.items !== true) {
      if (schEvaluated && !schEvaluated.dynamicItems) {
        if (schEvaluated.items !== undefined) {
          it.items = util_1.mergeEvaluated.items(gen, schEvaluated.items, it.items);
        }
      } else {
        var items = gen.var("items", codegen_1._(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["", ".evaluated.items"])), source));
        it.items = util_1.mergeEvaluated.items(gen, items, it.items, codegen_1.Name);
      }
    }
  }
}

exports.callRef = callRef;
exports.default = def;