import { __assign, __extends } from "tslib";
import * as React from "react";
import { FieldsGroupHeader } from "../../common-elements";
import { DropdownOrLabel } from "../DropdownOrLabel/DropdownOrLabel";
import { MediaTypesSwitch } from "../MediaTypeSwitch/MediaTypesSwitch";
import { Schema } from "../Schema";
import { Markdown } from "../Markdown/Markdown";
import { ResponseHeaders } from "./ResponseHeaders";

var ResponseDetails = function (e) {
  function r() {
    var r = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(r, "renderDropdown", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        return React.createElement(FieldsGroupHeader, {
          key: "header"
        }, "Response Schema: ", React.createElement(DropdownOrLabel, __assign({}, e)));
      }
    }), r;
  }

  return __extends(r, e), Object.defineProperty(r.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.response,
          r = e.description,
          t = e.headers,
          n = e.content;
      return React.createElement(React.Fragment, null, r && React.createElement(Markdown, {
        source: r
      }), React.createElement(ResponseHeaders, {
        headers: t
      }), React.createElement(MediaTypesSwitch, {
        content: n,
        renderDropdown: this.renderDropdown
      }, function (e) {
        var r = e.schema;
        return React.createElement(Schema, {
          skipWriteOnly: !0,
          key: "schema",
          schema: r
        });
      }));
    }
  }), r;
}(React.PureComponent);

export { ResponseDetails };