"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var dataType_1 = require("../../compile/validate/dataType");

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var equal_1 = require("../../runtime/equal");

var error = {
  message: function message(_ref) {
    var _ref$params = _ref.params,
        i = _ref$params.i,
        j = _ref$params.j;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must NOT have duplicate items (items ## ", " and ", " are identical)"])), j, i);
  },
  params: function params(_ref2) {
    var _ref2$params = _ref2.params,
        i = _ref2$params.i,
        j = _ref2$params.j;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{i: ", ", j: ", "}"])), i, j);
  }
};
var def = {
  keyword: "uniqueItems",
  type: "array",
  schemaType: "boolean",
  $data: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        data = cxt.data,
        $data = cxt.$data,
        schema = cxt.schema,
        parentSchema = cxt.parentSchema,
        schemaCode = cxt.schemaCode,
        it = cxt.it;
    if (!$data && !schema) return;
    var valid = gen.let("valid");
    var itemTypes = parentSchema.items ? dataType_1.getSchemaTypes(parentSchema.items) : [];
    cxt.block$data(valid, validateUniqueItems, codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " === false"])), schemaCode));
    cxt.ok(valid);

    function validateUniqueItems() {
      var i = gen.let("i", codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", ".length"])), data));
      var j = gen.let("j");
      cxt.setParams({
        i: i,
        j: j
      });
      gen.assign(valid, true);
      gen.if(codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", " > 1"])), i), function () {
        return (canOptimize() ? loopN : loopN2)(i, j);
      });
    }

    function canOptimize() {
      return itemTypes.length > 0 && !itemTypes.some(function (t) {
        return t === "object" || t === "array";
      });
    }

    function loopN(i, j) {
      var item = gen.name("item");
      var wrongType = dataType_1.checkDataTypes(itemTypes, item, it.opts.strictNumbers, dataType_1.DataType.Wrong);
      var indices = gen.const("indices", codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["{}"]))));
      gen.for(codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral([";", "--;"])), i), function () {
        gen.let(item, codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", "[", "]"])), data, i));
        gen.if(wrongType, codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["continue"]))));
        if (itemTypes.length > 1) gen.if(codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["typeof ", " == \"string\""])), item), codegen_1._(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["", " += \"_\""])), item));
        gen.if(codegen_1._(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["typeof ", "[", "] == \"number\""])), indices, item), function () {
          gen.assign(j, codegen_1._(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["", "[", "]"])), indices, item));
          cxt.error();
          gen.assign(valid, false).break();
        }).code(codegen_1._(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["", "[", "] = ", ""])), indices, item, i));
      });
    }

    function loopN2(i, j) {
      var eql = util_1.useFunc(gen, equal_1.default);
      var outer = gen.name("outer");
      gen.label(outer).for(codegen_1._(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral([";", "--;"])), i), function () {
        return gen.for(codegen_1._(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["", " = ", "; ", "--;"])), j, i, j), function () {
          return gen.if(codegen_1._(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["", "(", "[", "], ", "[", "])"])), eql, data, i, data, j), function () {
            cxt.error();
            gen.assign(valid, false).break(outer);
          });
        });
      });
    }
  }
};
exports.default = def;