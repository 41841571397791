import { __assign, __rest } from "tslib";
import * as React from "react";
import { observer } from "mobx-react";
import { DropdownOrLabel } from "../DropdownOrLabel/DropdownOrLabel";
import { MediaTypesSwitch } from "../MediaTypeSwitch/MediaTypesSwitch";
import { SourceCodeWithCopy } from "../SourceCode/SourceCode";
import { MimeLabel, NoSampleLabel } from "../../../../components/shared";
import { Dropdown } from "../../common-elements/dropdown";
import { DropdownWrapper } from "../../../src/components/PayloadSamples/styled.elements";
import { ExampleSwitch, useExampleKey } from "../../../../components/Samples";
export var CodeSample = observer(function (e) {
  var r,
      o = e.onlyDefaultSample,
      t = e.onlyDefaultMimeType,
      a = e.content,
      n = __rest(e, ["onlyDefaultSample", "onlyDefaultMimeType", "content"]),
      l = function l(e) {
    return n.source ? React.createElement(SourceCodeWithCopy, {
      lang: n.lang,
      source: n.source
    }) : React.createElement(SourceCodeWithCopy, {
      lang: n.lang,
      externalSource: {
        sample: n.externalSample,
        exampleName: e,
        operation: n.operation,
        pathParams: n.pathParams,
        properties: n.properties
      }
    });
  };

  if (void 0 === a || null != n.source) return l();

  var p = t && (null === (r = n.operation) || void 0 === r ? void 0 : r.defaultMimeType),
      m = a && a.mediaTypes.length > 1 && !p,
      i = function i(e) {
    var r;
    return null === (r = n.operation) || void 0 === r ? void 0 : r.activateExampleName(e);
  };

  return React.createElement(MediaTypesSwitch, {
    content: a,
    renderDropdown: m ? function (e) {
      return React.createElement(DropdownWrapper, null, React.createElement(DropdownOrLabel, __assign({
        Label: MimeLabel,
        Dropdown: Dropdown,
        variant: "dark",
        fullWidth: !0
      }, e)));
    } : function () {
      return null;
    }
  }, function (e) {
    var r = e.examples || {},
        t = Object.keys(r);
    if (!t.length) return React.createElement(NoSampleLabel, null, "No sample");
    if (1 === t.length) return l();
    var a = useExampleKey(e.operation, r),
        n = a.exampleKey;
    return a.defaultExampleName && o ? l(n) : React.createElement(React.Fragment, null, React.createElement(ExampleSwitch, {
      examples: r,
      exampleKey: n,
      onChange: i
    }), l(n));
  });
});