"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoLicense = void 0;

var utils_1 = require("../utils");

var InfoLicense = function InfoLicense() {
  return {
    Info: function Info(info, _ref) {
      var report = _ref.report;

      if (!info.license) {
        report({
          message: utils_1.missingRequiredField('Info', 'license'),
          location: {
            reportOnKey: true
          }
        });
      }
    }
  };
};

exports.InfoLicense = InfoLicense;