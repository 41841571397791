"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var error = {
  message: function message(_ref) {
    var params = _ref.params;
    return codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must match \"", "\" schema"])), params.ifClause);
  },
  params: function params(_ref2) {
    var _params = _ref2.params;
    return codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["{failingKeyword: ", "}"])), _params.ifClause);
  }
};
var def = {
  keyword: "if",
  schemaType: ["object", "boolean"],
  trackErrors: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        parentSchema = cxt.parentSchema,
        it = cxt.it;

    if (parentSchema.then === undefined && parentSchema.else === undefined) {
      util_1.checkStrictMode(it, '"if" without "then" and "else" is ignored');
    }

    var hasThen = hasSchema(it, "then");
    var hasElse = hasSchema(it, "else");
    if (!hasThen && !hasElse) return;
    var valid = gen.let("valid", true);
    var schValid = gen.name("_valid");
    validateIf();
    cxt.reset();

    if (hasThen && hasElse) {
      var ifClause = gen.let("ifClause");
      cxt.setParams({
        ifClause: ifClause
      });
      gen.if(schValid, validateClause("then", ifClause), validateClause("else", ifClause));
    } else if (hasThen) {
      gen.if(schValid, validateClause("then"));
    } else {
      gen.if(codegen_1.not(schValid), validateClause("else"));
    }

    cxt.pass(valid, function () {
      return cxt.error(true);
    });

    function validateIf() {
      var schCxt = cxt.subschema({
        keyword: "if",
        compositeRule: true,
        createErrors: false,
        allErrors: false
      }, schValid);
      cxt.mergeEvaluated(schCxt);
    }

    function validateClause(keyword, ifClause) {
      return function () {
        var schCxt = cxt.subschema({
          keyword: keyword
        }, schValid);
        gen.assign(valid, schValid);
        cxt.mergeValidEvaluated(schCxt, valid);
        if (ifClause) gen.assign(ifClause, codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", ""])), keyword));else cxt.setParams({
          ifClause: keyword
        });
      };
    }
  }
};

function hasSchema(it, keyword) {
  var schema = it.schema[keyword];
  return schema !== undefined && !util_1.alwaysValidSchema(it, schema);
}

exports.default = def;