var path = require("path");

function getEnv(a, b) {
  var c = process.env[a];
  return (!b || b.includes(a) || a.startsWith("REDOCLY_")) && "undefined" != typeof c ? c : void 0;
}

function replaceEnv(a, b) {
  return a ? a.replace(/{{\s*process\.env\.([a-zA-Z_][a-zA-Z0-9_]*)\s*}}/g, function (a, c) {
    var d = getEnv(c, b);
    return void 0 === d ? a : d;
  }) : a;
}

function replaceEnvDeep(a, b) {
  if (!a || "object" != typeof a) return a;

  for (var _i = 0, _Object$keys = Object.keys(a); _i < _Object$keys.length; _i++) {
    var c = _Object$keys[_i];
    a[c] = "string" == typeof a[c] ? replaceEnv(a[c], b) : replaceEnvDeep(a[c], b);
  }

  return a;
}

function isChildOf(a, b) {
  var c = path.relative(b, a);
  return c && !c.startsWith("..") && !path.isAbsolute(c);
}

function sanitizeDefinitionId(a) {
  return a.replace(/[^a-zA-Z0-9_]/g, "_");
}

function withPathPrefix(a, b) {
  return (b + a).replace(/\/\//, "/");
}

module.exports = {
  replaceEnv: replaceEnv,
  getEnv: getEnv,
  replaceEnvDeep: replaceEnvDeep,
  isChildOf: isChildOf,
  sanitizeDefinitionId: sanitizeDefinitionId,
  withPathPrefix: withPathPrefix
};