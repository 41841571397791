var _a, _b;

import { darken, lighten, readableColor, transparentize } from "polished";
import { ZERO_BREAKPOINT } from "./utils/media-css";
var defaultTheme = {
  spacing: {
    unit: 5,
    sectionHorizontal: function sectionHorizontal(r) {
      return 8 * r.spacing.unit;
    },
    sectionVertical: function sectionVertical(r) {
      return 4 * r.spacing.unit;
    }
  },
  breakpoints: {
    small: "50rem",
    medium: "85rem",
    large: "105rem"
  },
  colors: {
    tonalOffset: .2,
    primary: {
      main: "#0065FB",
      light: function light(r) {
        var o = r.colors;
        return lighten(o.tonalOffset, o.primary.main);
      },
      dark: function dark(r) {
        var o = r.colors;
        return darken(o.tonalOffset, o.primary.main);
      },
      contrastText: function contrastText(r) {
        var o = r.colors;
        return readableColor(o.primary.main);
      }
    },
    secondary: {
      main: "#E4E7EB",
      light: "#F5F7FA",
      dark: "#E4E7EB",
      contrastText: "#3E4C59"
    },
    accent: {
      main: "#59C3FF",
      light: "#b3dcf3",
      dark: "#033B73",
      contrastText: "#ffffff"
    },
    success: {
      main: "#41DE50",
      light: function light(r) {
        var o = r.colors;
        return lighten(2 * o.tonalOffset, o.success.main);
      },
      dark: function dark(r) {
        var o = r.colors;
        return darken(o.tonalOffset, o.success.main);
      },
      contrastText: function contrastText(r) {
        var o = r.colors;
        return readableColor(o.success.main);
      }
    },
    warning: {
      main: "#ffa500",
      light: function light(r) {
        var o = r.colors;
        return lighten(o.tonalOffset, o.warning.main);
      },
      dark: function dark(r) {
        var o = r.colors;
        return darken(o.tonalOffset, o.warning.main);
      },
      contrastText: "#ffffff"
    },
    error: {
      main: "#FF6161",
      light: function light(r) {
        var o = r.colors;
        return lighten(o.tonalOffset, o.error.main);
      },
      dark: function dark(r) {
        var o = r.colors;
        return darken(o.tonalOffset, o.error.main);
      },
      contrastText: "#ffffff"
    },
    text: {
      primary: "#1F2933",
      secondary: "#7b8794",
      light: "#F5F7FA"
    },
    border: {
      dark: "#616E7C",
      light: "#E4E7EB"
    },
    responses: {
      success: {
        borderColor: "#B1E996",
        backgroundColor: "#F6FFF4",
        tabTextColor: function tabTextColor(r) {
          return r.colors.responses.success.borderColor;
        }
      },
      error: {
        borderColor: "#FFC9C9",
        backgroundColor: "#FFF4F4",
        tabTextColor: function tabTextColor(r) {
          return r.colors.responses.error.borderColor;
        }
      },
      redirect: {
        borderColor: function borderColor(r) {
          return r.colors.warning.main;
        },
        backgroundColor: function backgroundColor(r) {
          var o = r.colors;
          return transparentize(.9, o.responses.redirect.borderColor);
        },
        tabTextColor: function tabTextColor(r) {
          return r.colors.responses.redirect.borderColor;
        }
      },
      info: {
        borderColor: "#87ceeb",
        backgroundColor: function backgroundColor(r) {
          var o = r.colors;
          return transparentize(.9, o.responses.info.borderColor);
        },
        tabTextColor: function tabTextColor(r) {
          return r.colors.responses.info.borderColor;
        }
      }
    },
    http: {
      get: "#3A9601",
      post: "#0065FB",
      put: "#93527B",
      options: "#947014",
      patch: "#bf581d",
      delete: "#C83637",
      basic: "#707070",
      link: "#07818F",
      head: "#A23DAD"
    }
  },
  schema: {
    linesColor: function linesColor(r) {
      return r.colors.border.light;
    },
    defaultDetailsWidth: "70%",
    typeNameColor: function typeNameColor(r) {
      return r.colors.text.secondary;
    },
    typeTitleColor: function typeTitleColor(r) {
      return r.schema.typeNameColor;
    },
    requireLabelColor: "#FF0000",
    labelsTextSize: "0.9em",
    nestingSpacing: "1em",
    nestedBackground: function nestedBackground(r) {
      return r.colors.secondary.light;
    },
    caretColor: function caretColor(r) {
      return r.colors.text.primary;
    },
    caretSize: "9px"
  },
  typography: {
    fontSize: "14px",
    lineHeight: "1.5em",
    fontWeightRegular: "400",
    fontWeightBold: "600",
    fontWeightLight: "300",
    fontFamily: "Source Sans Pro, sans-serif",
    smoothing: "antialiased",
    optimizeSpeed: !0,
    headings: {
      fontFamily: function fontFamily(r) {
        return r.typography.fontFamily;
      },
      fontWeight: "700",
      lineHeight: "1.6em"
    },
    code: {
      fontSize: "13px",
      fontFamily: "Source Code Pro, monospace",
      lineHeight: function lineHeight(r) {
        return r.typography.lineHeight;
      },
      fontWeight: function fontWeight(r) {
        return r.typography.fontWeightRegular;
      },
      color: "#e53935",
      backgroundColor: "rgba(38, 50, 56, 0.05)",
      wrap: !1
    },
    links: {
      color: function color(r) {
        return r.colors.primary.main;
      },
      visited: function visited(r) {
        return r.typography.links.color;
      },
      hover: function hover(r) {
        var o = r.typography;
        return lighten(.2, o.links.color);
      },
      textDecoration: "auto"
    },
    fieldName: {
      fontSize: function fontSize(r) {
        return r.typography.code.fontSize;
      },
      fontFamily: function fontFamily(r) {
        return r.typography.code.fontFamily;
      },
      fontWeight: function fontWeight(r) {
        return r.typography.code.fontWeight;
      }
    }
  },
  sidebar: {
    separatorLabelColor: function separatorLabelColor(r) {
      return r.sidebar.textColor;
    },
    separatorLineColor: "#DADADA",
    caretColor: function caretColor(r) {
      return r.sidebar.textColor;
    },
    caretSize: function caretSize(r) {
      return r.sidebar.spacing.unit;
    },
    spacing: {
      unit: 8,
      paddingHorizontal: function paddingHorizontal(r) {
        return r.sidebar.spacing.unit;
      },
      paddingVertical: function paddingVertical(r) {
        return r.sidebar.spacing.unit;
      },
      offsetTop: function offsetTop(r) {
        return 2 * r.sidebar.spacing.unit;
      },
      offsetLeft: function offsetLeft(r) {
        return 2 * r.sidebar.spacing.unit;
      },
      offsetNesting: function offsetNesting(r) {
        return 2 * r.sidebar.spacing.unit;
      }
    },
    borderRadius: "4px",
    fontFamily: function fontFamily(r) {
      return r.typography.fontFamily;
    },
    fontSize: function fontSize(r) {
      return r.typography.fontSize;
    },
    showAtBreakpoint: "small",
    width: "285px",
    backgroundColor: "#fff",
    rightLineColor: function rightLineColor(r) {
      return r.colors.border.light;
    },
    textColor: function textColor(r) {
      return r.colors.text.primary;
    },
    activeTextColor: function activeTextColor(r) {
      return r.sidebar.textColor;
    },
    activeBgColor: function activeBgColor(r) {
      return r.colors.border.light;
    }
  },
  components: {
    buttons: {
      borderRadius: function borderRadius(r) {
        return r.shape.borderRadius;
      },
      hoverStyle: "",
      fontWeight: function fontWeight(r) {
        return r.typography.fontWeightBold;
      },
      boxShadow: "none",
      hoverBoxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.1)",
      fontFamily: "inherit",
      sizes: {
        small: {
          fontSize: "12px",
          padding: "8px 10px",
          minWidth: "90px"
        },
        medium: {
          fontSize: "14px",
          padding: "8px 20px",
          minWidth: "120px"
        },
        large: {
          fontSize: "14px",
          padding: "12px 24px",
          minWidth: "150px"
        },
        xlarge: {
          fontSize: "16px",
          padding: "20px 24px",
          minWidth: "200px"
        }
      }
    },
    httpBadges: {
      borderRadius: "16px",
      fontFamily: function fontFamily(r) {
        return r.typography.code.fontFamily;
      },
      fontWeight: function fontWeight(r) {
        return r.typography.fontWeightBold;
      },
      color: function color(r) {
        return r.colors.text.light;
      },
      sizes: {
        medium: {
          fontSize: "12px",
          lineHeight: "20px"
        },
        small: {
          fontSize: "8px",
          lineHeight: "15px"
        }
      }
    },
    tryItButton: {
      fullWidth: !1
    },
    tryItSendButton: {
      fullWidth: !1
    },
    panels: {
      borderRadius: "8px"
    },
    layoutControls: {
      top: "20px"
    }
  },
  logo: {
    maxHeight: function maxHeight(r) {
      return r.sidebar.width;
    },
    maxWidth: function maxWidth(r) {
      return r.sidebar.width;
    },
    gutter: "2px"
  },
  rightPanel: {
    showAtBreakpoint: "medium",
    backgroundColor: "#1F2933",
    panelBackgroundColor: "#52606D",
    panelControlsBackgroundColor: "#323F4B",
    width: "37.5%",
    textColor: "#ffffff"
  },
  codeBlock: {
    backgroundColor: function backgroundColor(r) {
      return r.rightPanel.panelControlsBackgroundColor;
    }
  },
  shape: {
    borderRadius: "4px"
  },
  layout: {
    stacked: {
      maxWidth: (_a = {}, _a[ZERO_BREAKPOINT] = "", _a.small = "90%", _a.medium = "75%", _a.large = "1200px", _a)
    },
    "three-panel": {
      middlePanelMaxWidth: "none",
      maxWidth: (_b = {}, _b[ZERO_BREAKPOINT] = "100%", _b.small = "100%", _b.medium = "100%", _b.large = "1800px", _b)
    }
  }
};
export default defaultTheme;
export function resolveTheme(r) {
  var o = {},
      t = 0,
      n = function n(e, i) {
    Object.keys(e).forEach(function (a) {
      var l = (i ? i + "." : "") + a,
          c = e[a];
      "function" == typeof c ? Object.defineProperty(e, a, {
        get: function get() {
          if (!o[l]) {
            if (++t > 1e3) throw new Error("Theme probably contains circular dependency at " + l + ": " + c.toString());
            o[l] = c(r);
          }

          return o[l];
        },
        enumerable: !0
      }) : "object" == typeof c && n(c, l);
    });
  };

  return n(r, ""), JSON.parse(JSON.stringify(r));
}