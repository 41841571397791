import { __assign } from "tslib";
import * as DOMPurify from "dompurify";
import * as React from "react";
import { OptionsContext } from "../OptionsProvider";
import { StyledMarkdownBlock, StyledMarkdownSpan } from "./styled.elements";
export function SanitizedMarkdownHTML(t) {
  var o,
      e,
      n,
      i = React.useContext(OptionsContext),
      r = t.inline ? StyledMarkdownSpan : StyledMarkdownBlock,
      a = (null === (o = i.hooks) || void 0 === o ? void 0 : o.sanitize) ? null === (n = null === (e = i.hooks) || void 0 === e ? void 0 : e.sanitize) || void 0 === n ? void 0 : n.call(e, t.html) : i.untrustedDefinition ? DOMPurify.sanitize(t.html) : t.html;
  return React.createElement(r, __assign({
    className: "redoc-markdown " + (t.className || ""),
    dangerouslySetInnerHTML: {
      __html: a
    },
    "data-role": t["data-role"]
  }, t));
}