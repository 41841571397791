import * as React from "react";
import { IS_BROWSER } from "./redoc-lib";

var _store;

export var getGlobalStore = function getGlobalStore() {
  return _store;
};
export var setGlobalStore = function setGlobalStore(t) {
  return _store = t;
};
export function debounce(t, e, r) {
  var o,
      n = 0;
  return function () {
    for (var a = [], i = 0; i < arguments.length; i++) {
      a[i] = arguments[i];
    }

    function u() {
      n > 1 && t.apply(void 0, a), n = 0, o = null;
    }

    n++, o ? clearTimeout(o) : r && t.apply(void 0, a), o = setTimeout(u, e);
  };
}
export function toStorage(t, e) {
  window.sessionStorage.setItem("redoc." + t, e);
}
export function fromStorage(t) {
  return IS_BROWSER && window.sessionStorage.getItem("redoc." + t) || "";
}
export function setParameterValue(t, e, r) {
  toStorage(t + "." + e, JSON.stringify(r));
}
export function getParameterValue(t, e) {
  try {
    return JSON.parse(fromStorage(t + "." + e));
  } catch (t) {
    return null;
  }
}
export function toLocalStorage(t, e) {
  window.localStorage.setItem("redoc." + t, e);
}
export function fromLocalStorage(t) {
  return IS_BROWSER && window.localStorage.getItem("redoc." + t) || "";
}
export function useDimensions(t) {
  var e = React.useState(null),
      r = e[0],
      o = e[1];
  return React.useLayoutEffect(function () {
    if (t) {
      var e = function e() {
        return window.requestAnimationFrame(function () {
          return o(t.getBoundingClientRect());
        });
      };

      return e(), window.addEventListener("resize", e), function () {
        window.removeEventListener("resize", e);
      };
    }
  }, [t]), [r];
}
export function escapeFormId(t) {
  return t.replace(/\./g, "%2e");
}
export function unescapeFormId(t) {
  return t.replace(/%2e/g, ".");
}
export function setSecurityDetails(t, e) {
  t = escapeFormId(t), "string" == typeof e ? toStorage("auth." + t, e) : e && (toStorage("auth." + t + ".password", e.password || ""), toStorage("auth." + t + ".username", e.username || ""), toStorage("auth." + t + ".token", e.token && JSON.stringify(e.token) || ""), toStorage("auth." + t + ".client_id", e.client_id || ""), toStorage("auth." + t + ".client_secret", e.client_secret || ""), toStorage("auth." + t + ".scopes", e.scopes && JSON.stringify(e.scopes) || ""));
}
export function setSecurityDetailsVariants(t, e) {
  toStorage("auth." + (t = escapeFormId(t)) + ".variants", JSON.stringify(e));
}
export function getSecurityDetails(t) {
  var e = fromStorage("auth." + (t = escapeFormId(t))),
      r = fromStorage("auth." + t + ".token");
  return {
    raw: e,
    password: fromStorage("auth." + t + ".password"),
    username: fromStorage("auth." + t + ".username"),
    token: r && JSON.parse(r) || null,
    client_id: fromStorage("auth." + t + ".client_id"),
    client_secret: fromStorage("auth." + t + ".client_secret"),
    scopes: fromStorage("auth." + t + ".scopes")
  };
}
export function getSecurityDetailsOptions(t, e) {
  var r = fromStorage("auth." + (t = escapeFormId(t)) + ".variants");
  if (!r) return null;
  var o = JSON.parse(r);
  return o && Array.isArray(o) ? o.map(function (t) {
    return t.details ? "" === e && t.details && "string" == typeof t.details ? {
      label: t.label,
      value: t.details
    } : "object" == typeof t.details ? {
      label: t.label,
      value: t.details[e]
    } : null : null;
  }).filter(notNull) : null;
}

function notNull(t) {
  return null !== t;
}

export function get(t, e, r) {
  return "string" == typeof e && (e = e.split(".")), e.reduce(function (t, e) {
    return t && t[e] ? t[e] : r;
  }, t);
}
export function joinStringFactory(t) {
  return function () {
    for (var e = [], r = 0; r < arguments.length; r++) {
      e[r] = arguments[r];
    }

    return e.filter(Boolean).join(t);
  };
}
export var dottedString = joinStringFactory(".");