module.exports = [{
  plugin: require('../../../../../gatsby-plugin-layout/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../../../../gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": [],
    "maxWidth": 910,
    "linkImagesToOriginal": false,
    "showCaptions": false,
    "markdownCaptions": false,
    "sizeByPixelDensity": false,
    "backgroundColor": "white",
    "quality": 50,
    "withWebp": false,
    "tracedSVG": false,
    "loading": "lazy",
    "disableBgImageOnAlpha": false,
    "disableBgImage": false
  }
}, {
  plugin: require('../plugins/gatsby-remark-autolink-headers/gatsby-browser.js'),
  options: {
    "plugins": [],
    "offsetY": {
      "element": "nav"
    }
  }
}, {
  plugin: require('../plugins/gatsby-redocly-remark-code-buttons/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../../../../../gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": [],
    "excludePattern": "(idp-login)"
  }
}, {
  plugin: require('../../../../../gatsby-plugin-nprogress/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../../../../../gatsby-plugin-manifest/gatsby-browser.js'),
  options: {
    "plugins": [],
    "icon": "/data/trilogy-group/totogi-developer-portal/favicon.png",
    "name": "Totogi APIs",
    "legacy": true,
    "theme_color_in_head": true,
    "cache_busting_mode": "query",
    "crossOrigin": "anonymous",
    "include_favicon": true,
    "cacheDigest": "f2cd42397b5975ac525d23d8dbe997eb"
  }
}, {
  plugin: require('../../../../../gatsby-plugin-canonical-urls/gatsby-browser.js'),
  options: {
    "plugins": [],
    "siteUrl": "https://developer-portal-starter.redoc.ly"
  }
}, {
  plugin: require('../gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];