import { __assign } from "tslib";
import { format } from "util";
import { CodeBuilder } from "../../helpers/code-builder";
import shellHelpers from "../../helpers/shell";
import { addIndentation, buildUrlExpression } from "../../helpers/code-helpers";
import { normalizeMimeType } from "../../../../utils";
import { HTTPSnippet } from "../..";

var handler = function handler(e, t, s) {
  var a,
      r,
      o = s.target,
      i = s.client,
      n = Object.assign({
    indent: "  ",
    short: !1,
    binary: !1
  }, t),
      l = new CodeBuilder({
    indentation: n.indent,
    lineJoin: n.indent ? " \\\n" + n.indent : " "
  }),
      p = (null === (a = e.securityOAuth2ExtraCalls) || void 0 === a ? void 0 : a.length) ? new HTTPSnippet(null === (r = e.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r[0]).convert(o, i, __assign(__assign({}, n), {
    withComments: !1
  })) : "";
  l.push(0, "curl -i %s %s", n.short ? "-X" : "--request", e.method.toUpperCase()), e.basicAuth && l.push(format("-u %s:%s", e.basicAuth.username, e.basicAuth.password));
  var d = buildUrlExpression(e, l);
  if (l.push(format("%s%s", n.short ? "" : "--url ", shellHelpers.quote(d))), "HTTP/1.0" === e.httpVersion && l.push(n.short ? "-0" : "--http1.0"), Object.keys(e.headersObj).sort().forEach(function (t) {
    var s = format("%s: %s", t, e.headersObj[t]);
    l.push("%s %s", n.short ? "-H" : "--header", shellHelpers.quote(s));
  }), e.allHeaders.cookie && l.push("%s %s", n.short ? "-b" : "--cookie", shellHelpers.quote(e.allHeaders.cookie)), e.postData) switch (normalizeMimeType(e.postData.mimeType)) {
    case "multipart/form-data":
      e.postData.params.map(function (e) {
        var t;
        t = e.fileName ? format("%s=@%s", e.name, e.fileName) : format("%s=%s", e.name, e.value), l.push("%s %s", n.short ? "-F" : "--form", shellHelpers.quote(t));
      });
      break;

    case "application/x-www-form-urlencoded":
      e.postData.params ? e.postData.params.map(function (e) {
        l.push("%s %s", n.binary ? "--data-binary" : n.short ? "-d" : "--data", shellHelpers.quote(format("%s=%s", e.name, e.value)));
      }) : l.push("%s %s", n.binary ? "--data-binary" : n.short ? "-d" : "--data", shellHelpers.quote(e.postData.text));
      break;

    case "application/json":
      e.postData.jsonObj && l.push("%s %s", n.binary ? "--data-binary" : n.short ? "-d" : "--data", addIndentation(shellHelpers.quote(JSON.stringify(e.postData.jsonObj, null, n.indent)), {
        level: 1,
        indent: n.indent,
        firstLine: !1
      }));
      break;

    default:
      e.postData.text && l.push("%s %s", n.binary ? "--data-binary" : n.short ? "-d" : "--data", addIndentation(shellHelpers.quote(e.postData.text), {
        level: 1,
        indent: n.indent,
        firstLine: !1
      }));
  }
  return (n.withComments ? "# Requires curl >= 7.2\n\n" : "") + (p ? p + "\n\n" + l.join() : l.join());
};

export var info = {
  key: "curl",
  title: "curl",
  link: "http://curl.haxx.se/",
  description: "cURL is a command line tool and library for transferring data with URL syntax"
};
export default handler;