"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OasSpec = void 0;

var types_1 = require("../../types");

var utils_1 = require("../utils");

var ref_utils_1 = require("../../ref-utils");

var OasSpec = function OasSpec() {
  return {
    any: function any(node, _ref) {
      var report = _ref.report,
          type = _ref.type,
          location = _ref.location,
          key = _ref.key,
          resolve = _ref.resolve,
          ignoreNextVisitorsOnNode = _ref.ignoreNextVisitorsOnNode;

      var _a, _b;

      var nodeType = utils_1.oasTypeOf(node);

      if (type.items) {
        if (nodeType !== 'array') {
          report({
            message: "Expected type `".concat(type.name, "` (array) but got `").concat(nodeType, "`")
          });
          ignoreNextVisitorsOnNode();
        }

        return;
      } else if (nodeType !== 'object') {
        report({
          message: "Expected type `".concat(type.name, "` (object) but got `").concat(nodeType, "`")
        });
        ignoreNextVisitorsOnNode();
        return;
      }

      var required = typeof type.required === 'function' ? type.required(node, key) : type.required;

      var _iterator = _createForOfIteratorHelper(required || []),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _propName2 = _step.value;

          if (!node.hasOwnProperty(_propName2)) {
            report({
              message: "The field `".concat(_propName2, "` must be present on this level."),
              location: [{
                reportOnKey: true
              }]
            });
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var requiredOneOf = type.requiredOneOf || null;

      if (requiredOneOf) {
        var hasProperty = false;

        var _iterator2 = _createForOfIteratorHelper(requiredOneOf || []),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var propName = _step2.value;

            if (node.hasOwnProperty(propName)) {
              hasProperty = true;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        if (!hasProperty) report({
          message: 'Must contain at least one of the following fields: path, components, webhooks.',
          location: [{
            reportOnKey: true
          }]
        });
      }

      for (var _i = 0, _Object$keys = Object.keys(node); _i < _Object$keys.length; _i++) {
        var _propName = _Object$keys[_i];
        var propLocation = location.child([_propName]);
        var propValue = node[_propName];
        var propType = type.properties[_propName];
        if (propType === undefined) propType = type.additionalProperties;
        if (typeof propType === 'function') propType = propType(propValue, _propName);

        if (types_1.isNamedType(propType)) {
          continue; // do nothing for named schema, it is executed with the next any call
        }

        var propSchema = propType;
        var propValueType = utils_1.oasTypeOf(propValue);

        if (propSchema === undefined) {
          if (_propName.startsWith('x-')) continue;
          report({
            message: "Property `".concat(_propName, "` is not expected here."),
            suggest: utils_1.getSuggest(_propName, Object.keys(type.properties)),
            location: propLocation.key()
          });
          continue;
        }

        if (propSchema === null) {
          continue; // just defined, no validation
        }

        if (propSchema.resolvable !== false && ref_utils_1.isRef(propValue)) {
          propValue = resolve(propValue).node;
        }

        if (propSchema.enum) {
          if (!propSchema.enum.includes(propValue)) {
            report({
              location: propLocation,
              message: "`".concat(_propName, "` can be one of the following only: ").concat(propSchema.enum.map(function (i) {
                return "\"".concat(i, "\"");
              }).join(', '), "."),
              suggest: utils_1.getSuggest(propValue, propSchema.enum)
            });
          }
        } else if (propSchema.type && !utils_1.matchesJsonSchemaType(propValue, propSchema.type, false)) {
          report({
            message: "Expected type `".concat(propSchema.type, "` but got `").concat(propValueType, "`."),
            location: propLocation
          });
        } else if (propValueType === 'array' && ((_a = propSchema.items) === null || _a === void 0 ? void 0 : _a.type)) {
          var itemsType = (_b = propSchema.items) === null || _b === void 0 ? void 0 : _b.type;

          for (var i = 0; i < propValue.length; i++) {
            var item = propValue[i];

            if (!utils_1.matchesJsonSchemaType(item, itemsType, false)) {
              report({
                message: "Expected type `".concat(itemsType, "` but got `").concat(utils_1.oasTypeOf(item), "`."),
                location: propLocation.child([i])
              });
            }
          }
        }

        if (typeof propSchema.minimum === 'number') {
          if (propSchema.minimum > node[_propName]) {
            report({
              message: "The value of the ".concat(_propName, " field must be greater than or equal to ").concat(propSchema.minimum),
              location: location.child([_propName])
            });
          }
        }
      }
    }
  };
};

exports.OasSpec = OasSpec;