import { __makeTemplateObject } from "tslib";
import styled, { keyframes, css } from "../../redoc-lib/src/styled-components";
var templateObject_1,
    templateObject_2,
    templateObject_3,
    showPanel = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    transform: translateY(-10px);\n  }\n  100% {\n    transform: translateY(0);\n  }\n"], ["\n  0% {\n    transform: translateY(-10px);\n  }\n  100% {\n    transform: translateY(0);\n  }\n"]))),
    showPanelAnimation = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: ", " 0.2s;\n"], ["\n  animation: ", " 0.2s;\n"])), showPanel);
export var PanelBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n  background-color: #fff;\n"], ["\n  ", ";\n  ", ";\n\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  border-bottom-right-radius: ", ";\n  border-bottom-left-radius: ", ";\n  background-color: #fff;\n"])), function (e) {
  return e.animate && showPanelAnimation;
}, function (e) {
  return e.hidden && "visibility: hidden";
}, function (e) {
  return e.theme.components.panels.borderRadius;
}, function (e) {
  return e.theme.components.panels.borderRadius;
}, function (e) {
  return e.theme.components.panels.borderRadius;
}, function (e) {
  return e.theme.components.panels.borderRadius;
});