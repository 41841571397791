var _a;

import { __awaiter, __generator } from "tslib";
import * as React from "react";
import { loadAndBundleDefinition } from "../redoc-lib";
import { ProStore } from "../services/ProStore";
import slugify from "slugify";
import isDeepEqual from "fast-deep-equal";
import { FakeHistoryService } from "../services";
export var Provider = (_a = React.createContext(void 0), _a.Provider),
    Consumer = _a.Consumer;
export var ProStoreProvider = function ProStoreProvider(e) {
  var t = e.definitions,
      n = e.options,
      r = e.definitionUrl,
      i = e.definition,
      o = e.activeItemId,
      a = e.activeSampleLanguage,
      s = e.activeDeepLink,
      u = e.children,
      c = React.useState()[1],
      l = React.useState(!0),
      d = l[0],
      f = l[1],
      p = React.useState(null),
      v = p[0],
      m = p[1];
  React.useEffect(function () {
    !function () {
      __awaiter(this, void 0, void 0, function () {
        var e, o, a;
        return __generator(this, function (s) {
          switch (s.label) {
            case 0:
              f(!0), s.label = 1;

            case 1:
              return s.trys.push([1, 6,, 7]), e = m, t ? [4, loadMany(t)] : [3, 3];

            case 2:
              return o = s.sent(), [3, 5];

            case 3:
              return [4, loadSingle(i, r, n)];

            case 4:
              o = s.sent(), s.label = 5;

            case 5:
              return e.apply(void 0, [o]), [3, 7];

            case 6:
              return a = s.sent(), c(function () {
                throw a;
              }), [3, 7];

            case 7:
              return [2];
          }
        });
      });
    }();
  }, [t, i, r, n]);
  var h = React.useMemo(function () {
    var e, t, i;

    if (v) {
      var o = new ProStore(v, r, n);
      return (null === (e = o.options.hooks) || void 0 === e ? void 0 : e.onInit) && (null === (i = null === (t = o.options.hooks) || void 0 === t ? void 0 : t.onInit) || void 0 === i || i.call(t, {
        store: o
      })), o;
    }
  }, useDeepCompareMemoize([v, n]));
  return React.useEffect(function () {
    if (h) return f(!1), function () {
      return h.dispose();
    };
  }, [h]), React.useEffect(function () {
    var e;

    if (h && o) {
      var t = (null == o ? void 0 : o.split(h.options.deepLinkPrefix)[0]) || (null === (e = h.menu.flatItems[h.menu.activeItemIdx]) || void 0 === e ? void 0 : e.id),
          n = h.menu.flatItems.find(function (e) {
        return e.id === t;
      }),
          r = s ? t + h.options.deepLinkPrefix + s : void 0;
      r && h.menu.history instanceof FakeHistoryService && h.menu.history.replaceForField(r), h.menu.activateAndScroll(n, !0, !1, r);
    }
  }, [o, s, h]), React.useEffect(function () {
    h && a && h.setActiveSampleLanguage(a);
  }, [a, h]), u({
    loading: d,
    store: h
  });
};

function fixSpec(e) {
  for (var t = e.components && e.components.securitySchemes || {}, n = 0, r = Object.keys(t); n < r.length; n++) {
    var i = t[r[n]];
    i["x-type"] && (i.type = i["x-type"]), i["x-scheme"] && (i.scheme = i["x-scheme"]);
  }
}

function loadMany(e) {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (t) {
      switch (t.label) {
        case 0:
          return [4, loadSpecs(e || [])];

        case 1:
          return t.sent(), [2, e];
      }
    });
  });
}

function loadSingle(e, t, n) {
  return __awaiter(this, void 0, void 0, function () {
    var r, i, o, a;
    return __generator(this, function (s) {
      switch (s.label) {
        case 0:
          if (!(null == n ? void 0 : n.skipBundleAndConvert)) return [3, 1];
          if (!e) throw new Error('spec must be specified when using "skipBundleAndConvert"');
          return r = e, [3, 3];

        case 1:
          return [4, loadAndBundleDefinition(e || t)];

        case 2:
          r = s.sent(), s.label = 3;

        case 3:
          if (!(i = null == n ? void 0 : n.unstable_externalDescription)) return [3, 8];
          s.label = 4;

        case 4:
          return s.trys.push([4, 7,, 8]), [4, fetch(i)];

        case 5:
          return [4, s.sent().text()];

        case 6:
          return o = s.sent(), r.info.description = o, [3, 8];

        case 7:
          return a = s.sent(), console.warn("Failed to load external description from " + i + ": " + a.message), [3, 8];

        case 8:
          try {
            fixSpec(r);
          } catch (e) {}

          return [2, r];
      }
    });
  });
}

function loadAndBundleSpecOrMd(e, t) {
  return e.endsWith(".md") ? loadAndBundleDefinition({
    openapi: "3.0.0",
    info: {
      title: t || "",
      version: "1.0",
      description: {
        $ref: e
      }
    },
    paths: {}
  }) : loadAndBundleDefinition(e);
}

export function loadSpecs(e) {
  return __awaiter(this, void 0, void 0, function () {
    var t, n, r, i, o, a, s, u, c, l, d, f, p;
    return __generator(this, function (v) {
      switch (v.label) {
        case 0:
          if (!Array.isArray(e)) return [3, 8];
          t = 0, n = e, v.label = 1;

        case 1:
          return t < n.length ? ((r = n[t]).id = r.id || slugify(r.title), r.url ? (i = r, [4, loadAndBundleSpecOrMd(r.url, r.title)]) : [3, 3]) : [3, 8];

        case 2:
          return i.definition = v.sent(), [3, 7];

        case 3:
          o = 0, a = r.items || [], v.label = 4;

        case 4:
          return o < a.length ? (s = a[o]).url ? (u = s, [4, loadAndBundleSpecOrMd(s.url, r.title)]) : [3, 6] : [3, 7];

        case 5:
          u.definition = v.sent(), v.label = 6;

        case 6:
          return o++, [3, 4];

        case 7:
          return t++, [3, 1];

        case 8:
          if (c = e.versions, !Array.isArray(c)) return [3, 12];
          l = 0, d = c, v.label = 9;

        case 9:
          return l < d.length ? (f = d[l]).url ? (p = f, [4, loadAndBundleSpecOrMd(f.url)]) : [3, 11] : [3, 12];

        case 10:
          p.spec = v.sent(), v.label = 11;

        case 11:
          return l++, [3, 9];

        case 12:
          return [2];
      }
    });
  });
}

function useDeepCompareMemoize(e) {
  var t = React.useRef(),
      n = React.useRef(0);
  return isDeepEqual(e, t.current) || (t.current = e, n.current += 1), [n.current];
}