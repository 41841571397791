import { __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { MiddlePanel, Row, Section } from "../../common-elements/";
import { ExternalDocumentation } from "../ExternalDocumentation/ExternalDocumentation";
import { Markdown } from "../Markdown/Markdown";
import { StyledMarkdownBlock } from "../Markdown/styled.elements";
import { ApiHeader, DownloadButton, InfoSpan, InfoSpanBox, InfoSpanBoxWrap } from "./styled.elements";
import { l } from "../../services/Labels";

var ApiInfo = function (e) {
  function t() {
    var t = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(t, "handleDownloadClick", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value(e) {
        e.target.href || (e.target.href = t.props.store.definition.info.downloadLink);
      }
    }), t;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props.store,
          t = e.definition,
          n = t.info,
          a = t.externalDocs,
          o = e.options.hideDownloadButton,
          r = n.downloadFileName,
          c = n.downloadLink,
          i = n.license && React.createElement(InfoSpan, null, "License:", " ", n.license.identifier ? n.license.identifier : React.createElement("a", {
        href: n.license.url
      }, n.license.name)) || null,
          m = n.contact && n.contact.url && React.createElement(InfoSpan, null, "URL: ", React.createElement("a", {
        href: n.contact.url
      }, n.contact.url)) || null,
          d = n.contact && n.contact.email && React.createElement(InfoSpan, null, n.contact.name || "E-mail", ":", " ", React.createElement("a", {
        href: "mailto:" + n.contact.email
      }, n.contact.email)) || null,
          f = n.termsOfService && React.createElement(InfoSpan, null, React.createElement("a", {
        href: n.termsOfService
      }, "Terms of Service")) || null,
          s = n.version && React.createElement("span", null, "(", n.version, ")") || null;
      return React.createElement(Section, null, React.createElement(Row, null, React.createElement(MiddlePanel, {
        className: "api-info"
      }, React.createElement(ApiHeader, null, n.title, " ", s), !o && React.createElement("p", null, l("downloadSpecification"), ":", React.createElement(DownloadButton, {
        download: r || !0,
        target: "_blank",
        href: c,
        onClick: this.handleDownloadClick
      }, l("download"))), React.createElement(StyledMarkdownBlock, null, (n.license || n.contact || n.termsOfService) && React.createElement(InfoSpanBoxWrap, null, React.createElement(InfoSpanBox, null, d, " ", m, " ", i, " ", f)) || null), React.createElement(Markdown, {
        source: e.definition.info.summary,
        "data-role": "redoc-summary"
      }), React.createElement(Markdown, {
        source: e.definition.info.description,
        "data-role": "redoc-description"
      }), a && React.createElement(ExternalDocumentation, {
        externalDocs: a
      }))));
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { ApiInfo };