import { Tabs } from "react-tabs";
import * as React from "react";
import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { OptionsContext } from "../../../redoc-lib";
import { CollapsingTabList } from "./CollapsingTabList";
import { CollapsingTab } from "./CollapsingTab";
import { CollapsingDropdown } from "./CollapsingDropdown";
export var CollapsingTabsBase = function CollapsingTabsBase(e) {
  var t = e.tabs,
      a = e.children,
      o = e.hidden,
      n = e.activeIndex,
      i = e.onChange,
      l = useContext(OptionsContext).samplesTabsMaxCount,
      r = useMemo(function () {
    return t.slice(l).map(function (e, t) {
      return {
        idx: t,
        value: e.title
      };
    });
  }, [t, l]),
      s = r.length + " more",
      c = useState(s),
      p = c[0],
      u = c[1],
      m = React.useCallback(function () {
    var e = n >= l;
    p !== s && p === t[n].title || !e ? p === s || e || u(s) : u(t[n].title);
  }, [n, l, p, s, t]);
  useLayoutEffect(function () {
    setTimeout(m, 0);
  }, [n, l, m]);
  return React.createElement(Tabs, {
    selectedIndex: n,
    onSelect: i
  }, React.createElement(CollapsingTabList, {
    hidden: o
  }, t.map(function (e, t) {
    return React.createElement(CollapsingTab, {
      key: e.key,
      disabled: t >= l
    }, e.title);
  }), r.length ? React.createElement(CollapsingDropdown, {
    options: r,
    onChange: function onChange(e) {
      i(l + e.idx);
    },
    placeholder: s,
    value: p,
    active: n >= l
  }) : null), a);
};