import { __decorate } from "tslib";
import { bind, debounce } from "decko";
import { EventEmitter } from "eventemitter3";
import { IS_BROWSER } from "../utils/";

var EVENT = "hashchange",
    HistoryService = function () {
  function e() {
    var e = this;
    Object.defineProperty(this, "_emiter", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "basePath", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "emit", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        e._emiter.emit(EVENT, e.currentId);
      }
    }), this._emiter = new EventEmitter(), this.bind();
  }

  return Object.defineProperty(e.prototype, "currentId", {
    get: function get() {
      return IS_BROWSER ? decodeURIComponent(window.location.hash.substring(1)) : "";
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "linkForId", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return e ? "#" + e : "";
    }
  }), Object.defineProperty(e.prototype, "generateDeepLink", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      return "";
    }
  }), Object.defineProperty(e.prototype, "subscribe", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this._emiter.addListener(EVENT, e);

      return function () {
        return t.removeListener(EVENT, e);
      };
    }
  }), Object.defineProperty(e.prototype, "bind", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      IS_BROWSER && window.addEventListener("hashchange", this.emit, !1);
    }
  }), Object.defineProperty(e.prototype, "dispose", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      IS_BROWSER && window.removeEventListener("hashchange", this.emit);
    }
  }), Object.defineProperty(e.prototype, "replace", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      void 0 === t && (t = !1), IS_BROWSER && null != e && e !== this.currentId && (t ? window.history.replaceState(null, "", window.location.href.split("#")[0] + this.linkForId(e)) : (window.history.pushState(null, "", window.location.href.split("#")[0] + this.linkForId(e)), this.emit()));
    }
  }), Object.defineProperty(e.prototype, "replaceForField", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      void 0 === t && (t = !1);
    }
  }), __decorate([bind, debounce], e.prototype, "replace", null), __decorate([bind, debounce], e.prototype, "replaceForField", null), e;
}();

export { HistoryService };
export var history = new HistoryService();
module.hot && module.hot.dispose(function () {
  history.dispose();
});