import { __assign } from "tslib";
var SENTINEL = {};
export function memoize(e, t, o) {
  if ("function" == typeof o.value) return _memoizeMethod(e, t, o);
  if ("function" == typeof o.get) return _memoizeGetter(e, t, o);
  throw new Error("@memoize decorator can be applied to methods or getters, got " + String(o.value) + " instead");
}

function _memoizeGetter(e, t, o) {
  var i = "_memoized_" + t,
      r = o.get;
  return e[i] = SENTINEL, __assign(__assign({}, o), {
    get: function get() {
      return this[i] === SENTINEL && (this[i] = null == r ? void 0 : r.call(this)), this[i];
    }
  });
}

function _memoizeMethod(e, t, o) {
  if (!o.value || o.value.length > 0) throw new Error("@memoize decorator can only be applied to methods of zero arguments");
  var i = "_memoized_" + t,
      r = o.value;
  return e[i] = SENTINEL, __assign(__assign({}, o), {
    value: function value() {
      return this[i] === SENTINEL && (this[i] = r.call(this)), this[i];
    }
  });
}