"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boolOrEmptySchema = exports.topBoolOrEmptySchema = void 0;

var errors_1 = require("../errors");

var codegen_1 = require("../codegen");

var names_1 = require("../names");

var boolError = {
  message: "boolean schema is false"
};

function topBoolOrEmptySchema(it) {
  var gen = it.gen,
      schema = it.schema,
      validateName = it.validateName;

  if (schema === false) {
    falseSchemaError(it, false);
  } else if (typeof schema == "object" && schema.$async === true) {
    gen.return(names_1.default.data);
  } else {
    gen.assign(codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["", ".errors"])), validateName), null);
    gen.return(true);
  }
}

exports.topBoolOrEmptySchema = topBoolOrEmptySchema;

function boolOrEmptySchema(it, valid) {
  var gen = it.gen,
      schema = it.schema;

  if (schema === false) {
    gen.var(valid, false); // TODO var

    falseSchemaError(it);
  } else {
    gen.var(valid, true); // TODO var
  }
}

exports.boolOrEmptySchema = boolOrEmptySchema;

function falseSchemaError(it, overrideAllErrors) {
  var gen = it.gen,
      data = it.data; // TODO maybe some other interface should be used for non-keyword validation errors...

  var cxt = {
    gen: gen,
    keyword: "false schema",
    data: data,
    schema: false,
    schemaCode: false,
    schemaValue: false,
    params: {},
    it: it
  };
  errors_1.reportError(cxt, boolError, undefined, overrideAllErrors);
}