"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoDescriptionOverride = void 0;

var utils_1 = require("../../utils");

var InfoDescriptionOverride = function InfoDescriptionOverride(_ref) {
  var filePath = _ref.filePath;
  return {
    Info: {
      leave: function leave(info, _ref2) {
        var report = _ref2.report,
            location = _ref2.location;
        if (!filePath) throw new Error("Parameter \"filePath\" is not provided for \"info-description-override\" rule");

        try {
          info.description = utils_1.readFileAsStringSync(filePath);
        } catch (e) {
          report({
            message: "Failed to read markdown override file for \"info.description\".\n".concat(e.message),
            location: location.child('description')
          });
        }
      }
    }
  };
};

exports.InfoDescriptionOverride = InfoDescriptionOverride;