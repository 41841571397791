import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2;

import { typography } from "../../common-elements";
import { svgToDataUri } from "../../appTheme";
import { css } from "@redocly/reference-docs";
export function getAlertVariant(a, b) {
  if (b === void 0) {
    b = "info";
  }

  return a[b || "info"];
}
export function getAlertIcon(a, b) {
  var c = getAlertVariant(a.components.alert.variants, b);
  return svgToDataUri(c.icon, c.iconColor);
}
export var alertGeneral = function alertGeneral(_ref) {
  var _ref$theme = _ref.theme,
      b = _ref$theme.components,
      c = _ref$theme.colors,
      a = _ref.variant;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  position: relative;\n  align-items: center;\n  margin: ", " ", ";\n  padding: ", " ", ";\n  padding-left: calc(", " * 2 + ", ");\n  border-radius: ", ";\n  color: ", "; /* default color */\n\n  background-color: ", ";\n\n  ", "\n"])), b.alert.marginVertical, b.alert.marginHorizontal, b.alert.paddingVertical, b.alert.paddingHorizontal, b.alert.paddingHorizontal, b.alert.iconSize, b.alert.borderRadius, c.text.primary, getAlertVariant(b.alert.variants, a).backgroundColor, typography(b.alert));
};
export var alertHeading = function alertHeading(_ref2) {
  var b = _ref2.theme.components,
      a = _ref2.variant;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  display: block;\n  margin: 0;\n  padding: 0;\n\n  letter-spacing: ", ";\n  color: ", ";\n  ", "\n"])), b.alert.heading.letterSpacing, getAlertVariant(b.alert.variants, a).headingColor, typography(b.alert.heading));
};
export var alertIcon = function alertIcon(_ref3) {
  var a = _ref3.theme,
      b = _ref3.variant;
  return "\n  content: '';\n  position: absolute;\n  left: " + a.components.alert.paddingHorizontal + ";\n  top:  50%;\n  transform: translateY(-50%);\n\n  width: " + a.components.alert.iconSize + ";\n  height: " + a.components.alert.iconSize + ";\n  margin-right: " + a.components.alert.paddingHorizontal + ";\n  flex-shrink: 0;\n  path {\n    fill: " + getAlertVariant(a.components.alert.variants, b).iconColor + ";\n  }\n\n  background-image: url('" + getAlertIcon(a, b) + "');\n";
};