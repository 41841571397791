"use strict";

require("core-js/modules/es.array.flat-map.js");

require("core-js/modules/es.array.unscopables.flat-map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initRules = void 0;

var utils_1 = require("../utils");

function initRules(rules, config, type, oasVersion) {
  return rules.flatMap(function (ruleset) {
    return Object.keys(ruleset).map(function (ruleId) {
      var rule = ruleset[ruleId];
      var ruleSettings = type === 'rules' ? config.getRuleSettings(ruleId, oasVersion) : type === 'preprocessors' ? config.getPreprocessorSettings(ruleId, oasVersion) : config.getDecoratorSettings(ruleId, oasVersion);

      if (ruleSettings.severity === 'off') {
        return undefined;
      }

      var visitor = rule(ruleSettings);
      return {
        severity: ruleSettings.severity,
        ruleId: ruleId,
        visitor: visitor
      };
    });
  }).filter(utils_1.notUndefined);
}

exports.initRules = initRules;