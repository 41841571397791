import { __assign } from "tslib";
import { CodeBuilder } from "../../helpers/code-builder";
import { HTTPSnippet } from "../..";
import { Lang } from "../../helpers/constants";
import { buildUrlExpression, printUrlVariablesDeclarations } from "../../helpers/code-helpers";
import { normalizeMimeType } from "../../../../utils";
var indent = 1,
    contentType = {
  X_FORM_URLENCODED: "application/x-www-form-urlencoded",
  JSON: "application/json",
  FORM_DATA: "multipart/form-data"
};

function hasMimeType(e, t) {
  return void 0 === e && (e = {}), normalizeMimeType(e.mimeType) === t;
}

var handler = function handler(e, t, n) {
  var a,
      r,
      s,
      i,
      o,
      p,
      d = n.target,
      l = n.client,
      u = __assign({
    capitalize: !0,
    showBoilerplate: !0,
    checkErrors: !1,
    printBody: !0,
    timeout: -1,
    indent: "  "
  }, t),
      h = null === (a = e.securityOAuth2ExtraCalls) || void 0 === a ? void 0 : a[0],
      c = u.checkErrors ? "err" : "_",
      v = e.method.toUpperCase(),
      m = new CodeBuilder({
    indentation: u.indent,
    variablesPrefix: u.variablesPrefix,
    capitalize: u.capitalize,
    lang: Lang.GO
  }),
      y = function y() {
    u.checkErrors && m.push(indent, "if err != nil {").push(indent + 1, "panic(err)").push(indent, "}");
  };

  if (u.showBoilerplate && (m.push("package main").blank().push("import (").push(indent, '"fmt"'), u.timeout > 0 && m.push(indent, '"time"'), h && m.push(indent, '"encoding/json"'), hasMimeType(e.postData, contentType.FORM_DATA) && (m.push(indent, '"mime/multipart"'), m.push(indent, '"io"'), m.push(indent, '"bytes"')), (hasMimeType(e.postData, contentType.X_FORM_URLENCODED) || hasMimeType(null === (s = null === (r = e.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r[0]) || void 0 === s ? void 0 : s.postData, contentType.X_FORM_URLENCODED)) && (m.push(indent, '"net/url"'), m.push(indent, '"strconv"')), (hasMimeType(e.postData, contentType.JSON) || hasMimeType(null === (o = null === (i = e.securityOAuth2ExtraCalls) || void 0 === i ? void 0 : i[0]) || void 0 === o ? void 0 : o.postData, contentType.JSON)) && m.push(indent, '"bytes"'), (e.postData && !hasMimeType(e.postData, contentType.JSON) || h) && m.push(indent, '"strings"'), m.push(indent, '"net/http"'), u.printBody && m.push(indent, '"io/ioutil"'), m.push(")").blank().push("func main() {")), Object.keys(e.allHeaders).length && Object.keys(e.allHeaders).forEach(function (t) {
    e.allHeaders[t] = '"' + e.allHeaders[t] + '"';
  }), h) {
    var f = "oAuth2",
        b = u.checkErrors ? c + " := " : "",
        w = u.capitalize ? "Res" : "res",
        T = new HTTPSnippet(h).convert(d, l, __assign(__assign({}, u), {
      printBody: !1,
      variablesPrefix: f,
      showBoilerplate: !1
    }));
    m.push(T).blank().push(indent, "var oAuth2" + w + "Body struct {").push(indent + 1, "Access_token string").push(indent, "}").push(indent, b + "json.NewDecoder(" + f + w + ".Body).Decode(&" + f + w + "Body)").blank(), y(), e.allHeaders.Authorization = '"Bearer " + oAuth2' + w + "Body.Access_token";
  }

  if (printUrlVariablesDeclarations(e, m, indent), u.timeout > 0 && (l = m.var("client"), m.push(indent, "%s := http.Client{", l).push(indent + 1, "Timeout: time.Duration(%s * time.Second),", u.timeout).push(indent, "}").blank()), m.push(indent, m.var("reqUrl") + " := " + buildUrlExpression(e, m)), e.postData) {
    var D = void 0;

    switch (normalizeMimeType(e.postData.mimeType)) {
      case contentType.X_FORM_URLENCODED:
        m.push(indent, "%s := url.Values{}", m.var("data")), e.postData.params.forEach(function (e) {
          m.push(indent, '%s.Set("%s", "%s")', m.var("data"), e.name, e.value);
        }), m.push(indent, '%s, %s := http.NewRequest("%s", %s, strings.NewReader(%s.Encode()))', m.var("req"), c, v, m.var("reqUrl"), m.var("data")), e.allHeaders["Content-Length"] = "strconv.Itoa(len(" + m.var("data") + ".Encode()))";
        break;

      case contentType.JSON:
        D = JSON.stringify(e.postData.jsonObj, null, 2).split("\n").map(function (e) {
          return u.indent + e;
        }).join("\n").trim(), m.push(indent, "var " + m.var("data") + " = []byte(`" + D + "`)"), m.push(indent, '%s, %s := http.NewRequest("%s", %s, bytes.NewBuffer(%s))', m.var("req"), c, v, m.var("reqUrl"), m.var("data"));
        break;

      case contentType.FORM_DATA:
        m.push(indent, "%s := &bytes.Buffer{}", m.var("data")).push(indent, "writer := multipart.NewWriter(%s)", m.var("data")), e.postData.params.forEach(function (e) {
          if (!e.fileName && !e.contentType) return m.push(indent, '%s, _ := writer.CreateFormField("%s")', m.var(e.name + "Fw"), e.name).push(indent, '_, %s = io.Copy(%s, strings.NewReader("%s"))', c, m.var(e.name + "Fw"), e.value), void y();
          e.fileName && (m.push(indent, '%s, %s = writer.CreateFormFile("%s", "%s")', m.var(e.name + "Fw"), c, e.name, e.fileName), y());
        }), m.push(indent, "writer.Close()").blank().push(indent, "%s := bytes.NewReader(%s.Bytes())", m.var("payload"), m.var("data")).push(indent, '%s, %s := http.NewRequest("%s", %s, %s)', m.var("req"), c, v, m.var("reqUrl"), m.var("payload")), e.allHeaders["Content-Type"] = "writer.FormDataContentType()";
        break;

      default:
        e.postData.text && m.push(indent, "%s := strings.NewReader(%s)", m.var("payload"), JSON.stringify(null === (p = e.postData) || void 0 === p ? void 0 : p.text)).push(indent, '%s, %s := http.NewRequest("%s", %s, %s)', m.var("req"), c, v, m.var("reqUrl"), m.var("payload"));
    }
  } else m.push(indent, '%s, %s := http.NewRequest("%s", %s, nil)', m.var("req"), c, v, m.var("reqUrl"));

  if (Object.getOwnPropertyNames(e.queryObj).length) {
    for (var O in m.blank().push(indent, m.var("query") + " := " + m.var("req") + ".URL.Query()"), e.queryObj) {
      m.push(indent, m.var("query") + '.Add("' + O + '", "' + e.queryObj[O] + '")');
    }

    m.push(indent, m.var("req") + ".URL.RawQuery = " + m.var("query") + ".Encode()").blank();
  }

  if (y(), e.basicAuth) {
    var q = e.basicAuth,
        N = q.username,
        g = q.password;
    m.push(indent, '%s.SetBasicAuth("' + N + '", "' + g + '")', m.var("req"));
  }

  return Object.keys(e.allHeaders).length && Object.keys(e.allHeaders).forEach(function (t) {
    m.push(indent, '%s.Header.Add("%s", %s)', m.var("req"), t, e.allHeaders[t]);
  }), m.push(indent, "%s, %s := %s.Do(%s)", m.var("res"), c, l, m.var("req")), y(), u.printBody && (m.push(indent, "defer %s.Body.Close()", m.var("res")).push(indent, "%s, %s := ioutil.ReadAll(%s.Body)", m.var("body"), c, m.var("res")), y()), m.blank().push(indent, "fmt.Println(%s)", m.var("res")), u.printBody && m.push(indent, "fmt.Println(string(%s))", m.var("body")), u.showBoilerplate && m.push("}"), m.join();
};

export var info = {
  key: "native",
  title: "NewRequest",
  link: "http://golang.org/pkg/net/http/#NewRequest",
  description: "Golang HTTP client request"
};
export default handler;