"use strict";

var _slicedToArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/slicedToArray");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationSecurityDefined = void 0;

var OperationSecurityDefined = function OperationSecurityDefined() {
  var referencedSchemes = new Map();
  return {
    DefinitionRoot: {
      leave: function leave(_, _ref) {
        var report = _ref.report;

        var _iterator = _createForOfIteratorHelper(referencedSchemes.entries()),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _step$value = _slicedToArray(_step.value, 2),
                name = _step$value[0],
                scheme = _step$value[1];

            if (scheme.defined) continue;

            var _iterator2 = _createForOfIteratorHelper(scheme.from),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var reportedFromLocation = _step2.value;
                report({
                  message: "There is no `".concat(name, "` security scheme defined."),
                  location: reportedFromLocation.key()
                });
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    },
    SecurityScheme: function SecurityScheme(_securityScheme, _ref2) {
      var key = _ref2.key;
      referencedSchemes.set(key.toString(), {
        defined: true,
        from: []
      });
    },
    SecurityRequirement: function SecurityRequirement(requirements, _ref3) {
      var location = _ref3.location;

      for (var _i = 0, _Object$keys = Object.keys(requirements); _i < _Object$keys.length; _i++) {
        var requirement = _Object$keys[_i];
        var authScheme = referencedSchemes.get(requirement);
        var requirementLocation = location.child([requirement]);

        if (!authScheme) {
          referencedSchemes.set(requirement, {
            from: [requirementLocation]
          });
        } else {
          authScheme.from.push(requirementLocation);
        }
      }
    }
  };
};

exports.OperationSecurityDefined = OperationSecurityDefined;