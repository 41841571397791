import { __assign } from "tslib";
import { CodeBuilder } from "../../helpers/code-builder";
import { HTTPSnippet } from "../..";
import { normalizeMimeType } from "../../../../utils";
import { addIndentation, buildUrlExpression, printUrlVariablesDeclarations } from "../../helpers/code-helpers";
import { Lang } from "../../helpers/constants";

var URL_ENCODED = "application/x-www-form-urlencoded",
    hasQueryParams = function hasQueryParams(t) {
  return !(!t.uriObj.query || !Object.keys(t.uriObj.query).length);
},
    getBody = function getBody(t) {
  return t.postData && t.postData.mimeType === URL_ENCODED ? "HttpRequest.BodyPublishers.ofString(form)" : t.postData ? "HttpRequest.BodyPublishers.ofString(payload)" : ["POST", "PUT", "PATCH"].includes(t.method.toUpperCase()) ? 'HttpRequest.BodyPublishers.ofString("some body text")' : "";
},
    handler = function handler(t, a, e) {
  var s,
      r,
      n,
      i = e.target,
      o = e.client,
      p = Object.assign({
    indent: "  ",
    withWrapper: !0
  }, a),
      l = new CodeBuilder({
    indentation: p.indent,
    variablesPrefix: p.variablesPrefix,
    capitalize: !0,
    lang: Lang.JAVA
  }),
      u = !1;

  if (p.withComments && addComments(l, t), p.withImports && addImports(l, t), p.withWrapper && (l.push("public class App {"), l.push(1, "public static void main(String[] args) throws Exception {"), l.push(2, "var httpClient = HttpClient.newBuilder().build();"), l.blank()), null === (s = t.securityOAuth2ExtraCalls) || void 0 === s ? void 0 : s.length) {
    var h = new HTTPSnippet(null === (r = t.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r[0]).convert(i, o, __assign(__assign({}, p), {
      withImports: !1,
      withWrapper: !1,
      withComments: !1,
      variablesPrefix: "oauth2"
    }));
    l.push(h), l.blank(), l.push(2, "var oauth2Response = httpClient.send(oauth2Request, HttpResponse.BodyHandlers.ofString());"), l.push(2, 'var accessToken = new JSONObject(oauth2Response.body()).getString("access_token");'), l.blank();
  }

  if (t.postData) switch (normalizeMimeType(t.postData.mimeType)) {
    case URL_ENCODED:
      t.postData.params && (l.push(2, "HashMap<String, String> params = new HashMap<>();"), t.postData.params.forEach(function (t) {
        l.push(2, 'params.put("%s", "%s");', t.name, t.value);
      }), l.blank(), l.push(2, "var form = params.keySet().stream()"), l.push(3, '.map(key -> key + "=" + URLEncoder.encode(params.get(key), StandardCharsets.UTF_8))'), l.push(3, '.collect(Collectors.joining("&"));'), l.blank());
      break;

    case "application/json":
      t.postData.jsonObj && (l.push(2, 'var payload = String.join("\\n"\n      ' + addIndentation(JSON.stringify(t.postData.jsonObj, null, 1).replace(/"/g, '\\"').replace(/^/gm, ', "').replace(/$/gm, '"'), {
        level: 3,
        indent: p.indent,
        firstLine: !1
      })), l.push(2, ");"), l.blank());
      break;

    case "application/xml":
      t.postData.text && (l.push(2, 'var payload = String.join("\\n"'), t.postData.text.trim().split("\n").forEach(function (t) {
        var a = t.replace(/"/g, '\\"');
        l.push(3, ', "' + a + '"');
      }), l.push(2, ");"), l.blank());
      break;

    case "multipart/form-data":
      u = !0, l.unshift("# multipart/form-data is not supported yet\n");
  }

  if (hasQueryParams(t)) {
    l.push(2, "HashMap<String, String> params = new HashMap<>();");

    for (var d = 0, m = Object.entries(t.uriObj.query); d < m.length; d++) {
      var c = m[d],
          v = c[0],
          g = c[1];
      l.push(2, 'params.put("%s", "%s");', v, g);
    }

    l.blank(), l.push(2, "var query = params.keySet().stream()"), l.push(3, '.map(key -> key + "=" + URLEncoder.encode(params.get(key), StandardCharsets.UTF_8))'), l.push(3, '.collect(Collectors.joining("&"));'), l.blank();
  }

  l.push(2, "var " + l.var("host") + ' = "%s";', t.uriObj.protocol + "//" + t.uriObj.host), printUrlVariablesDeclarations(t, l, 2);

  var b = __assign(__assign({}, t), {
    pathname: t.uriObj.pathname
  });

  l.push(2, "var " + l.var("pathname") + " = %s;", buildUrlExpression(b, l)), l.push(2, "var " + l.var("request") + " = HttpRequest.newBuilder()"), "PATCH" === t.method.toUpperCase() ? l.push(3, '.method("PATCH", %s)', getBody(t)) : l.push(3, ".%s(%s)", t.method.toUpperCase(), getBody(t)), l.push(3, ".uri(URI.create(" + l.var("host") + " + " + l.var("pathname") + " %s))", hasQueryParams(t) ? "+ '?' + query" : ""), (null === (n = t.securityOAuth2ExtraCalls) || void 0 === n ? void 0 : n.length) && (t.allHeaders.Authorization = '"Bearer " + accessToken');
  var y = Object.keys(t.allHeaders);

  if (y.length && y.forEach(function (a) {
    var e;
    l.push(3, '.header("%s", %s)', a, "Authorization" === a && (null === (e = t.securityOAuth2ExtraCalls) || void 0 === e ? void 0 : e.length) ? t.allHeaders[a] : '"' + t.allHeaders[a] + '"');
  }), t.basicAuth) {
    var f = t.basicAuth,
        C = f.username,
        j = f.password;
    l.push(3, '.header("Authorization", "Basic " + Base64.getEncoder().encodeToString(("' + C + ":" + j + '").getBytes()))');
  }

  return l.push(3, ".build();"), p.withWrapper && (l.blank(), l.push(2, "var response = httpClient.send(request, HttpResponse.BodyHandlers.ofString());"), l.blank(), l.push(2, "System.out.println(response.body());"), l.push(1, "}"), l.push("}")), u && l.clean(), l.join();
};

export var info = {
  key: "httpclient",
  title: "HttpClient",
  link: "https://docs.oracle.com/en/java/javase/11/docs/api/java.net.http/java/net/http/HttpClient.html",
  description: "HttpClient (Java SE 11 & JDK 11 )"
};
export default handler;

function addComments(t, a) {
  var e;
  t.push("/**"), t.push(" * Requires JDK >= 11"), (null === (e = a.securityOAuth2ExtraCalls) || void 0 === e ? void 0 : e.length) && (t.push(" *"), t.push(' * Requires package "org.json" >= 20201115'), t.push(" * See here for installation details:"), t.push(" *  https://search.maven.org/artifact/org.json/json")), t.push(" */"), t.blank();
}

function addImports(t, a) {
  var e, s, r, n;
  t.push("import java.net.*;"), t.push("import java.net.http.*;"), t.push("import java.util.*;"), (normalizeMimeType(null === (e = a.postData) || void 0 === e ? void 0 : e.mimeType) === URL_ENCODED && (null === (s = a.postData) || void 0 === s ? void 0 : s.params) || (null === (r = a.securityOAuth2ExtraCalls) || void 0 === r ? void 0 : r.length) || hasQueryParams(a)) && (t.push("import java.nio.charset.StandardCharsets;"), t.push("import java.util.stream.Collectors;")), (null === (n = a.securityOAuth2ExtraCalls) || void 0 === n ? void 0 : n.length) && t.push("import org.json.JSONObject;"), t.blank();
}