import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2;

import { css, mediaCSS } from "@redocly/reference-docs";
export function typography(a) {
  return a ? css(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n    ", "\n  "])), mediaCSS({
    fontSize: a.fontSize || "",
    fontWeight: a.fontWeight || "",
    fontFamily: a.fontFamily || "",
    lineHeight: a.lineHeight || "",
    color: a.color || "",
    textTransform: a.transform || ""
  })) : "";
}
export function margins(a) {
  return a ? css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n    ", "\n  "])), mediaCSS({
    marginTop: a.marginTop || a.marginVertical || "",
    marginBottom: a.marginBottom || a.marginVertical || "",
    marginLeft: a.marginLeft || a.marginHorizontal || "",
    marginRight: a.marginRight || a.marginHorizontal || ""
  })) : "";
}