import { __makeTemplateObject } from "tslib";
import styled from "../../redoc-lib/src/styled-components";
import { ShelfIcon } from "../../redoc-lib/src/common-elements/icons";
import { Panel } from "./Panel";
import { Header } from "./Header";
import { DarkHeader } from "./DarkHeader";
import { PanelBody } from "./PanelBody";
export var CodePanel = styled(Panel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > ", " {\n    ", ";\n\n    ", " {\n      fill: ", ";\n      position: relative;\n    }\n  }\n\n  && {\n    ", " {\n      padding: 10px 20px 20px;\n      background-color: ", ";\n      color: ", ";\n    }\n  }\n"], ["\n  > ", " {\n    ", ";\n\n    ", " {\n      fill: ", ";\n      position: relative;\n    }\n  }\n\n  && {\n    ", " {\n      padding: 10px 20px 20px;\n      background-color: ", ";\n      color: ", ";\n    }\n  }\n"])), Header, DarkHeader, ShelfIcon, function (e) {
  return e.theme.colors.text.light;
}, PanelBody, function (e) {
  return e.theme.rightPanel.panelBackgroundColor;
}, function (e) {
  return e.theme.colors.text.light;
});
var templateObject_1;