"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var error = {
  message: function message(_ref) {
    var _ref$params = _ref.params,
        min = _ref$params.min,
        max = _ref$params.max;
    return max === undefined ? codegen_1.str(_templateObject || (_templateObject = _taggedTemplateLiteral(["must contain at least ", " valid item(s)"])), min) : codegen_1.str(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["must contain at least ", " and no more than ", " valid item(s)"])), min, max);
  },
  params: function params(_ref2) {
    var _ref2$params = _ref2.params,
        min = _ref2$params.min,
        max = _ref2$params.max;
    return max === undefined ? codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["{minContains: ", "}"])), min) : codegen_1._(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["{minContains: ", ", maxContains: ", "}"])), min, max);
  }
};
var def = {
  keyword: "contains",
  type: "array",
  schemaType: ["object", "boolean"],
  before: "uniqueItems",
  trackErrors: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        schema = cxt.schema,
        parentSchema = cxt.parentSchema,
        data = cxt.data,
        it = cxt.it;
    var min;
    var max;
    var minContains = parentSchema.minContains,
        maxContains = parentSchema.maxContains;

    if (it.opts.next) {
      min = minContains === undefined ? 1 : minContains;
      max = maxContains;
    } else {
      min = 1;
    }

    var len = gen.const("len", codegen_1._(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", ".length"])), data));
    cxt.setParams({
      min: min,
      max: max
    });

    if (max === undefined && min === 0) {
      util_1.checkStrictMode(it, "\"minContains\" == 0 without \"maxContains\": \"contains\" keyword ignored");
      return;
    }

    if (max !== undefined && min > max) {
      util_1.checkStrictMode(it, "\"minContains\" > \"maxContains\" is always invalid");
      cxt.fail();
      return;
    }

    if (util_1.alwaysValidSchema(it, schema)) {
      var cond = codegen_1._(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["", " >= ", ""])), len, min);

      if (max !== undefined) cond = codegen_1._(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["", " && ", " <= ", ""])), cond, len, max);
      cxt.pass(cond);
      return;
    }

    it.items = true;
    var valid = gen.name("valid");

    if (max === undefined && min === 1) {
      validateItems(valid, function () {
        return gen.if(valid, function () {
          return gen.break();
        });
      });
    } else {
      gen.let(valid, false);
      var schValid = gen.name("_valid");
      var count = gen.let("count", 0);
      validateItems(schValid, function () {
        return gen.if(schValid, function () {
          return checkLimits(count);
        });
      });
    }

    cxt.result(valid, function () {
      return cxt.reset();
    });

    function validateItems(_valid, block) {
      gen.forRange("i", 0, len, function (i) {
        cxt.subschema({
          keyword: "contains",
          dataProp: i,
          dataPropType: util_1.Type.Num,
          compositeRule: true
        }, _valid);
        block();
      });
    }

    function checkLimits(count) {
      gen.code(codegen_1._(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", "++"])), count));

      if (max === undefined) {
        gen.if(codegen_1._(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["", " >= ", ""])), count, min), function () {
          return gen.assign(valid, true).break();
        });
      } else {
        gen.if(codegen_1._(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", " > ", ""])), count, max), function () {
          return gen.assign(valid, false).break();
        });
        if (min === 1) gen.assign(valid, true);else gen.if(codegen_1._(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["", " >= ", ""])), count, min), function () {
          return gen.assign(valid, true);
        });
      }
    }
  }
};
exports.default = def;