import { __extends } from "tslib";
import * as React from "react";
import { H2, MiddlePanel, Row, Section, ShareLink } from "../../common-elements";
import { titleize } from "../../utils/helpers";
import { Markdown } from "../Markdown/Markdown";
import { StyledMarkdownBlock } from "../Markdown/styled.elements";

var AUTH_TYPES = {
  oauth2: "OAuth2",
  apiKey: "API Key",
  http: "HTTP",
  openIdConnect: "OpenID Connect"
},
    OAuthFlow = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.type,
          n = e.flow;
      return React.createElement("tr", null, React.createElement("th", null, " ", t, " OAuth Flow "), React.createElement("td", null, "implicit" === t || "authorizationCode" === t ? React.createElement("div", null, React.createElement("strong", null, " Authorization URL: "), n.authorizationUrl) : null, "password" === t || "clientCredentials" === t || "authorizationCode" === t ? React.createElement("div", null, React.createElement("strong", null, " Token URL: "), n.tokenUrl) : null, n.refreshUrl && React.createElement("div", null, React.createElement("strong", null, " Refresh URL: "), n.refreshUrl), React.createElement("div", null, React.createElement("strong", null, " Scopes: ")), React.createElement("ul", null, Object.keys(n.scopes || {}).map(function (e) {
        return React.createElement("li", {
          key: e
        }, React.createElement("code", null, e), " - ", React.createElement(Markdown, {
          inline: !0,
          source: n.scopes[e] || ""
        }));
      }))));
    }
  }), t;
}(React.PureComponent);

export { OAuthFlow };

var SecurityDefs = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return this.props.securitySchemes.schemes.map(function (e) {
        return React.createElement(Section, {
          id: e.sectionId,
          key: e.id
        }, React.createElement(Row, null, React.createElement(MiddlePanel, null, React.createElement(H2, null, React.createElement(ShareLink, {
          to: e.sectionId
        }), e.id), React.createElement(Markdown, {
          source: e.description || ""
        }), React.createElement(StyledMarkdownBlock, null, React.createElement("table", {
          className: "security-details"
        }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("th", null, " Security Scheme Type "), React.createElement("td", null, " ", AUTH_TYPES[e.type] || e.type, " ")), e.apiKey ? React.createElement("tr", null, React.createElement("th", null, " ", titleize(e.apiKey.in || ""), " parameter name:"), React.createElement("td", null, " ", e.apiKey.name, " ")) : e.http ? [React.createElement("tr", {
          key: "scheme"
        }, React.createElement("th", null, " HTTP Authorization Scheme "), React.createElement("td", null, " ", e.http.scheme, " ")), "bearer" === e.http.scheme && e.http.bearerFormat && React.createElement("tr", {
          key: "bearer"
        }, React.createElement("th", null, " Bearer format "), React.createElement("td", null, ' "', e.http.bearerFormat, '" '))] : e.openId ? React.createElement("tr", null, React.createElement("th", null, " Connect URL "), React.createElement("td", null, React.createElement("a", {
          target: "_blank",
          rel: "noopener noreferrer",
          href: e.openId.connectUrl
        }, e.openId.connectUrl))) : e.flows ? Object.keys(e.flows).map(function (t) {
          return React.createElement(OAuthFlow, {
            key: t,
            type: t,
            flow: e.flows[t]
          });
        }) : null))))));
      });
    }
  }), t;
}(React.PureComponent);

export { SecurityDefs };