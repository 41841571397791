import { __makeTemplateObject } from "tslib";
import { Tab as ReactTab } from "react-tabs";
import styled from "../../redoc-lib/src/styled-components";
export var Tab = styled(ReactTab)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  cursor: pointer;\n  padding: 5px 10px;\n  font-size: 14px;\n  line-height: 26px;\n  font-weight: 400;\n  text-align: center;\n  color: ", ";\n  background: ", ";\n\n  &.react-tabs__tab--selected {\n    background: ", ";\n    border-bottom: 4px solid ", ";\n    margin-bottom: -4px;\n  }\n\n  &:first-child {\n    border-top-left-radius: ", ";\n  }\n\n  &:last-child {\n    border-top-right-radius: ", ";\n  }\n"], ["\n  flex: 1;\n  cursor: pointer;\n  padding: 5px 10px;\n  font-size: 14px;\n  line-height: 26px;\n  font-weight: 400;\n  text-align: center;\n  color: ", ";\n  background: ", ";\n\n  &.react-tabs__tab--selected {\n    background: ", ";\n    border-bottom: 4px solid ", ";\n    margin-bottom: -4px;\n  }\n\n  &:first-child {\n    border-top-left-radius: ", ";\n  }\n\n  &:last-child {\n    border-top-right-radius: ", ";\n  }\n"])), function (n) {
  return n.theme.colors.text.light;
}, function (n) {
  return n.theme.rightPanel.panelBackgroundColor;
}, function (n) {
  return n.theme.rightPanel.panelControlsBackgroundColor;
}, function (n) {
  return n.theme.colors.accent.main;
}, function (n) {
  return n.theme.shape.borderRadius;
}, function (n) {
  return n.theme.shape.borderRadius;
});
var templateObject_1;