import "core-js/es/symbol";
import * as styledComponents from "styled-components";
var styled = styledComponents.default,
    css = styledComponents.css,
    createGlobalStyle = styledComponents.createGlobalStyle,
    keyframes = styledComponents.keyframes,
    ThemeProvider = styledComponents.ThemeProvider,
    ThemeContext = styledComponents.ThemeContext,
    withTheme = styledComponents.withTheme;
export { css, createGlobalStyle, keyframes, ThemeProvider, ThemeContext, withTheme };
export default styled;