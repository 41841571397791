import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject;

import styled from "../../styled-components";
import { replaceHashInColor } from "../../appTheme";
var defaultWidth = "7px",
    defaultHeight = "5px",
    defaultDirection = "down",
    defaultColor = "#fff";

function directionToTransform(a) {
  var b;
  var c = a.direction || defaultDirection;
  return b = "down" === c ? 0 : "up" === c ? 180 : "right" === c ? -90 : "left" === c ? 90 : 0, "rotate(" + b + "deg)";
}

export var Arrow = styled.i(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  width: ", ";\n  height: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n  display: inline-block;\n  background-size: ", " ", ";\n  background-image: url(\"data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 5'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.5 2.51L5.806.205a.7.7 0 01.99.99l-2.8 2.8a.698.698 0 01-.99 0l-2.8-2.8a.7.7 0 11.99-.99L3.5 2.51z' fill='", "'/%3E%3C/svg%3E\");\n  transform: ", ";\n\n  vertical-align: middle;\n"])), function (a) {
  return a.width || defaultWidth;
}, function (a) {
  return a.height || defaultHeight;
}, function (a) {
  return a.width || defaultWidth;
}, function (a) {
  return a.height || defaultHeight;
}, function (a) {
  return replaceHashInColor(a.color || defaultColor);
}, directionToTransform);