"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject, _templateObject2, _templateObject3;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var equal_1 = require("../../runtime/equal");

var error = {
  message: "must be equal to constant",
  params: function params(_ref) {
    var schemaCode = _ref.schemaCode;
    return codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["{allowedValue: ", "}"])), schemaCode);
  }
};
var def = {
  keyword: "const",
  $data: true,
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        data = cxt.data,
        $data = cxt.$data,
        schemaCode = cxt.schemaCode,
        schema = cxt.schema;

    if ($data || schema && typeof schema == "object") {
      cxt.fail$data(codegen_1._(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["!", "(", ", ", ")"])), util_1.useFunc(gen, equal_1.default), data, schemaCode));
    } else {
      cxt.fail(codegen_1._(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " !== ", ""])), schema, data));
    }
  }
};
exports.default = def;