"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoEnumTypeMismatch = void 0;

var utils_1 = require("../utils");

var NoEnumTypeMismatch = function NoEnumTypeMismatch() {
  return {
    Schema: function Schema(schema, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (schema.enum && schema.type && !Array.isArray(schema.type)) {
        var typeMismatchedValues = schema.enum.filter(function (item) {
          return !utils_1.matchesJsonSchemaType(item, schema.type, schema.nullable);
        });

        var _iterator = _createForOfIteratorHelper(typeMismatchedValues),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var mismatchedValue = _step.value;
            report({
              message: "All values of `enum` field must be of the same type as the `type` field: expected \"".concat(schema.type, "\" but received \"").concat(utils_1.oasTypeOf(mismatchedValue), "\"."),
              location: location.child(['enum', schema.enum.indexOf(mismatchedValue)])
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }

      if (schema.enum && schema.type && Array.isArray(schema.type)) {
        var mismatchedResults = {};

        var _iterator2 = _createForOfIteratorHelper(schema.enum),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var enumValue = _step2.value;
            mismatchedResults[enumValue] = [];

            var _iterator3 = _createForOfIteratorHelper(schema.type),
                _step3;

            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var type = _step3.value;
                var valid = utils_1.matchesJsonSchemaType(enumValue, type, schema.nullable);
                if (!valid) mismatchedResults[enumValue].push(type);
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }

            if (mismatchedResults[enumValue].length !== schema.type.length) delete mismatchedResults[enumValue];
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        ;

        for (var _i = 0, _Object$keys = Object.keys(mismatchedResults); _i < _Object$keys.length; _i++) {
          var mismatchedKey = _Object$keys[_i];
          report({
            message: "Enum value `".concat(mismatchedKey, "` must be of one type. Allowed types: `").concat(schema.type, "`."),
            location: location.child(['enum', schema.enum.indexOf(mismatchedKey)])
          });
        }

        ;
      }
    }
  };
};

exports.NoEnumTypeMismatch = NoEnumTypeMismatch;