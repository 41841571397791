import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import * as React from "react";
import { addTrailingSlash, usePathPrefix } from "../utils";
export var REDOCLY_COOKIE_NAME = "redocly_jwt";
export var IDP_ACCESS_TOKEN_COOKIE_NAME = "redocly_idp_access_token";
export var IDP_ID_TOKEN_COOKIE_NAME = "redocly_idp_id_token";
export var REDOCLY_LOGIN_RESULT_COOKIE_NAME = "redocly_login_result";
export function getCookie(a) {
  if ("undefined" == typeof document) return "";
  var b = a + "=",
      d = decodeURIComponent(document.cookie),
      e = d.split(";");

  for (var c = 0; c < e.length; c++) {
    for (var f = e[c]; " " === f.charAt(0);) {
      f = f.substring(1);
    }

    if (0 === f.indexOf(b)) return f.substring(b.length, f.length);
  }

  return "";
}
export function deleteCookie(a, b) {
  document.cookie = a + "=; " + (b ? "path=" + b + "; " : "") + "Max-Age=0";
}
export function logout(a, b) {
  if (b === void 0) {
    b = !0;
  }

  var c = usePathPrefix();
  deleteCookie(REDOCLY_COOKIE_NAME), deleteCookie(REDOCLY_COOKIE_NAME, c || "/"), deleteCookie(IDP_ACCESS_TOKEN_COOKIE_NAME), deleteCookie(IDP_ACCESS_TOKEN_COOKIE_NAME, c || "/"), deleteCookie(IDP_ID_TOKEN_COOKIE_NAME), deleteCookie(IDP_ID_TOKEN_COOKIE_NAME, c || "/"), deleteCookie("id_token"), deleteCookie("access_token"), deleteCookie("token_type"), deleteCookie("expires_in");
  b && setTimeout(function () {
    if ("undefined" != typeof window) {
      if (a) return void (window.location.href = a);
      window.location.href = c ? addTrailingSlash(c) : "/";
    }
  }, 20);
}
export function useProxiedFetch(a) {
  return function (b, c) {
    var d = new URL(b.replace(/^\//, ""), "https://api.redoc.ly/proxy/" + a + "/").href;
    return fetch(d, _objectSpread(_objectSpread({}, c), {}, {
      headers: _objectSpread(_objectSpread({}, c === null || c === void 0 ? void 0 : c.headers), {}, {
        Authorization: getUserJwt()
      })
    }));
  };
}
export function getUserJwt() {
  return getCookie(REDOCLY_COOKIE_NAME) || getCookie("id_token");
}
export function useIsLoggedIn() {
  var _React$useState = React.useState(!1),
      a = _React$useState[0],
      b = _React$useState[1];

  return React.useEffect(function () {
    b(!!getUserJwt());
  }, []), a;
}
export function useIsAuthenticated() {
  var _React$useState2 = React.useState(!1),
      a = _React$useState2[0],
      b = _React$useState2[1],
      _React$useState3 = React.useState(!0),
      c = _React$useState3[0],
      d = _React$useState3[1];

  return React.useEffect(function () {
    b(!!getUserJwt()), d(!1);
  }, []), {
    loading: c,
    isAuthenticated: a
  };
}
export function useUserClaims() {
  var _React$useState4 = React.useState(null),
      a = _React$useState4[0],
      b = _React$useState4[1],
      _React$useState5 = React.useState(!0),
      c = _React$useState5[0],
      d = _React$useState5[1];

  return React.useEffect(function () {
    b(getUserClaims()), d(!1);
  }, []), {
    loading: c,
    claims: a
  };
}
export function getIdPJwt() {
  var _getCookie;

  return (_getCookie = getCookie(IDP_ID_TOKEN_COOKIE_NAME)) !== null && _getCookie !== void 0 ? _getCookie : null;
}
export function getIdPAccessToken() {
  var _getCookie2;

  return (_getCookie2 = getCookie(IDP_ACCESS_TOKEN_COOKIE_NAME)) !== null && _getCookie2 !== void 0 ? _getCookie2 : null;
}
export function getUserId() {
  var _getUserClaims$sub, _getUserClaims;

  return (_getUserClaims$sub = (_getUserClaims = getUserClaims()) === null || _getUserClaims === void 0 ? void 0 : _getUserClaims.sub) !== null && _getUserClaims$sub !== void 0 ? _getUserClaims$sub : null;
}
export function getUserClaims() {
  var a = getUserJwt();
  if (!a) return null;
  var b = parseClaims(a);
  return b ? b : null;
}
export function parseClaims(a) {
  if (!a) return null;
  var b = a.split(".")[1];
  if (!b) return null;
  var c = null;

  try {
    c = JSON.parse(atob(sanitizeBase64(b)));
  } catch (a) {
    return c;
  }

  return c;
}
export function sanitizeBase64(a) {
  return a.replace(/-/g, "+").replace(/_/g, "/");
}