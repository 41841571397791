"use strict";

var _taggedTemplateLiteral = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

var _templateObject;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("../../compile/codegen");

var util_1 = require("../../compile/util");

var error = {
  message: "property name must be valid",
  params: function params(_ref) {
    var _params = _ref.params;
    return codegen_1._(_templateObject || (_templateObject = _taggedTemplateLiteral(["{propertyName: ", "}"])), _params.propertyName);
  }
};
var def = {
  keyword: "propertyNames",
  type: "object",
  schemaType: ["object", "boolean"],
  error: error,
  code: function code(cxt) {
    var gen = cxt.gen,
        schema = cxt.schema,
        data = cxt.data,
        it = cxt.it;
    if (util_1.alwaysValidSchema(it, schema)) return;
    var valid = gen.name("valid");
    gen.forIn("key", data, function (key) {
      cxt.setParams({
        propertyName: key
      });
      cxt.subschema({
        keyword: "propertyNames",
        data: key,
        dataTypes: ["string"],
        propertyName: key,
        compositeRule: true
      }, valid);
      gen.if(codegen_1.not(valid), function () {
        cxt.error(true);
        if (!it.allErrors) gen.break();
      });
    });
    cxt.ok(valid);
  }
};
exports.default = def;