import { __makeTemplateObject } from "tslib";
import classnames from "classnames";
import { darken } from "polished";
import { deprecatedCss, ShelfIcon } from "../../common-elements/";
import styled from "../../styled-components";
export var OperationBadge = styled.span.attrs(function (n) {
  return {
    className: "operation-type " + n.type
  };
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n  background-color: #333;\n  border-radius: ", ";\n  width: 36px;\n  height: 14px;\n  text-transform: uppercase;\n  text-align: center;\n  margin-right: 6px;\n\n  &.get {\n    background-color: ", ";\n  }\n  &.post {\n    background-color: ", ";\n  }\n  &.put {\n    background-color: ", ";\n  }\n  &.options {\n    background-color: ", ";\n  }\n  &.patch {\n    background-color: ", ";\n  }\n  &.delete {\n    background-color: ", ";\n  }\n  &.basic {\n    background-color: ", ";\n  }\n  &.link {\n    background-color: ", ";\n  }\n  &.head {\n    background-color: ", ";\n  }\n  &.hook {\n    background-color: ", ";\n  }\n"], ["\n  display: inline-block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n  background-color: #333;\n  border-radius: ", ";\n  width: 36px;\n  height: 14px;\n  text-transform: uppercase;\n  text-align: center;\n  margin-right: 6px;\n\n  &.get {\n    background-color: ", ";\n  }\n  &.post {\n    background-color: ", ";\n  }\n  &.put {\n    background-color: ", ";\n  }\n  &.options {\n    background-color: ", ";\n  }\n  &.patch {\n    background-color: ", ";\n  }\n  &.delete {\n    background-color: ", ";\n  }\n  &.basic {\n    background-color: ", ";\n  }\n  &.link {\n    background-color: ", ";\n  }\n  &.head {\n    background-color: ", ";\n  }\n  &.hook {\n    background-color: ", ";\n  }\n"])), function (n) {
  return n.theme.components.httpBadges.sizes.small.fontSize;
}, function (n) {
  return n.theme.components.httpBadges.fontFamily;
}, function (n) {
  return n.theme.components.httpBadges.fontWeight;
}, function (n) {
  return n.theme.components.httpBadges.sizes.small.lineHeight;
}, function (n) {
  return n.theme.components.httpBadges.color;
}, function (n) {
  return n.theme.components.httpBadges.borderRadius;
}, function (n) {
  return n.theme.colors.http.get;
}, function (n) {
  return n.theme.colors.http.post;
}, function (n) {
  return n.theme.colors.http.put;
}, function (n) {
  return n.theme.colors.http.options;
}, function (n) {
  return n.theme.colors.http.patch;
}, function (n) {
  return n.theme.colors.http.delete;
}, function (n) {
  return n.theme.colors.http.basic;
}, function (n) {
  return n.theme.colors.http.link;
}, function (n) {
  return n.theme.colors.http.head;
}, function (n) {
  return n.theme.colors.primary.main;
});
export var MenuItemUl = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n\n  & & {\n    font-size: 14px;\n  }\n\n  ", ";\n"], ["\n  margin: 0;\n  padding: 0;\n\n  & & {\n    font-size: 14px;\n  }\n\n  ", ";\n"])), function (n) {
  return n.expanded ? "" : "display: none;";
});
export var MenuItemLi = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  list-style: none inside none;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding: 0;\n  ", ";\n"], ["\n  list-style: none inside none;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding: 0;\n  ", ";\n"])), function (n) {
  return 0 === n.depth ? "margin-top: 10px" : "";
});
export var MenuItemLabel = styled.label.attrs(function (n) {
  return {
    role: "menuitem",
    className: classnames("-depth" + n.depth, {
      active: n.active
    })
  };
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n  margin: 1px 0 0 16px;\n  display: flex;\n  text-decoration: none;\n  line-height: 15px;\n\n  align-items: ", ";\n\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  border-top-left-radius: ", ";\n  border-bottom-left-radius: ", ";\n  background-color: ", ";\n  pointer-events: ", ";\n\n  color: ", ";\n\n  margin-left: ", ";\n\n  padding-top: ", "px;\n  padding-right: ", "px;\n  padding-bottom: ", "px;\n  margin-bottom: 1px; // hardcoded for now\n\n  padding-left: ", "px;\n\n  ", ";\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n    transition: background-color 0.3s, color 0.3s;\n  }\n\n  ", " {\n    height: ", ";\n    width: ", ";\n    fill: ", ";\n    margin-right: ", "px;\n  }\n"], ["\n  cursor: pointer;\n  position: relative;\n  margin: 1px 0 0 16px;\n  display: flex;\n  text-decoration: none;\n  line-height: 15px;\n\n  align-items: ", ";\n\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n\n  border-top-left-radius: ", ";\n  border-bottom-left-radius: ", ";\n  background-color: ", ";\n  pointer-events: ", ";\n\n  color: ", ";\n\n  margin-left: ", ";\n\n  padding-top: ", "px;\n  padding-right: ", "px;\n  padding-bottom: ", "px;\n  margin-bottom: 1px; // hardcoded for now\n\n  padding-left: ", "px;\n\n  ", ";\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n    transition: background-color 0.3s, color 0.3s;\n  }\n\n  ", " {\n    height: ", ";\n    width: ", ";\n    fill: ", ";\n    margin-right: ", "px;\n  }\n"])), function (n) {
  return n.hasChildren ? "center" : "";
}, function (n) {
  return n.theme.sidebar.fontFamily;
}, function (n) {
  return n.theme.sidebar.fontSize;
}, function (n) {
  var e = n.theme;
  return 0 === n.depth ? e.typography.fontWeightBold : e.typography.fontWeightRegular;
}, function (n) {
  return n.theme.sidebar.borderRadius;
}, function (n) {
  return n.theme.sidebar.borderRadius;
}, function (n) {
  var e = n.active,
      t = n.theme;
  return e ? t.sidebar.activeBgColor : "";
}, function (n) {
  return 0 === n.depth ? "none" : "auto";
}, function (n) {
  var e = n.active,
      t = n.theme;
  return e ? t.sidebar.activeTextColor : t.sidebar.textColor;
}, function (n) {
  var e = n.depth,
      t = n.theme;
  return t.sidebar.spacing.offsetLeft + (e > 0 ? e - 1 : e) * t.sidebar.spacing.offsetNesting + "px";
}, function (n) {
  return n.theme.sidebar.spacing.paddingVertical;
}, function (n) {
  return n.theme.sidebar.spacing.paddingHorizontal;
}, function (n) {
  return n.theme.sidebar.spacing.paddingVertical;
}, function (n) {
  var e = n.theme,
      t = n.hasChildren,
      o = n.depth;
  return t && 0 !== o ? e.sidebar.spacing.paddingHorizontal : e.sidebar.spacing.paddingHorizontal + e.sidebar.caretSize + .5 * e.sidebar.spacing.unit;
}, function (n) {
  return n.deprecated && deprecatedCss || "";
}, function (n) {
  return n.theme.sidebar.activeBgColor;
}, function (n) {
  return n.theme.sidebar.activeTextColor;
}, ShelfIcon, function (n) {
  return n.theme.sidebar.caretSize;
}, function (n) {
  return n.theme.sidebar.caretSize;
}, function (n) {
  return n.theme.sidebar.caretColor;
}, function (n) {
  return n.theme.sidebar.spacing.unit / 2;
});
export var MenuItemLink = MenuItemLabel.withComponent("a");
export var MenuItemTitle = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-block;\n  vertical-align: middle;\n  width: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 15px;\n"], ["\n  display: inline-block;\n  vertical-align: middle;\n  width: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 15px;\n"])), function (n) {
  return n.width ? n.width : "auto";
});
export var RedocAttribution = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (n) {
  var e = n.theme;
  return "\n  font-size: 0.8em;\n  margin-top: " + 2 * e.spacing.unit + "px;\n  padding: 0 " + 4 * e.spacing.unit + "px;\n  text-align: left;\n\n  opacity: 0.7;\n\n  a,\n  a:visited,\n  a:hover {\n    color: " + e.sidebar.textColor + " !important;\n    border-top: 1px solid " + darken(.1, e.sidebar.backgroundColor) + ";\n    padding: " + e.spacing.unit + "px 0;\n    display: block;\n  }\n";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;