"use strict";

var _toConsumableArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/toConsumableArray");

var _slicedToArray = require("/data/trilogy-group/totogi-developer-portal/node_modules/@babel/runtime/helpers/slicedToArray");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAstNodeByPointer = exports.getLineColLocation = exports.getCodeframe = void 0;

var colorette_1 = require("colorette");

var yamlAst = require("yaml-ast-parser");

var ref_utils_1 = require("../ref-utils");

var MAX_LINE_LENGTH = 150;
var MAX_CODEFRAME_LINES = 3; // TODO: temporary

function parsePointer(pointer) {
  return pointer.substr(2).split('/').map(ref_utils_1.unescapePointer);
}

function getCodeframe(location, color) {
  colorette_1.options.enabled = color;
  var start = location.start,
      _location$end = location.end,
      end = _location$end === void 0 ? {
    line: start.line,
    col: start.col + 1
  } : _location$end,
      source = location.source;
  var lines = source.getLines();
  var startLineNum = start.line;
  var endLineNum = Math.max(Math.min(end.line, lines.length), start.line);
  var skipLines = Math.max(endLineNum - startLineNum - MAX_CODEFRAME_LINES + 1, 0);
  if (skipLines < 2) skipLines = 0; // do not skip one line
  // Lines specified like this: ["prefix", "string"],

  var prefixedLines = [];
  var currentPad = 0;

  for (var i = startLineNum; i <= endLineNum; i++) {
    if (skipLines > 0 && i >= endLineNum - skipLines) break;
    var line = lines[i - 1] || '';
    if (line !== '') currentPad = padSize(line);
    var startIdx = i === startLineNum ? start.col - 1 : currentPad;
    var endIdx = i === endLineNum ? end.col - 1 : line.length;
    prefixedLines.push(["".concat(i), markLine(line, startIdx, endIdx, colorette_1.red)]);
    if (!color) prefixedLines.push(['', underlineLine(line, startIdx, endIdx)]);
  }

  if (skipLines > 0) {
    prefixedLines.push(["\u2026", "".concat(whitespace(currentPad)).concat(colorette_1.gray("< ".concat(skipLines, " more lines >")))]); // print last line

    prefixedLines.push(["".concat(endLineNum), markLine(lines[endLineNum - 1], -1, end.col - 1, colorette_1.red)]);
    if (!color) prefixedLines.push(['', underlineLine(lines[endLineNum - 1], -1, end.col - 1)]);
  }

  return printPrefixedLines([["".concat(startLineNum - 2), markLine(lines[startLineNum - 1 - 2])], ["".concat(startLineNum - 1), markLine(lines[startLineNum - 1 - 1])]].concat(prefixedLines, [["".concat(endLineNum + 1), markLine(lines[endLineNum - 1 + 1])], ["".concat(endLineNum + 2), markLine(lines[endLineNum - 1 + 2])]]));

  function markLine(line) {
    var startIdx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
    var endIdx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : +Infinity;
    var variant = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : colorette_1.gray;
    if (!color) return line;
    if (!line) return line;

    if (startIdx === -1) {
      startIdx = padSize(line);
    }

    endIdx = Math.min(endIdx, line.length);
    return line.substr(0, startIdx) + variant(line.substring(startIdx, endIdx)) + line.substr(endIdx);
  }
}

exports.getCodeframe = getCodeframe;

function printPrefixedLines(lines) {
  var existingLines = lines.filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        _ = _ref2[0],
        line = _ref2[1];

    return line !== undefined;
  });
  var padLen = Math.max.apply(Math, _toConsumableArray(existingLines.map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 1),
        prefix = _ref4[0];

    return prefix.length;
  })));
  var dedentLen = Math.min.apply(Math, _toConsumableArray(existingLines.map(function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
        _ = _ref6[0],
        line = _ref6[1];

    return line === '' ? Infinity : padSize(line);
  })));
  return existingLines.map(function (_ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
        prefix = _ref8[0],
        line = _ref8[1];

    return colorette_1.gray(leftPad(padLen, prefix) + ' |') + (line ? ' ' + limitLineLength(line.substring(dedentLen)) : '');
  }).join('\n');
}

function limitLineLength(line) {
  var maxLen = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : MAX_LINE_LENGTH;
  var overflowLen = line.length - maxLen;

  if (overflowLen > 0) {
    var charsMoreText = colorette_1.gray("...<".concat(overflowLen, " chars>"));
    return line.substring(0, maxLen - charsMoreText.length) + charsMoreText;
  } else {
    return line;
  }
}

function underlineLine(line) {
  var startIdx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
  var endIdx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : +Infinity;

  if (startIdx === -1) {
    startIdx = padSize(line);
  }

  endIdx = Math.min(endIdx, line.length);
  return whitespace(startIdx) + '^'.repeat(Math.max(endIdx - startIdx, 1));
}

function whitespace(len) {
  return ' '.repeat(len);
}

function leftPad(len, str) {
  return whitespace(len - str.length) + str;
}

function padSize(line) {
  for (var i = 0; i < line.length; i++) {
    if (line[i] !== ' ') return i;
  }

  return line.length;
}

function getLineColLocation(location) {
  var _a, _b;

  if (location.pointer === undefined) return location;
  var source = location.source,
      pointer = location.pointer,
      reportOnKey = location.reportOnKey;
  var ast = source.getAst(yamlAst.safeLoad);
  var astNode = getAstNodeByPointer(ast, pointer, !!reportOnKey);
  return Object.assign(Object.assign(Object.assign({}, location), {
    pointer: undefined
  }), positionsToLoc(source.body, (_a = astNode === null || astNode === void 0 ? void 0 : astNode.startPosition) !== null && _a !== void 0 ? _a : 1, (_b = astNode === null || astNode === void 0 ? void 0 : astNode.endPosition) !== null && _b !== void 0 ? _b : 1));
}

exports.getLineColLocation = getLineColLocation;

function positionsToLoc(source, startPos, endPos) {
  var currentLine = 1;
  var currentCol = 1;
  var start = {
    line: 1,
    col: 1
  };

  for (var i = 0; i < endPos - 1; i++) {
    if (i === startPos - 1) {
      start = {
        line: currentLine,
        col: currentCol + 1
      };
    }

    if (source[i] === '\n') {
      currentLine++;
      currentCol = 1;

      if (i === startPos - 1) {
        start = {
          line: currentLine,
          col: currentCol
        };
      }

      if (source[i + 1] === '\r') i++; // TODO: test it

      continue;
    }

    currentCol++;
  }

  var end = startPos === endPos ? Object.assign({}, start) : {
    line: currentLine,
    col: currentCol + 1
  };
  return {
    start: start,
    end: end
  };
}

function getAstNodeByPointer(root, pointer, reportOnKey) {
  var pointerSegments = parsePointer(pointer);

  if (root === undefined) {
    return undefined;
  }

  var currentNode = root;

  var _iterator = _createForOfIteratorHelper(pointerSegments),
      _step;

  try {
    var _loop = function _loop() {
      var key = _step.value;

      if (currentNode.kind === yamlAst.Kind.MAP) {
        var mapping = currentNode.mappings.find(function (m) {
          return m.key.value === key;
        });
        if (!(mapping === null || mapping === void 0 ? void 0 : mapping.value)) return "break";
        currentNode = mapping === null || mapping === void 0 ? void 0 : mapping.value;
      } else if (currentNode.kind === yamlAst.Kind.SEQ) {
        var elem = currentNode.items[parseInt(key, 10)];
        if (!elem) return "break";
        currentNode = elem;
      }
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _ret = _loop();

      if (_ret === "break") break;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  if (!reportOnKey) {
    return currentNode;
  } else {
    var parent = currentNode.parent;
    if (!parent) return currentNode;

    if (parent.kind === yamlAst.Kind.SEQ) {
      return currentNode;
    } else if (parent.kind === yamlAst.Kind.MAPPING) {
      return parent.key;
    } else {
      return currentNode;
    }
  }
}

exports.getAstNodeByPointer = getAstNodeByPointer;