import { __assign, __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { OneOfButton as StyledOneOfButton, OneOfList, OneOfWrap } from "../../common-elements/schema";
import { Schema } from "./Schema";

var OneOfButton = function (e) {
  function t() {
    var t = null !== e && e.apply(this, arguments) || this;
    return Object.defineProperty(t, "activateOneOf", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        t.props.schema.activateOneOf(t.props.idx);
      }
    }), t;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.idx,
          n = e.schema,
          r = e.subSchema;
      return React.createElement(StyledOneOfButton, {
        active: t === n.activeOneOf,
        onClick: this.activateOneOf
      }, r.title || r.typePrefix + r.displayType);
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { OneOfButton };

var OneOfSchema = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.schema.oneOf,
          n = e.schema;
      return void 0 === t ? null : React.createElement("div", null, React.createElement(OneOfWrap, null, React.createElement("span", null, n.oneOfType, ":"), React.createElement(OneOfList, null, t.map(function (e, t) {
        return React.createElement(OneOfButton, {
          key: e.pointer,
          schema: n,
          subSchema: e,
          idx: t
        });
      }))), React.createElement(Schema, __assign({}, this.props, {
        schema: t[n.activeOneOf]
      })));
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { OneOfSchema };