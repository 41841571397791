import { __decorate } from "tslib";
import { bind, debounce } from "decko";
import { EventEmitter } from "eventemitter3";
import { IS_BROWSER } from "../../redoc-lib";
import { isSameHash, joinWithSeparator } from "./helpers";

var PushStateHistoryService = function () {
  function e(e, t) {
    var i = this;
    Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "multispec", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    }), Object.defineProperty(this, "_emiter", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "basePath", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "markdownItemsPagination", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "pagination", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "emit", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: function value() {
        i._emiter.emit("popstate", i.currentId);
      }
    });
    var r = e.routingBasePath;
    this.markdownItemsPagination = e.markdownItemsPagination, this.pagination = e.pagination, this.basePath = r.endsWith("/") ? r.substring(0, r.length - 1) : r, this.basePath.startsWith("/") && (this.basePath = this.basePath.substring(1)), this._emiter = new EventEmitter(), this.bind();
  }

  return Object.defineProperty(e.prototype, "currentId", {
    get: function get() {
      if (!IS_BROWSER) return "";
      var e = decodeURIComponent(window.location.hash.substr(1)),
          t = window.location.pathname.substring(1);
      return t.startsWith(this.basePath + "/") && (t = t.substring(this.basePath.length + 1)), t.endsWith("/") && (t = t.substring(0, t.length - 1)), e && e.startsWith("operation/") ? joinWithSeparator(t, e, "/") : t ? "item" === this.pagination && e.startsWith(this.options.deepLinkPrefix) ? joinWithSeparator(t, e, this.options.deepLinkPrefix) : joinWithSeparator(t, e, "/") : e;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "makeUrl", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      var i,
          r = "";
      return IS_BROWSER && (r = window.location.search), (i = e.startsWith("/") ? "/" + joinWithSeparator(this.basePath, e.substring(1), "/") : e) + r ? joinWithSeparator(i + r, t, "#") : "#" + t;
    }
  }), Object.defineProperty(e.prototype, "generateDeepLink", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      return t ? t + ("item" === this.options.pagination ? "#" : "") + this.options.deepLinkPrefix + e : null;
    }
  }), Object.defineProperty(e.prototype, "linkForId", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      if (void 0 === e && (e = ""), "" === e) return this.makeUrl("/", "");
      var t = "";

      if (this.multispec && (t = "/" + e.split("/")[0], e = e.split("/").slice(1).join("/")), e.startsWith("operation/")) {
        if (this.multispec && IS_BROWSER) {
          var i = window.location.pathname.substring(1);
          i.startsWith(this.basePath) && (i = i.substring(this.basePath.length)).startsWith("/") && (i = i.substring(1));
          var r = "/" + i.split("/")[0];
          return t === r ? this.makeUrl("", e) : this.makeUrl(i.replace(r, t), e);
        }

        return this.makeUrl("", e);
      }

      var n,
          a = t,
          o = "";

      if (e.startsWith("section/") || !e) {
        var s = (e || "").split("/")[1];
        s && "section" === this.markdownItemsPagination ? (a = t + "/section/" + s, o = e) : (a = t + "/", o = e);
      }

      if (e.startsWith("group/")) {
        var l = e.match(/group\/(.*)\/(paths\/.*\/\w+|operation\/[^/]*)/);
        if (l) a = t + "/group/" + l[1], o = l[2] || "";else a = t + "/" + e, o = "";
      }

      "section" === this.pagination && e.startsWith("tag/") && (a = t + "/" + (n = e.split("/")).slice(0, 2).join("/"), o = n.slice(2).join("/"));
      "item" === this.pagination && e.startsWith("tag/") && (a = t + "/" + e, "section" === (n = e.split("/"))[2] && (a = t + "/" + n.slice(0, 2).join("/"), o = n.slice(2).join("/")));
      return this.makeUrl(a, o);
    }
  }), Object.defineProperty(e.prototype, "subscribe", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      var t = this._emiter.addListener("popstate", e);

      return function () {
        return t.removeListener("popstate", e);
      };
    }
  }), Object.defineProperty(e.prototype, "bind", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      IS_BROWSER && window.addEventListener("popstate", this.emit, !1);
    }
  }), Object.defineProperty(e.prototype, "dispose", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      IS_BROWSER && window.removeEventListener("popstate", this.emit);
    }
  }), Object.defineProperty(e.prototype, "replace", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      if (void 0 === t && (t = !1), t) return this.replaceDebounced(e);
      this.replaceNow(e, t);
    }
  }), Object.defineProperty(e.prototype, "replaceForField", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      if (void 0 === t && (t = !1), IS_BROWSER && e) {
        var i,
            r = new URL(e, window.location.href);
        i = r, t && window.history.replaceState(null, "", i), window.history.pushState(null, "", i), this.emit();
      }
    }
  }), Object.defineProperty(e.prototype, "replaceNow", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e, t) {
      if (void 0 === t && (t = !1), IS_BROWSER && null != e && !isSameHash(e, this.currentId)) {
        var i = new URL(this.linkForId(e), window.location.href);
        t ? IS_BROWSER && window.history.replaceState(null, "", i.href) : IS_BROWSER && (window.history.pushState(null, "", i.href), this.emit());
      }
    }
  }), Object.defineProperty(e.prototype, "replaceDebounced", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return this.replaceNow(e, !0);
    }
  }), __decorate([bind], e.prototype, "replace", null), __decorate([bind], e.prototype, "replaceForField", null), __decorate([bind, debounce], e.prototype, "replaceDebounced", null), e;
}();

export { PushStateHistoryService };