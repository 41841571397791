import { __makeTemplateObject } from "tslib";
import * as React from "react";
import { styled, mediaCSS, OptionsContext, MenuItem, MenuItemPath } from "../../redoc-lib";
var templateObject_1,
    templateObject_2,
    ResultMeta = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 0.8em;\n  ", "\n"], ["\n  font-size: 0.8em;\n  ", "\n"])), function (e) {
  var t = e.theme;
  return mediaCSS({
    fontFamily: t.typography.code.fontFamily
  });
}),
    Place = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
export var MenuItemPro = function MenuItemPro(e) {
  var t = e.result,
      a = e.active,
      n = e.term,
      l = e.onActivate,
      r = e.history,
      c = React.useContext(OptionsContext),
      o = "path-only" === c.searchMode,
      m = "path-first" === c.sideNavStyle;
  return React.createElement(MenuItem, {
    item: Object.create(t.item, {
      active: {
        value: a
      },
      name: {
        value: o ? t.item.name : ""
      },
      path: {
        value: ""
      },
      search: {
        value: !0
      }
    }),
    onActivate: function onActivate(e, a) {
      if (a.ctrlKey) {
        var n = r.linkForId(t.item.id);
        window.open(n, "_blank");
      } else l(t);
    },
    withoutChildren: !0,
    "data-role": "search:result"
  }, (m || o) && "operation" === t.item.type ? React.createElement(React.Fragment, null, React.createElement(MenuItemPath, {
    title: t.item.path
  }, markString(t.item.path, n))) : markString(t.item.name, n), t.count > 1 ? React.createElement(ResultMeta, null, t.count, " matching ", formatPlaces(t.places)) : 1 === t.count ? React.createElement(ResultMeta, null, "in ", React.createElement(Place, null, t.places[0].place), " ", formatPath(n, t.places[0].paths[0])) : null);
};

function formatPlaces(e) {
  return e.slice().sort(), e.map(function (e) {
    return e.place;
  }).join(" and ");
}

function formatPath(e, t) {
  return t && t.length && t[t.length - 1].toLowerCase().startsWith(e.toLowerCase()) && (t = t.slice(0, t.length - 1)), t && 0 !== t.length ? " → " + t.join(" → ") : "";
}

function markString(e, t) {
  for (var a = new RegExp("(" + t.replace(/[\\^$.*+?()[\]{}|]/g, "\\$&") + ")", "gi"), n = e.split(a), l = 1, r = n.length; l < r; l += 2) {
    n[l] = React.createElement("span", {
      key: l,
      style: {
        backgroundColor: "#ffff03"
      }
    }, n[l]);
  }

  return n;
}