import { __extends } from "tslib";
import * as React from "react";
import { RequestBodyModel, PayloadSamples, Row, MiddlePanel, RightPanel, BodyContent, OperationModel } from "../../redoc-lib";
import { SamplesWrap, FullWidthPanel } from "./styled.components";

var RedocRequestBody = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e = this.props,
          t = e.store,
          n = e.pointer,
          o = e.hideSamples,
          r = new OperationModel(t.definition.parser, {
        pointer: n,
        pathName: "",
        httpVerb: "",
        pathParameters: [],
        pathServers: [],
        isWebhook: !1,
        responses: {}
      }, void 0, t.options, !1),
          a = new RequestBodyModel({
        parser: t.definition.parser,
        infoOrRef: {
          $ref: n
        },
        options: t.options,
        operation: r
      }),
          l = o ? FullWidthPanel : MiddlePanel;
      return React.createElement(Row, {
        layout: t.layout
      }, React.createElement(l, null, a.content && React.createElement(BodyContent, {
        content: a.content,
        description: a.description
      })), !o && React.createElement(RightPanel, null, React.createElement(SamplesWrap, null, a.content && React.createElement(PayloadSamples, {
        content: a.content
      }))) || React.createElement("span", null));
    }
  }), t;
}(React.Component);

export { RedocRequestBody };