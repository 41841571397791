import { __makeTemplateObject } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { Endpoint } from "../../../../components/Endpoint";
import { Badge, H2, MiddlePanel, RightPanel, Row } from "../../common-elements";
import { ShareLink } from "../../common-elements";
import styled from "../../styled-components";
import { CallbacksList } from "../Callbacks";
import { CallbackSamples } from "../CallbackSamples/CallbackSamples";
import { ExternalDocumentation } from "../ExternalDocumentation/ExternalDocumentation";
import { Extensions } from "../Fields/Extensions";
import { Markdown } from "../Markdown/Markdown";
import { Parameters } from "../Parameters/Parameters";
import { RequestSamples } from "../RequestSamples/RequestSamples";
import { ResponsesList } from "../Responses/ResponsesList";
import { ResponseSamples } from "../ResponseSamples/ResponseSamples";
import { SecurityRequirements } from "../SecurityRequirement/SecurityRequirement";
import { StoreContext } from "../StoreBuilder";
import { ScrollableContentPanel } from "../../../../components/Panel";
import { LayoutVariant } from "../../../../services/store-types";
import { RenderHook } from "../helper";
import { creatPanelToggleEvent } from "../RequestSamples/languageSwitchEvent";
import { l } from "../../services/Labels";
var templateObject_1,
    templateObject_2,
    OperationRow = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  backface-visibility: hidden;\n  contain: content;\n  overflow: hidden;\n"], ["\n  backface-visibility: hidden;\n  contain: content;\n  overflow: hidden;\n"]))),
    Description = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: ", "px;\n"], ["\n  margin-bottom: ", "px;\n"])), function (e) {
  return 4 * e.theme.spacing.unit;
});
export var Operation = observer(function (e) {
  var t,
      o,
      a,
      n,
      r = e.operation,
      i = r.name,
      s = r.description,
      c = r.deprecated,
      m = r.externalDocs,
      p = r.isWebhook,
      d = e.operation,
      u = e.layout,
      R = React.useContext(StoreContext),
      E = !(!s && !m),
      b = null == R ? void 0 : R.options.hooks,
      k = null == R ? void 0 : R.options.events,
      v = React.useCallback(function (e) {
    var t,
        o = creatPanelToggleEvent({
      operation: d,
      isExpanded: e,
      panelType: "responses"
    });
    null === (t = null == k ? void 0 : k.panelToggle) || void 0 === t || t.call(k, o);
  }, [k]),
      f = React.useCallback(function (e) {
    var t,
        o = creatPanelToggleEvent({
      operation: d,
      isExpanded: e,
      panelType: "request"
    });
    null === (t = null == k ? void 0 : k.panelToggle) || void 0 === t || t.call(k, o);
  }, [k]);
  return React.createElement(OperationRow, {
    layout: u
  }, React.createElement(MiddlePanel, {
    isStacked: u === LayoutVariant.STACKED
  }, React.createElement(RenderHook, {
    Hook: null == b ? void 0 : b.BeforeOperation,
    props: {
      operation: d
    }
  }), React.createElement(H2, null, React.createElement(ShareLink, {
    to: d.id
  }), React.createElement(RenderHook, {
    Hook: null == b ? void 0 : b.BeforeOperationSummary,
    props: {
      operation: d
    }
  }), i, React.createElement(RenderHook, {
    Hook: null == b ? void 0 : b.AfterOperationSummary,
    props: {
      operation: d
    }
  }), c && React.createElement(Badge, {
    type: "warning"
  }, "Deprecated"), p && React.createElement(Badge, {
    type: "primary"
  }, "Webhook")), (null == R ? void 0 : R.options.pathInMiddlePanel) && !p && React.createElement(Endpoint, {
    operation: d
  }), E && React.createElement(Description, null, void 0 !== s && React.createElement(Markdown, {
    source: s
  }), m && React.createElement(ExternalDocumentation, {
    externalDocs: m
  }), Object.keys(d.extensions || {}).length ? React.createElement(Extensions, {
    extensions: d.extensions
  }) : null), (null === (t = d.security) || void 0 === t ? void 0 : t.length) || (null === (o = d.parameters) || void 0 === o ? void 0 : o.length) || d.requestBody ? React.createElement(ScrollableContentPanel, {
    header: l("request"),
    onToggle: f
  }, React.createElement(SecurityRequirements, {
    securities: d.security
  }), React.createElement(Parameters, {
    parameters: d.parameters,
    body: d.requestBody
  })) : null, (null === (a = d.responses) || void 0 === a ? void 0 : a.length) ? React.createElement(ScrollableContentPanel, {
    header: l("responses"),
    onToggle: v
  }, React.createElement(ResponsesList, {
    responses: d.responses
  })) : null, (null === (n = d.callbacks) || void 0 === n ? void 0 : n.length) ? React.createElement(ScrollableContentPanel, {
    header: l("callbacks")
  }, React.createElement(CallbacksList, {
    callbacks: d.callbacks
  })) : null, React.createElement(RenderHook, {
    Hook: null == b ? void 0 : b.AfterOperation,
    props: {
      operation: d
    }
  })), (null == R ? void 0 : R.showRightPanel) ? React.createElement(RightPanel, {
    "data-cy": "samples-block"
  }, !(null == R ? void 0 : R.options.pathInMiddlePanel) && !p && React.createElement(Endpoint, {
    operation: d
  }), React.createElement(RequestSamples, {
    operation: d
  }), React.createElement(ResponseSamples, {
    operation: d
  }), React.createElement(CallbackSamples, {
    callbacks: d.callbacks
  })) : null);
});