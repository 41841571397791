import { __assign } from "tslib";
import { SECURITY_SCHEMES_SECTION_PREFIX } from "../../utils";

var SecurityRequirementModel = function SecurityRequirementModel(e, i) {
  Object.defineProperty(this, "schemes", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  });
  var n = i.definition.components && i.definition.components.securitySchemes || {};
  this.schemes = Object.keys(e || {}).map(function (t) {
    var r = i.deref(n[t]),
        s = e[t] || [];
    if (r) return __assign(__assign({}, r), {
      id: t,
      sectionId: SECURITY_SCHEMES_SECTION_PREFIX + t,
      scopes: s
    });
    console.warn("Non existing security scheme referenced: " + t + ". Skipping");
  }).filter(function (e) {
    return void 0 !== e;
  });
};

export { SecurityRequirementModel };