import { __decorate } from "tslib";
import { SpecStore } from "../redoc-lib";
import { action, observable, makeObservable } from "mobx";

function assignSpecIdxDeep(e, r, t) {
  e.forEach(function (e) {
    if (e.items && assignSpecIdxDeep(e.items, r, t), e.id = t + "/" + e.id, "operation" === e.type) {
      e.specIdx = r;

      for (var i = 0, n = e.security; i < n.length; i++) {
        for (var o = 0, s = n[i].schemes; o < s.length; o++) {
          var c = s[o];
          c.sectionId = t + "/" + c.sectionId;
        }
      }
    }
  });
}

var VersionedSpecStore = function () {
  function e(e, r, t) {
    if (Object.defineProperty(this, "config", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: e
    }), Object.defineProperty(this, "options", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: r
    }), Object.defineProperty(this, "versions", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "activeVersionIdx", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: -1
    }), makeObservable(this), this.versions = [], e.versions) {
      for (var i = 0, n = 0, o = 0, s = e.versions || []; o < s.length; o++) {
        var c = s[o];
        c.isDefault && (n = i);
        var a = new SpecStore(c.spec, void 0, r);
        this.versions.push(a), assignSpecIdxDeep(a.contentItems, i, c.id);

        for (var u = 0, f = a.securitySchemes.schemes; u < f.length; u++) {
          var p = f[u];
          p.sectionId = c.id + "/" + p.sectionId;
        }

        c.id === t && (this.activeVersionIdx = i), i++, c._searchIndexName && (a._searchIndexName = c._searchIndexName);
      }

      -1 === this.activeVersionIdx && (this.activeVersionIdx = n), e.versions[n].isDefault = !0;
    }
  }

  return Object.defineProperty(e.prototype, "versionsInfo", {
    get: function get() {
      return this.config.versions || [];
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "currentVersionId", {
    get: function get() {
      return this.versionsInfo[this.activeVersionIdx] && this.versionsInfo[this.activeVersionIdx].id;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "contentItems", {
    get: function get() {
      return this.versions[this.activeVersionIdx].contentItems;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "securitySchemes", {
    get: function get() {
      return this.versions[this.activeVersionIdx].securitySchemes;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "externalDocs", {
    get: function get() {
      return this.versions[this.activeVersionIdx].externalDocs;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "parser", {
    get: function get() {
      return this.versions[this.activeVersionIdx].parser;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "info", {
    get: function get() {
      return this.versions[this.activeVersionIdx].info;
    },
    enumerable: !1,
    configurable: !0
  }), Object.defineProperty(e.prototype, "changeVersion", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      this.activeVersionIdx = e;
    }
  }), Object.defineProperty(e.prototype, "toJS", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return this.config;
    }
  }), __decorate([observable], e.prototype, "activeVersionIdx", void 0), __decorate([action], e.prototype, "changeVersion", null), e;
}();

export { VersionedSpecStore };