import { __decorate, __extends, __makeTemplateObject } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { Endpoint } from "../../../../components/Endpoint";
import styled from "../../styled-components";
import { ExternalDocumentation } from "../ExternalDocumentation/ExternalDocumentation";
import { Extensions } from "../Fields/Extensions";
import { Markdown } from "../Markdown/Markdown";
import { Parameters } from "../Parameters/Parameters";
import { ResponsesList } from "../Responses/ResponsesList";
import { SecurityRequirements } from "../SecurityRequirement/SecurityRequirement";
import { ContentPanel } from "../../../../components/Panel";
import { l } from "../../services/Labels";

var CallbackDetails = function (e) {
  function t() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e,
          t,
          n,
          r = this.props.operation,
          o = r.description,
          a = r.externalDocs,
          s = !(!o && !a);
      return React.createElement(React.Fragment, null, s && React.createElement(Description, null, void 0 !== o && React.createElement(Markdown, {
        source: o
      }), a && React.createElement(ExternalDocumentation, {
        externalDocs: a
      })), React.createElement(Endpoint, {
        operation: this.props.operation,
        compact: !0
      }), Object.keys(r.extensions || {}).length ? React.createElement(Extensions, {
        extensions: r.extensions
      }) : null, (null === (e = r.security) || void 0 === e ? void 0 : e.length) || (null === (t = r.parameters) || void 0 === t ? void 0 : t.length) || r.requestBody ? React.createElement(ContentPanel, {
        header: l("request")
      }, React.createElement(SecurityRequirements, {
        securities: r.security
      }), React.createElement(Parameters, {
        parameters: r.parameters,
        body: r.requestBody
      })) : null, (null === (n = r.responses) || void 0 === n ? void 0 : n.length) ? React.createElement(ContentPanel, {
        header: l("callbackResponses")
      }, React.createElement(ResponsesList, {
        responses: r.responses
      })) : null);
    }
  }), t = __decorate([observer], t);
}(React.Component);

export { CallbackDetails };
var templateObject_1,
    Description = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", "px;\n"], ["\n  margin-bottom: ", "px;\n"])), function (e) {
  return 3 * e.theme.spacing.unit;
});