"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoIdenticalPaths = void 0;

var NoIdenticalPaths = function NoIdenticalPaths() {
  return {
    PathMap: function PathMap(pathMap, _ref) {
      var report = _ref.report,
          location = _ref.location;
      var pathsMap = new Map();

      for (var _i = 0, _Object$keys = Object.keys(pathMap); _i < _Object$keys.length; _i++) {
        var pathName = _Object$keys[_i];
        var id = pathName.replace(/{.+?}/g, '{VARIABLE}');
        var existingSamePath = pathsMap.get(id);

        if (existingSamePath) {
          report({
            message: "The path already exists which differs only by path parameter name(s): `".concat(existingSamePath, "` and `").concat(pathName, "`."),
            location: location.child([pathName]).key()
          });
        } else {
          pathsMap.set(id, pathName);
        }
      }
    }
  };
};

exports.NoIdenticalPaths = NoIdenticalPaths;