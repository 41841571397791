import { __awaiter, __generator } from "tslib";
import { useEffect, useRef, useState } from "react";
export function useExternalExample(e, r) {
  var t = this,
      n = useState(!0)[1],
      u = useRef(void 0),
      a = useRef(void 0);
  return a.current !== e && (u.current = void 0), a.current = e, useEffect(function () {
    __awaiter(t, void 0, void 0, function () {
      var t, a;
      return __generator(this, function (s) {
        switch (s.label) {
          case 0:
            n(!0), s.label = 1;

          case 1:
            return s.trys.push([1, 3,, 4]), t = u, [4, e.getExternalValue(r)];

          case 2:
            return t.current = s.sent(), [3, 4];

          case 3:
            return a = s.sent(), u.current = a, [3, 4];

          case 4:
            return n(!1), [2];
        }
      });
    });
  }, [e, r]), u.current;
}