import { __makeTemplateObject } from "tslib";
import styled, { mediaCSS } from "../styled-components";
export var Badge = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 2px 8px;\n  vertical-align: middle;\n  line-height: 1.6;\n  border-radius: ", ";\n  font-size: 12px;\n  margin: 0 0 0 0.5em;\n  ", "\n"], ["\n  display: inline-block;\n  padding: 2px 8px;\n  vertical-align: middle;\n  line-height: 1.6;\n  border-radius: ", ";\n  font-size: 12px;\n  margin: 0 0 0 0.5em;\n  ", "\n"])), function (e) {
  return e.theme.shape.borderRadius;
}, function (e) {
  var n = e.theme,
      t = e.type;
  return mediaCSS({
    backgroundColor: n.colors[t].main,
    color: n.colors[t].contrastText,
    fontSize: n.typography.code.fontSize,
    fontWeight: n.typography.fontWeightBold
  });
});
var templateObject_1;