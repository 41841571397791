import { __assign } from "tslib";
import { parse as parseQueryString, stringify as stringifyQueryString } from "query-string";
import * as Sampler from "openapi-sampler";
import merge from "deepmerge";
import { serializeParameterValue } from "../../redoc-lib";
import { getSecurityDetails, getParameterValue } from "../../utils";
import { normalizeMimeType, arrayMerge } from "../utils";
import { HTTPSnippet } from "./httpsnippet";

function mapOperationToHAR(e, a) {
  var t,
      r,
      i,
      n,
      s,
      o = a.exampleName,
      l = void 0 === o ? "" : o,
      u = a.skipOptionalParameters,
      p = void 0 !== u && u,
      c = a.withOAuth2Call,
      d = void 0 !== c && c,
      m = a.spec,
      h = a.generatedPayloadSamplesMaxDepth,
      f = void 0 === h ? 8 : h,
      v = a.pathParams,
      g = void 0 === v ? {} : v,
      y = a.properties,
      O = void 0 === y ? {} : y,
      _ = getSecurityData(null === (t = e.security) || void 0 === t ? void 0 : t[0], d),
      S = _.securityHeaders,
      T = _.securityCookies,
      w = _.securityQueries,
      C = _.securityOAuth2ExtraCalls,
      P = _.basicAuth,
      b = null === (i = null === (r = e.requestBody) || void 0 === r ? void 0 : r.content) || void 0 === i ? void 0 : i.active,
      A = {
    skipNonRequired: p,
    skipReadOnly: !0,
    maxSampleDepth: f
  },
      x = !1,
      E = e.parameters.filter(function (e) {
    return "header" === e.in;
  }).filter(function (e) {
    return !(p && !e.required);
  }).map(function (e) {
    return {
      name: e.name,
      value: getParameterValue("header", e.name) || serializeParameterValue(e, Sampler.sample(e.schema.rawSchema, A, m))
    };
  }).map(function (e) {
    return (null == b ? void 0 : b.name) && "content-type" === e.name.toLowerCase() && (x = !0, e.value = b.name), e;
  }).concat(S);

  !x && (null == b ? void 0 : b.name) && E.unshift({
    name: "Content-Type",
    value: null == b ? void 0 : b.name
  });
  var k = e.parameters.filter(function (e) {
    return "cookie" === e.in;
  }).filter(function (e) {
    return !(p && !e.required);
  }).map(function (e) {
    return parseQueryString(serializeParameterValue(e, Sampler.sample(e.schema.rawSchema, A, m)));
  }).reduce(function (e, a) {
    for (var t = 0, r = Object.entries(a); t < r.length; t++) {
      var i = r[t],
          n = i[0],
          s = i[1];
      e.push({
        name: n,
        value: String(s)
      });
    }

    return e;
  }, []).concat(T),
      H = e.parameters.filter(function (e) {
    return "query" === e.in;
  }).filter(function (e) {
    return !(p && !e.required);
  }).map(function (e) {
    return parseQueryString(serializeParameterValue(e, Sampler.sample(e.schema.rawSchema, A, m)));
  }).reduce(function (e, a) {
    for (var t = 0, r = Object.entries(a); t < r.length; t++) {
      var i = r[t],
          n = i[0],
          s = i[1];
      e.push({
        name: n,
        value: String(s)
      });
    }

    return e;
  }, []).concat(w),
      R = (null === (n = e.activeServer) || void 0 === n ? void 0 : n.url.replace(/\/$/, "")) + "/" + e.path.replace(/^\//, ""),
      z = e.parameters.filter(function (e) {
    return "path" === e.in;
  }).reduce(function (e, a) {
    var t = a.in,
        r = a.name;
    return e[r] = g[r] || getParameterValue(t, r), e;
  }, {}),
      D = null === (s = null == b ? void 0 : b.examples) || void 0 === s ? void 0 : s[l],
      j = (null == b ? void 0 : b.examples) || {},
      N = Object.values(j)[0],
      q = (null == D ? void 0 : D.value) || (null == N ? void 0 : N.value) || (null == b ? void 0 : b.schema) && Sampler.sample(null == b ? void 0 : b.schema.rawSchema, A, m),
      V = q && "object" == typeof q && merge(q, O, {
    arrayMerge: arrayMerge
  }),
      U = null == b ? void 0 : b.name.toLowerCase(),
      I = "",
      Y = [];

  switch (normalizeMimeType(U)) {
    case "application/json":
      I = JSON.stringify(V);
      break;

    case "application/x-www-form-urlencoded":
    case "multipart/form-data":
      I = stringifyQueryString(V), Y = objectToHarParams(V);
      break;

    default:
      I = String(q || "");
  }

  var M = I ? {
    mimeType: (null == b ? void 0 : b.name) || "application/octet-stream",
    text: I,
    params: Y
  } : void 0;
  return {
    method: e.httpVerb,
    url: R,
    httpVersion: "HTTP/1.1",
    cookies: k,
    headers: E,
    queryString: H,
    postData: M,
    headersSize: -1,
    bodySize: -1,
    securityOAuth2ExtraCalls: C,
    basicAuth: P,
    pathParameters: z,
    serverVariables: e.activeServer.variables
  };
}

var defaultOptions = {
  withImports: !0,
  withComments: !1
},
    langToSnippetConfig = {
  curl: {
    code: "shell",
    defaultTarget: "curl",
    defaultOptions: __assign(__assign({}, defaultOptions), {
      short: !0
    })
  },
  JavaScript: {
    code: "javascript",
    defaultTarget: "fetch",
    defaultOptions: __assign(__assign({}, defaultOptions), {
      withImports: !1
    })
  },
  "Node.js": {
    code: "node",
    defaultTarget: "fetch",
    defaultOptions: __assign({}, defaultOptions)
  },
  Python: {
    code: "python",
    defaultTarget: "requests",
    defaultOptions: __assign({}, defaultOptions)
  },
  "Java8+Apache": {
    code: "java8",
    defaultTarget: "apachehttp",
    defaultOptions: __assign({}, defaultOptions)
  },
  Java: {
    code: "java",
    defaultTarget: "httpclient",
    defaultOptions: __assign({}, defaultOptions)
  },
  "C#": {
    code: "csharp",
    defaultTarget: "httpclient",
    defaultOptions: __assign({}, defaultOptions)
  },
  Go: {
    code: "go",
    defaultTarget: "http.DefaultClient",
    defaultOptions: __assign({}, defaultOptions)
  },
  PHP: {
    code: "php",
    defaultTarget: "curl",
    defaultOptions: __assign({}, defaultOptions)
  },
  Ruby: {
    code: "ruby",
    defaultTarget: "net::http",
    defaultOptions: __assign({}, defaultOptions)
  }
};
export function getCodeSample(e) {
  var a = e.lang,
      t = e.operation,
      r = e.exampleName,
      i = e.pathParams,
      n = e.properties,
      s = e.options,
      o = void 0 === s ? {} : s;

  try {
    var l = mapOperationToHAR(t, {
      exampleName: r,
      pathParams: i,
      properties: n,
      skipOptionalParameters: o.skipOptionalParameters,
      withOAuth2Call: o.withOAuth2Call,
      spec: o.spec,
      generatedPayloadSamplesMaxDepth: o.generatedPayloadSamplesMaxDepth
    }),
        u = new HTTPSnippet(l);
    return langToSnippetConfig[a] ? u.convert(langToSnippetConfig[a].code, langToSnippetConfig[a].defaultTarget, __assign(__assign({}, langToSnippetConfig[a].defaultOptions), o)) : "Language is not supported.";
  } catch (e) {
    return console.error(e), "Failed to generate code sample.";
  }
}

function getSecurityData(e, a) {
  for (var t, r, i, n, s = {
    securityHeaders: [],
    securityCookies: [],
    securityQueries: [],
    securityOAuth2ExtraCalls: [],
    basicAuth: void 0
  }, o = 0, l = (null == e ? void 0 : e.schemes) || []; o < l.length; o++) {
    var u = l[o],
        p = getSecurityDetails(u.id);

    switch (u.type) {
      case "openIdConnect":
        null === (t = s.securityHeaders) || void 0 === t || t.push({
          name: "Authorization",
          value: "Bearer <YOUR_TOKEN_HERE>"
        });
        break;

      case "oauth2":
        var c = p.token ? (p.token.token_type || "Bearer") + " " + p.token.access_token : "Bearer <YOUR_TOKEN_HERE>";
        u.flows.clientCredentials && a ? s.securityOAuth2ExtraCalls.push(getOAuth2ClientCredsCallHar(u.flows.clientCredentials, u.scopes, p)) : u.flows.password && a && s.securityOAuth2ExtraCalls.push(getOAuth2PasswordCallHar(u.flows.password, u.scopes, p)), null === (r = s.securityHeaders) || void 0 === r || r.push({
          name: "Authorization",
          value: c
        });
        break;

      case "apiKey":
        var d = p.raw || "YOUR_API_KEY_HERE";
        "header" === u.in && (null === (i = s.securityHeaders) || void 0 === i || i.push({
          name: u.name,
          value: d
        })), "cookie" === u.in && s.securityCookies.push({
          name: u.name,
          value: d
        }), "query" === u.in && s.securityQueries.push({
          name: u.name,
          value: d
        });
        break;

      case "http":
        "basic" === u.scheme ? s.basicAuth = {
          username: p.username || "<username>",
          password: p.password || "<password>"
        } : null === (n = s.securityHeaders) || void 0 === n || n.push({
          name: "Authorization",
          value: capitalizeFirst(u.scheme || "bearer") + " <YOUR_" + (u.bearerFormat || "TOKEN") + "_HERE>"
        });
    }
  }

  return s;
}

function getOAuth2PasswordCallHar(e, a, t) {
  return {
    method: "POST",
    url: e.tokenUrl,
    httpVersion: "HTTP/1.1",
    headers: [{
      name: "Content-Type",
      value: "application/x-www-form-urlencoded"
    }, {
      name: "Accept",
      value: "application/json"
    }],
    queryString: [],
    postData: {
      mimeType: "application/x-www-form-urlencoded",
      text: "",
      params: [{
        name: "grant_type",
        value: "password"
      }, {
        name: "client_id",
        value: t.client_id || "YOUR_CLIENT_ID"
      }, {
        name: "client_secret",
        value: t.client_secret || "YOUR_CLIENT_SECRET"
      }, {
        name: "username",
        value: t.username || "<username>"
      }, {
        name: "password",
        value: t.password || "<password>"
      }, a.length ? {
        name: "scope",
        value: a.join(" ")
      } : void 0].filter(isDefined)
    },
    cookies: [],
    headersSize: -1,
    bodySize: -1,
    securityOAuth2ExtraCalls: []
  };
}

function getOAuth2ClientCredsCallHar(e, a, t) {
  return {
    method: "POST",
    url: e.tokenUrl,
    httpVersion: "HTTP/1.1",
    headers: [{
      name: "Content-Type",
      value: "application/x-www-form-urlencoded"
    }, {
      name: "Accept",
      value: "application/json"
    }],
    queryString: [],
    postData: {
      mimeType: "application/x-www-form-urlencoded",
      text: "",
      params: [{
        name: "grant_type",
        value: "client_credentials"
      }, {
        name: "client_id",
        value: t.client_id || "YOUR_CLIENT_ID"
      }, {
        name: "client_secret",
        value: t.client_secret || "YOUR_CLIENT_SECRET"
      }, a.length ? {
        name: "scope",
        value: a.join(" ")
      } : void 0].filter(isDefined)
    },
    cookies: [],
    headersSize: -1,
    bodySize: -1,
    securityOAuth2ExtraCalls: []
  };
}

function objectToHarParams(e) {
  for (var a = [], t = 0, r = Object.entries(e); t < r.length; t++) {
    var i = r[t],
        n = i[0],
        s = i[1];
    a.push({
      name: n,
      value: String(s)
    });
  }

  return a;
}

export function isDefined(e) {
  return void 0 !== e;
}
export function capitalizeFirst(e) {
  return e.charAt(0).toUpperCase() + e.slice(1);
}