import { MediaContentModel } from "./MediaContent";
import { getContentWithLegacyExamples } from "../../utils";

var RequestBodyModel = function RequestBodyModel(e) {
  Object.defineProperty(this, "description", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "required", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  }), Object.defineProperty(this, "content", {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: void 0
  });
  var t = e.parser,
      i = e.infoOrRef,
      o = e.options,
      r = e.isEvent,
      n = e.operation,
      a = !r,
      d = t.deref(i);
  this.description = d.description || "", this.required = !!d.required, t.exitRef(i);
  var l = getContentWithLegacyExamples(d);
  void 0 !== l && (this.content = new MediaContentModel(t, l, a, o, {
    operation: n,
    type: "request"
  }));
};

export { RequestBodyModel };