import { __makeTemplateObject } from "tslib";
import { baseButton } from "../../../../components/shared/Button";
import { H1, MiddlePanel } from "../../common-elements";
import styled, { extensionsHook } from "../../styled-components";
var templateObject_1,
    templateObject_2,
    templateObject_3,
    templateObject_4,
    templateObject_5,
    delimiterWidth = 15;
export var ApiInfoWrap = MiddlePanel;
export var ApiHeader = styled(H1)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0;\n  margin-bottom: 0.5em;\n\n  ", ";\n"], ["\n  margin-top: 0;\n  margin-bottom: 0.5em;\n\n  ", ";\n"])), extensionsHook("ApiHeader"));
export var DownloadButton = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n\n  margin-left: 0.5em;\n  text-decoration: none;\n  vertical-align: middle;\n\n  ", ";\n"], ["\n  ", ";\n\n  margin-left: 0.5em;\n  text-decoration: none;\n  vertical-align: middle;\n\n  ", ";\n"])), baseButton, extensionsHook("DownloadButton"));
export var InfoSpan = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &::before {\n    content: '|';\n    display: inline-block;\n    opacity: 0.5;\n    width: ", "px;\n    text-align: center;\n  }\n\n  &:last-child::after {\n    display: none;\n  }\n"], ["\n  &::before {\n    content: '|';\n    display: inline-block;\n    opacity: 0.5;\n    width: ", "px;\n    text-align: center;\n  }\n\n  &:last-child::after {\n    display: none;\n  }\n"])), delimiterWidth);
export var InfoSpanBoxWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: hidden;\n"], ["\n  overflow: hidden;\n"])));
export var InfoSpanBox = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  // hide separator on new lines: idea from https://stackoverflow.com/a/31732902/1749888\n  margin-left: -", "px;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  // hide separator on new lines: idea from https://stackoverflow.com/a/31732902/1749888\n  margin-left: -", "px;\n"])), delimiterWidth);