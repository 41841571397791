"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathNotIncludeQuery = void 0;

var PathNotIncludeQuery = function PathNotIncludeQuery() {
  return {
    PathMap: {
      PathItem: function PathItem(_operation, _ref) {
        var report = _ref.report,
            key = _ref.key;

        if (key.toString().includes('?')) {
          report({
            message: "Don't put query string items in the path, they belong in parameters with `in: query`.",
            location: {
              reportOnKey: true
            }
          });
        }
      }
    }
  };
};

exports.PathNotIncludeQuery = PathNotIncludeQuery;