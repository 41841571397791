import { __makeTemplateObject } from "tslib";
import * as React from "react";
import { darken, getLuminance, lighten } from "polished";
import styled, { mediaCSS } from "../../styled-components";
import { MenuItemLabel } from "../SideMenu/styled.elements";
export var SearchWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 5px 0;\n"], ["\n  padding: 5px 0;\n"])));
export var SearchInput = styled.input.attrs(function () {
  return {
    className: "search-input"
  };
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: calc(100% - ", "px);\n  box-sizing: border-box;\n  margin: 0 ", "px;\n  padding: 5px ", "px 5px\n    ", "px;\n  border: 0;\n  border-bottom: 1px solid\n    ", ";\n  ", "\n  font-weight: bold;\n  font-size: 13px;\n  color: ", ";\n  background-color: transparent;\n  outline: none;\n"], ["\n  width: calc(100% - ", "px);\n  box-sizing: border-box;\n  margin: 0 ", "px;\n  padding: 5px ", "px 5px\n    ", "px;\n  border: 0;\n  border-bottom: 1px solid\n    ", ";\n  ", "\n  font-weight: bold;\n  font-size: 13px;\n  color: ", ";\n  background-color: transparent;\n  outline: none;\n"])), function (n) {
  return 8 * n.theme.spacing.unit;
}, function (n) {
  return 4 * n.theme.spacing.unit;
}, function (n) {
  return 2 * n.theme.spacing.unit;
}, function (n) {
  return 4 * n.theme.spacing.unit;
}, function (n) {
  var e = n.theme;
  return (getLuminance(e.sidebar.backgroundColor) > .5 ? darken : lighten)(.1, e.sidebar.backgroundColor);
}, function (n) {
  var e = n.theme;
  return mediaCSS({
    fontFamily: e.typography.fontFamily
  });
}, function (n) {
  return n.theme.sidebar.textColor;
});
export var SearchIcon = styled(function (n) {
  return React.createElement("svg", {
    className: n.className,
    version: "1.1",
    viewBox: "0 0 1000 1000",
    x: "0px",
    xmlns: "http://www.w3.org/2000/svg",
    y: "0px"
  }, React.createElement("path", {
    d: "M968.2,849.4L667.3,549c83.9-136.5,66.7-317.4-51.7-435.6C477.1-25,252.5-25,113.9,113.4c-138.5,138.3-138.5,362.6,0,501C219.2,730.1,413.2,743,547.6,666.5l301.9,301.4c43.6,43.6,76.9,14.9,104.2-12.4C981,928.3,1011.8,893,968.2,849.4z M524.5,522c-88.9,88.7-233,88.7-321.8,0c-88.9-88.7-88.9-232.6,0-321.3c88.9-88.7,233-88.7,321.8,0C613.4,289.4,613.4,433.3,524.5,522z"
  }));
}).attrs({
  className: "search-icon"
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: ", "px;\n  height: 1.8em;\n  width: 0.9em;\n\n  path {\n    fill: ", ";\n  }\n"], ["\n  position: absolute;\n  left: ", "px;\n  height: 1.8em;\n  width: 0.9em;\n\n  path {\n    fill: ", ";\n  }\n"])), function (n) {
  return 4 * n.theme.spacing.unit;
}, function (n) {
  return n.theme.sidebar.textColor;
});
export var SearchResultsBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", "px 0;\n  background-color: ", "};\n  color: ", ";\n  min-height: 150px;\n  max-height: 250px;\n  border-top: ", "};\n  border-bottom: ", "};\n  margin-top: 10px;\n  line-height: 1.4;\n  font-size: 0.9em;\n\n  li {\n    background-color: inherit;\n  }\n\n  ", " {\n    padding-top: 6px;\n    padding-bottom: 6px;\n\n    &:hover,\n    &.active {\n      background-color: ", ";\n    }\n\n    > svg {\n      display: none;\n    }\n  }\n"], ["\n  padding: ", "px 0;\n  background-color: ", "};\n  color: ", ";\n  min-height: 150px;\n  max-height: 250px;\n  border-top: ", "};\n  border-bottom: ", "};\n  margin-top: 10px;\n  line-height: 1.4;\n  font-size: 0.9em;\n\n  li {\n    background-color: inherit;\n  }\n\n  ", " {\n    padding-top: 6px;\n    padding-bottom: 6px;\n\n    &:hover,\n    &.active {\n      background-color: ", ";\n    }\n\n    > svg {\n      display: none;\n    }\n  }\n"])), function (n) {
  return n.theme.spacing.unit;
}, function (n) {
  var e = n.theme;
  return darken(.05, e.sidebar.backgroundColor);
}, function (n) {
  return n.theme.sidebar.textColor;
}, function (n) {
  return n.theme.colors.border.light;
}, function (n) {
  return n.theme.colors.border.light;
}, MenuItemLabel, function (n) {
  var e = n.theme;
  return darken(.1, e.sidebar.backgroundColor);
});
export var ClearIcon = styled.i(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  display: inline-block;\n  width: ", "px;\n  text-align: center;\n  right: ", "px;\n  line-height: 2em;\n  vertical-align: middle;\n  margin-right: 2px;\n  cursor: pointer;\n  font-style: normal;\n  color: '#666';\n"], ["\n  position: absolute;\n  display: inline-block;\n  width: ", "px;\n  text-align: center;\n  right: ", "px;\n  line-height: 2em;\n  vertical-align: middle;\n  margin-right: 2px;\n  cursor: pointer;\n  font-style: normal;\n  color: '#666';\n"])), function (n) {
  return 2 * n.theme.spacing.unit;
}, function (n) {
  return 4 * n.theme.spacing.unit;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;