import { __makeTemplateObject } from "tslib";
import styled, { mediaCSS } from "../../redoc-lib/src/styled-components";
export var NoSampleLabel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 12px;\n  min-height: 100px;\n  padding: 20px 0;\n  ", "\n"], ["\n  font-size: 12px;\n  min-height: 100px;\n  padding: 20px 0;\n  ", "\n"])), function (e) {
  var t = e.theme;
  return mediaCSS({
    color: t.colors.error.main,
    fontFamily: t.typography.code.fontFamily
  });
});
export var WarnMessage = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 12px;\n  padding-bottom: 10px;\n  ", "\n"], ["\n  font-size: 12px;\n  padding-bottom: 10px;\n  ", "\n"])), function (e) {
  var t = e.theme;
  return mediaCSS({
    color: t.colors.warning.main,
    fontFamily: t.typography.code.fontFamily
  });
});
var templateObject_1, templateObject_2;