import { __extends, __makeTemplateObject } from "tslib";
import * as React from "react";
import styled from "../styled-components";

var templateObject_1,
    ErrorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  color: red;\n"], ["\n  padding: 20px;\n  color: red;\n"]))),
    ErrorBoundary = function (e) {
  function t(t) {
    var r = e.call(this, t) || this;
    return r.state = {
      error: void 0
    }, r;
  }

  return __extends(t, e), Object.defineProperty(t.prototype, "componentDidCatch", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value(e) {
      return this.setState({
        error: e
      }), !1;
    }
  }), Object.defineProperty(t.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      return this.state.error ? React.createElement(ErrorWrapper, null, React.createElement("h1", null, "Something went wrong..."), React.createElement("small", null, " ", this.state.error.message, " "), React.createElement("p", null, React.createElement("details", null, React.createElement("summary", null, "Stack trace"), React.createElement("pre", null, this.state.error.stack))), "undefined" != typeof __REDOC_VERSION__ ? React.createElement(React.Fragment, null, React.createElement("small", null, " References docs version: ", __REDOC_VERSION__), " ", React.createElement("br", null)) : null, "undefined" != typeof __REDOC_REVISION__ ? React.createElement("small", null, " Commit: ", __REDOC_REVISION__) : null) : React.Children.only(this.props.children);
    }
  }), t;
}(React.Component);

export { ErrorBoundary };