import { __assign, __decorate, __extends } from "tslib";
import { observer } from "mobx-react";
import * as React from "react";
import { RecursiveLabel, TypeName, TypeTitle } from "../../common-elements/fields";
import { FieldDetails } from "../Fields/FieldDetails";
import { ArraySchema } from "./ArraySchema";
import { ObjectSchema } from "./ObjectSchema";
import { OneOfSchema } from "./OneOfSchema";
import { l } from "../../services/Labels";

var Schema = function (e) {
  function r() {
    return null !== e && e.apply(this, arguments) || this;
  }

  return __extends(r, e), Object.defineProperty(r.prototype, "render", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function value() {
      var e,
          r = this.props.schema;
      if (!r) return React.createElement("em", null, " Schema not provided ");
      var t = r.type,
          a = r.oneOf,
          i = r.discriminatorProp,
          n = r.isCircular,
          c = (this.props.level || 0) + 1;
      if (n) return React.createElement("div", null, React.createElement(TypeName, null, r.displayType), r.title && React.createElement(TypeTitle, null, " ", r.title, " "), React.createElement(RecursiveLabel, null, l("recursive")));
      if (void 0 !== i) return a && a.length ? React.createElement(ObjectSchema, __assign({}, __assign(__assign({}, this.props), {
        schema: a[r.activeOneOf],
        level: c
      }), {
        discriminator: {
          fieldName: i,
          parentSchema: r
        }
      })) : (console.warn("Looks like you are using discriminator wrong: you don't have any definition inherited from the " + r.title), null);
      if (void 0 !== a) return React.createElement(OneOfSchema, __assign({
        schema: r
      }, this.props));
      var s = Array.isArray(t) ? t : [t];

      if (s.includes("object")) {
        if (null === (e = r.fields) || void 0 === e ? void 0 : e.length) return React.createElement(ObjectSchema, __assign({}, __assign(__assign({}, this.props), {
          level: c
        })));
      } else if (s.includes("array")) return React.createElement(ArraySchema, __assign({}, __assign(__assign({}, this.props), {
        level: c
      })));

      var o = {
        schema: r,
        name: "",
        required: !1,
        description: r.description,
        externalDocs: r.externalDocs,
        deprecated: !1,
        toggle: function toggle() {
          return null;
        },
        expanded: !1
      };
      return React.createElement("div", null, React.createElement(FieldDetails, {
        field: o
      }));
    }
  }), r = __decorate([observer], r);
}(React.Component);

export { Schema };